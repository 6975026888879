import {Component, OnInit} from '@angular/core';
import {HelpPageComponent} from '../../help-page.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-help-page-contact',
    templateUrl: './help-page-contact.component.html',
    styleUrls: ['./help-page-contact.component.scss', '../../helpPageModule.scss']
})
export class HelpPageContactComponent extends HelpPageComponent implements OnInit
{
    constructor(trans: TranslateService)
    {
        super(trans);
    }

    ngOnInit(): void
    {
    }
}
