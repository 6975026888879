import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';


@Component({
  selector: 'app-thing-subcomp-parent',
  templateUrl: './thing-subcomp-parent.component.html',
  styleUrls: ['./thing-subcomp-parent.component.scss']
})
export class ThingSubcompParentComponent extends BaseSubcomp implements OnChanges
{


  ngOnChanges(changes: SimpleChanges): void
  {

  }


}
