<div *ngIf="thing != null">

    <app-thing-banner [items]="[['Motorrolle 1', 'MR1'], ['Motorrolle 2' , 'MR2']]"></app-thing-banner>

    <!--Überschrift-->
    <div class="row">
        <div class="col-lg-12">
            <div class="heading">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-6">
                        <h2>{{thing.displayName + ' ' + location.name + ' - ' + system.name}}</h2>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6">
                        <app-datepicker-popup [thing]="thing"></app-datepicker-popup>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Motorrolle 1 -->
    <div class="row">

        <div class="col-lg-12 subheading">
            <h3 id="MR1">Motorrolle 1</h3>
        </div>

        <app-text-tile class="col-lg-3"
                       heading="Motorrolle 1 aktiv"
                       [error]="thing.HCV_MCC_DATA.MR1_ACTIVE.isError"
                       [datapoint]="thing.HCV_MCC_DATA.MR1_ACTIVE.TimeseriesData.currentDatapoint"
                       [historicValues]="[thing.HCV_MCC_DATA.MR1_ACTIVE.TimeseriesData.values]"
                       [historicLegend]="['Aktiv']">
        </app-text-tile>

        <app-text-tile class="col-lg-3"
                       heading="Sensor 1 beschäftigt"
                       [error]="thing.HCV_MCC_DATA.SENSOR1_BUSY.isError"
                       [datapoint]="thing.HCV_MCC_DATA.SENSOR1_BUSY.TimeseriesData.currentDatapoint"
                       [historicValues]="[thing.HCV_MCC_DATA.SENSOR1_BUSY.TimeseriesData.values]"
                       [historicLegend]="['Sensor beschäftigt']">
        </app-text-tile>

        <app-text-tile class="col-lg-3"
                       heading="Motorrolle 1 Spannung"
                       [error]="thing.HCV_MCC_DATA.MR1_VOLTAGE_STAGE.isError"
                       [datapoint]="thing.HCV_MCC_DATA.MR1_VOLTAGE_STAGE.TimeseriesData.currentDatapoint"
                       [historicValues]="[thing.HCV_MCC_DATA.MR1_VOLTAGE_STAGE.TimeseriesData.values]"
                       [historicLegend]="['Motorrolle Spannung']">
        </app-text-tile>

        <app-text-tile class="col-lg-3"
                       heading="Motorrolle 1 Richtung"
                       [error]="thing.HCV_MCC_DATA.MR1_DIRECTION.isError"
                       [datapoint]="thing.HCV_MCC_DATA.MR1_DIRECTION.TimeseriesData.currentDatapoint"
                       [historicValues]="[thing.HCV_MCC_DATA.MR1_DIRECTION.TimeseriesData.values]"
                       [historicLegend]="['Motorrolle Richtung']">
        </app-text-tile>
    </div>

    <!--Motorrolle 2-->
    <div class="row">

        <div class="col-lg-12 subheading">
            <h3 id="MR2">Motorrolle 2</h3>
        </div>

        <app-text-tile class="col-lg-3"
                       heading="Motorrolle 2 aktiv"
                       [error]="thing.HCV_MCC_DATA.MR2_ACTIVE.isError"
                       [datapoint]="thing.HCV_MCC_DATA.MR2_ACTIVE.TimeseriesData.currentDatapoint"
                       [historicValues]="[thing.HCV_MCC_DATA.MR2_ACTIVE.TimeseriesData.values]"
                       [historicLegend]="['Aktiv']">
        </app-text-tile>

        <app-text-tile class="col-lg-3"
                       heading="Sensor 2 beschäftigt"
                       [error]="thing.HCV_MCC_DATA.SENSOR2_BUSY.isError"
                       [datapoint]="thing.HCV_MCC_DATA.SENSOR2_BUSY.TimeseriesData.currentDatapoint"
                       [historicValues]="[thing.HCV_MCC_DATA.SENSOR2_BUSY.TimeseriesData.values]"
                       [historicLegend]="['Sensor beschäftigt']">
        </app-text-tile>

        <app-text-tile class="col-lg-3"
                       heading="Motorrolle 2 Spannung"
                       [error]="thing.HCV_MCC_DATA.MR2_VOLTAGE_STAGE.isError"
                       [datapoint]="thing.HCV_MCC_DATA.MR2_VOLTAGE_STAGE.TimeseriesData.currentDatapoint"
                       [historicValues]="[thing.HCV_MCC_DATA.MR2_VOLTAGE_STAGE.TimeseriesData.values]"
                       [historicLegend]="['Motorrolle Spannung']">
        </app-text-tile>

        <app-text-tile class="col-lg-3"
                       heading="Motorrolle 2 Richtung"
                       [error]="thing.HCV_MCC_DATA.MR2_DIRECTION.isError"
                       [datapoint]="thing.HCV_MCC_DATA.MR2_DIRECTION.TimeseriesData.currentDatapoint"
                       [historicValues]="[thing.HCV_MCC_DATA.MR2_DIRECTION.TimeseriesData.values]"
                       [historicLegend]="['Motorrolle Richtung']">
        </app-text-tile>
    </div>

    <div class="row gap"></div>
</div>

<app-monitoring-console *ngIf="data.showMonitoring && thing.errorCount > 0"
                        [criticalThingToShowInConsole]="[thing]"></app-monitoring-console>
