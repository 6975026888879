<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>

<div class="container">
    <div>
        <h2>Cheetah Heavy</h2>
        <div>
            <h3 class="subheading" id="ElektrischeLeistung">{{'Elektrische Leistung' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CTH/voltageCurrent_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Spannung: ' | translate}} </strong>{{'Vom Cheetah Heavy' | translate}}</li>
                <li class="list-group-item">
                    <strong>{{'Stromstärke: ' | translate}}</strong>{{'Vom Cheetah Heavy' | translate}}</li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="SummeDerLeistungsdaten">{{'Summe der Leistungsdaten' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CTH//sumOfPower_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Wirkleistung: '| translate}}</strong>{{'Die Wirkleistung ist die Energie, die tatsächlich wirkt und in eine andere Energieform (z.B. Bewegungsenergie) umgewandelt werden kann.'| translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Blindleistung: '| translate}}</strong>{{'Die Blindleistung befindet sich in Transformatoren und Kondensatoren, um Wirkleistung zu übertragen.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Scheinleistung: ' | translate}}</strong>{{'Die Scheinleistung ist die geometrische Summe aus Wirk- und Blindleistung.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Energieverbrauch">{{'Energieverbrauch' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CTH/energyConsumption_{{urlLanguage}}.png"
                 width="100%">
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Distanz">{{'Distanz' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CTH/distance_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Horizontale Strecke: ' | translate}}</strong>{{'Zurückgelegte horizontale Strecke (in km).' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Vertikale Strecke: ' | translate}}</strong>{{'Zurückgelegte vertikale Strecke (in km).' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Statistik">{{'Statistik' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CTH/statistic_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Einlagerungen: ' | translate}}</strong>{{'Anzahl der Einlagerungen' | translate}}</li>
                <li class="list-group-item">
                    <strong>{{'Auslagerungen: ' | translate}}</strong>{{'Anzahl der Auslagerungen' | translate}}</li>
                <li class="list-group-item">
                    <strong>{{'Umlagerungen: ' | translate}}</strong>{{'Anzahl der Umlagerungen' | translate}}</li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Status">{{'Status' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CTH/status_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Betriebart: ' | translate}}</strong>{{'Läuft der RBG manuell oder automatisch?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Erste Fehlernummer: ' | translate}}</strong>{{'Zeigt die erste Fehlernummer.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Betriebsstunden: ' | translate}}</strong>{{'Wie lange ist der RBG schon seit Inbetriebnahme/ Wartung in Betrieb?' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px; margin-bottom: 100px;">
            <h3 class="subheading" id="CmSensorDaten">{{'CM Sensor Daten' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CTH/cmSensor_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px; margin-bottom: 100px;">
                <li class="list-group-item">
                    <strong>{{'CM Sensor: ' | translate}}</strong>{{'Daten zu dem Condition Monitoring Sensor.' | translate}}
                </li>
            </ul>
        </div>
    </div>
</div>
