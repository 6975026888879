<app-group-header [Heading]="'Gut-/Fehl-Lesungen (seit Reset)' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card"
                      [Error]="GoodReads.isError || BadReads.isError"
                      [ErrorLevel]="GoodReads.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[GoodReads.TimeseriesData, BadReads.TimeseriesData]"
                      [ChartType]="'pie'"
                      [ChartOption]="loadOptionsPie"
                      [Legend]="['Gut-Lesungen' | translate, 'Fehl-Lesungen' | translate]"
                      [Info]="'Der rote Bereich („Gut-Lesungen“) gibt an, wie viele Barcodes seit der letzten Ausschaltung gelesen werden konnten. ' +
                       'Der graue Bereich („Fehl-Lesungen“) gibt an, wie viele Barcodes seit der letzten Ausschaltung nicht gelesen werden konnten.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
