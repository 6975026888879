import {Component, Input} from '@angular/core';
import {Tile} from '../tile';

@Component({
    selector: 'app-progress-tile',
    templateUrl: './progress-tile.component.html',
    styleUrls: ['./progress-tile.component.scss',
        '../tile.scss']
})
/**
 * Fortschrittsanzeige Kachel
 */
export class ProgressTileComponent extends Tile
{
    /**
     * Wert
     */
    @Input() value: number;

    /**
     *
     */
    constructor()
    {
        super();
    }
}
