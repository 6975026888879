<app-temperature *ngIf="widget === 'temperature'"
                 [resizeEvent]="resizeEvent"
                 [Temperature]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-temperature>

<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Omnipallet' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Omnipallet' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>


<app-movements *ngIf="widget === 'movements'"
               [resizeEvent]="resizeEvent"
               [Movements]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-movements>

<app-orders *ngIf="widget === 'orders'"
            [resizeEvent]="resizeEvent"
            [Orders]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-orders>

<app-time *ngIf="widget === 'time'"
          [resizeEvent]="resizeEvent"
          [Distance]="Thing.propertySets.get('GENERAL_INFORMATION')"
          [TimeGeneral]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-time>

<app-position *ngIf="widget === 'position'"
              [resizeEvent]="resizeEvent"
              [Position]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-position>

<app-fine-positioning *ngIf="widget === 'fine-position'"
                      [resizeEvent]="resizeEvent"
                      [FinePositioning]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-fine-positioning>

<app-battery *ngIf="widget === 'battery'"
             [resizeEvent]="resizeEvent"
             [ActualData]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-battery>
