import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-sum-of-power',
    templateUrl: './sum-of-power.component.html',
    styleUrls: ['./sum-of-power.component.scss',
        './../card.scss']
})
export class SumOfPowerComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{
    @Input() Currentmeter: PropertySet;

    ActivePower: Property<any>;
    @Input() ActivePowerUnit = 'W';


    ReactivePower: Property<any>;
    @Input() ReactivePowerUnit = 'VAR';

    ApparantPower: Property<any>;
    @Input() ApparentPowerUnit = 'VA';

    constructor()
    {
        super('sum-of-power');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Currentmeter == null) return;

        this.ActivePower = this.Currentmeter.properties.get('SUM_ACTIVE_POWER');
        this.ReactivePower = this.Currentmeter.properties.get('SUM_REACTIVE_POWER');
        this.ApparantPower = this.Currentmeter.properties.get('SUM_APPARENT_POWER');


        if (this.type.toLowerCase() === 'ssagm')
        {
            this.ActivePowerUnit = 'kW';
            this.ReactivePowerUnit = 'kVAR';
            this.ApparentPowerUnit = 'kVA';
        }
    }
}
