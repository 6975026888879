<app-group-header [Heading]="'Testfahrt Status' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Automatischer Start der Testfahrt' | translate"
                      [Error]="AutoStartError"
                      [ErrorLevel]="AutoStartErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AutoStartValue]"
                      [Legend]="['Automatischer Start der Testfahrt' | translate]"
                      [Type]="'boolean'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Manueller Start der Testfahrt' | translate"
                      [Error]="ManualStartError"
                      [ErrorLevel]="ManualStartErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ManualStartValue]"
                      [Legend]="['Manueller Start der Testfahrt' | translate]"
                      [Type]="'boolean'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Testfahrt aktiv' | translate"
                      [Error]="ActiveError"
                      [ErrorLevel]="ActiveErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ActiveValue]"
                      [Legend]="['Testfahrt aktiv' | translate]"
                      [Type]="'boolean'"
                      [resizeEvent]="childResizeEvent"></app-default-card>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Testfahrt zurückgesetzt' | translate"
                      [Error]="ResetError"
                      [ErrorLevel]="ResetErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ResetValue]"
                      [Legend]="['Testfahrt zurückgesetzt' | translate]"
                      [Type]="'boolean'"
                      [resizeEvent]="childResizeEvent"></app-default-card>
</div>
<!--
<div #cardWidth class="card rounded-0">

    <div class="card-header">
        Automatischer Start der Testfahrt
    </div>
    <div class="card-body" [style.fontSize]="fontsize">
        {{AutoStartValue | boolean}}
    </div>
</div>
<div class="card rounded-0">

    <div class="card-header">
        Manueller Start der Testfahrt
    </div>

    <div class="card-body" [style.fontSize]="fontsize">
        {{ManualStartValue | boolean}}
    </div>

</div>
<div class="card rounded-0">
    <div class="card-header">
        Testfahrt aktiv
    </div>

    <div class="card-body" [style.fontSize]="fontsize">
        {{ActiveValue | boolean}}
    </div>

</div>
<div class="card rounded-0">
    <div class="card-header">
        Testfahrt zurückgesetzt
    </div>

    <div class="card-body" [style.fontSize]="fontsize">
        {{ResetValue | boolean}}
    </div>

</div>

</div>
<!--
@Input() AutoStartError: boolean;
@Input() AutoStartValue: boolean;
@Input() AutoStartHistoric: DataPoint<boolean>[][];

@Input() ManualStartError: boolean;
@Input() ManualStartValue: boolean;
@Input() ManualStartHistoric: DataPoint<boolean>[][];

@Input() ActiveError: boolean;
@Input() ActiveValue: boolean;
@Input() ActiveHistoric: DataPoint<boolean>[][];

@Input() ResetError: boolean;
@Input() ResetValue: boolean;
@Input() ResetHistoric: DataPoint<boolean>[][];



<app-text-tile class="col-lg-3"
           [heading]="'Automatischer Start der Testfahrt' | translate"
           [error]="thing.RBG_SPS_RECORDING.STARTTIME_TESTRUN_ACTIVE.isError"
           [directInput]="thing.RBG_SPS_RECORDING.STARTTIME_TESTRUN_ACTIVE.TimeseriesData.currentDatapoint?.value | boolean"
           [historicValues]="[thing.RBG_SPS_RECORDING.STARTTIME_TESTRUN_ACTIVE.TimeseriesData.values | historicBoolean]"
           [historicLegend]="['Ja/Nein' | translate]">
</app-text-tile>

<app-text-tile class="col-lg-3"
           [heading]="'Manueller Start der Testfahrt' | translate"
           [error]="thing.RBG_SPS_RECORDING.START.isError"
           [directInput]="thing.RBG_SPS_RECORDING.START.TimeseriesData.currentDatapoint?.value | boolean"
           [historicValues]="[thing.RBG_SPS_RECORDING.START.TimeseriesData.values | historicBoolean]"
           [historicLegend]="['Ja/Nein' | translate]">
</app-text-tile>

<app-text-tile class="col-lg-3"
           [heading]="'Testfahrt aktiv' | translate"
           [error]="thing.RBG_SPS_RECORDING.ACTIVE.isError"
           [directInput]="thing.RBG_SPS_RECORDING.ACTIVE.TimeseriesData.currentDatapoint?.value | boolean"
           [historicValues]="[thing.RBG_SPS_RECORDING.ACTIVE.TimeseriesData.values | historicBoolean]"
           [historicLegend]="['Ja/Nein' | translate]">
</app-text-tile>

<app-text-tile class="col-lg-3"
           [heading]="'Testfahrt zurückgesetzt' | translate"
           [error]="thing.RBG_SPS_RECORDING.RESET.isError"
           [directInput]="thing.RBG_SPS_RECORDING.RESET.TimeseriesData.currentDatapoint?.value | boolean"
           [historicValues]="[thing.RBG_SPS_RECORDING.RESET.TimeseriesData.values | historicBoolean]"
           [historicLegend]="['Ja/Nein' | translate]">
</app-text-tile>
-->
