<app-current-voltage *ngIf="widget === 'current-voltage'"
                     [resizeEvent]="resizeEvent"
                     [Currentmeter]="Thing.propertySets.get('CURRENTMETER')">
</app-current-voltage>

<app-sum-of-power *ngIf="widget === 'sum-of-power'"
                  [resizeEvent]="resizeEvent"
                  [Currentmeter]="Thing.propertySets.get('CURRENTMETER')">
</app-sum-of-power>

<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="('Darstellung ' + Thing.type) | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="('Darstellung ' + Thing.type) | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>

<app-energy-consumption *ngIf="widget === 'energy-consumption'"
                        [resizeEvent]="resizeEvent"
                        [Energy]="Thing.propertySets.get('ENERGY')">
</app-energy-consumption>

<app-distance *ngIf="widget === 'distance'"
              [resizeEvent]="resizeEvent"
              [HorizontalDistance]="Thing.propertySets.get('HORIZONTAL_DISTANCE')"
              [VerticalDistance]="Thing.propertySets.get('VERTICAL_DISTANCE')"
              [TelescopeDistance]="Thing.propertySets.get('TELESCOPE_DISTANCE')">
</app-distance>

<app-statistic *ngIf="widget === 'statistic'"
               [resizeEvent]="resizeEvent"
               [Statistic]="Thing.propertySets.get('STATISTICS')">
</app-statistic>

<app-status *ngIf="widget === 'status'"
            [resizeEvent]="resizeEvent"
            [Status]="Thing.propertySets.get('STATUS')">
</app-status>

<app-distance-vertical *ngIf="widget === 'distance-vertical'"
                       [resizeEvent]="resizeEvent"
                       [VerticalDistance]="Thing.propertySets.get('VERTICAL_DISTANCE')">
</app-distance-vertical>

<app-roll-in-out *ngIf="widget === 'roll-in-out'"
                 [resizeEvent]="resizeEvent"
                 [Statistic]="Thing.propertySets.get('STATISTICS')">
</app-roll-in-out>

<!--CMS-->
<app-cm-sensors *ngIf="widget === 'cm-lam'"
                [resizeEvent]="resizeEvent"
                [Type]="'LAM'"
                [Environment]="Thing.propertySets.get('DS_ENV')"
                [Rotation]="Thing.propertySets.get('DS_ROT')"
                [AngularVelocity]="Thing.propertySets.get('DS_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('DS_ACL')">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cm-mdw'"
                [resizeEvent]="resizeEvent"
                [Type]="'MDW'"
                [Environment]="Thing.propertySets.get('MDW_ENV')"
                [Rotation]="Thing.propertySets.get('MDW_ROT')"
                [AngularVelocity]="Thing.propertySets.get('MDW_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('MDW_ACL')">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cm-mtop'"
                [resizeEvent]="resizeEvent"
                [Type]="'MTOP'"
                [Environment]="Thing.propertySets.get('MTOP_ENV')"
                [Rotation]="Thing.propertySets.get('MTOP_ROT')"
                [AngularVelocity]="Thing.propertySets.get('MTOP_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('MTOP_ACL')">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cm-drr'"
                [resizeEvent]="resizeEvent"
                [Type]="''"
                [Environment]="Thing.propertySets.get('DRR_ENV')"
                [Rotation]="Thing.propertySets.get('DRR_ROT')"
                [AngularVelocity]="Thing.propertySets.get('DRR_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('DRR_ACL')">
</app-cm-sensors>

<app-cm-sensors-two *ngIf="widget === 'cm-drr-two'"
                    [resizeEvent]="resizeEvent"
                    [Type]="''"
                    [Environment]="Thing.propertySets.get('DRR_ENV')"
                    [Sound]="Thing.propertySets.get('DRR_SOUND')"
                    [AngularVelocity]="Thing.propertySets.get('DRR_ANGVEL')"
                    [Acceleration]="Thing.propertySets.get('DRR_ACL')">
</app-cm-sensors-two>


<app-control-cabinet *ngIf="widget === 'control-cabinet'"
                     [resizeEvent]="resizeEvent"
                     [TemperatureError]="Thing.propertySets.get('CMS_CC_ENV')?.properties.get('TEMPERATURE').isError"
                     [TemperatureErrorLevel]="Thing.propertySets.get('CMS_CC_ENV')?.properties.get('TEMPERATURE').status"
                     [TemperatureValue]="[Thing.propertySets.get('CMS_CC_ENV')?.properties.get('TEMPERATURE').TimeseriesData]">
</app-control-cabinet>

<!--BOT Specific-->
<app-bot-volcurtemp *ngIf="widget === 'bot-volcurtemp'"
                    [resizeEvent]="resizeEvent"
                    [ActualData]="Thing.propertySets.get('ACTUALDATA')">
</app-bot-volcurtemp>

<app-bot-status *ngIf="widget === 'bot-status'"
                [resizeEvent]="resizeEvent"
                [ActualData]="Thing.propertySets.get('ACTUALDATA')">
</app-bot-status>


<!--FTS Specific-->
<app-fts-energy *ngIf="widget === 'fts-energy'"
                [resizeEvent]="resizeEvent"
                [EnergyManagement]="Thing.propertySets.get('ENERGIEMANAGEMENT')">
</app-fts-energy>

<app-fts-chassis *ngIf="widget === 'fts-chassis'"
                 [resizeEvent]="resizeEvent"
                 [Fahrwerk]="Thing.propertySets.get('FAHRWERK')">
</app-fts-chassis>

<app-fts-chassis-km *ngIf="widget === 'fts-chassis-km'"
                    [resizeEvent]="resizeEvent"
                    [Fahrwerk]="Thing.propertySets.get('FAHRWERK')">
</app-fts-chassis-km>

<app-fts-general *ngIf="widget === 'fts-general'"
                 [resizeEvent]="resizeEvent"
                 [FTS_ALLGEMEIN]="Thing.propertySets.get('FTS_ALLGEMEIN')">
</app-fts-general>


<!--Leuze Reader Specific-->
<app-lsc-scan-information *ngIf="widget === 'lsc-scan-information'"
                          [resizeEvent]="resizeEvent"
                          [GeneralInformation]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-lsc-scan-information>

<app-good-bad-reads *ngIf="widget === 'good-bad-reads'"
                    [resizeEvent]="resizeEvent"
                    [GeneralInformation]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-good-bad-reads>

<app-scan-quality *ngIf="widget === 'scan-quality'"
                  [resizeEvent]="resizeEvent"
                  [GeneralInformation]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-scan-quality>

<app-last-scan *ngIf="widget === 'last-scan'"
               [resizeEvent]="resizeEvent"
               [GeneralInformation]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-last-scan>

<!--OLS* Sepcific-->
<app-temperature *ngIf="widget === 'temperature'"
                 [resizeEvent]="resizeEvent"
                 [Temperature]="Thing.propertySets.get('OLS_TEMPERATUR')">
</app-temperature>

<app-error-messages *ngIf="widget === 'error-messages'"
                    [resizeEvent]="resizeEvent"
                    [Status]="Thing.propertySets.get('OLS_SRC_STATUS')">
</app-error-messages>

<app-movements *ngIf="widget === 'movements'"
               [resizeEvent]="resizeEvent"
               [Movements]="Thing.propertySets.get('OLS_MOVEMENTS')">
</app-movements>

<app-orders *ngIf="widget === 'orders'"
            [resizeEvent]="resizeEvent"
            [Orders]="Thing.propertySets.get('OLS_ORDERS')">
</app-orders>

<app-time *ngIf="widget === 'time'"
          [resizeEvent]="resizeEvent"
          [Distance]="Thing.propertySets.get('OLS_DISTANCE')"
          [TimeGeneral]="Thing.propertySets.get('OLS_TIME_GENERAL')">
</app-time>

<app-position *ngIf="widget === 'position'"
              [resizeEvent]="resizeEvent"
              [Position]="Thing.propertySets.get('OLS_POSITION')">
</app-position>

<app-fine-positioning *ngIf="widget === 'fine-position'"
                      [resizeEvent]="resizeEvent"
                      [FinePositioning]="Thing.propertySets.get('OLS_TIME_FINE_POSITIONING')">
</app-fine-positioning>

<app-finger-movements *ngIf="widget === 'finger-movements'"
                      [resizeEvent]="resizeEvent"
                      [Finger11]="Thing.propertySets.get('OLS_FINGER_1_1')"
                      [Finger12]="Thing.propertySets.get('OLS_FINGER_1_2')"
                      [Finger21]="Thing.propertySets.get('OLS_FINGER_2_1')"
                      [Finger22]="Thing.propertySets.get('OLS_FINGER_2_2')"
                      [Finger31]="Thing.propertySets.get('OLS_FINGER_3_1')"
                      [Finger32]="Thing.propertySets.get('OLS_FINGER_3_2')">
</app-finger-movements>

<app-finger-time *ngIf="widget === 'finger-time'"
                 [resizeEvent]="resizeEvent"
                 [Finger11]="Thing.propertySets.get('OLS_FINGER_1_1')"
                 [Finger12]="Thing.propertySets.get('OLS_FINGER_1_2')"
                 [Finger21]="Thing.propertySets.get('OLS_FINGER_2_1')"
                 [Finger22]="Thing.propertySets.get('OLS_FINGER_2_2')"
                 [Finger31]="Thing.propertySets.get('OLS_FINGER_3_1')"
                 [Finger32]="Thing.propertySets.get('OLS_FINGER_3_2')">
</app-finger-time>

<!--PRBG Specific-->
<app-cm-sensors *ngIf="widget === 'cth-cm-ccm'"
                [resizeEvent]="resizeEvent"
                [Type]="'CCM'"
                [Environment]="Thing.propertySets.get('CCM_ENV')"
                [Rotation]="Thing.propertySets.get('CCM_ROT')"
                [AngularVelocity]="Thing.propertySets.get('CCM_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('CCM_ACL')">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cth-cm-lfdown'"
                [resizeEvent]="resizeEvent"
                [Type]="'LFDOWN'"
                [Environment]="Thing.propertySets.get('LFDW_ENV')"
                [Rotation]="Thing.propertySets.get('LFDW_ROT')"
                [AngularVelocity]="Thing.propertySets.get('LFDW_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('LFDW_ACL')">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cth-cm-lftop'"
                [resizeEvent]="resizeEvent"
                [Type]="'LFTOP'"
                [Environment]="Thing.propertySets.get('LFTOP_ENV')"
                [Rotation]="Thing.propertySets.get('LFTOP_ROT')"
                [AngularVelocity]="Thing.propertySets.get('LFTOP_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('LFTOP_ACL')">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cth-cm-mbl'"
                [resizeEvent]="resizeEvent"
                [Type]="'MBL'"
                [Environment]="Thing.propertySets.get('MBL_ENV')"
                [Rotation]="Thing.propertySets.get('MBL_ROT')"
                [AngularVelocity]="Thing.propertySets.get('MBL_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('MBL_ACL')">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cth-cm-smd2'"
                [resizeEvent]="resizeEvent"
                [Type]="'SMD2'"
                [Environment]="Thing.propertySets.get('SMD2_ENV')"
                [Rotation]="Thing.propertySets.get('SMD2_ROT')"
                [AngularVelocity]="Thing.propertySets.get('SMD2_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('SMD2_ACL')">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cth-cm-smd3'"
                [resizeEvent]="resizeEvent"
                [Type]="'SMD3'"
                [Environment]="Thing.propertySets.get('SMD3_ENV')"
                [Rotation]="Thing.propertySets.get('SMD3_ROT')"
                [AngularVelocity]="Thing.propertySets.get('SMD3_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('SMD3_ACL')">
</app-cm-sensors>