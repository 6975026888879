<app-group-header [Heading]="'Statistik' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Einschleusungen' | translate"
                      [Error]="AmountInfeed?.isError"
                      [ErrorLevel]="AmountInfeed?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AmountInfeed?.TimeseriesData]"
                      [Legend]="['Anzahl Einschleusungen' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <!--    <app-default-card *ngIf="this.AmountFlapMovementsValue != null" class="card"
                          style="border: none; border-radius: unset;"
                          [Heading]="'Anzahl Klappenbewegungen' | translate"
                          [Error]="AmountFlapMovementsError"
                          [ErrorLevel]="AmountFlapMovementsErrorLevel"
                          [showChart]="chart"
                          [showInfo]="info"
                          [Values]="AmountFlapMovementsValue"
                          [Legend]="['Anzahl Klappenbewegungen' | translate]"
                          [resizeEvent]="childResizeEvent"></app-default-card>-->

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Fehler' | translate"
                      [Error]="AmountError?.isError"
                      [ErrorLevel]="AmountError?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AmountError?.TimeseriesData]"
                      [Legend]="['Anzahl Fehler' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Crashs' | translate"
                      [Error]="AmountCrash?.isError"
                      [ErrorLevel]="AmountCrash?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AmountCrash?.TimeseriesData]"
                      [Legend]="['Anzahl Crashs' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>
</div>
