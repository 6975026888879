import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ChartsModule} from '../../core/controller/modules/charts.module';
import {DatepickerModule} from '../../core/controller/modules/datepicker.module';
import {PipesModule} from '../../core/controller/modules/pipes.module';
import {TileModule} from '../../core/controller/modules/tile.module';
import {BronzeRoutingModule} from './bronze-routing.module';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {StorewareComponent} from './storeware/storeware.component';
import {MonitoringModule} from '../../core/controller/modules/monitoring.module';
import {TranslateModule} from '@ngx-translate/core';
import {BannerModule} from '../../core/controller/modules/banner.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        BronzeRoutingModule,
        PipesModule,
        ChartsModule,
        DatepickerModule,
        TileModule,
        MonitoringModule,
        TranslateModule,
        BannerModule
    ],
    declarations: [
        DashboardComponent,
        StorewareComponent,
        PageNotFoundComponent
    ],
    exports: []
})
export class BronzeModule
{
}
