import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-energy-consumption',
    templateUrl: './energy-consumption.component.html',
    styleUrls: ['./energy-consumption.component.scss',
        './../card.scss']
})
export class EnergyConsumptionComponent extends BaseViewElement implements OnInit, OnChanges
{
    @ViewChild('cardWidth', {read: ElementRef}) cardWidth: ElementRef;

    @Input() Energy: PropertySet;

    Price: Property<any>;
    EnergyHour: Property<any>;
    EnergyWeek: Property<any>;
    EnergyMonth: Property<any>;
    EnergyYear: Property<any>;
    EnergyAll: Property<any>;


    width = 'auto';
    height = '100%';


    constructor()
    {
        super('energy-consumption');
    }

    onResize(result: any)
    {
        if (this == null) return;

        if (this.cardWidth != null)
        {
            const fontSizeWidth = this.cardWidth.nativeElement.offsetWidth * 0.1;

            this.fontsize = fontSizeWidth + 'px';

            if (this.cardWidth.nativeElement.offsetWidth > this.cardWidth.nativeElement.offsetHeight)
            {
                this.width = 'auto';
                this.height = '100%';
            }
            else
            {
                this.width = '100%';
                this.height = 'auto';
            }
        }
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Energy == null) return;
        if (this.Energy.properties.get('ACTIVE_ENERGY_PRICE') == null) return;

        this.Price = this.Energy.properties.get('ACTIVE_ENERGY_PRICE');
        this.EnergyHour = this.Energy.properties.get('ACTIVE_ENERGY_HOUR');
        this.EnergyWeek = this.Energy.properties.get('ACTIVE_ENERGY_WEEK');
        this.EnergyMonth = this.Energy.properties.get('ACTIVE_ENERGY_MONTH');
        this.EnergyYear = this.Energy.properties.get('ACTIVE_ENERGY_YEAR');
        this.EnergyAll = this.Energy.properties.get('ACTIVE_ENERGY_ALL');
    }
}
