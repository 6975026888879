<mat-card
    [ngClass]="sassClassConditions"
>
    <mat-card-content class="card_content">
        <div>
        <h4>{{
            (
                node.data.entity_type === 'thing' ? 'Thing' :
                    node.data.entity_type === 'bauteil' ? 'Bauteil' :
                        node.data.entity_type === 'komponenten' ? 'Komponente':
                            undefined
            )
        | translate}} {{'Gesundheit Überblick'  | translate}}</h4>
        </div>
    </mat-card-content>
    <mat-card-content class="card_content">
    <div>
        <h6>{{'(Zum Ansehen klicken)' | translate}}</h6>
    </div>
    </mat-card-content>
</mat-card>
