<div class="row">

    <!--<div class="col-lg-12 subheading">-->
        <!--<div class="row">-->
            <!--<div class="col-12">-->
                <!--<h3 id="All" style="float: left">{{'Gateways'}}</h3>-->
                <!--<button [routerLink]="'/gateway/cache'" class="btn">{{'Cache Managment'}}</button>-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->



    <div *ngFor="let gateway of gatewayServiceV2.gateways"
         class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-12 monitoring-card">

        <div style="padding-top: 34px;">
            <div [ngClass]="gateway | gatewayStatusV2" (click)="gotoGateway(gateway)"
                 class="monitoring-text-card-body">
                <h4 class="title-over-image-center">
                    {{
                        gateway | gatewayStatusV2 : "provice-gateway-name"
                    }}</h4>
            </div>
        </div>
    </div>

</div>

