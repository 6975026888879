import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-sses-statistic',
    templateUrl: './sses-statistic.component.html',
    styleUrls: ['./sses-statistic.component.scss', './../card.scss']
})
export class SsesStatisticComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{
    @Input() Endstelle: PropertySet;

    AmountOutfeed: Property<any>;
    AmountOutfeedFull: Property<any>;
    CrashCount: Property<any>;

    constructor()
    {
        super('sses-statistic');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Endstelle == null) return;
        this.AmountOutfeed = this.Endstelle.properties.get('ANZ_AUSSCHLEUSUNGEN');
        this.AmountOutfeedFull = this.Endstelle.properties.get('ANZ_ENDSTELLE_VOLL');
        this.CrashCount = this.Endstelle.properties.get('CRASH_ANZ');

    }

}

