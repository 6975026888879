<app-group-header [Heading]="'Temperatur' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card *ngIf="Temp1 != null" class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Temperatur Teleskopmotor' | translate"
                      [Error]="Temp1?.isError"
                      [ErrorLevel]="Temp1?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Temp1?.TimeseriesData]"
                      [Unit]="'°C'"
                      [min]="15"
                      [max]="52"
                      [Legend]="['Teleskopmotor' | translate]"
                      [resizeEvent]="childResizeEvent"
                      [ChartType]="'gauge'"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Temperatur Fahrmotor' | translate"
                      [Error]="Temp2?.isError"
                      [ErrorLevel]="Temp2?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Temp2?.TimeseriesData]"
                      [Unit]="'°C'"
                      [min]="15"
                      [max]="52"
                      [Legend]="['Fahrmotor' | translate]"
                      [resizeEvent]="childResizeEvent"
                      [ChartType]="'gauge'"></app-default-card>

</div>
