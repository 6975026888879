import {Component, Input} from '@angular/core';
import {DataPoint} from '../../../model/helper-models/datapoint.model';
import {PiechartOptions} from '../../charts/options/piechart-options';
import {Tile} from '../tile';

@Component({
    selector: 'app-pie-tile',
    templateUrl: './pie-tile.component.html',
    styleUrls: ['./pie-tile.component.scss',
        '../tile.scss']
})
/**
 * Kuchendiagramm Kachel
 */
export class PieTileComponent extends Tile
{
    /**
     * Werte
     */
    @Input() value: Array<DataPoint<number>> = [];

    /**
     * Optionen
     */
    @Input() options: PiechartOptions;

    /**
     *
     */
    constructor()
    {
        super();
    }
}
