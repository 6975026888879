import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from './authentication.service';
import {Observable} from 'rxjs';
import {GatewayV2} from '../../model/gateway.v2.model';
import {map} from 'rxjs/operators';
import {Configurations} from '../../../configurations';
import { GatewayFlowHealth, GatewayMachineInfo, GatewayTestdriveInfo } from "../../model/gateway_status.v2.model";
import { S7Info } from "../../model/gateway_extended_status.model";


interface Programs {
    [program: string]: ProgramInfo;
}

interface ProgramInfo
{
    version: number;
    specification?: string;
    full?: object;
}

@Injectable({
    providedIn: 'root'
})
export class MonitoringGatewayV2Service
{
    public defaultAuthHeaders: HttpHeaders;
    private url_deviceList = environment.apiUrl + environment.apiVersion + '/health_getDeviceList';
    public gateways: GatewayV2[] = [];
    private pullTimer: any = false;

    public activeGateway: GatewayV2;
    public activeMachineInfo: GatewayMachineInfo;
    public activeFlowInfo: GatewayFlowHealth[];
    public activePrograms: Programs;
    public activeTestdrive: GatewayTestdriveInfo[];
    private extendedPullTimer: any = false;
    private extendedPullTimer_flows: any = false;
    private extendedPullTimer_testdrive: any = false;

    constructor(
        private _http: HttpClient,
        private auth: AuthenticationService
    )
    {
    }

    private async updatePermissions(authHeaders?: HttpHeaders)
    {
        if (authHeaders !== undefined)
        {
            this.defaultAuthHeaders = authHeaders;
            return;
        }
        this.defaultAuthHeaders = await this.auth.getDefaultHttpHeaders();
    }

    public initialize(authHeaders?: HttpHeaders)
    {
        if (authHeaders !== undefined) this.updatePermissions(authHeaders);
        else this.updatePermissions();
    }

    /**
     * Startet Timer um alle Status der Gateways zu pullen
     */
    public startPullTimer(): void
    {
        this.setGatewayStatus();
        this.pullTimer = setInterval(() =>
        {
            this.setGatewayStatus();
        }, Configurations.chartUpdateTime);
    }

    public stopExtendedPullTimer(): void
    {
        clearInterval(this.extendedPullTimer);
        clearInterval(this.extendedPullTimer_flows);
        // Wird false gesetzt um zu wissen ob Timer aktiv oder nicht
        this.pullTimer = false;
    }
    /**
     *  Beendet die Pull Timer Routine
     **/
    public stopPullTimer(): void
    {
        clearInterval(this.pullTimer);
        // Wird false gesetzt um zu wissen ob Timer aktiv oder nicht
        this.pullTimer = false;
    }

    public startExtendedPullTimer(gatewayId: string): void
    {
        this.getGateway(gatewayId).subscribe(result => this.activeGateway = result);
        this.pullGatewayStatus(gatewayId, true, true, true);
        this.extendedPullTimer = setInterval(() =>
        {
            this.pullGatewayStatus(gatewayId, true, false, false);
        }, Configurations.chartUpdateTime * 100);
        this.extendedPullTimer_flows = setInterval(() =>
        {
            this.pullGatewayStatus(gatewayId, false, true, false);
        }, Configurations.flowUpdateTime * 100);
        this.extendedPullTimer_testdrive = setInterval(() =>
        {
            this.pullGatewayStatus(gatewayId, false, false, true);
        }, Configurations.flowUpdateTime * 100);
    }

    private getGateway(deviceOId: string): Observable<GatewayV2>
    {
        const queryParams = {
            deviceOId: deviceOId
        };
        const url_deviceList = this.url_deviceList + '?' + this.encodeGetParams(queryParams);
        return this._http.get(url_deviceList, { headers: this.defaultAuthHeaders }).pipe(
            map((resp: any) =>
            {
                return {
                    deviceOId: resp['devices'][0]._id,
                    ...resp['devices'][0]
                } as GatewayV2;
            })
        );

    }

    public pullGatewayStatus(deviceOId: string, setMachineInfoOnly: boolean = true, setFlowInfoOnly: boolean = false, setTestdriveOnly: boolean = false)
    {
        if (setMachineInfoOnly)
        {
            this.getSingleGatewayStatus(deviceOId).subscribe((resp: any) =>
            {
                this.activeMachineInfo = resp.data[0].data[0];
                if (!this.activePrograms) this.activePrograms = {};
                this.ingestPrograms(this.activeMachineInfo);
            });
        }

        if (setFlowInfoOnly)
        {
            this.getExtendedGatewayStatus(deviceOId).subscribe((resp: any) =>
            {
                this.activeFlowInfo = resp.data[0].data;
            });
        }

        if (setTestdriveOnly)
        {
            this.getTestdriveStatus(deviceOId).subscribe((resp: any) =>
            {
                this.activeTestdrive = resp;
            });
        }

    }

    private encodeGetParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join('=')).join('&');

    public UpdateGatewayList(): Promise<void>
    {
        return new Promise((resolve, reject) =>
        {
            this.GetGatewayList().subscribe(value =>
            {
                this.gateways = value;
                resolve();
            });
        });
    }

    private GetGatewayList(): Observable<GatewayV2[]>
    {
        const respObs = this._http.get(this.url_deviceList, { headers: this.defaultAuthHeaders });
        const modObs = respObs.pipe(map((resp: any) => resp['devices'].map(elem =>
        {
            return {
                deviceOId: elem.device._id,
                connectionStatus:  elem.connection_status,
                ...elem };
        }) as GatewayV2[]));

        return modObs;
    }

    private setGatewayStatus()
    {
        for (let i = 0; i < this.gateways.length; i++)
        {
            this.getSingleGatewayStatus(this.gateways[i].device._id).subscribe((resp: any) =>
            {
                this.gateways[i].machineInfo = resp.data[0].data;
            });
        }
    }

    private getSingleGatewayStatus(deviceOId: string): Observable<any>
    {
        const baseurl = environment.apiUrl + environment.apiVersion + '/health_getGatewayHealth';
        const parameters = {
            deviceOId: deviceOId,
            healthParam: 'machine'
        };
        const url = baseurl + '?' + this.encodeGetParams(parameters);
        return this._http.get(url, { headers: this.defaultAuthHeaders });
    }

    private setGatewayExtendedStatus()
    {
        for (let i = 0; i < this.gateways.length; i++)
        {
            this.getExtendedGatewayStatus(this.gateways[i].device._id).subscribe((resp: any) =>
            {
                this.gateways[i].flowInfo = resp.data[0].data;
            });
        }
    }

    private getExtendedGatewayStatus(deviceOId: string): Observable<any>
    {
        const baseurl = environment.apiUrl + environment.apiVersion + '/health_getGatewayHealth';
        const parameters = {
            deviceOId: deviceOId,
            healthParam: 'flow'
        };
        const url = baseurl + '?' + this.encodeGetParams(parameters);
        return this._http.get(url, { headers: this.defaultAuthHeaders });
    }

    private getTestdriveStatus(deviceOId: string): Observable<any>
    {
        const baseurl = environment.apiUrl + environment.apiVersion + '/gateway/testdrive';
        const parameters = {
            deviceOId: deviceOId
        };
        const url = baseurl + '?' + this.encodeGetParams(parameters);
        return this._http.get(url, { headers: this.defaultAuthHeaders });
    }


    //    Program Specific Operations

    private ingestPrograms(machineInfo: GatewayMachineInfo)
    {
        for (const prop in machineInfo)
        {
            const prog_split = this.__splitProgramProp(prop);
            if (prog_split !== null)
            {
                this.__setProgram(
                    prog_split,
                    machineInfo[prop]
                );
            }
        }
    }

    private __setProgram(prog_split: [string, string], value: any)
    {
        const prog = prog_split[0];
        const prop = prog_split[1];
        if (!(prog in this.activePrograms))
        {
            this.activePrograms[prog] = {
                [prop]: value
            } as ProgramInfo;
            return;
        }
        this.activePrograms[prog] = { ...this.activePrograms[prog], [prop]: value };
    }

    private __splitProgramProp(propString: string): [string, string]
    {
        const prog_split = propString.split(/(?=[A-Z])/).map(word => word.toLowerCase());
        if (!Configurations.ProgramSupport.map(elem => elem.toLowerCase()).includes(prog_split[0]))
        {
            // console.warn('Some keys do not represent any program');
            return null;
        }
        const prop = prog_split.slice(1).join('').toLowerCase();
        return [prog_split[0], prop];
    }

    // Setting the Testdrive data on the edge, over API request
    public setTestdriveStatus(deviceOId: string, s7Data: GatewayTestdriveInfo)
    {
        const baseurl = environment.apiUrl + environment.apiVersion + '/gateway/testdrive';
        const parameters = {
            deviceOId: deviceOId
        };
        const url = baseurl + '?' + this.encodeGetParams(parameters);
        return this._http.post(url, s7Data, { headers: this.defaultAuthHeaders } ).subscribe(value => {
            console.log(value);
        }, error => console.error(error));
    }
}
