import {BaseOptions} from './base-options';

/**
 * Optionen für Linecharts
 */
export class LinechartOptions extends BaseOptions
{
    /**
     * Array mit Farbcodes für jede Linie
     */
    public colors = Array<string>();

    /**
     * Oberer Grenzwert
     */
    public upperUpperThreshold = null;

    /**
     * Obere Warnung
     */
    public upperThreshold = null;

    /**
     * Untere Warnung
     */
    public lowerThreshold = null;

    /**
     * Unterer Grenzwert
     */
    public lowerLowerThreshold = null;

    /**
     * Untere Grenze der y-Achse
     */
    public yMin = null;

    /**
     * Obere Grenze der y-Achse
     */
    public yMax = null;

    /**
     * ausgewählter Bereich x-Achse start
     */
    public dataZoomStart = 0;

    /**
     * ausgewählter Bereich x-Achse ende
     */
    public dataZoomEnd = 100;

    /**
     * Konstruktor
     * @param pTitle
     * @param pLegend
     * @param pUpperUpperT
     * @param pUpperT
     * @param pLowerT
     * @param pLowerLowerT
     * @param pMin
     * @param pMax
     * @param pDataZoomStart
     * @param pDataZoomEnd
     */
    constructor(pTitle: string, pLegend: Array<string>, pUpperUpperT?: number, pUpperT?: number, pLowerT?: number, pLowerLowerT?: number,
                pMin?: number, pMax?: number, pDataZoomStart?: number, pDataZoomEnd?: number)
    {
        super('', pTitle, '');
        this.legend = pLegend;

        this.upperUpperThreshold = pUpperUpperT;
        this.upperThreshold = pUpperT;
        this.lowerThreshold = pLowerT;
        this.lowerLowerThreshold = pLowerLowerT;

        this.yMin = pMin;
        this.yMax = pMax;

        this.dataZoomStart = pDataZoomStart;
        this.dataZoomEnd = pDataZoomEnd;
    }
}
