import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-ssif-belt',
    templateUrl: './ssif-belt.component.html',
    styleUrls: ['./ssif-belt.component.scss', './../card.scss']
})
export class SsifBeltComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{
    @Input() Title: string;

    @Input() Band: PropertySet;

    OperatingHours: Property<any>;
    Current: Property<any>;
    RealEnergy: Property<any>;
    Temperature: Property<any>;

    constructor()
    {
        super('ssif-belt');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Band == null) return;
        this.OperatingHours = this.Band.properties.get('BETRIEBSSTUNDEN');
        this.Current = this.Band.properties.get('STROM');
        this.RealEnergy = this.Band.properties.get('WIRKARBEIT');
        this.Temperature = this.Band.properties.get('TEMPERATUR');
    }

}
