<app-group-header [Heading]="'Fingerbewegungen' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Error]="Movements11?.isError"
                      [ErrorLevel]="Movements11?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Movements11?.TimeseriesData, Movements12?.TimeseriesData, Movements21?.TimeseriesData, Movements22?.TimeseriesData, Movements31?.TimeseriesData, Movements32?.TimeseriesData]"
                      [ChartType]="'bar'"
                      [Legend]="['Finger 1.1' | translate,'Finger 1.2' | translate,'Finger 2.1' | translate,'Finger 2.2' | translate,'Finger 3.1' | translate,'Finger 3.2' | translate]"
                      [Info]="'Zwei Finger bilden immer ein Paar. Die Grafik zeigt die Anzahl der Fingerbewegungen an.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
