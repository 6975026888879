import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-conver-errors',
    templateUrl: './conver-errors.component.html',
    styleUrls: ['./conver-errors.component.scss',
        './../card.scss']
})
export class ConverErrorsComponent extends BaseViewElement implements OnInit
{

    /**
     * Übersetzung der Fehlernummern
     */
    errorMessages = ['keine', 'Kaltstart', 'Restart', 'Init', 'SK1_Unterbruch',
        'SK2_Unterbruch', 'SK3_Unterbruch', 'SK4_Unterbruch', 'SKx_Fehler', 'Stopschalter',
        'Zonefehlt', 'TuerUeberB', 'Motorzuheiss', 'Silisstop', 'RevoGesp',
        'Laufzeitueb', 'Silisaktiv', 'HA0ZVFehler', 'oHAZVFehler', 'SK1StartFehler',
        'Sk3Fehler', 'FehlerMagnet', 'Fehlstap', 'Loeschen', 'Tuerfehl',
        'Riegelfehl', 'Ueberla', 'Volla', 'Ueberdrehzahl', 'AntriebGestoert',
        'WMFahrtrichtsperre', 'WMParafehlen', 'WMfehlt', 'WMLernfahrtAusf', 'NVramError',
        'WMSPIFehler0', 'WMSPIFehler1', 'WMSPIFehler2', 'WMSPIFehler3', 'WMSPIFehler4',
        'WMGeberFehler', 'WMFehlGebertyp', 'WMFehlSchachtTyp', 'WMFehlHAAnzahl', 'WMFehlFoerderhoehe',
        'WMkeineZone', 'WMkeineZV', 'WMFehlZV', 'WMFehlSchlupf', 'WMFehlReferenz',
        'WMkeineLernfahrt', 'WMUeberlauf', 'WMZVUeberlauf', 'WMParaBuendig', 'WMParameterFehler',
        'WMSchachtTabelle', 'WMRestart', 'WMbitteWMinit', 'WMFehlKop', 'WMFehlHP',
        'WMFehlV', 'WMFehlVorLern', 'WMklAufloesung', 'WMgrAufloesung', 'WMreserve4',
        'WMreserve5', 'WMreserve6', 'WMreserve7', 'WMreserve9', 'WMreserve10',
        'WMreserve10', 'AntriebnonStill', 'TuerTabelle', 'Aufzugwaerter', 'Endschoben',
        'GrayFehler', 'GrayAbZuTief', 'GrayAbZuHoch', 'GrayAufZuTief', 'GrayAufZuHoch',
        'StoerungW', 'Anlauft1_0', 'Anlauft1_1', 'Anlauft2_0', 'Anlauft2_1'];

    /**
     * Zum verbinden der Fehlernummern mit Meldungen
     */
    errorMap = new Map<number, string>();

    errorHistory = [];

    @Input() CurrentError: boolean;
    @Input() CurrentErrorLevel: MonitoringLevel;
    @Input() CurrentValue: LimitSizeArrayModel<any>[];

    @Input() CommentError: boolean;
    @Input() CommentErrorLevel: MonitoringLevel;
    @Input() CommentValue: LimitSizeArrayModel<any>[];

    @Input() HistoryError: boolean;
    @Input() HistoryErrorLevel: MonitoringLevel;
    @Input() HistoryValue: LimitSizeArrayModel<any>[];

    @ViewChild('card', {read: ElementRef}) card: ElementRef;
    width = 'auto';
    height = '100%';


    constructor(private _translate: TranslateService)
    {
        super('conver-errors');

        let counter = 0;
        for (let i = 1; i <= 85; i++)
        {
            this.errorMap.set(i, this.errorMessages[counter]);
            counter++;
        }
    }

    ngOnInit(): void
    {
        super.ngOnInit();

        if (this.HistoryValue == null) return;
        if (this.HistoryValue.length === 0) return;

        this.HistoryValue[0].valuePushed.subscribe((data) =>
        {
            if (data == null) return;
            // this._translate.get(JSON.parse(data.value)).subscribe(x => this.errorHistory = x);
        });

        this.resizeSub = this.resizeEvent.subscribe((widget) =>
        {
            if (this.card != null)
            {

                if (this.card.nativeElement.offsetWidth > this.card.nativeElement.offsetHeight)
                {
                    this.width = 'auto';
                    this.height = '100%';
                }
                else
                {
                    this.width = '100%';
                    this.height = 'auto';
                }

                this.childResizeEvent.emit();
            }
        });
    }


    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

}
