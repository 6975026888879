import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'calcPrice'
})
export class CalcPricePipe implements PipeTransform
{
    // ToDo Diesne bereich so nacharbeiten so das | hier nicht mehr benötigt wird und zu der app-text-tile ausgelagert wird.
    /**
     *
     */
    transform(pCurrent: number, pPrice: number, setPlaceholder: boolean): any
    {
        if (pPrice == null || isNaN(pCurrent) || isNaN(pPrice)) return '';

        if (setPlaceholder)
        {
            return ' | ' + this.reformPrice(pCurrent * pPrice);
        }
        else
        {
            return this.reformPrice(pCurrent * pPrice);
        }
    }

    reformPrice(rawPrice)
    {
        const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
        });

        return formatter.format(rawPrice);
    }
}
