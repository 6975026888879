import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../interfaces/dialog-data';
import {InfoPoint} from '../../../../../core/model/helper-models/infopoint.model';
import {DataPoint} from '../../../../../core/model/helper-models/datapoint.model';
import {AlertDialogComponent} from '../alert-dialog/alert-dialog.component';
import {WarningGaugechartOptions} from '../../../../../core/view/charts/options/warning-gaugechart-options';

@Component({
  selector: 'health-dialog',
  templateUrl: './material-dialog.component.html',
  styleUrls: ['./material-dialog.component.scss']
})
/**
 * Health Dialog component implemented with Material Dialog
 * @constructor
 * @param {MatDialogRef<MaterialHealthDialogComponent>} dialogRef [public] (injectable) reference to self as a dialog_ref
 * @param {@Inject(MAT_DIALOG_DATA) DialogData} data [public] Internally injected dialog data
 * @param {MatDialog} dialog [public] Dialog object for creating new Material Dialogs
 * @return {MaterialHealthDialogComponent}
 * */
export class MaterialHealthDialogComponent implements OnInit {
    injInfoPoints: InfoPoint<number>[];
    allAlerts: any[] ;
    nodeTitle: string;

    gaugeOptions = new WarningGaugechartOptions(
        'Health',
        'Average Health',
        '%',
        0,
        100
    );

  constructor(
      public dialogRef: MatDialogRef<MaterialHealthDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      public dialog: MatDialog
  ) {
      this.nodeTitle = this.data.data[`${this.data.data.entity_type}_name_de`];
  }

  /**
   * Get Information Points for the display over chart
   * @return {InfoPoint<any>[]}
   * */
    private getInfoPoints(): InfoPoint<any>[] {
      let data: InfoPoint<any>[] = [];
      this.data.data.messgroessen.forEach((mg, i) =>{
          let mg_data: InfoPoint<number> = new InfoPoint<number>(
              mg['messgroessen_name'],
              new DataPoint<number>(
                  (1 - (mg['live_value']/mg['momentaner_grenzwert']))*100,
                  new Date()
              )
          );
          // mg_data.data = mg;
          data.push(mg_data);
      });
      return data;
    }

    /**
     * On a click outside the dialog region, close the dialog!
     * @return
     * */
    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * Create a new alerting dialog of the component {AlertDialogComponent}
     *
     * @param {any} obj
     * @param {string} mode     What kind of alert mode is it ? ['WARNING', 'ALERTING']
     * @return
     * */
    showAlert(obj: any, mode: string): void{

     const alertDialogRef = this.dialog.open(AlertDialogComponent,
          {
              data: {
                  mode: mode.toUpperCase(),
                iData: {
                    alerting_obj: obj
                },
                  treeInfo: {
                      node: this.data.treeInfo.node,
                      treeControl: this.data.treeInfo.treeControl
                  }
              },
              height: '80%',
              width: '70%'
          }
          );

     alertDialogRef.afterClosed().subscribe(d_results => {
        if(d_results){
            if(d_results.action === 'CLOSE_PARENT'){
                this.dialogRef.close(d_results);
            }
        }
     });
    }

    /**
     * Close the modal/dialog opened currently
     * [Similar abstraction as {this.onNoClick()}]
     * @return
     * */
    closeModal(): void {
        this.dialogRef.close();
    }

  ngOnInit(): void {
      this.injInfoPoints = this.getInfoPoints();
      this.allAlerts = [...this.data.iData.warning, ...this.data.iData.alerting];
  }


}
