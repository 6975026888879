import {Component, Input} from '@angular/core';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';

@Component({
    selector: 'app-control-cabinet',
    templateUrl: './control-cabinet.component.html',
    styleUrls: ['./control-cabinet.component.scss',
        './../card.scss']
})
export class ControlCabinetComponent extends BaseViewElement
{
    @Input() TemperatureError: boolean;
    @Input() TemperatureErrorLevel: MonitoringLevel;
    @Input() TemperatureValue: LimitSizeArrayModel<DataPoint<any>>[];

    constructor()
    {
        super('control-cabinet');
        this.chart = true;
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }
}
