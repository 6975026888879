import {Component, Input, OnInit} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {Thing} from '../../../core/model/thing.model';

@Component({
    selector: 'app-ssagm-stat-if',
    templateUrl: './ssagm-stat-if.component.html',
    styleUrls: ['./ssagm-stat-if.component.scss']
})
export class SsagmStatIfComponent extends BaseViewElement implements OnInit
{

    @Input() Infeeds: Array<Thing>;


    constructor()
    {
        super('ssagm-stat-inf');

    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }
}
