<div class="tile" [ngClass]="{'errorTile' : error}">

    <div class="tileHead">
        {{heading}}
        <div *ngIf="ain != null" class="unit">
            <a [href]="ain" target="_blank">{{'Kundenportal' | translate }}</a>
            <!--            <a [routerLink]="" (click)="openAinLink()">Kundenportal</a>-->
        </div>
    </div>

    <div class="tileBody tileImage">
        <img class="img-fluid" [src]="src" [alt]="alt" [style.width]="width">
    </div>
</div>