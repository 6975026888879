import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Configurations} from '../../../configurations';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';
import {DateService} from '../../../core/controller/services/date.service';
import {GetThingDataService} from '../../../core/controller/services/get-thing-data.service';
import {HelperService} from '../../../core/controller/services/helper.service';
import {NavigationService} from '../../../core/controller/services/navigation.service';
import {PiechartOptions} from '../../../core/view/charts/options/piechart-options';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';
import {TranslateService} from '@ngx-translate/core';
import {Thing} from '../../../core/model/thing.model';
import {environment} from '../../../../environments/environment';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss',
        '../../../core/view/tile/tile.scss',
        '../../../../assets/css/template.scss']
})
/**
 * Dashboard Seite
 */
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit
{
    private lagerfuellstand: string;
    private LeBehaelter: string;
    private BeBehealter: string;

    /**
     * Wird verwendet um auf die Methoden von Rafael in der 3D Darstellung zuzugreifen
     */
    @ViewChild('unityIframe', {static: false}) unityIframe: ElementRef;

    /**
     * StoreWare Objekt um Lagerstatistik anzuzeigen
     */
    public storeware: Thing;

    /**
     * Route zum 3D Modell
     */
    public frameSrc: any;

    /**
     * Optionen für die Lagerfüllstand PieChart
     */
    public loadOptionsPie = new PiechartOptions('Lagerfüllstand', '', '',
        ['Leere Behälter', 'Befüllte Behälter']);

    /**
     * Gassenfüllstand Namen
     */
    aisleFillLevelAisleWms = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);
    /**
     * Gassenfüllstand Werte
     */
    aisleFillLevelValue = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);

    private loadedIntervall: any;

    private pageChangeIntervall: any;

    /**
     *
     * @param data
     * @param _getData
     * @param _date
     * @param helper
     * @param nav
     */
    constructor(public data: DataProviderService,
                private _getData: GetThingDataService,
                public translate: TranslateService,
                private _date: DateService,
                public helper: HelperService,
                public nav: NavigationService)
    {
        this.translate.get('Lagerfüllstand').subscribe((x: string) =>
        {
            this.lagerfuellstand = x;
        });
        this.translate.get('Leere Behälter').subscribe((x: string) =>
        {
            this.LeBehaelter = x;
        });
        this.translate.get('Befüllte Behälter').subscribe((x: string) =>
        {
            this.BeBehealter = x;
            this.loadOptionsPie = new PiechartOptions(this.lagerfuellstand, '', '',
                [this.LeBehaelter, this.BeBehealter]);
        });
    }

    /**
     * Holt StoreWare Objekt um StoreWare Daten anzuzeigen
     */
    ngOnInit()
    {
        this._date.auto = true;

        Configurations.InitaliseComplete.subscribe(x =>
        {
            if (x)
            {
                this.nav.setActiveCustomerDetails();
                /*
                const storewares = this.data.activeSystem.allThingTypes['STOREWARE'];

                if (storewares.length > 0)
                {

                    this.storeware = storewares[0] as Thing;

                    this._getData.setThings([this.storeware]);
                    this._getData.startPullTimer();

                    this.storeware.STW_AISLE_FILL_LEVEL.dataPulled.subscribe((x) =>
                    {
                        if (!x) return;

                        this.aisleFillLevelAisleWms.pushDataPoint([new DataPoint('Gasse 1', 0), new DataPoint('Gasse 4', 0), new DataPoint('Gasse 2 Ebene 1', 0), new DataPoint('Gasse 2 Ebene 2', 0)]);// = this.thing.STW_AISLE_FILL_LEVEL.deboxList(this.thing.STW_AISLE_FILL_LEVEL.AISLE_WMS.TimeseriesData);
                        this.aisleFillLevelValue = this.storeware.STW_AISLE_FILL_LEVEL.deboxList(this.storeware.STW_AISLE_FILL_LEVEL.VALUE.TimeseriesData);
                    });
                }*/
            }
        });
    }

    /**
     *
     */
    ngOnDestroy()
    {
        this._getData.stopPullTimer();
        clearInterval(this.loadedIntervall);
        clearInterval(this.pageChangeIntervall);
    }

    /**
     * Setzt die Links im 3D Modell
     */
    set3DLinks()
    {
        //rewrite foreach to for and set env.storageUrl to class field
        this.data.activeSystem.getAllThings().forEach(thing =>
        {
            const layoutId = thing.layoutPosition;
            const url = environment.redirectUri + this.nav.buildThingUrl(thing);

            console.log(layoutId + ' : ' + url);

            // Hier wird Link gesetzt
            this.unityIframe.nativeElement.contentWindow.postMessage(
                {'function': 'SetLinkThing', 'layoutId': layoutId, 'url': url},
                environment.storageUrl);

            thing.outdatedProperties.subscribe(x =>
            {
                if (x != null)
                {
                    if (x.length > 0)
                    {
                        this.unityIframe.nativeElement.contentWindow.postMessage(
                            {'function': 'ErrorThing', 'layoutId': layoutId},
                            environment.storageUrl);
                    }
                    else
                    {
                        this.unityIframe.nativeElement.contentWindow.postMessage(
                            {'function': 'NoErrorThing', 'layoutId': layoutId},
                            environment.storageUrl);
                    }
                }
            });

            /*
            this.unityIframe.nativeElement.contentWindow.postMessage(
                {'function': 'SetColorThing', 'layoutId': layoutId, 'color': '#bd230fff'},
                environment.storageUrl);
            */

        });
    }

    /**
     * Lädt 3D Modell
     */
    ngAfterViewInit(): void
    {
        Configurations.InitaliseComplete.subscribe(x =>
        {
            if (x)
            {
                console.log(this.data.activeSystem.path3D);
                // this.frameSrc = '../../../../assets/Anlage_3D_Modell/index.html';
                this.frameSrc = environment.storageUrl + this.data.activeSystem.path3D;
                console.log(this.frameSrc);

                window.addEventListener('message', (event) =>
                {
                    console.log(event);

                    if (event.data.includes('loaded'))
                    {
                        if (this.data.activeCompany.id === '649d71726663955656359ad4')
                        {
                            if (this.data.activeSystem.name.includes('AKL'))
                            {
                                this.unityIframe.nativeElement.contentWindow.postMessage(
                                    {'function': 'SetView', 'view': 0},
                                    environment.storageUrl);
                            }

                            if (this.data.activeSystem.name.includes('Sequenzer'))
                            {
                                this.unityIframe.nativeElement.contentWindow.postMessage(
                                    {'function': 'SetView', 'view': 1},
                                    environment.storageUrl);
                            }

                            if (this.data.activeSystem.name.includes('Picktower'))
                            {
                                this.unityIframe.nativeElement.contentWindow.postMessage(
                                    {'function': 'SetView', 'view': 2},
                                    environment.storageUrl);
                            }
                        }

                        this.set3DLinks();
                    }
                    else if (event.data.includes('Object chosen'))
                    {
                        this.set3DLinks();
                    }
                });

                setTimeout(() =>
                {

                }, 10000);


                /*
                                this.loadedIntervall = setInterval(() =>
                                {
                                    console.log('not loaded');

                                    if (this.unityIframe.nativeElement.contentWindow != null)
                                    {
                                        if (this.unityIframe.nativeElement.contentWindow.unityLoaded)
                                        {
                                            if (this.unityIframe.nativeElement.contentWindow.unityInstance.SendMessage != null)
                                            {
                                                console.log('loaded');

                                                this.set3DLinks();

                                                clearInterval(this.loadedIntervall);

                                                this.pageChangeIntervall = setInterval(() =>
                                                {
                                                    if (this.unityIframe.nativeElement.contentWindow != null)
                                                    {
                                                        if (this.unityIframe.nativeElement.contentWindow.objectChosen)
                                                        {
                                                            this.unityIframe.nativeElement.contentWindow.objectChosen = false;

                                                            this.set3DLinks();
                                                        }
                                                    }
                                                }, 1000);
                                            }
                                        }
                                    }
                                }, 1000);*/
            }
        });
    }
}
