import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'tableTranslation'
})
export class TableTranslationPipe implements PipeTransform {

    constructor(
        public translate: TranslateService
    )
    {
    }
  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

}
