import {Component, EventEmitter, Input} from '@angular/core';
import {DataPoint} from '../../../model/helper-models/datapoint.model';
import {LinechartOptions} from '../../charts/options/linechart-options';
import {Tile} from '../tile';

@Component({
    selector: 'app-line-tile',
    templateUrl: './line-tile.component.html',
    styleUrls: ['./line-tile.component.scss',
        '../tile.scss']
})
/**
 * Liniendiagramm Kachel
 */
export class LineTileComponent extends Tile
{
    /**
     * Werte
     */
    @Input() value: Array<Array<DataPoint<number>>>;

    /**
     * Legende
     * Nimmt direkt ein Array der Legende an. Ersetzt options.
     */
    @Input() legend: Array<string>;

    /**
     * Optionen
     * Bietet mehr optionen als Legende.
     */
    @Input() options: LinechartOptions;

    @Input() resizeEvent: EventEmitter<any>;

    /**
     *
     */
    constructor()
    {
        super();
    }
}
