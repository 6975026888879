<div class="row" *ngIf="s7Data">
    <div class="col">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput value="{{s7Data.Name}}" readonly>
        </mat-form-field>
    </div>
    <div class="col">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Startzeit Messfahrten</mat-label>
            <input matInput id="newTime" placeholder="00:00:00" (input)="changeTime($event)"
                   value="{{s7Data.StartTimeTestrun | date:'HH:mm:ss': '+0000'}}">
        </mat-form-field>
    </div>
    <div class="col">
        <mat-form-field appearance="fill" id="TestdriveActiveDropDown">
            <mat-label>Startzeit MessfahrtenAktive</mat-label>
            <mat-select [(ngModel)]="changedActive">
                <mat-option [value]="true">Ja</mat-option>
                <mat-option [value]="false">Nein</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Modus</mat-label>
            <input matInput id="newModus" placeholder="00:00:00" (input)="changeModus($event)"
                   value="{{s7Data.RecordingMode}}">
        </mat-form-field>
    </div>
</div>

<div class="row" style="float: right">
    <div class="col">
        <button type="button" class="btn btn btn-danger" style="margin-right: 10px" (click)="onNoClick()">Abbrechen
        </button>
        <button type="button" class="btn btn btn-success" (click)="onSaveClick()">Speichern</button>
    </div>
</div>
