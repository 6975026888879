import {Component} from '@angular/core';
import {DataProviderService} from '../../controller/services/data-provider.service';
import {NavigationService} from '../../controller/services/navigation.service';
import {MonitoringService} from '../../controller/services/monitoring.service';
import {FavoriteService} from '../../controller/services/favorite.service';

@Component({
    selector: 'app-favorite',
    templateUrl: './favorite.component.html',
    styleUrls: ['./favorite.component.scss', '../monitoring/monitoring.scss']
})
/**
 * Favoriten Komponente
 */
export class FavoriteComponent
{
    /**
     * Konstruktor
     * @param data
     * @param monitoring
     * @param favorite
     * @param nav
     */
    constructor(public data: DataProviderService,
                public monitoring: MonitoringService,
                public favorite: FavoriteService,
                public nav: NavigationService)
    {
    }
}
