export class CardMemento
{
    showChart: boolean;
    showInfo: boolean;
    Type: string;
    ChartType: string;

    constructor(showChart: boolean, showInfo: boolean, Type: string, ChartType: string)
    {
        this.showChart = showChart;
        this.showInfo = showInfo;
        this.Type = Type;
        this.ChartType = ChartType;
    }

    getState()
    {
        return {
            showChart: this.showChart,
            showInfo: this.showInfo,
            Type: this.Type,
            ChartType: this.ChartType,
        };
    }


}
