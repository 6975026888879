import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';
import {DateService} from '../../../core/controller/services/date.service';
import {NavigationService} from '../../../core/controller/services/navigation.service';
import {BaseThing} from '../../base-thing';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../../core/controller/services/layout.service';
import {UserSettingsService} from '../../../core/controller/services/user-settings.service';

@Component({
    selector: 'app-rbg',
    templateUrl: './rbg.component.html',
    styleUrls: ['./rbg.component.scss',
        '../../../../assets/css/template.scss']
})
// Regalbediengerät Seite
export class RbgComponent extends BaseThing implements OnInit, OnDestroy
{
    /**
     * Zustände
     */
    operationModes = new Map<number, string>([[0, 'keine'], [1, 'Hand'], [2, 'Halbautomatik'],
        [3, 'Automatik'], [4, 'Messfahrt']]);


    constructor(public route: ActivatedRoute,
                private _router: Router,
                public nav: NavigationService,
                public data: DataProviderService,
                public date: DateService,
                private _translate: TranslateService,
                private _layout: LayoutService,
                private _user: UserSettingsService)
    {
        super(route, _router, nav, data, date, _translate, _layout, _user);
    }


    ngOnInit()
    {

        super.ngOnInit();

        if (this.thing == null) return;

        /*
                if (this.horizontalDate == null)
                {
                    const Hsub = this.thing.TESTDRIVE_HORIZONTAL.SEW_H.dataPulled.subscribe((x) =>
                    {
                        if (!x) return;
                        this.horizontalDate = super.getDate(this.thing.TESTDRIVE_HORIZONTAL.SEW_H.ACTUAL_DRIVE.TimeseriesData);

                        if (this.horizontalDate != null) Hsub.unsubscribe();
                    });
                }

                if (this.verticalDate == null)
                {
                    const Vsub = this.thing.TESTDRIVE_VERTICAL.SEW_V.dataPulled.subscribe((x) =>
                    {
                        if (!x) return;
                        this.verticalDate = super.getDate(this.thing.TESTDRIVE_VERTICAL.SEW_V.ACTUAL_DRIVE.TimeseriesData);

                        if (this.verticalDate != null) Vsub.unsubscribe();
                    });
                }
        */
        for (let i = 0; i < this.operationModes.size; i++)
        {
            this._translate.get(this.operationModes.get(i)).subscribe(x => this.operationModes.set(i, x));
        }
    }

    ngOnDestroy()
    {
        super.ngOnDestroy();
    }
}
