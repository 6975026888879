import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {icon, latLng, marker, tileLayer} from 'leaflet';
import {Subject} from 'rxjs';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';
import {CLocation} from '../../../core/model/location.model';

@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss',
        '../../../core/view/tile/tile.scss',
        '../../../../assets/css/template.scss']
})

export class LocationsComponent implements OnInit, AfterViewInit
{
    @Input() locations: CLocation[];

    public options = {};
    public markers = [];
    public center: any;

    /**
     * Map Koordinaten (Subject ähnlich wie Observable)
     */
    public locationCords = new Subject<any>();

    /**
     * Subscribt auf Änderungen der activeLocation und setzt das center neu
     * @param data
     */
    constructor(public data: DataProviderService)
    {
        this.locationCords.subscribe(value =>
        {
            this.center = value;
        });
    }

    ngOnInit()
    {
        if (this.locations == null)
        {
            if (this.data.activeCompany != null && this.data.activeCompany.locations != null)
            {
                this.locations = Array.from(this.data.activeCompany.locations.values());
            }
        }

        if (this.locations == null) return;

        this.options =
            {
                layers: [
                    tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'})
                ],
                zoom: 6,
                center: latLng(this.locations[0].mapCoordinates.lat, this.locations[0].mapCoordinates.lng)
            };
        this.buildLayers();
        this.addPopupToMarkers();
        // this.calcCenter();
    }

    /**
     * Setzt Marker für jeden Standort auf die Karte
     **/
    buildLayers()
    {
        this.locations.forEach(loc =>
        {
            this.markers.push(
                marker(
                    [loc.mapCoordinates.lat, loc.mapCoordinates.lng],
                    {
                        // TODO : read location address and write in options
                        title: loc.mapCoordinates.label,
                        alt: loc.mapCoordinates.label,
                        icon: icon(
                            {
                                iconSize: [25, 41],
                                iconAnchor: [13, 41],
                                iconUrl: './assets/marker-icon.png',
                                shadowUrl: './assets/marker-shadow.png'
                            })
                    }));
        });
    }

    addPopupToMarkers()
    {
        this.markers.forEach(mark =>
        {
            // TODO: read address from mark.options and write in popup window!!!
            const popuptext = '<span style="color: black; ">' +
                '<b>' + mark.options.title + '</b>' + '</span>';
            mark.bindPopup(popuptext);
        });
    }

    ngAfterViewInit()
    {
    }

    // Berechnet neues Center für locations
    public calcCenter()
    {
        this.locationCords.next(latLng(this.data.activeLocation.mapCoordinates.lat, this.data.activeLocation.mapCoordinates.lng));
    }
}
