<app-group-header [Heading]="'Statistik' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">
    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Einschleusungen / Stunde' | translate"
                      [Error]="InputHour?.isError"
                      [ErrorLevel]="InputHour?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[inputHour]"
                      [Legend]="['Einschleusungen / Stunde' | translate]"
                      [ChartType]="'gauge'"
                      [Unit]="''"
                      [min]="0"
                      [max]="5000"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Ausschleusungen / Stunde' | translate"
                      [Error]="OutputHour?.isError"
                      [ErrorLevel]="OutputHour?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[outputHour]"
                      [Legend]="['Ausschleusungen / Stunde' | translate]"
                      [ChartType]="'gauge'"
                      [Unit]="''"
                      [min]="0"
                      [max]="5000"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Strecke / Stunde' | translate"
                      [Error]="DistanceHour?.isError"
                      [ErrorLevel]="DistanceHour?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[distanceHour]"
                      [Legend]="['Strecke / Stunde' | translate]"
                      [ChartType]="'gauge'"
                      [Unit]="'km'"
                      [min]="0"
                      [max]="10"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Druck' | translate"
                      [Error]="null"
                      [ErrorLevel]="null"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="tmpPressure"
                      [Unit]="'bar'"
                      [Legend]="['Druck' | translate]"
                      [min]="5.8"
                      [max]="6.2"
                      [ChartType]="'gauge'"
                      [backgroundColor]="CompressionOn?.TimeseriesData.currentDatapoint.value === true ? '#90EE90' : '#ffffff'"
                      [resizeEvent]="childResizeEvent"></app-default-card>
</div>
