<app-group-header [Heading]="'Scanner Informationen' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card"
                      [Heading]="'Durchschnittliche Lesedauer (seit Auslieferung)' | translate"
                      [Error]="AverageReadSinceDelivery.isError"
                      [ErrorLevel]="AverageReadSinceDelivery.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AverageReadSinceDelivery.TimeseriesData]"
                      [Unit]="'ms'"
                      [Legend]="['Durchschnittliche Lesedauer (seit Auslieferung)' | translate]"
                      [Info]="'Wie viele Millisekunden eine Barcodeauslesung durch den Scanner im Durchschnitt seit der ersten Benutzung benötigt werden.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card"
                      [Heading]="'Durchschnittliche Lesedauer (seit Reset)'"
                      [Error]="AverageReadSinceReset.isError"
                      [ErrorLevel]="AverageReadSinceReset.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AverageReadSinceReset.TimeseriesData]"
                      [Unit]="'ms'"
                      [Legend]="['Durchschnittliche Lesedauer (seit Reset)' | translate]"
                      [Info]="'Wie viele Millisekunden eine Barcodeauslesung durch den Scanner im Durchschnitt seit der letzten Ausschaltung benötigt werden.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card"
                      [Heading]="'Status' | translate"
                      [Error]="Status.isError"
                      [ErrorLevel]="Status.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [customTemplate]="customStatus"
                      [customNoData]="Status.TimeseriesData.values.length === 0"
                      [Legend]="['Status' | translate]"
                      [Info]="'Gibt des aktuelle Staus des Scanners an: Scanning/Untätig/Error'"
                      [resizeEvent]="childResizeEvent"></app-default-card>
    <ng-template #customStatus>
        {{Status.TimeseriesData.currentDatapoint.value | operatingMode: operationModes}}
    </ng-template>

    <app-default-card class="card"
                      [Heading]="'Serialnummer' | translate"
                      [Error]="Serialnumber.isError"
                      [ErrorLevel]="Serialnumber.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Serialnumber.TimeseriesData]"
                      [Type]="'text'"
                      [Legend]="['Serialnummer' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
