import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Configurations} from '../../../../../configurations';
import {DateService} from '../../../../controller/services/date.service';
import {DataPoint} from '../../../../model/helper-models/datapoint.model';
import {BarchartOptions} from '../../options/barchart-options';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-barchart',
    template:
        `
    <div #chart style="height: 100%" echarts [options]="options" [merge]="updateOptions"></div>
  `
})
/**
 * Balkendiagramm
 */
export class BarchartComponent implements OnInit, OnDestroy
{
    @Input() name = '';

    @Input() unit = '';

    @Input() title = '';

    @Input() legend = [];


    /**
     * Array mit Datenpunkten
     * 1 Datenpunkt = 1 Balken
     */
    @Input() value: Array<Array<DataPoint<number>>> = null;

    /**
     * Optionen für die Chart
     */
    @Input() setOptions: BarchartOptions;

    /**
     * Optionen die an die Chart gebindet werden
     */
    options: any;

    /**
     * Optionen die in jedem Intervall übergeben werden um die Chart upzudaten
     */
    updateOptions: any;

    /**
     * Standardfarben
     */
    public color_default = ['#20818C', '#1BA68C', '#18855A', '#A4D97E', '#6EA61B', '#599225', '#9C9828', '#857129', '#9C6E28', '#924922'];

    /**
     * Neues Farbschema
     */
    public color_new = ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'];

    /**
     * Standard Optionen falls keine setOptions übergeben wurden
     */
    standardOptions = {
        title: {
            text: ''
        },
        tooltip: {},
        xAxis: {
            data: '',
            silent: false,
            splitLine: {
                show: true
            }
        },
        yAxis: {},
        series: [
            {
                name: '',
                color: this.color_new,
                type: 'bar',
                data: this.value
            }
        ]
    };

    /**
     * Intervall zum Chart Updaten
     */
    timer: any;

    /**
     * Konstruktor
     * @param Date
     */
    constructor(private Date: DateService, private router: Router)
    {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() =>
            {

            });
    }

    /**
     * Setzt Optionen und Initalisiert Chart
     */
    ngOnInit()
    {
        if (this.setOptions == null && this.value == null)
        {
            this.options = this.standardOptions;
            return;
        }


        this.options = {
            tooltip: {},
            legend: {},
            xAxis: {
                type: 'category',
                data: this.calcLegend()
            },
            yAxis: {},
            series: this.parseData(this.value),
            animationEasing: 'elasticOut'
        };

        // Startet timer der im Interval aufgerufen wird
        this.timer = setInterval(() =>
        {
            if (this.Date.auto)
            {
                // Updatet Barchart Datenpunkte
                this.updateOptions = {
                    xAxis: {
                        type: 'category',
                        data: this.calcLegend()
                    },
                    animation: false,
                    series: this.parseData(this.value)
                };
            }
            else
            {
                clearInterval(this.timer);
            }
        }, Configurations.chartUpdateTime);
    }

    /**
     * Wandelt einen DataPoint in ein Objekt um das von der Barchart angenommen wird.
     */
    private parseData<T>(pDataArray: any)
    {
        const datapoints = [{
            type: 'bar',
            data: []
        }];


        for (let i = 0; i < pDataArray.length; i++)
        {
            if (pDataArray[i].length > 0)
            {
                datapoints[0].data.push(
                    {
                        value: pDataArray[i][pDataArray.length - 1]._value,
                        itemStyle: {
                            color: this.color_new[i]
                        }
                    });
            }
        }


        return datapoints;
    }

    calcLegend()
    {
        const result = [];

        for (let i = 0; i < this.value.length; i++)
        {
            if (this.value[i] == null) continue;
            if (this.value[i].length === 0) continue;
            result.push(this.legend[i]);
        }

        return result;
    }

    /**
     * Stoppt Timer
     */
    ngOnDestroy()
    {
        clearInterval(this.timer);
    }
}
