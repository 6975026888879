import {DataPoint} from './datapoint.model';
import {LimitSizeArrayModel} from './limit-size-array.model';
import {MonitoringLevel} from '../MonitoringLevels.enum';

/**
 * Abstrakte Klasse für ein PropertySet
 */
export class Property<T>
{
    public Name: string;
    public isError: boolean;
    public TimeseriesData: LimitSizeArrayModel<DataPoint<T>>;

    /**
     * Wert der über den last-maintenance Service eingetragen wird
     */
    public ValueLastService: DataPoint<T> = null;

    /**
     * Maximale Anzahl an Datensätzen im Live Betrieb
     */
    public maxDataCount: number;

    public upperThreshold: number;
    public upperupperThreshold: number;
    public lowerThreshold: number;
    public lowerlowerThreshold: number;

    /**
     * Typ wird für berechnete Testfahrten benötigt
     */
    public type: string;

    /**
     * Status wie schwerwiegend es ist wenn keine Daten für das Property kommen
     */
    public status: MonitoringLevel;

    /**
     * Gibt an ob Status angezeigt werden soll (wenn keine Daten kommen)
     */
    public statusActive = false;

    /**
     * Gibt an ob das Property über einen Snapshot call geholt werden soll
     */
    public isSnapshot = false;

    public isCalculated = false;

    public constructor(pMaxDataCount: number, pStatus = MonitoringLevel.Error, pData: LimitSizeArrayModel<DataPoint<T>> = null)
    {
        if (pData != null)
        {
            this.TimeseriesData = pData;
        }
        else
        {
            this.TimeseriesData = new LimitSizeArrayModel<DataPoint<T>>(pMaxDataCount);
        }
        this.maxDataCount = pMaxDataCount;
        this.status = pStatus;
    }

    public insertData(pValues: any)
    {
        pValues.forEach((value) =>
        {
            this.TimeseriesData.pushDataPoint(new DataPoint<typeof value.value>(value.value, new Date(value.timestamp)));
        });
    }

    public insertValues(pValues: Array<DataPoint<T>>)
    {
        pValues.forEach((x) =>
        {
            this.TimeseriesData.pushDataPoint(x);
        });
    }
}
