import {Directive, Input} from '@angular/core';

/**
 * Basis Klasse für Kacheln
 */
@Directive()
export abstract class Tile
{
    /**
     * Kachel Überschrift
     */
    @Input() heading: string;

    /**
     * Kachel Einheit
     */
    @Input() unit: string;

    /**
     * Gibt an ob Fehler vorhanden sind
     */
    @Input() error: boolean;
}
