<app-group-header [Heading]="('Gesundheitsvorhersage - aktueller Zustand' | translate) + ' ' + actualRating + '%'"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Error]="Error"
                      [ErrorLevel]="ErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="PredictionValues"
                      [Unit]="'%'"
                      [Legend]="['gemessener Wert' | translate,'Vorhersage' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
<!--
<app-line-tile class="col-lg-12"
               [heading]="'Testfahrt-Statistik' | translate"
               [unit]="'(%)' | translate"
               [error]="chartError"
               [value]="predictionChartValues"
               [legend]="['gemessene Werte','Vorhersage' | translate]">
</app-line-tile>
    -->
