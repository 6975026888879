import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Configurations} from '../../../../configurations';
import {DataProviderService} from '../../../controller/services/data-provider.service';
import {NavigationService} from '../../../controller/services/navigation.service';
import {CLocation} from '../../../model/location.model';
import {CSystem} from '../../../model/system.model';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-monitoring-system',
    templateUrl: './monitoring-system.component.html',
    styleUrls: ['./monitoring-system.component.scss',
        '../monitoring.scss']
})
/**
 * Komponente in die einzelnen Anlagen Cards angezeigt werden
 */
export class MonitoringSystemComponent implements OnInit, OnDestroy, AfterViewInit
{
    /**
     * Location dessen Anlagen angezeigt werden
     */
    public location: CLocation;

    public isOneLine = true;

    @ViewChild('banner') banner: ElementRef;

    public systems: Array<CSystem>;

    /**
     * Konstruktor
     * @param data
     * @param nav
     * @param _route
     */
    constructor(public data: DataProviderService,
                public nav: NavigationService,
                private _route: ActivatedRoute)
    {
    }

    /**
     *  Setzt die aktiven Customer Details und holt den Standort
     */
    ngOnInit()
    {
        Configurations.InitaliseComplete.subscribe(x =>
        {
            if (x)
            {
                this._route.paramMap.subscribe(params =>
                {
                    this.nav.setActiveCustomerDetails();

                    this.data.customer.forEach(customer =>
                    {
                        if (customer.name === params.get('company'))
                        {
                            customer.locations.forEach(location =>
                            {
                                if (location.name === params.get('location'))
                                {
                                    this.location = location;
                                    this.systems = Array.from(this.location.systems.values());
                                }
                            });
                        }
                    });


                    this.nav.MonitoringNavigation = true;
                });
            }
        });
    }

    ngAfterViewInit(): void
    {
        if (this.banner == null) return;
        this.isOneLine = this.banner.nativeElement.offsetHeight <= 40;
    }

    /**
     * Setzt Monitoring Navigation auf false
     */
    ngOnDestroy(): void
    {
        this.nav.MonitoringNavigation = false;
    }

    protected readonly environment = environment;
}
