import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Configurations} from '../../../configurations';
import {DataPoint} from '../../model/helper-models/datapoint.model';
import {LimitSizeArrayModel} from '../../model/helper-models/limit-size-array.model';
import {PropertySet} from '../../model/helper-models/property-set.model';
import {Thing} from '../../model/thing.model';
import {DateService} from './date.service';
import {ThresholdsService} from './thresholds.service';
import {AuthenticationService} from './authentication.service';
import {lastValueFrom} from 'rxjs/internal/lastValueFrom';
import {environment} from '../../../../environments/environment';

@Injectable()
/*
 * Service der die Timeseries Daten der Things holt
 */
export class GetThingDataService
{

    constructor(private _http: HttpClient,
                private _date: DateService,
                private _toastr: ToastrService,
                private _thresholdsService: ThresholdsService,
                private _authService: AuthenticationService)
    {
    }

    /**
     * Timer zum periodischen abrufen der Timeseries Daten
     */
    static pullTimer: any = false;
    static pullBackendTimer: any = false;

    /**
     * Alle Things die mit jedem intervall abgerufen werden sollen
     */
    private Things: Array<Thing>;


    public getTimeToNextXMinutes(minutes: number): number
    {
        const nextXMinutes = new Date();
        nextXMinutes.setMinutes(nextXMinutes.getMinutes() + minutes, 0, 0);

        return nextXMinutes.getTime() - Date.now();
    }

    /**
     * Setzt Things die gepulled werden sollen
     * @param pThings
     */
    public setThings(pThings: Array<Thing>): void
    {
        this.Things = pThings;
    }

    /**
     * Gibt alle Things zurück die zum pullen vorgesehen sind
     */
    public getThings(): Array<Thing>
    {
        return this.Things;
    }

    /**
     *  Stoppt den Timer
     **/
    public stopPullTimer(): void
    {
        clearInterval(GetThingDataService.pullTimer);
    }

    /**
     * Startet Timer um alle this.Things zu pullen
     */
    public startPullTimer(): void
    {
        const nextFiveMinutes = this.getTimeToNextXMinutes(15);
        GetThingDataService.pullTimer = setInterval(this.pullLiveData.bind(this), nextFiveMinutes);
    }

    private pullLiveData()
    {
        if (GetThingDataService.pullTimer == null || GetThingDataService.pullTimer === false)
        {
            return;
        }
        clearInterval(GetThingDataService.pullTimer);
        const nextFiveMinutes = this.getTimeToNextXMinutes(15);
        this.getTimeseriesInRange(new Date(), 1);
        GetThingDataService.pullTimer = setInterval(this.pullLiveData.bind(this), nextFiveMinutes);
    }

    // ToDo Auslagerung in einen eigenen Service
    /**
     *  Stoppt den Timer für ausgewertete Daten aus dem Backend
     **/
    public stopBackendPullTimer(): void
    {
        clearInterval(GetThingDataService.pullBackendTimer);
    }

    // Auslagerung in einen eigenen Service
    /**
     * Startet Timer um zyklisch ausgewertete Daten aus dem Backend
     */
    public startBackendPullTimer(): void
    {
        GetThingDataService.pullBackendTimer = setInterval(() =>
        {
            this.getEvaluatedBackendData();
        }, Configurations.pullBackendTimer);
    }

    /**
     * Startet den timer neu.
     * Wird beim umstellen von historischen Daten auf live verwendet.
     */
    public restartTimer(): void
    {
        for (const thing of this.Things)
        {

            if (thing == null || thing.propertySets.size == null)
            {
                return;
            }

            thing.lastPull = null;
        }

        this.getTimeseriesInRange(new Date(), 1);

        this.startPullTimer();
    }

    /**
     * Holt einmalig Daten Things
     */
    public callOnce(): void
    {
        if (this._date.auto)
        {
            this.getTimeseriesInRange(new Date(), 1);
        }
        else
        {
            this.stopPullTimer();
            this.getHistoricThingData();
            this.stopBackendPullTimer();
        }
    }

    /**
     * Formatiert den Zeitstempel des Datenpunkts und gibt ihn zurück
     * @param pDatapoint
     */
    public getDate(pDatapoint: DataPoint<any>): string
    {
        if (pDatapoint != null)
        {
            if (typeof pDatapoint.timestamp === 'string' && Date.parse(pDatapoint.timestamp) > 0)
            {
                const tmpDate = new Date(pDatapoint.timestamp);

                return tmpDate.getDate() + '.' + (tmpDate.getMonth() + 1) + '.' + tmpDate.getFullYear();
            }
            else if (pDatapoint.timestamp instanceof Date)
            {
                return pDatapoint.timestamp.getDate() + '.' + (pDatapoint.timestamp.getMonth() + 1) + '.' +
                    pDatapoint.timestamp.getFullYear();
            }
            else
            {
                return null;
            }
        }
        else
        {
            return null;
        }
    }

    /**
     * Holt sich die ausgewerteten Energiedaten aus dem Backend
     * Anfrage ans Backend
     */
    private GetCurrentDataFromBackend(thing, propSet): void
    {
        const pSetName = 'galileoiot.live.' + Configurations.packageName + ':' + thing.thingType;
        const urlstring = '/backend/api/current/Things(\'' + thing.id + '\')/' + pSetName + '?$timerange=' + this.checkTimerange(propSet);

        const httpOptions =
            {
                headers: new HttpHeaders({
                    'cache-control': 'no-cache',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'company': thing.parentSystem.parentLocation.parentCompany.name,
                    'location': thing.location
                })
            };
    }

    private getEvaluatedBackendData()
    {
        for (const thing of this.Things)
        {
            this._thresholdsService.setThresholds(thing);
        }
    }

    /**
     * Holt sich die historischen Daten der Things
     * Anfrage ans Backend
     */
    private getHistoricThingData()
    {
        this._toastr.info('Historische Daten werden abgerufen. Der Vorgang kann einige Zeit in Anspruch nehmen.', null, {
            positionClass: 'toast-bottom-right',
            timeOut: 10000
        });
        this.Things.forEach(pThing =>
        {
            const dates = this.historicTimerange(this._date.startDate, this._date.endDate);

            if (pThing.id != null)
            {
                this.callHistoricData(pThing, dates[0], dates[1]).then(
                    value =>
                    {
                        if (Object.keys(value.sensor).length !== 0)
                        {
                            this._toastr.success('Historische Daten erfolreich abgerufen', null, {
                                positionClass: 'toast-bottom-right',
                                timeOut: 10000
                            });
                            pThing.pushHistoricValues(value.sensor);
                            console.log(pThing.propertySets);
                            Configurations.HistoricFinished.next(pThing.id);
                        }
                    },
                    error =>
                    {
                        this._toastr.error('Fehler beim Abrufen der Historischen Daten!', null, {
                            positionClass: 'toast-bottom-right',
                            timeOut: 10000
                        });
                    });
            }
        });
    }


    private async getTimeseries(pDevice: string, pTimeStart: string, pTimeEnd, headers): Promise<any>
    {

        const url = environment.apiUrl + environment.apiVersion + '/thing/timeseries';

        const params = new HttpParams()
            .set('ThingId', pDevice)
            .set('TimeStart', pTimeStart)
            .set('TimeEnd', pTimeEnd)
            .set('Top', 250);

        return lastValueFrom(this._http.get(url, {headers: headers, params: params}));

    }

    private async getPowerConsumption(pDevice: string, headers)
    {
        const url = environment.apiUrl + environment.apiVersion + '/thing/power-consumption';

        return lastValueFrom(this._http.get(url + '?ThingId=' + pDevice, {headers: headers}));

    }

    private async getTimeseriesInRange(pStartTime: Date, pRange: number)
    {

        const accessToken = await this._authService.getToken();
        const headers = new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + accessToken.accessToken
        });

        const calls = [];
        const callsPower = [];


        let timeStart;

        for (const thing of this.Things)
        {
            if (thing.id == null) continue;

            if (thing.lastPull == null)
            {
                timeStart = new Date();
                timeStart.setHours(timeStart.getHours() - 24);
            }
            else
            {
                timeStart = thing.lastPull;
            }

            const timeEnd = new Date();

            calls.push({call: this.getTimeseries(thing.id, timeStart.toISOString(), timeEnd.toISOString(), headers), thing: thing});
            callsPower.push({call: this.getPowerConsumption(thing.id, headers), thing: thing});
            
            thing.lastPull = new Date();
        }

        calls.forEach((x) => {
            x.call.then(response =>
            {
                const propertySets = new Map(Object.entries(response.sensor));
                x.thing.insertData(propertySets);
                console.log(propertySets);
            });
        });
        /*
        calls[0].then((x) =>
        {
            const propertySets = new Map(Object.entries(x.sensor));
            thing.insertData(propertySets);
            console.log(propertySets);
        });
*/

        callsPower.forEach(x => {
            x.call.then(y => {
                if (y == null) return;
                const propertySets = new Map(Object.entries(y.sensor));
                x.thing.insertData(propertySets);
                console.log(propertySets);
            });
        });
        /*
        callsPower[0].then((x) =>
        {
            if (x == null) return;
            const propertySets = new Map(Object.entries(x.sensor));
            thing.insertData(propertySets);
            console.log(propertySets);
        });
*/

    }


    /**
     * Ruft /backend/api/historicData ab um historische Daten zu bekommen
     * Gibt Parameter als Post im Request Body mit
     * @param pThing
     * @param pStartTime
     * @param pEndTime
     */
    private async callHistoricData(pThing: Thing, pStartTime: string, pEndTime: string)
    {

        const url = environment.apiUrl + environment.apiVersion + '/thing/history?'
            + 'ThingId=' + pThing.id
            + '&TimeStart=' + pStartTime
            + '&TimeEnd=' + pEndTime;

        const headers = await this._authService.getDefaultHttpHeaders();

        const result = await lastValueFrom(this._http.get(url, {headers: headers})) as any;

        if (result == null)
        {
            return;
        }
/*
        for (const key of Object.keys(result.sensor))
        {

            for (const propkey of Object.keys(result.sensor[key]))
            {
                const limitArr = new LimitSizeArrayModel<DataPoint<any>>(10000);

                result.sensor[key][propkey].forEach(datapoint =>
                {
                    limitArr.pushDataPoint(new DataPoint<any>(datapoint.value, datapoint.timestamp));
                });
                result.sensor[key][propkey] = limitArr;
            }
        }
*/
        return result;

    }

    /**
     * Gibt die Zeitspanne zurück in der Daten für ein Property Set geholt werden sollen
     * @param pPropertySet
     */
    private checkTimerange(pPropertySet: PropertySet): string
    {
        const currentdate = new Date();

        if (pPropertySet.lastPullDate == null)
        {
            pPropertySet.lastPullDate = new Date();
            pPropertySet.lastPullDate.setSeconds(pPropertySet.lastPullDate.getSeconds() - pPropertySet.initPullTimerange);
        }

        pPropertySet.lastPullDate.setMilliseconds(pPropertySet.lastPullDate.getMilliseconds() + 1);

        return pPropertySet.lastPullDate.toISOString() + '-' + currentdate.toISOString();
    }

    /**
     * Wandelt das vom Datepicker gewählte Start- und Enddatum in SAPDate Strings um
     * @param pStartDate
     * @param pEndDate
     */
    private historicTimerange(pStartDate: Date, pEndDate: Date): Array<string>
    {
        this.stopPullTimer();

        if (pStartDate.getUTCHours() === 0)
        {
            // Für Firefox da etwas mit der Zeitumwandlung schief läuft und die +2 von GMT
            // zweimal addiert werden
            pStartDate.setUTCHours(this._date.startDate.getUTCHours() - 2);
            pEndDate.setUTCHours(this._date.endDate.getUTCHours() - 2);
        }
        return [pStartDate.toISOString(), pEndDate.toISOString()];
    }

}

