<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">

    <h2>HealthPage</h2>
    <p>
        {{'Das Gebhardt Galileo IoT HealthPage Feature ermöglicht das Monitoring des Gesundheitszustandes einzelner Geräte innerhalb einer Anlage, sowie einzelner Geräte-Komponenten basierend auf Schwellenwerten.' | translate}}
    </p>

    <h3 class="subheading">{{'Tagesübersicht' | translate}}</h3>
    <p>
        {{'Mithilfe der Tagesübersicht kann man einen Gesamteindruck der kompletten Anlage bekommen. Man wählt lediglich das Gerät und das Datum aus. In der Tabelle sieht man dann die einzelnen Komponenten des Geräts mit ihren jeweiligen Gesundheitszuständen.' | translate}}
    </p>
    <img id="imageThingOverview"
         src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageDayliOverview_de.png" class="w-100">

    <h3 class="subheading">{{'Messgrößensicht' | translate}}</h3>
    <p>
        {{'In der Messgrößensicht lassen sich Livewerte, Grenzwerte und der Healt-Wert nach Datum anzeigen. Zunächst filtert man nach dem gewünschten Gerät, Bauteil, Komponente und anschließend die Messgröße.' | translate}}
    </p>
    <img id="imageThingOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageMeasurement_de.png"
         class="w-100">
    <p>{{'Nachdem gefiltert wurde, aktualisiert sich die Grafik automatisch.' | translate}}</p>
    <p>{{'Durch einen Click in der Grafik den Grafen, lässt sich dieser vergrößert anzeigen. Hovert man mit dem Mauszeiger auf den Grafen, wird eine Detailansicht zu diesem Zeitpunkt angezeigt.' | translate}}</p>
    <img id="imageThingOverview"
         src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageMeasurementGraphics_de.png" class="w-100">

    <h3 class="subheading">{{'Historie' | translate}}</h3>
    <p>{{'In der Übersicht Historie lassen sich Health-Wert, nach Datum und Bauteil anzeigen. Hierfür muss lediglich das Gerät als Filter ausgewählt werden.'  | translate}}</p>
    <img id="imageThingOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageHistory_de.png"
         class="w-100">


<h3 class="subheading">{{'Aufruf der Einzelgeräte' | translate}}</h3>
    <p>
     {{'Die Health Page kann folgendermaßen aufgerufen werden.' | translate}}</p>
    <img id="imageThingOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageCall_de.png" class="w-100">

    <h3 class="subheading">{{'Übersicht' | translate}}</h3>    
    <img id="imageThingOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageOverView_de.png" class="w-100">
    <p>{{'1. Bezeichnung des Gerätes' | translate}}</p>
    <p>{{'2. Aktueller Zustand betrifft alle Einzelkomponenten des Gerätes' | translate}}</p>
    <p><span class="tab"></span>a.<img id="warningIcon" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageWarning_de.png" class="w-100"> {{'22 Warnungen' | translate}}</p>
    <p><span class="tab"></span>b.<img id="warningIcon" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageThresholdValueExceeded_de.png" class="w-100"> {{'28 Schwellwertüberschreitungen' | translate}}</p>


    <h3 class="subheading">{{'Einzelkomponenten' | translate}}</h3>
    <p>{{'1. Kurzinfo / Aufklappen Überblick der Baugruppen' | translate}}</p>
    <p>2. <img id="imageThingOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageIndividualComponentsInfo_de.png" class="w-100"></p>
    <p>{{'3. Aufklappen der Baugruppe Sicht der Einzelkomponenten' | translate}}</p>
    <p>{{'4. Einzelkomponente / Doppelklich Schnellübersicht' | translate}}</p>
    <img id="imageThingOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageIndividualComponent_de.png" class="w-100">
    <p>{{'5. Status der Baugruppen/Komponenten' | translate}}</p>
    <p>{{'6. Health detaillierte Informationen' | translate}}</p>
    <img id="imageThingOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageIndividualComponentDetailedInformation_de.png" class="w-100">

    <h3 class="subheading">{{'Health detaillierte Informationen / Wartungsinformation Information und Bearbeitung' | translate}}</h3>
    <p>{{'Für die Bearbeitung haben nur bestimmte User Berechtigungen nach Absprache' | translate}} </p>
    <img id="imageThingOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageTelescopicRail_de.png" class="w-100">
    <p>{{'1. Austausch vorziehen - bedeutet die Komponente ist schon stärker abgenutzt, als erwartet z.B. 4 Jahre bis zum Austausch wird auf 2 verkürzt und mit OK bestätigt - Messwerte werden dementsprechend angepasst' | translate}}</p>
    <img id="imageThingOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healtPageExchange_de.png" class="w-100">
    <p>{{'2. Austausch verschieben - Komponentenzustand ist noch sehr gut und wird um 1 Jahr verlängert. - Messwerte werden dementsprechend angepasst ' | translate}} </p>
    <p>{{'3. Komponente ausgetauscht - Komponente wird gewechselt und dies wird auch dann direkt dokumentiert ' | translate}} </p>
    <p>{{'4. Wartungsinformation zur Prüfung der Komponente' | translate}} </p>
    <p>{{'5. Welche Messgrößen werden angewandt' | translate}} </p>

    <h3 class="subheading">{{'Grenzwerte der Einzelkomponenten verändern' | translate}}</h3>
    <p>{{'Grenzwertbearbeitung' | translate}}</p>
    <img id="imageThingOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/healthPage/healthPageLimitValueProcessing_de.png" class="w-100">
    <p>{{'1. Change Thresholds Schieberegler einstellen' | translate}}</p>
    <p>{{'2. Neuen Grenzwert eintragen' | translate}}</p>
    <p>{{'3. Apply Globally Auswählen nur für diese Einzelkomponente bei diesem Gerät oder für alle gleichen Einzelkomponenten bei diesem Gerätetyp' | translate}}</p>
    <p>{{'4. Save' | translate}}</p>



