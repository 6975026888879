<app-group-header [Heading]="'Energie Management' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Ladezyklen' | translate"
                      [Error]="CountLadezyklen?.isError"
                      [ErrorLevel]="CountLadezyklen?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[CountLadezyklen?.TimeseriesData]"
                      [Legend]="['Ladezyklen' | translate]"
                      [Unit]="''"
                      [Info]="'Anzahl der Vollladungen des Geräts'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Steckzyklen' | translate"
                      [Error]="CountSteckzyklen?.isError"
                      [ErrorLevel]="CountSteckzyklen?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[CountSteckzyklen?.TimeseriesData]"
                      [Legend]="['Steckzyklen' | translate]"
                      [Unit]="''"
                      [Info]="'Anzahl der Steckungen des Geräts'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Ladezyklen Stromabnehmer' | translate"
                      [Error]="CountLadezyklenStromabnehmer?.isError"
                      [ErrorLevel]="CountLadezyklenStromabnehmer?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[CountLadezyklenStromabnehmer?.TimeseriesData]"
                      [Legend]="['Steckzyklen' | translate]"
                      [Unit]="''"
                      [Info]="'Anzahl der Ladezyklen des Stromabnehmers'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
