import {NgModule} from '@angular/core';
import {SumOfPowerComponent} from '../../../packages/thing-view-component/sum-of-power/sum-of-power.component';
import {ImageComponent} from '../../../packages/thing-view-component/image/image.component';
import {EnergyConsumptionComponent} from '../../../packages/thing-view-component/energy-consumption/energy-consumption.component';
import {TestrunStatusComponent} from '../../../packages/thing-view-component/testrun-status/testrun-status.component';
import {TestrunComponent} from '../../../packages/thing-view-component/testrun/testrun.component';
import {DistanceComponent} from '../../../packages/thing-view-component/distance/distance.component';
import {StatisticComponent} from '../../../packages/thing-view-component/statistic/statistic.component';
import {StatusComponent} from '../../../packages/thing-view-component/status/status.component';
import {CmSensorsComponent} from '../../../packages/thing-view-component/cm-sensors/cm-sensors.component';
import {ControlCabinetComponent} from '../../../packages/thing-view-component/control-cabinet/control-cabinet.component';
import {HealthPredictionComponent} from '../../../packages/thing-view-component/health-prediction/health-prediction.component';
import {TestrunCalculatedComponent} from '../../../packages/thing-view-component/testrun-calculated/testrun-calculated.component';
import {DefaultCardComponent} from '../../view/cards/default-card/default-card.component';
import {GroupHeaderComponent} from '../../view/cards/group-header/group-header.component';
import {CurrentVoltageComponent} from '../../../packages/thing-view-component/current-voltage/current-voltage.component';
import {TranslateModule} from '@ngx-translate/core';
import {ChartsModule} from './charts.module';
import {CommonModule} from '@angular/common';
import {PipesModule} from './pipes.module';
import {TripleCardComponent} from '../../view/cards/triple-card/triple-card.component';
import {DistanceVerticalComponent} from '../../../packages/thing-view-component/distance-vertical/distance-vertical.component';
import {RollInOutComponent} from '../../../packages/thing-view-component/roll-in-out/roll-in-out.component';
import {TemperatureComponent} from '../../../packages/thing-view-component/temperature/temperature.component';
import {ErrorMessagesComponent} from '../../../packages/thing-view-component/error-messages/error-messages.component';
import {MovementsComponent} from '../../../packages/thing-view-component/movements/movements.component';
import {ElectricityComponent} from '../../../packages/thing-view-component/electricity/electricity.component';
import {OrdersComponent} from '../../../packages/thing-view-component/orders/orders.component';
import {FingerTimeComponent} from '../../../packages/thing-view-component/finger-time/finger-time.component';
import {TimeComponent} from '../../../packages/thing-view-component/time/time.component';
import {PositionComponent} from '../../../packages/thing-view-component/position/position.component';
import {FingerMovementsComponent} from '../../../packages/thing-view-component/finger-movements/finger-movements.component';
import {FinePositioningComponent} from '../../../packages/thing-view-component/fine-positioning/fine-positioning.component';
import {ConverStatusComponent} from '../../../packages/thing-view-component/conver-status/conver-status.component';
import {ConverStatisticComponent} from '../../../packages/thing-view-component/conver-statistic/conver-statistic.component';
import {ConverErrorsComponent} from '../../../packages/thing-view-component/conver-errors/conver-errors.component';
import {LscScanInformationComponent} from '../../../packages/thing-view-component/lsc-scan-information/lsc-scan-information.component';
import {GoodBadReadsComponent} from '../../../packages/thing-view-component/good-bad-reads/good-bad-reads.component';
import {ScanQualityComponent} from '../../../packages/thing-view-component/scan-quality/scan-quality.component';
import {LastScanComponent} from '../../../packages/thing-view-component/last-scan/last-scan.component';
import {
    SsagmCarrierStatisticComponent
} from '../../../packages/thing-view-component/ssagm-carrier-statistic/ssagm-carrier-statistic.component';
import {SsagmStatisticComponent} from '../../../packages/thing-view-component/ssagm-statistic/ssagm-statistic.component';
import {SsagmStatisticStdComponent} from '../../../packages/thing-view-component/ssagm-statistic-std/ssagm-statistic-std.component';
import {SsifStatisticComponent} from '../../../packages/thing-view-component/ssif-statistic/ssif-statistic.component';
import {SsifBeltComponent} from '../../../packages/thing-view-component/ssif-belt/ssif-belt.component';
import {TemperatureCurrentComponent} from '../../../packages/thing-view-component/temperature-current/temperature-current.component';
import {SsdtmInfoComponent} from '../../../packages/thing-view-component/ssdtm-info/ssdtm-info.component';
import {
    SsdtmLubricationCartridgeComponent
} from '../../../packages/thing-view-component/ssdtm-lubrication-cartridge/ssdtm-lubrication-cartridge.component';
import {
    SsdtmLubricationGeneralComponent
} from '../../../packages/thing-view-component/ssdtm-lubrication-general/ssdtm-lubrication-general.component';
import {
    SsdtmClampingGeneralComponent
} from '../../../packages/thing-view-component/ssdtm-clamping-general/ssdtm-clamping-general.component';
import {SsdtmClampingChainComponent} from '../../../packages/thing-view-component/ssdtm-clamping-chain/ssdtm-clamping-chain.component';
import {SsesStatisticComponent} from '../../../packages/thing-view-component/sses-statistic/sses-statistic.component';
import {SsesFlapsComponent} from '../../../packages/thing-view-component/sses-flaps/sses-flaps.component';
import {SscrrStatisticComponent} from '../../../packages/thing-view-component/sscrr-statistic/sscrr-statistic.component';
import {SscrrCylinderComponent} from '../../../packages/thing-view-component/sscrr-cylinder/sscrr-cylinder.component';
import {SsagmStatIfComponent} from '../../../packages/thing-view-component/ssagm-stat-if/ssagm-stat-if.component';
import {SsagmStatEsComponent} from '../../../packages/thing-view-component/ssagm-stat-es/ssagm-stat-es.component';
import {SsagmStatDtmComponent} from '../../../packages/thing-view-component/ssagm-stat-dtm/ssagm-stat-dtm.component';
import {PlaceholderCardComponent} from '../../view/cards/placeholder-card/placeholder-card.component';
import {FtsEnergyComponent} from '../../../packages/thing-view-component/fts-energy/fts-energy.component';
import {FtsChassisKmComponent} from '../../../packages/thing-view-component/fts-chassis-km/fts-chassis-km.component';
import {FtsChassisComponent} from '../../../packages/thing-view-component/fts-chassis/fts-chassis.component';
import {FtsGeneralComponent} from '../../../packages/thing-view-component/fts-general/fts-general.component';
import {BotStatusComponent} from '../../../packages/thing-view-component/bot-status/bot-status.component';
import {BotVolcurtempComponent} from '../../../packages/thing-view-component/bot-volcurtemp/bot-volcurtemp.component';
import {CmSensorsTwoComponent} from '../../../packages/thing-view-component/cm-sensors-two/cm-sensors-two.component';
import {BatteryComponent} from '../../../packages/thing-view-component/battery/battery.component';

@NgModule({
    declarations: [
        CurrentVoltageComponent,
        SumOfPowerComponent,
        ImageComponent,
        EnergyConsumptionComponent,
        TestrunStatusComponent,
        TestrunComponent,
        DistanceComponent,
        StatisticComponent,
        StatusComponent,
        CmSensorsComponent,
        ControlCabinetComponent,
        HealthPredictionComponent,
        TestrunCalculatedComponent,
        DefaultCardComponent,
        GroupHeaderComponent,
        TripleCardComponent,
        PlaceholderCardComponent,
        DistanceVerticalComponent,
        RollInOutComponent,
        TemperatureComponent,
        ErrorMessagesComponent,
        MovementsComponent,
        ElectricityComponent,
        OrdersComponent,
        PositionComponent,
        TimeComponent,
        FingerMovementsComponent,
        FingerTimeComponent,
        FinePositioningComponent,
        ConverStatusComponent,
        ConverStatisticComponent,
        ConverErrorsComponent,
        LscScanInformationComponent,
        GoodBadReadsComponent,
        ScanQualityComponent,
        LastScanComponent,
        SsagmCarrierStatisticComponent,
        SsagmStatisticComponent,
        SsagmStatisticStdComponent,
        SsifStatisticComponent,
        SsifBeltComponent,
        TemperatureCurrentComponent,
        SsdtmInfoComponent,
        SsdtmLubricationCartridgeComponent,
        SsdtmLubricationGeneralComponent,
        SsdtmClampingGeneralComponent,
        SsdtmClampingChainComponent,
        SsesStatisticComponent,
        SsesFlapsComponent,
        SscrrStatisticComponent,
        SscrrCylinderComponent,
        SsagmStatIfComponent,
        SsagmStatEsComponent,
        SsagmStatDtmComponent,
        FtsEnergyComponent,
        FtsChassisKmComponent,
        FtsChassisComponent,
        FtsGeneralComponent,
        BotStatusComponent,
        BotVolcurtempComponent,
        CmSensorsTwoComponent,
        BatteryComponent
    ],
    exports: [
        CurrentVoltageComponent,
        SumOfPowerComponent,
        ImageComponent,
        EnergyConsumptionComponent,
        TestrunStatusComponent,
        TestrunComponent,
        DistanceComponent,
        StatisticComponent,
        StatusComponent,
        CmSensorsComponent,
        ControlCabinetComponent,
        HealthPredictionComponent,
        TestrunCalculatedComponent,
        DefaultCardComponent,
        GroupHeaderComponent,
        TripleCardComponent,
        PlaceholderCardComponent,
        DistanceVerticalComponent,
        RollInOutComponent,
        TemperatureComponent,
        ErrorMessagesComponent,
        MovementsComponent,
        ElectricityComponent,
        OrdersComponent,
        PositionComponent,
        TimeComponent,
        FingerMovementsComponent,
        FingerTimeComponent,
        FinePositioningComponent,
        ConverStatusComponent,
        ConverStatisticComponent,
        ConverErrorsComponent,
        LscScanInformationComponent,
        GoodBadReadsComponent,
        ScanQualityComponent,
        LastScanComponent,
        SsagmCarrierStatisticComponent,
        SsagmStatisticComponent,
        SsagmStatisticStdComponent,
        SsifStatisticComponent,
        SsifBeltComponent,
        TemperatureCurrentComponent,
        SsdtmInfoComponent,
        SsdtmLubricationCartridgeComponent,
        SsdtmLubricationGeneralComponent,
        SsdtmClampingGeneralComponent,
        SsdtmClampingChainComponent,
        SsesStatisticComponent,
        SsesFlapsComponent,
        SscrrStatisticComponent,
        SscrrCylinderComponent,
        SsagmStatIfComponent,
        SsagmStatEsComponent,
        SsagmStatDtmComponent,
        FtsEnergyComponent,
        FtsChassisKmComponent,
        FtsChassisComponent,
        FtsGeneralComponent,
        BotStatusComponent,
        BotVolcurtempComponent,
        CmSensorsTwoComponent,
        BatteryComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ChartsModule,
        PipesModule
    ]
})
export class SubComponentModule
{
}
