import {Component, Input, OnInit} from '@angular/core';
import {EChartsOption} from 'echarts/types/dist/echarts';
import {InfoPoint} from '../../../../model/helper-models/infopoint.model';

interface TreeNode
{
    name: string;
    children?: TreeNode[];
}

@Component({
  selector: 'app-treechart',
  template: `
        <div *ngIf="isTreeLoaded" echarts [options]="options" ></div>
  `,
  styleUrls: ['./treechart.component.scss']
})
export class TreechartComponent implements OnInit {
    @Input() data: any[];
    isTreeLoaded = false;

    treeData: any[] = [];

    options: EChartsOption = {
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        series: [
            {
                type: 'tree',

                data: this.treeData,

                left: '2%',
                right: '2%',
                top: '8%',
                bottom: '20%',

                symbol: 'emptyCircle',

                orient: 'vertical',

                expandAndCollapse: true,

                label: {
                    position: 'top',
                    rotate: -90,
                    verticalAlign: 'middle',
                    align: 'right',
                    fontSize: 9
                },

                leaves: {
                    label: {
                        position: 'bottom',
                        rotate: -90,
                        verticalAlign: 'middle',
                        align: 'left'
                    }
                },

                animationDurationUpdate: 750
            }
        ]
    };
  constructor() { }

  ngOnInit(): void {
      this.pushData();
      this.isTreeLoaded = true;
      console.log(this.treeData);
  }

  pushData() {
      this.treeData = this.data as TreeNode[];
  }

}
