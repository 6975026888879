import {Component, EventEmitter, Inject, Input, OnInit, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../interfaces/dialog-data';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {AuthenticationService} from '../../../../../core/controller/services/authentication.service';
import {environment} from '../../../../../../environments/environment';
import {DataProviderService} from '../../../../../core/controller/services/data-provider.service';
import {Thing} from '../../../../../core/model/thing.model';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {ThingHealthV2Component} from '../../../thing-health-v2/thing-health-v2.component';
import {TreeNodeComponent} from '../../tree/material-tree-health-node/tree-node/tree-node.component';
import Tree from 'echarts/types/src/data/Tree';

@Component({
  selector: 'app-material-component-view-dialog',
  templateUrl: './material-component-view-dialog.component.html',
  styleUrls: ['./material-component-view-dialog.component.scss']
})
export class MaterialComponentViewDialogComponent implements OnInit {
    thing: Thing;
    @Input() nodeData: any;
    public newTableData: any;
    public onUpdate = new EventEmitter();

  constructor(
      public dialogRef: MatDialogRef<MaterialComponentViewDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      public _data: DataProviderService,
      public auth: AuthenticationService,
      private http: HttpClient,
      private activatedRoute: ActivatedRoute
  ) {
  }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeModal(): void {
        this.dialogRef.close();
    }

  ngOnInit(): void {
      this.thing = this.data['thingInfo'];
      this.newTableData = [this.nodeData];
      console.log(this.newTableData);
  }

    private async updateComponent(materialnummer)
    {
        this.onUpdate.emit();
        this.closeModal();
    }

    private async shortenMaintenanceDate(bauteil, komponente, materialnummer)
    {
        console.log('DEBUG', 'Shorten Maintainance');
        const headers = await this.auth.getDefaultHttpHeaders();

        const requestJson = {
            'Username': this.auth.ActiveUser.name,
            'SystemId': this._data.activeSystem.id,
            'ThingId': this.thing.id,
            'ThingType': this.thing.type,
            'Bauteil': bauteil,
            'Komponente': komponente,
            'Materialnummer': materialnummer,
            'Month': -12
        };
        if (confirm('Bitte bestätigen Sie die Verschiebung der Wartung des Bauteils ' + bauteil + ' ' + komponente))
        {
            this.http.post(environment.apiUrl + environment.apiVersion + '/health/extendMaintenance', requestJson, {headers: headers}).subscribe(res =>
            {
                // this.loadingBool = true;
                // this.getNewHealthTableData();
                this.updateComponent(materialnummer);

            });
        }
    }

    private async extendMaintenanceDate(bauteil, komponente, materialnummer)
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const requestJson = {
            'Username': this.auth.ActiveUser.name,
            'SystemId': this._data.activeSystem.id,
            'ThingId': this.thing.id,
            'ThingType': this.thing.type,
            'Bauteil': bauteil,
            'Komponente': komponente,
            'Materialnummer': materialnummer,
            'Month': 12
        };
        if (confirm('Bitte bestätigen Sie die Verschiebung der Wartung des Bauteils ' + bauteil + ' ' + komponente))
        {
            this.http.post(environment.apiUrl + environment.apiVersion + '/health/extendMaintenance', requestJson, {headers: headers}).subscribe(res =>
            {
                // this.loadingBool = true;
                // this.getNewHealthTableData();
                this.updateComponent(materialnummer);
                console.log(this.newTableData);

            });
        }
    }

    private async resetComponent(bauteil, komponente, materialnummer)
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const requestJson = {
            'Username': this.auth.ActiveUser.name,
            'SystemId': this._data.activeSystem.id,
            'ThingId': this.thing.id,
            'ThingType': this.thing.thingType,
            'Bauteil': bauteil,
            'Komponente': komponente,
            'Materialnummer': materialnummer,
            'isFrontendRequest': true
        };
        if (confirm('Bitte bestätigen Sie, dass das Bauteil ' + bauteil + ' ' + komponente + ' Ausgetauscht wurde'))
        {
            this.http.post(environment.apiUrl + environment.apiVersion + '/health/resetComponent', requestJson, {headers: headers}).subscribe(res =>
            {
                // this.loadingBool = true;
                // this.getNewHealthTableData();
                this.updateComponent(materialnummer);
            });
        }
    }
}
