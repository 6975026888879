<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>

<div class="container">
    <div>
        <h2>ConVer</h2>
        <div>
            <h3 class="subheading" id="Status">Status</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CONVER/status_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Position: ' | translate}} </strong>{{'In welcher Ebene befindet sich der ConVer Aufzug?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Ziel: ' | translate}}</strong>{{'Welche Ebene ist das Ziel des ConVer Aufzugs?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Fahrt: ' | translate}}</strong>{{'Zeigt an, ob der Güteraufzug hält oder fährt.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Statistik">{{'Statistik' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CONVER/statistic_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Betriebsstunden: '| translate}}</strong>{{'Zeit in der der Aufzug gefahren ist.'| translate}}
                </li>
                <li class="list-group-item"><strong>{{'Fahrten'| translate}} a
                    <svg style="padding-bottom: 3px;" xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                         fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                    b: </strong>{{'Fahrten von der Etage a zur Etage b.' | translate}}</li>
                <li class="list-group-item"><strong>{{'Fahrten' | translate}} b
                    <svg style="padding-bottom: 3px;" xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                         fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                    a: </strong>{{'Fahrten von der Etage b zur Etage a.' | translate}}</li>
                <li class="list-group-item">
                    <strong>{{'Fahrten gesamt: ' | translate}}</strong>{{'Fahrten, die insgesamt von dem Güteraufzug seit Inbetriebnahme/ Wartung durchgeführt wurden.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="FehlerUndKommentare">{{'Fehler und Kommentare' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CONVER/errorComment_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Fehler Momentan: ' | translate}}</strong>{{'Anzeige, ob derzeit ein Fehler auftritt.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Kommentare: ' | translate}}</strong>{{'Kommentare zu den Fehlern werden hier aufgelistet.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Fehler Historie: ' | translate}}</strong>{{'Alle Fehler, die seit Inbetriebnahme/ Wartung aufgetreten sind.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px; margin-bottom: 100px;">
            <h3 class="subheading" id="CmSensorDaten">{{'CM Sensor Daten' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/CONVER/cmSensor_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">{{'Beim Conver befinden sich die Condition Monitoring Sensoren an der Decke Links/Rechts und der Aufhängung Links/Rechts.' | translate}}</li>
            </ul>
        </div>
    </div>
</div>