<h5>Aktuelle Bewertung: {{actualRating}} % </h5>
<div class="row">
    <!-- <div *ngFor="let tabledata of testdrivesXandY ">-->
    <div [ngClass]="providedColumns" *ngFor="let tabledata of testdrivesXandY ">
        <div class="tile" [ngClass]="{'errorTile' : error}">
            <div class="tileHead">
                {{tabledata.header}}
                <div class="unit"></div>
            </div>

            <table mat-table
                   class="mat-elevation-z8"
                   *ngIf="tabledata.content != null; else noFaults"
                   [dataSource]="tabledata.content"
                   multiTemplateDataRows>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef style="font-size: 16px;">{{'Typ' | translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef style="font-size: 16px;">{{'Name' | translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef style="font-size: 16px;">{{'Wert' | translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->


                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="example-element-detail"
                             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="example-element-description" style="width: 100%">
                                <table class="table">
                                    <thead style="text-align: center;">
                                    <tr>
                                        <th scope="col">{{'Grenzwertfehler unten' | translate}}</th>
                                        <th scope="col">{{'Untere Warnung' | translate}}</th>
                                        <th scope="col">{{'aktueller Wert' | translate}}</th>
                                        <th scope="col">{{'Obere Warnung' | translate}}</th>
                                        <th scope="col">{{'Grenzwertfehler oben' | translate}}</th>
                                    </tr>
                                    </thead>
                                    <tbody style="text-align: center;">
                                    <tr>
                                        <td [ngClass]="element.value | errorColor: numberMax : numberMax : numberMin : element.lowerlowerThreshold">
                                            {{element.lowerlowerThreshold}}
                                        </td>
                                        <td [ngClass]="element.value | errorColor: numberMax : numberMax : element.lowerThreshold : numberMin">
                                            {{element.lowerThreshold}}
                                        </td>
                                        <td [ngClass]="element.value | errorColor: element.upperupperThreshold : element.upperThreshold : element.lowerThreshold : element.lowerlowerThreshold">
                                            {{element.value}}
                                        </td>
                                        <td [ngClass]="element.value | errorColor: numberMax : element.upperThreshold : numberMin : numberMin">
                                            {{element.upperThreshold}}
                                        </td>
                                        <td [ngClass]="element.value | errorColor: element.upperupperThreshold : numberMax : numberMin : numberMin">
                                            {{element.upperupperThreshold}}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns;"
                    [ngClass]="row.value | errorColor: row.upperupperThreshold : row.upperThreshold : row.lowerThreshold : row.lowerlowerThreshold"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === row"
                    (click)="expandedElement = expandedElement === row ? null : row">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
        </div>
    </div>
</div>
<!--</div>-->


<!---->


<ng-template #noFaults>
    <div class="tablePlaceholdText">
        {{'Keine Probleme' | translate}}
    </div>
</ng-template>

<app-line-tile class="col-lg-12"
               [heading]="'Testfahrt-Statistik' | translate"
               [unit]="'(%)' | translate"
               [error]="chartError"
               [value]="predictionChartValues"
               [legend]="['gemessene Werte','Vorhersage' | translate]">
</app-line-tile>
<!--
<ng-container matColumnDef="upperThreshold">
    <th mat-header-cell *matHeaderCellDef> Obere Warnung</th>
    <td mat-cell *matCellDef="let element"> {{element.upperThreshold}} </td>
</ng-container>
<ng-container matColumnDef="upperupperThreshold">
    <th mat-header-cell *matHeaderCellDef> Oberer Fehler </th>
    <td mat-cell *matCellDef="let element"> {{element.upperupperThreshold}} </td>
</ng-container>
<ng-container matColumnDef="lowerThreshold">
    <th mat-header-cell *matHeaderCellDef> Untere Warnung </th>
    <td mat-cell *matCellDef="let element"> {{element.lowerThreshold}} </td>
</ng-container>
<ng-container matColumnDef="lowerlowerThreshold">
    <th mat-header-cell *matHeaderCellDef> Unterer Fehler </th>
    <td mat-cell *matCellDef="let element"> {{element.lowerlowerThreshold}} </td>
</ng-container>
    -->
