<div class="grid"
     [class.node-outline]="node === this._parent.selectedNode"
>
    <div class="row">

    <div class="col-6 align-items-center" *ngIf="isLeaf">
        <div class="d-flex align-items-center justify-content-between">

            <div class="d-flex align-items-center">
            <button
                    mat-button
                    color="primary"
                    (click)="openDialog(node)"
                    class="button-black"
                >{{node.name}}</button>


                <div class="ms-5"></div>

                <div
                    class="matnum-disp"
                >
                    {{node.data.materialnummer}}
                </div>

            </div>

            <div
                *ngIf="node.data.komponenten_instance_description !== 'Default Instance' && node.data.komponenten_instance_description !== 'Default Komponent'"
            >
                ({{node.data.komponenten_instance_description}})
            </div>

        </div>
    </div>

    <div class="col-6 align-items-center" *ngIf="!isLeaf">
        <div
            *ngIf="node['data']['entity_type'] === 'bauteil'"
        >
            <table
                mat-table
                *ngIf="node['data']['entity_type'] === 'bauteil'"
                [dataSource]="dataSource_nodeLevel"
            >
                <ng-container matColumnDef="nodeLevel_chevron">
                    <th mat-header-cell *matHeaderCellDef
                    >
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            class="d-inline-block"
                            mat-icon-button
                            matTreeNodeToggle
                            [attr.aria-label]="'Toggle ' + node.name"
                            (click)="onTreeNodeToggle(node)"
                        >
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nodeLevel_name">
                    <th mat-header-cell *matHeaderCellDef
                    >
                    </th>
                    <td mat-cell *matCellDef="let element">

                        <button
                            mat-button
                            (mouseenter)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
                            (mouseleave)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
                            (click)="openDialog(node)"
                        >
                            <button
                                *ngIf="node.data.live_aggregation.alerting.length > 0 || node.data.live_aggregation.warning.length > 0"
                                mat-flat-button
                                color="{{node.color}}"
                                class="d-inline-block align-items-center"
                            >
                <span class="d-inline-block">
                    <mat-icon
                        class="mat-icon-rtl-mirror vertical-center-icon"
                    >
                        error
                    </mat-icon>
                </span>
                                <span class="d-inline-block">
                    {{node.data.live_aggregation.alerting.length}}
                </span>
                                <span class="d-inline-block">
                    <mat-icon
                        class="mat-icon-rtl-mirror vertical-center-icon"
                    >
                        warning
                    </mat-icon>
                </span>
                                <span class="d-inline-block">
                    {{node.data.live_aggregation.warning.length}}
                </span>
                            </button>

                            <div
                                color="primary"
                                class="d-inline-block align-items-center bold-black-text"
                            >{{node.name}}</div>
                        </button>
                    </td>
                </ng-container>

                <!--<tr mat-header-row *matHeaderRowDef="displayColumns_nodeLevel"></tr>-->
                <tr mat-row *matRowDef="let row; columns: displayColumns_nodeLevel;"></tr>
            </table>
        </div>


        <app-health-card
            *ngIf="!isHealthDialogCardHidden"
            [inData]="onGetNodeData(node)"
            [scaleFactor]="0.65"
        ></app-health-card>


        <div
            *ngIf="node['data']['entity_type'] === 'thing'"
        >
            <button

            class="d-inline-block"
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name"
            (click)="onTreeNodeToggle(node)"
        >
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
            </button>

            <button
                mat-button
                (mouseenter)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
                (mouseleave)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
                (click)="openDialog(node)"
            >
            <button
                *ngIf="node.data.live_aggregation.alerting.length > 0 || node.data.live_aggregation.warning.length > 0"
                mat-flat-button
                color="{{node.color}}"
                class="d-inline-block align-items-center"
            >
                    <span class="d-inline-block">
                        <mat-icon
                            class="mat-icon-rtl-mirror vertical-center-icon"
                        >
                            error
                        </mat-icon>
                    </span>
                <span class="d-inline-block">
                        {{node.data.live_aggregation.alerting.length}}
                    </span>
                <span class="d-inline-block">
                        <mat-icon
                            class="mat-icon-rtl-mirror vertical-center-icon"
                        >
                            warning
                        </mat-icon>
                    </span>
                <span class="d-inline-block">
                        {{node.data.live_aggregation.warning.length}}
                    </span>
            </button>

            <div
                color="primary"
                class="d-inline-block align-items-center bold-black-text"
            >{{node.name}}</div>
            </button>

            <table
                mat-table
                *ngIf="node['data']['entity_type'] === 'thing'"
                [dataSource]="dataSource_nodeLevel"
            >
                <ng-container matColumnDef="nodeLevel_chevron">
                    <th mat-header-cell *matHeaderCellDef>
                        <span [style.paddingLeft.px]="80"></span>
                    </th>
                </ng-container>


                <ng-container matColumnDef="nodeLevel_name">
                    <th mat-header-cell *matHeaderCellDef>
                        <span [style.paddingLeft.px]="40"> Name </span>
                    </th>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayColumns_nodeLevel"></tr>
                <!--<tr mat-row *matRowDef="let row; columns: displayColumns_nodeLevel;"></tr>-->
            </table>
        </div>
    </div>

    <div class="col-6 align-items-center">
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center justify-content-between">
                <button
                    *ngIf="isLeaf"
                    mat-flat-button
                    class="d-inline-block ms-5 bg-gray"
                    (click)="setNodeLevelInfoViewMode()"
                >
                    <mat-icon
                        class="vertical-center-icon"
                    >visibility</mat-icon>
                </button>

                <button
                    *ngIf="isLeaf"
                    mat-flat-button
                    class="d-inline-block ms-5 bg-gray"
                    (click)="setNodeLevelEditMode()"
                >
                    <mat-icon
                        class="vertical-center-icon"
                    >edit</mat-icon>
                </button>

                <button
                    *ngIf="isLeaf"
                    mat-flat-button
                    [disabled]="viewMode.get('nodeLevelEdit')"
                    class="d-inline-block ms-5 bg-gray"
                    (click)="setNodeLevelComponentViewMode()"
                >
                    <mat-icon
                        class="vertical-center-icon"
                    >add_circle_outline</mat-icon>
                </button>
            </div>
        </div>

        <!--<button-->
            <!--*ngIf="node.data.live_aggregation.alerting.length > 0 || node.data.live_aggregation.warning.length > 0"-->
            <!--mat-flat-button-->
            <!--color="{{node.color}}"-->
            <!--class="d-flex justify-content-end align-items-center"-->
            <!--(click)="openDialog(node)"-->
        <!--&gt;-->
        <!--<span class="d-inline-block">-->
            <!--<span class="d-inline-block" *ngIf="node.data.live_aggregation.alerting.length > 0">-->
                <!--<div class="d-inline-block">-->
                    <!--<mat-icon-->
                        <!--class="mat-icon-rtl-mirror vertical-center-icon"-->
                    <!--&gt;-->
                        <!--error-->
                    <!--</mat-icon>-->
                <!--</div>-->
                <!--<div class="d-inline-block">-->
                    <!--{{node.data.live_aggregation.alerting.length}}-->
                <!--</div>-->
            <!--</span>-->
            <!--<span class="d-inline-block" *ngIf="node.data.live_aggregation.warning.length > 0">-->
                <!--<div class="d-inline-block">-->
                    <!--<mat-icon-->
                        <!--class="mat-icon-rtl-mirror vertical-center-icon">-->
                          <!--warning-->
                    <!--</mat-icon>-->
                <!--</div>-->
                <!--<div class="d-inline-block">-->
                    <!--{{ node.data.live_aggregation.warning.length }}-->
                <!--</div>-->
            <!--</span>-->
        <!--</span>-->
        <!--</button>-->

    </div>

    <!--<div class="col-6 table" *ngIf="hasChildKomponenten && treeControl.isExpanded(node)">-->
        <!--<thead>-->
            <!--<tr>-->
                <!--<th>-->
                    <!--Komponente-->
                <!--</th>-->

                <!--<th>-->
                    <!--Material Nummer-->
                <!--</th>-->
            <!--</tr>-->
        <!--</thead>-->
    <!--</div>-->
    <!--<div class="row" *ngIf="hasChildKomponenten && treeControl.isExpanded(node)">-->
        <!--<div class="col-7">-->
            <!--<div class="d-flex align-items-center justify-content-between">-->
                <!--<div class="d-flex align-items-center">-->
                    <!--<div class="ms-5"></div>-->

                    <!--<div-->
                        <!--class="ml-5"-->
                    <!--&gt;Komponente</div>-->
                <!--</div>-->

            <!--</div>-->

            <!--<div-->
                <!--style="height: 100%;"-->
                <!--class="align-items-center justify-content-end"-->
            <!--&gt;-->
                <!--Material Nummer-->
            <!--</div>-->
            <!--<div class="ms-5 justify-content-end"></div>-->
        <!--</div>-->
    <!--</div>-->

    </div>
</div>

<!--Node Level Info View Table-->
<div class="grid">
    <div class="row">
        <div class="col-11">
            <div *ngIf="isLeaf && viewMode.get('nodeLevelInfoView')" class="info-table">
                <table mat-table [dataSource]="dataSource_nodeViewTable" class="info-table bg-gray">
                    <ng-container matColumnDef="komponenten_name_de">
                        <th mat-header-cell *matHeaderCellDef class="view-table-header"> Komponente</th>
                        <td mat-cell *matCellDef="let element"> {{element.komponenten_name_de}} </td>
                    </ng-container>

                    <ng-container matColumnDef="materialnummer">
                        <th mat-header-cell *matHeaderCellDef class="view-table-header"> Material Nummer</th>
                        <td mat-cell *matCellDef="let element"> {{element.materialnummer}} </td>
                    </ng-container>

                    <ng-container matColumnDef="extendCounter">
                        <th mat-header-cell *matHeaderCellDef class="view-table-header"> Verschobene Wartungen</th>
                        <td mat-cell *matCellDef="let element"> {{element.extendCounter}} </td>
                    </ng-container>

                    <ng-container matColumnDef="resetCounter">
                        <th mat-header-cell *matHeaderCellDef class="view-table-header"> Austausch </th>
                        <td mat-cell *matCellDef="let element"> {{element.resetCounter}} </td>
                    </ng-container>

                    <ng-container matColumnDef="worstHealthValue">
                        <th mat-header-cell *matHeaderCellDef class="view-table-header"> Schlechteste Komponente </th>
                        <td mat-cell *matCellDef="let element"> {{element.worstHealthValue}} </td>
                    </ng-container>

                    <ng-container matColumnDef="averageHealthValue">
                        <th mat-header-cell *matHeaderCellDef class="view-table-header"> Durchschnitt </th>
                        <td mat-cell *matCellDef="let element"> {{element.averageHealthValue}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns_nodeViewTable"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns_nodeViewTable;"></tr>
                </table>
                <br>
            </div>
        </div>
    </div>
</div>

<!--Node Level Edit Table-->
<div class="grid">
    <div class="row">
        <div class="col-7">
            <div *ngIf="isLeaf && viewMode.get('nodeLevelEdit')" class="info-table">
                <table mat-table [dataSource]="dataSource_editWertTable_messgroessen" class="info-table bg-gray">
                    <ng-container matColumnDef="messgroessen_name">
                        <th mat-header-cell *matHeaderCellDef class="view-table-header" style="width: 30%"> Messgroesse</th>
                        <td mat-cell *matCellDef="let element"> {{element.messgroessen_name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="momentaner_grenzwert">
                        <th mat-header-cell *matHeaderCellDef class="view-table-header" style="width: 30%"> Wert</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field class="example-full-width">
                                <input matInput type="number" [(ngModel)]="element.momentaner_grenzwert"
                                    (change)="changeInWert(element)"
                                >
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="einheit">
                        <th mat-header-cell *matHeaderCellDef class="view-table-header" style="width: 40%"> Einheit</th>
                        <td mat-cell *matCellDef="let element"> {{element.einheit}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns_editWertTable_messgroessen"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns_editWertTable_messgroessen;"></tr>
                </table>
                <br>
            </div>
        </div>
    </div>
</div>
