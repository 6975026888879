import {SelectionModel} from '@angular/cdk/collections';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';
import {HelperService} from '../../../core/controller/services/helper.service';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';
import {LinechartOptions} from '../../../core/view/charts/options/linechart-options';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Thing} from '../../../core/model/thing.model';
import {ThresholdsService} from '../../../core/controller/services/thresholds.service';

enum ThresholdSelectionMode
{
    None,
    SingleThreshold,
    MultibleThreshold
}

@Component({
    selector: 'app-testdrives',
    templateUrl: './testdrives.component.html',
    styleUrls: ['./testdrives.component.scss',
        '../../../core/view/tile/tile.scss']
})
export class TestdrivesComponent implements OnInit
{
    tmpTestdrivesArray = new Array<any>();
    displayedColumns: string[] = ['select', 'name', 'type', 'eventtype', 'starttime', 'status', 'changeStatus'];
    dataSource = new MatTableDataSource<any>(this.tmpTestdrivesArray);
    private constantDataSource = new Array<any>();
    private dataSourceUpdated = new Subject<any>();
    selection = new SelectionModel<any>(true, []);

    // Filtering
    nameFilter = new UntypedFormControl('');
    typeFilter = new UntypedFormControl('');
    eventtypeFilter = new UntypedFormControl('');
    starttimeFilter = new UntypedFormControl('');
    statusFilter = new UntypedFormControl('');


    range = new UntypedFormGroup({
        begin: new UntypedFormControl(),
        end: new UntypedFormControl()
    });


    /*
    *   Threshold
    * */
    selectionMode: ThresholdSelectionMode = ThresholdSelectionMode.None;
    thing: any;
    things = [];

    public currentFilter = {
        name: '',
        type: '',
        eventtype: '',
        date: {
            begin: '',
            end: '',
        },
        status: '',
    };

    // @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    // @ViewChild(MatSort) sort: MatSort;
    @ViewChild('aPopup', {static: true}) popup: ElementRef;

    public testdrivehtmlurl = '../../../../assets/testdrive/testdrive_table.html';
    public htmlUrl_Options;
    public displayedCharts;
    public tmpDisplayedCharts = [];
    public filterDropdown = {
        names: [],
        types: [],
        eventtypes: [],
        status: [],
    };
    // public getData = new Array<any>();
    // private getData:Itestdrives[] = [];

    public selectedCount = 0;
    public allCount;

    public testinglegend;
    public testingvalues: Array<Array<DataPoint<number>>>;

    public confidenceBandsTolerance = 0.1;

    sortedData: any[];
    public singleSelectedTestdriveElement;
    OK = 'OK';
    Ausstehend = 'Pending';
    Fehlerhaft = 'Warning';
    Undefined = 'Undefined';

    //Boolean workaround to display loading animations
    public loadingBool;
    public chartsLoadingBool;

    public noConfidencebandsFound = false;

    constructor(private Data: DataProviderService, private http: HttpClient,
                private _toastr: ToastrService,
                private _translate: TranslateService,
                private _thresholdService: ThresholdsService)
    {
        this.dataSource = new MatTableDataSource<any>(this.tmpTestdrivesArray);
        this.dataSource.filterPredicate = this.tableFilter();
    }

    ngOnInit()
    {
        this.chartsLoadingBool = false;
        this.loadingBool = false;
        this.getTableData();

        // ToDo
        // Muss richtig gemacht werden über https://stackoverflow.com/questions/47593692/how-to-translate-mat-paginator-in-angular-4
        this.paginator._intl.itemsPerPageLabel = 'Elemente per Seite';
        this._translate.get(this.paginator._intl.itemsPerPageLabel).subscribe(x => this.paginator._intl.itemsPerPageLabel = x);

        this.dataSource.paginator = this.paginator;

        this.dataSource.sort = this.sort;

        const options = {
            'g_mfrEventThingIds': this.Data.getActiveInfoID(),
            'ENVIRONMENT': 'prod'
        };

        const options_encrypted = encodeURIComponent(JSON.stringify(options));
        this.htmlUrl_Options = this.testdrivehtmlurl + '?options=' + options_encrypted;

        this.selection.changed.subscribe(selectedItems =>
        {
            let selectedCountPrev = this.selectedCount;
            this.selectedCount += selectedItems.added.length;
            this.selectedCount -= selectedItems.removed.length;
            if (selectedCountPrev == 0 && this.selectedCount == 1)
            {
                this.applyAutoFilter(selectedItems);
            }
        });

        this.nameFilter.valueChanges.subscribe(name =>
        {
            this.filterTable(name, 'name');
        });
        this.typeFilter.valueChanges.subscribe(type =>
        {
            this.filterTable(type, 'type');
        });
        this.eventtypeFilter.valueChanges.subscribe(eventtype =>
        {
            this.filterTable(eventtype, 'eventtype');
        });
        this.starttimeFilter.valueChanges.subscribe(starttime =>
        {
            this.filterTable(starttime, 'starttime');
        });
        this.statusFilter.valueChanges.subscribe(status =>
        {
            this.filterTable(status, 'status');
        });
        this.range.valueChanges.subscribe(range =>
        {
            if (range.end != null)
            {
                this.filterTable(range, 'date');
            }
        });
    }

    applyAutoFilter(selectedItem)
    {
        this.eventtypeFilter.setValue(selectedItem.added[0].eventType);
        this.typeFilter.setValue(selectedItem.added[0].thingType);
    }

    resetDateFilter()
    {
        let tmpdate = {
            'begin': null,
            'end': null
        };
        this.range.reset();
    }

    clearSelection()
    {
        for (let selectedTableEntry of this.selection.selected)
        {
            if ((this.currentFilter.eventtype != null && this.currentFilter.eventtype != '' && (selectedTableEntry.eventType != this.currentFilter.eventtype))
                || (this.currentFilter.type != null && this.currentFilter.type != '' && (selectedTableEntry.thingType != this.currentFilter.type))
                || (this.currentFilter.name != null && this.currentFilter.name != '' && (selectedTableEntry.thingName != this.currentFilter.name)))
            {
                this.selection.clear();
                return;
            }
            if (this.currentFilter.date.begin !== '' && this.currentFilter.date.begin != null)
            {
                let start = new Date(this.currentFilter.date.begin);
                let end = new Date(start.getTime());
                if ((this.currentFilter.date.end !== '') && (this.currentFilter.date.end != null) &&
                    (new Date(this.currentFilter.date.begin).getTime() != new Date(this.currentFilter.date.end).getTime()))
                {
                    end = new Date(this.currentFilter.date.end);
                }
                end.setTime(end.getTime() + 86399999);
                for (let element of this.selection.selected)
                {
                    const time = new Date(Number(element.startTime));
                    if ((start.getTime() > time.getTime()) || (end.getTime() < time.getTime()))
                    {
                        this.selection.deselect(selectedTableEntry);
                    }
                }
            }
        }
    }

    filterTable(filter, filtertype)
    {
        switch (filtertype)
        {
            case 'name':
                this.currentFilter.name = filter;
                break;
            case 'type':
                this.currentFilter.type = filter;
                break;
            case 'eventtype':
                this.currentFilter.eventtype = filter;
                break;
            case 'date':
                this.currentFilter.date = filter;
                break;
            case 'status':
                this.currentFilter.status = filter;
                break;
            default:
                break;
        }
        this.clearSelection();
        let tmpDataSourceData = [];
        Object.assign(tmpDataSourceData, this.constantDataSource);
        let tmpDataArray = [];
        if (this.currentFilter.name !== '' && this.currentFilter.name != null)
        {
            tmpDataArray = [];
            for (let element of tmpDataSourceData)
            {
                if (element.thingName == (this.currentFilter.name))
                {
                    tmpDataArray.push(element);
                }
            }
            tmpDataSourceData = [];
            Object.assign(tmpDataSourceData, tmpDataArray);
        }
        if (this.currentFilter.type !== '' && this.currentFilter.type != null)
        {
            tmpDataArray = [];
            for (let element of tmpDataSourceData)
            {
                if (element.thingType == (this.currentFilter.type))
                {
                    tmpDataArray.push(element);
                }
            }
            tmpDataSourceData = [];
            Object.assign(tmpDataSourceData, tmpDataArray);
        }
        if (this.currentFilter.eventtype !== '' && this.currentFilter.eventtype != null)
        {
            tmpDataArray = [];
            for (let element of tmpDataSourceData)
            {
                if (element.eventType == (this.currentFilter.eventtype))
                {
                    tmpDataArray.push(element);
                }
            }
            tmpDataSourceData = [];
            Object.assign(tmpDataSourceData, tmpDataArray);
        }
        if (this.currentFilter.date.begin !== '' && this.currentFilter.date.begin != null)
        {
            //console.log(this.currentFilter);
            tmpDataArray = [];

            let start = new Date(this.currentFilter.date.begin);
            let end = new Date(start.getTime());
            if ((this.currentFilter.date.end !== '') && (this.currentFilter.date.end != null) &&
                (new Date(this.currentFilter.date.begin).getTime() != new Date(this.currentFilter.date.end).getTime()))
            {
                end = new Date(this.currentFilter.date.end);
            }
            end.setTime(end.getTime() + 86399999);
            for (let element of tmpDataSourceData)
            {
                const time = new Date(Number(element.startTime));
                if ((start.getTime() < time.getTime()) && (end.getTime() > time.getTime()))
                {
                    tmpDataArray.push(element);
                }
            }
            tmpDataSourceData = [];
            Object.assign(tmpDataSourceData, tmpDataArray);
        }
        if (this.currentFilter.status !== '' && this.currentFilter.status != null)
        {
            tmpDataArray = [];
            for (let element of tmpDataSourceData)
            {
                if (element.status == (this.currentFilter.status))
                {
                    tmpDataArray.push(element);
                }
            }
            tmpDataSourceData = [];
            Object.assign(tmpDataSourceData, tmpDataArray);
        }
        this.dataSource = new MatTableDataSource(tmpDataSourceData);
        this.dataSource.paginator = this.paginator;
        this.allCount = tmpDataSourceData.length;
    }

    //
    tableFilter(): (data: any, filter: string) => boolean
    {
        const filterFunction = function (data, filter): boolean
        {
            const searchTerms = JSON.parse(filter);
            return data.name.toLowerCase().indexOf(searchTerms.name) !== -1
                && data.type.toLowerCase().indexOf(searchTerms.type) !== -1
                && data.eventtype.toLowerCase().indexOf(searchTerms.eventtype) !== -1
                && data.starttime.toLowerCase().indexOf(searchTerms.starttime) !== -1
                && data.status.toLowerCase().indexOf(searchTerms.status) !== -1;
        };
        return filterFunction;
    }

    getTableData()
    {
        if (this.Data.activeSystem == null) return;
        //console.log(this.Data.activeSystem);
        const httpOptions = {
            headers: new HttpHeaders({
                'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'thingId': 'tmp' //this.Data.activeSystem._allThings.find(item => item.thingType == 'INFO').id,
            })
        };
        this.http.get('/backend/api/testdrive/infoThing', httpOptions).pipe(map(res =>
        {
            const tmp = res as Array<any>;
            this.tmpTestdrivesArray = tmp;
            return tmp;
        })).subscribe(res =>
        {
            let tmp2: any;
            tmp2 = res;
            this.dataSource = new MatTableDataSource(tmp2);
            this.constantDataSource = tmp2;
            this.filterDropdown = this.setFilterDropdownValues(tmp2);
            this.allCount = tmp2.length;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.sortedData = this.constantDataSource.slice();
            this.loadingBool = true;
        });
    }

    applyFilter(filterValue: string)
    {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    sortData(sort: Sort)
    {
        const data = this.dataSource.data.slice();
        if (!sort.active || sort.direction === '')
        {
            this.sortedData = data;
            return;
        }

        this.sortedData = data.sort((a, b) =>
        {
            const isAsc = sort.direction === 'asc';
            switch (sort.active)
            {
                case 'name':
                    return compare(a.thingName, b.thingName, isAsc);
                case 'type':
                    return compare(a.thingType, b.thingType, isAsc);
                case 'eventtype':
                    return compare(a.eventType, b.eventType, isAsc);
                case 'starttime':
                    return compare(a.startTime, b.startTime, isAsc);
                case 'status':
                    return compare(a.status, b.status, isAsc);
                default:
                    return 0;
            }
        });
        const tmpTestdrivesArray = this.sortedData;
        this.dataSource = new MatTableDataSource<any>(tmpTestdrivesArray);
        this.dataSource.paginator = this.paginator;
    }

    isAllSelected()
    {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle()
    {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    checkboxLabel(row?: any): string
    {
        if (!row)
        {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    compareTestdrives()
    {
        if (this.checkSelection(true))
        {
            if (this.selection.selected.length >= 1)
            {
                this.chartsLoadingBool = true;
                this.displayedCharts = [];
                this.thing = null;
                //console.log(this.selection.selected);
                const httpOptions = {
                    headers: new HttpHeaders({
                        'cache-control': 'no-cache',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    })
                };
                this.http.post('/backend/api/testdrive/testdrivesChartsData/', JSON.stringify(this.selection.selected), httpOptions).subscribe(res =>
                {
                    const tmpCompany = {...this.Data.activeCompany};
                    const tmpLocation = {...this.Data.activeLocation};
                    const tmpSystem = {...this.Data.activeSystem};
                    const body = {
                        thingtype: this.selection.selected[0].thingType,
                        eventtype: this.selection.selected[0].eventType,
                        activeCustomer: tmpCompany,
                        activeLocation: tmpLocation,
                        activeSystem: {name: tmpSystem.name},
                    };
                    // Quickfix, da sonst die Objektstruktur circular ist und man somit kein stringify durchführen kann
                    body.activeCustomer.locations = null;
                    body.activeLocation.parentCompany = null;
                    body.activeLocation.systems = null;

                    this.http.post('/backend/api/testdrive/getConfidenceBands', JSON.stringify(body), httpOptions).subscribe(confBands =>
                    {
                        let tmpConfBands: any;
                        tmpConfBands = confBands;
                        this.displayedCharts = res;
                        // Blendet Thresholds aus
                        this.selectionMode = ThresholdSelectionMode.None;
                        this.clearThings();
                        this.tmpDisplayedCharts = [];
                        // Getting linechart options
                        this.displayedCharts.forEach(chart =>
                        {
                            const valuesArray = [];
                            chart.value.forEach(series =>
                            {
                                const tmpArray: Array<DataPoint<Number>> = [];
                                series.forEach(valuePoint =>
                                {
                                    tmpArray.push(new DataPoint<Number>(valuePoint.value, HelperService.parseUTCToGMT(valuePoint.timestamp)));
                                });
                                valuesArray.push(tmpArray);
                            });
                            const chartOptons = new LinechartOptions('plain', chart.legend);
                            let tmpConfBand = null;
                            if (confBands[0] !== undefined && confBands[0] !== null)
                            {
                                tmpConfBands.forEach(element =>
                                {
                                    if (element.name.includes(chart.title.replace(' ', '.')))
                                    {
                                        tmpConfBand = element;
                                    }
                                });
                            }
                            this.tmpDisplayedCharts.push({
                                'value': valuesArray,
                                'options': chartOptons,
                                'title': chart.title,
                                'legend': chart.legend,
                                'confidenceBand': tmpConfBand,
                            });
                            this.testinglegend = 'one', 'two';
                            this.testingvalues = valuesArray;
                        });
                        if (confBands[0] === undefined || confBands[0] === null)
                        {
                            this.noConfidencebands();
                            let toast = 'Es sind keine Confidencebands für diese Konfiguration angelegt';
                            this._translate.get(toast).subscribe(x => toast = x);
                            this._toastr.error(toast, null, {positionClass: 'toast-bottom-right', disableTimeOut: true});
                            console.log('no confidencebands for this configuration');
                        }
                        this.chartsLoadingBool = false;
                    });
                });
            }
            else
            {
                this.showNothingSelectedError();
            }
        }
    }

    setFilterDropdownValues(tablecontent)
    {
        const filterDropdown = {
            names: [],
            types: [],
            eventtypes: [],
            status: [],
        };
        tablecontent.forEach(element =>
        {
            if (!filterDropdown.names.includes(element.thingName))
            {
                filterDropdown.names.push(element.thingName);
            }
            if (!filterDropdown.types.includes(element.thingType))
            {
                filterDropdown.types.push(element.thingType);
            }
            if (!filterDropdown.eventtypes.includes(element.eventType))
            {
                filterDropdown.eventtypes.push(element.eventType);
            }
            if (!filterDropdown.status.includes(element.status))
            {
                filterDropdown.status.push(element.status);
            }
        });
        filterDropdown.names.sort();
        filterDropdown.types.sort();
        filterDropdown.eventtypes.sort();
        filterDropdown.status.sort();
        return filterDropdown;
    }

    calculateConfidenceBands()
    {
        if (this.checkSelection(false))
        {
            const httpOptions = {
                headers: new HttpHeaders({
                    'cache-control': 'no-cache',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            };
            const eventtypesForBody = [];
            this.selection.selected.forEach(function (element)
            {
                if (!eventtypesForBody.includes(element.eventType))
                {
                    eventtypesForBody.push(element.eventType);
                }
            });
            const thingtpyesForBody = [];
            this.selection.selected.forEach(function (element)
            {
                if (!thingtpyesForBody.includes(element.thingType))
                {
                    thingtpyesForBody.push(element.thingType);
                }
            });
            const tmpCompany = {...this.Data.activeCompany};
            const tmpLocation = {...this.Data.activeLocation};
            const tmpSystem = {...this.Data.activeSystem};
            const body = {
                selectedThings: this.selection.selected,
                eventtypes: eventtypesForBody,
                thingtype: thingtpyesForBody[0],
                tolerance: this.confidenceBandsTolerance,
                activeCustomer: tmpCompany,
                activeLocation: tmpLocation,
                activeSystem: {name: tmpSystem.name},
            };
            // Quickfix, da sonst die Objektstruktur circular ist und man somit kein stringify durchführen kann
            body.activeCustomer.locations = null;
            body.activeLocation.parentCompany = null;
            body.activeLocation.systems = null;
            this.http.post('/backend/api/testdrive/calculateConfidenceBands/', JSON.stringify(body), httpOptions).subscribe(
                async res =>
                {
                    let toast = 'Confidence Bands berechnet';
                    this._translate.get(toast).subscribe(x => toast = x);
                    this._toastr.success(toast, null, {positionClass: 'toast-bottom-right'});
                },
                error =>
                {
                    let toast = 'Fehler beim speichern der Confidence Bands';
                    this._translate.get(toast).subscribe(x => toast = x);
                    this._toastr.error(toast, null,
                        {positionClass: 'toast-bottom-right', disableTimeOut: true});
                }
            );
        }
    }

    checkSelection(checkForEventtypes)
    {
        const thingtypes = [];
        const eventtypes = [];
        const status = [];
        this.selection.selected.forEach(element =>
        {
            if (!thingtypes.includes(element.thingType))
            {
                thingtypes.push(element.thingType);
            }
            if (checkForEventtypes)
            {
                if (!eventtypes.includes(element.eventType))
                {
                    eventtypes.push(element.eventType);
                }
            }
            if (element.status !== 'OK')
            {
                status.push(element.status);
            }
        });

        if (thingtypes.length > 1)
        {
            let toast = 'Es können nicht mehrere Typen miteinander verglichen werden';
            this._translate.get(toast).subscribe(x => toast = x);
            this._toastr.error(toast, null,
                {positionClass: 'toast-bottom-right', disableTimeOut: true});
            return false;
        }
        if (eventtypes.length > 1)
        {
            let toast = 'Es können nicht mehrere Eventtypen miteinander verglichen werden';
            this._translate.get(toast).subscribe(x => toast = x);
            this._toastr.error(toast, null,
                {positionClass: 'toast-bottom-right', disableTimeOut: true});
            return false;
        }
        return true;
    }

    changeStatus(statuscode)
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            })
        };
        const body = {
            testdrive: this.singleSelectedTestdriveElement,
            statuscode: statuscode,
            thingId: 'placehold'// this.Data.activeSystem._allThings.find(item => item.thingType == 'INFO').id,
        };
        this.http.post('/backend/api/testdrive/changeStatusManual/', body, httpOptions).subscribe(res =>
            {
                //console.log(res);
                if (res == null)
                {
                    let toast = 'Status erfolgreich gespeichert';
                    this._translate.get(toast).subscribe(x => toast = x);
                    this._toastr.success(toast, null, {positionClass: 'toast-bottom-right'});
                    //console.log(this.dataSource.filteredData);
                    this.dataSource.filteredData.forEach(row =>
                    {
                        if (row == body.testdrive)
                        {
                            row.status = body.statuscode;
                            return;
                        }
                    });
                }
                else
                {
                    let toast = 'Fehler beim speichern des Status';
                    this._translate.get(toast).subscribe(x => toast = x);
                    this._toastr.error(toast, null,
                        {positionClass: 'toast-bottom-right', disableTimeOut: true});
                }
            },
            error =>
            {
                let toast = 'Fehler beim speichern des Status';
                this._translate.get(toast).subscribe(x => toast = x);
                this._toastr.error(toast, null,
                    {positionClass: 'toast-bottom-right', disableTimeOut: true});
            }
        );
    }

    changeStatusMultiElements(statuscode)
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            })
        };
        this.selection.selected.forEach((testdrive) =>
        {
            const body = {
                testdrive: testdrive,
                statuscode: statuscode,
                thingId: 'tmp'//this.Data.activeSystem._allThings.find(item => item.thingType == 'INFO').id,
            };
            this.http.post('/backend/api/testdrive/changeStatusManual/', body, httpOptions).subscribe(res =>
                {
                    //console.log(res);
                    if (res == null)
                    {
                        let toast = 'Status erfolgreich gespeichert';
                        this._translate.get(toast).subscribe(x => toast = x);
                        this._toastr.success(toast, null, {positionClass: 'toast-bottom-right'});
                        //console.log(this.dataSource.filteredData);
                        this.dataSource.filteredData.forEach(row =>
                        {
                            if (row == body.testdrive)
                            {
                                row.status = body.statuscode;
                            }
                        });
                    }
                    else
                    {
                        let toast = 'Fehler beim speichern des Status';
                        this._translate.get(toast).subscribe(x => toast = x);
                        this._toastr.error(toast, null,
                            {positionClass: 'toast-bottom-right', disableTimeOut: true});
                    }
                },
                error =>
                {
                    let toast = 'Fehler beim speichern des Status';
                    this._translate.get(toast).subscribe(x => toast = x);
                    this._toastr.error(toast, null,
                        {positionClass: 'toast-bottom-right', disableTimeOut: true});
                }
            );
        });
    }

    noConfidencebands()
    {
        this.noConfidencebandsFound = true;
    }

    singleSelectedTestdrive(testdrive)
    {
        this.singleSelectedTestdriveElement = testdrive;
    }

    resetNoConfidencebandsFoundBool()
    {
        this.noConfidencebandsFound = false;
        // this.dialog.open()
    }

    /*
    *
    *       Thresholds vergleichen/anzeigen
    *
    * */

    showThreshold()
    {
        this.clearThings();
        if (this.selection.selected.length >= 1)
        {
            this.chartsLoadingBool = true;
            this.displayedCharts = [];
            // Löscht die jetztigen Charts
            this.tmpDisplayedCharts = [];

            if (this.selection.selected.length >= 2)
            {
                this.showMultipleThresholds();
            }
            else
            {
                this.showSingleThreshold();
            }

            this.chartsLoadingBool = false;
        }
        else
        {
            this.showNothingSelectedError();
        }
    }

    /*
    *   Zeigt unter der Tabelle die Diegramme an.
    *   (Geht von mindestens zwei ausgewählte aus.)
    * */
    // ToDo Feature hinzufügen mit dem man mehrere Thresholds miteinander vergleichen kann.
    // Im Moment ist es durch die Oberfläche deaktiviert.
    showMultipleThresholds()
    {
        console.log('Show multible.');
        this.selectionMode = ThresholdSelectionMode.MultibleThreshold;
    }

    /*
    *   Zeigt unter der Tabelle eine weitere Tabelle mit den Thresholds.
    *   (Geht von mindestens eine auswahl aus.)
    * */
    async showSingleThreshold()
    {
        console.log('Show one.');
        this.selectionMode = ThresholdSelectionMode.SingleThreshold;
        let testdriveThing = this.selection.selected[0];
        let thing = this.getThingByThingName(testdriveThing);

        testdriveThing['THINGID'] = thing['id'];

        let threshold = await this.postThresholds([testdriveThing]);

        this._thresholdService.mapThresholdOnThing(thing, threshold[0]);

        this.thing = thing;
    }

    async postThresholds(things: Array<any>)
    {
        return new Promise((resolve, reject) =>
        {
            const httpOptions = {
                headers: new HttpHeaders({
                    'cache-control': 'no-cache',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            };

            this.http.post('/backend/api/threshold/', JSON.stringify(things), httpOptions).subscribe(res =>
            {
                resolve(res);
            });
        });
    }

    /*
    *   Zeigt eine Fehlermeldung an, dass kein Objekt ausgewählt wurde.
    * */
    showNothingSelectedError()
    {
        let toast = 'Es muss mindestens eine Testfahrt ausgewählt sein.';
        this._translate.get(toast).subscribe(x => toast = x);
        this._toastr.error(toast, null, {positionClass: 'toast-bottom-right', timeOut: 5000});
    }

    /*
    *   Entfernt die nicht benutzten Things aus dem RAM.
    * */
    clearThings()
    {
        this.thing = null;
        this.things = [];
    }

    private mappedSearchNumbers = {'ENTWICKLUNG': '40036276'};

    /*
    *
    *   Findet ein Thing bei dem Namen in dem data-provider.service
    *   ToDo Abklären ob es in den data-provider auszulagert werden soll
    * */
    getThingByThingName(testDriveThing: any)
    {
        let thing: Thing;
        let splittedName = testDriveThing['thingName'].split('_');

        if (splittedName.length == 4)
        {
            let searchNumber = splittedName[1];

            thing = this.Data.things.find(element => element.serialNumber === searchNumber);

            // Testweiße auskommentiert.
            // /*
            // *    ToDo Anscheinend wurden für das VCV falsche Nummern bei Wenglor hinterlegt.
            // *    Die if abfrage ist ein quick fix dafür und sucht das Thing anhand einer anderen Nummer
            // */
            if (thing == null)
            {
                let mappedSearchNumber = this.mappedSearchNumbers[searchNumber];

                thing = this.Data.things.find(element => element.serialNumber === mappedSearchNumber);
            }

            return thing;
        }
        else
        {
            console.warn('WARNING: A thing was searched by name, that doesen\'t exist.');
            return null;
        }
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean)
{
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export interface Itestdrives
{
    name: string;
    type: string;
    eventtype: string;
    starttime: string;
    status: string;
}
