import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {
    AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest
} from '@azure/msal-browser';
import {filter, lastValueFrom, Subject, takeUntil} from 'rxjs';
import jwt_decode from 'jwt-decode';


@Injectable({
    providedIn: 'root'
})
// Service der die Authentifikation regelt
export class AuthenticationService implements OnDestroy
{
    private _error: boolean;
    private _bronze: boolean;
    private _silver: boolean;
    private _gold: boolean;
    private _platinum: boolean;
    private _admin: boolean;

    public UserId: string;

    public ActiveUser: AccountInfo;

    public HealthReset = false;
    public GatewayMonitoring = false;
    public Monitoring = false;

    isIframe = false;
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();

    constructor(private _http: HttpClient,
                @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
                private msalService: MsalService,
                private msalBroadcastService: MsalBroadcastService)
    {
        this.setPackage('platinum');

    }

    async checkAndSetActiveAccount()
    {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.msalService.instance.getActiveAccount();
        const numberAllAcounts = this.msalService.instance.getAllAccounts().length > 0;

        if (this.msalService.instance.getAllAccounts().length > 0)
        {
            const accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
        else
        {
            await this.loginRedirect();

            const accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);

        }
    }

    async loginRedirect()
    {
        if (this.msalGuardConfig.authRequest)
        {
            await lastValueFrom(this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest));
        }
        else
        {
            await lastValueFrom(this.msalService.loginRedirect());
        }
    }

    loginPopup()
    {
        if (this.msalGuardConfig.authRequest)
        {
            this.msalService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
                .subscribe((response: AuthenticationResult) =>
                {
                    this.msalService.instance.setActiveAccount(response.account);
                });
        }
        else
        {
            this.msalService.loginPopup()
                .subscribe((response: AuthenticationResult) =>
                {
                    this.msalService.instance.setActiveAccount(response.account);
                });
        }
    }

    setLoginDisplay()
    {
        this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
        console.log('Acounts: ' + this.msalService.instance.getAllAccounts().length);
    }

    public async getToken(): Promise<AuthenticationResult>
    {
        const request = {
            scopes: ['api://97b96046-8098-48e4-8943-bf80d8391aa5/.default']
        };

        try
        {
            const result = await lastValueFrom(this.msalService.acquireTokenSilent(request));

            if (result == null)
            {
                console.log('token request is null');
                return result;
            }
            else
            {
                return result;
            }
        } catch (error)
        {
            console.log(error);
            console.log('try login via redirect');
            const result = await lastValueFrom(this.msalService.acquireTokenRedirect(request));

            return null;
        }

        // console.log(tokenResponse.accessToken);

        // localStorage.setItem('accToken', tokenResponse.accessToken);
    }

    public logout(popup?: boolean)
    {
        if (popup)
        {
            this.msalService.logoutPopup({
                mainWindowRedirectUri: '/'
            });
        }
        else
        {
            this.msalService.logoutRedirect();
        }
    }

    public login()
    {

        return new Promise(resolve =>
        {
            this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
            this.setLoginDisplay();

            this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
            this.msalBroadcastService.msalSubject$
                .pipe(
                    filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
                )
                .subscribe((result: EventMessage) =>
                {
                    if (this.msalService.instance.getAllAccounts().length === 0)
                    {
                        // window.location.pathname = '/';
                        this.login().then((x) =>
                        {
                            console.log(x);
                            this.setLoginDisplay();
                        });
                    }
                    else
                    {
                        this.setLoginDisplay();
                    }
                });

            this.msalBroadcastService.inProgress$
                .pipe(
                    filter((status: InteractionStatus) => status === InteractionStatus.None),
                    takeUntil(this._destroying$)
                )
                .subscribe(async () =>
                {
                    this.setLoginDisplay();
                    await this.checkAndSetActiveAccount();

                    //const token = await this.getToken();

                    // localStorage.setItem('accToken', token.accessToken);
                    console.log(this.msalService.instance.getActiveAccount());
                    this.ActiveUser = this.msalService.instance.getActiveAccount();
                    this.UserId = this.msalService.instance.getActiveAccount().localAccountId;
                    let token = await this.getToken() as any;
                    token = jwt_decode(token.accessToken);
                    this.setRoles(token.roles);
                    console.log('logged in');

                    resolve('true');
                });

            this.msalBroadcastService.msalSubject$
                .pipe(
                    filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
                    takeUntil(this._destroying$)
                )
                .subscribe((result: EventMessage) =>
                {
                    console.log(result);
                });

            this.msalBroadcastService.msalSubject$
                .pipe(
                    takeUntil(this._destroying$)
                )
                .subscribe((result: EventMessage) =>
                {
                    console.log(result);
                });
        });

    }

    public async getDefaultHttpHeaders(): Promise<HttpHeaders>
    {
        const accessToken = await this.getToken();
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + accessToken.accessToken
        });
    }

    ngOnDestroy()
    {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    private setRoles(roles: Array<string>)
    {
        if (roles == null)
        {
            return;
        }

        if (roles.includes('GalileoIoT.HealthReset'))
        {
            this.HealthReset = true;
        }

        if (roles.includes('GalileoIoT.Gatewaymonitoring'))
        {
            this.GatewayMonitoring = true;
            this._admin = true;
        }

        if (roles.includes('GalileoIoT.Monitoring'))
        {
            this.Monitoring = true;
        }
    }

    /*

        login() {

            this.msalService.loginPopup()
                .subscribe({
                    next: (result) => {
                        this.setLoginDisplay();
                        console.log(result);
                    },
                    error: (error) => console.log(error)
                });
        }

        setLoginDisplay() {
            this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
        }*/

    /**
     * Bekommt einen String und entscheidet daran welcher
     * Gruppe der User angehört
     * @param pPackage die Berechtigungsstufe des Users als string
     **/
    public setPackage(pPackage: string)
    {
        this.allFalse();

        switch (pPackage)
        {
            case 'bronze':
                this._bronze = true;
                break;
            case 'silver':
                this._bronze = true;
                this._silver = true;
                break;
            case 'gold':
                this._bronze = true;
                this._silver = true;
                this._gold = true;
                break;
            case 'platinum':
                this._bronze = true;
                this._silver = true;
                this._gold = true;
                this._platinum = true;
                break;
            case 'admin':
                this._bronze = true;
                this._silver = true;
                this._gold = true;
                this._platinum = true;
                this._admin = true;
                break;
            default:
                this.allFalse();
                this._error = true;
                break;
        }
    }

    /**
     * Gibt an ob User Bronze Berechtigungen hat
     */
    public isBronze(): boolean
    {
        return this._bronze;
    }

    /**
     * Gibt an ob User Silber Berechtigungen hat
     */
    public isSilver(): boolean
    {
        return this._silver;
    }

    /**
     * Gibt an ob User Gold Berechtigungen hat
     */
    public isGold(): boolean
    {
        return this._gold;
    }

    /**
     * Gibt an ob User Platin Berechtigungen hat
     */
    public isPlatinum(): boolean
    {
        return this._platinum;
    }

    public isAdmin(): boolean
    {
        return this._admin;
    }

    /**
     * Setzt alle Berechtigungen auf false
     */
    private allFalse()
    {
        this._error = false;
        this._bronze = false;
        this._silver = false;
        this._gold = false;
        this._platinum = false;
        this._admin = false;
    }

    /**
     * Gibt eine lesbare Bezeichnung des Users wieder
     */
    public readableName(): string
    {
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (auth['firstName'] && auth['lastName'])
        {
            return auth['firstName'] + ' ' + auth['lastName'];
        }
        else if (auth['loginName'])
        {
            return auth['loginName'];
        }
        else if (auth['email'])
        {
            return auth['email'];
        }
        else
        {
            return 'User';
        }


    }
}
