export class GatewayExtendedStatus
{
    GatewayId: number;
    timestamp: Date;
    Cpu: InfoRange;
    Ram: InfoRange;
    Drives: Drives[];
    Programs: Program[];
    S7Info: S7Info[];
}

export class InfoRange
{
    Min: number;
    Value: number;
    Max: number;
}

export class Drives
{
    Name: string;
    Label: string;
    Type: string;
    Size: number;
    Free: number;
}

export class Program
{
    IconBase64: string;
    Name: string;
    Version: string;
}

export class S7Info
{
    Ip: string;
    Port: string;
    Name: string;
    CpuType: number;
    Rack: number;
    Slot: number;
    S7TestdriveData: S7TestdriveData;
}

export class S7TestdriveData
{
    Start: string;
    Aktive: string;
    Reset: string;
    CpuZeit: string;
    StartzeitMessfahrtenAktive: string;
    StartzeitMessfahrten: string;
    LetzterAnfang: string;
    LetztesEnde: string;
    Aufzeichnung_Modus: string;
}

