import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
/**
 * Footer Komponente
 * Wird zur Zeit nicht benutzt
 */
export class FooterComponent
{
}
