<app-temperature *ngIf="widget === 'temperature'"
                 [resizeEvent]="resizeEvent"
                 [Temperature]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-temperature>

<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Instapick' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Instapick' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>


<app-movements *ngIf="widget === 'movements'"
               [resizeEvent]="resizeEvent"
               [Movements]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-movements>

<app-orders *ngIf="widget === 'orders'"
            [resizeEvent]="resizeEvent"
            [Orders]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-orders>

<app-time *ngIf="widget === 'time'"
          [resizeEvent]="resizeEvent"
          [Distance]="Thing.propertySets.get('GENERAL_INFORMATION')"
          [TimeGeneral]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-time>

<app-position *ngIf="widget === 'position'"
              [resizeEvent]="resizeEvent"
              [Position]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-position>

<app-fine-positioning *ngIf="widget === 'fine-position'"
                      [resizeEvent]="resizeEvent"
                      [FinePositioning]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-fine-positioning>

<app-battery *ngIf="widget === 'battery'"
             [resizeEvent]="resizeEvent"
             [ActualData]="Thing.propertySets.get('GENERAL_INFORMATION')">
</app-battery>

<app-cm-sensors *ngIf="widget === 'cm-mdw'"
                [resizeEvent]="resizeEvent"
                [Type]="''"
                [Environment]="Thing.propertySets.get('DS_ENV')"
                [Rotation]="Thing.propertySets.get('DS_ROT')"
                [AngularVelocity]="Thing.propertySets.get('DS_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('DS_ACL')">
</app-cm-sensors>

<app-cm-sensors-two *ngIf="widget === 'cm-mdw-two'"
                    [resizeEvent]="resizeEvent"
                    [Type]="''"
                    [Environment]="Thing.propertySets.get('DS_ENV')"
                    [Sound]="Thing.propertySets.get('DS_SOUND')"
                    [AngularVelocity]="Thing.propertySets.get('DS_ANGVEL')"
                    [Acceleration]="Thing.propertySets.get('DS_ACL')">
</app-cm-sensors-two>