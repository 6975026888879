<app-group-header [Heading]="'Aufträge' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Aufträge pro Stunde' | translate"
                      [Error]="OrdersHour?.isError"
                      [ErrorLevel]="OrdersHour?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[OrdersHour?.TimeseriesData]"
                      [Legend]="['Aufträge pro Stunde' | translate]"
                      [Info]="'Anzahl der Ein-/Aus-/Umlagerungsaufträge pro Stunde.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Aufträge gesamt' | translate"
                      [Error]="OrdersTotal?.isError"
                      [ErrorLevel]="OrdersTotal?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[OrdersTotal?.TimeseriesData]"
                      [Legend]="['Aufträge gesamt' | translate]"
                      [Info]="'Gesamtanzahl der Ein-/Aus-/Umlagerungsaufträge.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
