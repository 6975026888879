<app-group-header [Heading]="'Battery' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Ladezeit' | translate"
                      [Error]="LADEZEIT?.isError"
                      [ErrorLevel]="LADEZEIT?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[LADEZEIT?.TimeseriesData]"
                      [Unit]="'h'"
                      [Legend]="['Ladezeit' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Kapazität' | translate"
                      [Error]="KAPAZITAET?.isError"
                      [ErrorLevel]="KAPAZITAET?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[KAPAZITAET?.TimeseriesData]"
                      [MaintenanceValue]="KAPAZITAET?.ValueLastService"
                      [Unit]="'%'"
                      [Legend]="['Kapazität' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Kapazität Spannung' | translate"
                      [Error]="KAPAZITAET_SPANNUNG?.isError"
                      [ErrorLevel]="KAPAZITAET_SPANNUNG?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[KAPAZITAET_SPANNUNG?.TimeseriesData]"
                      [MaintenanceValue]="KAPAZITAET_SPANNUNG?.ValueLastService"
                      [Unit]="'V'"
                      [Legend]="['Kapazität Spannung' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Kapazität Temperatur' | translate"
                      [Error]="KAPAZITAET_TEMPERATUR?.isError"
                      [ErrorLevel]="KAPAZITAET_TEMPERATUR?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[KAPAZITAET_TEMPERATUR?.TimeseriesData]"
                      [MaintenanceValue]="KAPAZITAET_TEMPERATUR?.ValueLastService"
                      [Unit]="'°C'"
                      [Legend]="['Kapazität Temperatur' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>


</div>
