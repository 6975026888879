import {Component, OnInit} from '@angular/core';
import {HelpPageComponent} from '../../help-page.component';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {environment} from '../../../../../../environments/environment';

@Component({
    selector: 'app-help-page-gebhardt-insight',
    templateUrl: './help-page-gebhardt-insight.component.html',
    styleUrls: ['./help-page-gebhardt-insight.component.scss', '../../helpPageModule.scss']
})
export class HelpPageGebhardtInsightComponent extends HelpPageComponent implements OnInit
{
    urlLanguage: string;

    constructor(public translate: TranslateService, private router: Router)
    {
        super(translate);
    }

    ngOnInit(): void
    {
        this.urlLanguage = this.translate.currentLang;
    }

    gotoSelection(path: string)
    {
        this.router.navigateByUrl(path);
    }
    public readonly environment = environment;
}
