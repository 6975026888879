<div id='console-window' [style.top.px]='top' [style.height.px]='height'>

    <div (mousedown)='onCornerClick($event, horiziontResize)' id="top-edge"></div>
    <div class="card-info-window">
        <div class="info-window-container">
            <div class="window-row" (click)="toggleConsoleCloseIcon()">
                <div class="window-column window-right">
                    <span class="info-window-close-button">
                        <img id="info-window-close-icon" class="icon" [src]="environment.storageUrl + '/icons/arrow_up.png'"
                             style="width:16px"/>
                    </span>
                </div>
                <div class="window-column window-left">
                    <span style="font-weight:bold">{{'Monitoring-Konsole' | translate}}</span>
                </div>
            </div>
            <div style="overflow-y: auto; height: 88%;">
                <table matSort (matSortChange)="sortData($event)" style="width: 100%">
                    <tr style="background:#f1f1f1;">
                        <th mat-sort-header="id">ID</th>
                        <th mat-sort-header="name">{{'Name' | translate}}</th>
                        <th mat-sort-header="type">{{'Typ' | translate}}</th>
                        <th mat-sort-header="fehler">{{'Fehler' | translate}}</th>
                    </tr>

                    <tr *ngFor="let criticalThing of criticalThingToShowInConsole"
                        style="border-bottom: 0.5pt dotted gray;">
                        <td>{{criticalThing.id}}</td>
                        <td>{{criticalThing.name}}</td>
                        <td>{{criticalThing.type}}</td>
                        <td style="color:red">
                    <tr *ngFor="let prop of criticalThing.outdatedProperties.getValue()">
                        <img [src]="environment.storageUrl + '/icons/fehler-48.png'" style="width:16px"/>
                        <span style="color:red">{{prop.split(";")[0]}} : {{prop.split(";")[1]}}</span>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
