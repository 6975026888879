import {AfterViewInit, Directive, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs/internal/Subscription';
import {MonitoringLevel} from '../../core/model/MonitoringLevels.enum';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseViewElement implements OnInit, AfterViewInit, OnDestroy
{

    @Input() type = '';

    constructor(type: string)
    {
        this.type = type;
    }

    monitoringLevel = MonitoringLevel;
    /*@ViewChild("cardWidth", { read: ElementRef }) cardWidth: ElementRef;*/

    @Input() resizeEvent: EventEmitter<GridsterItem>;

    resizeSub: Subscription;

    childResizeEvent: EventEmitter<any> = new EventEmitter<any>();

    fontsize = '30px';

    chart = false;
    info = false;

    abstract onResize(result: any);

    getChartEvent(value: boolean)
    {
        this.chart = value;
    }

    getInfoEvent(value: boolean)
    {
        this.info = value;
    }

    ngOnInit(): void
    {
        if (this.resizeEvent == null) return;
        this.resizeSub = this.resizeEvent.subscribe((result) =>
        {
            if (result.type === this.type || result.type === 'all')
            {
                this.onResize(result);
            }
        });
    }

    ngAfterViewInit(): void
    {

        this.onResize(null);

    }

    ngOnDestroy()
    {
        this.resizeSub.unsubscribe();
    }


}
