<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung FTS' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung FTS' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>

<app-fts-energy *ngIf="widget === 'fts-energy'"
                [resizeEvent]="resizeEvent"
                [EnergyManagement]="Thing.propertySets.get('ENERGIEMANAGEMENT')">
</app-fts-energy>

<app-fts-chassis *ngIf="widget === 'fts-chassis'"
                 [resizeEvent]="resizeEvent"
                 [Fahrwerk]="Thing.propertySets.get('FAHRWERK')">
</app-fts-chassis>

<app-fts-chassis-km *ngIf="widget === 'fts-chassis-km'"
                    [resizeEvent]="resizeEvent"
                    [Fahrwerk]="Thing.propertySets.get('FAHRWERK')">
</app-fts-chassis-km>

<app-fts-general *ngIf="widget === 'fts-general'"
                 [resizeEvent]="resizeEvent"
                 [FTS_ALLGEMEIN]="Thing.propertySets.get('FTS_ALLGEMEIN')">
</app-fts-general>
<!--
<app-fts-energy *ngIf="widget === 'fts-energy'"
                [resizeEvent]="resizeEvent"
                [EnergyManagement]="Thing.propertySets.get('ENERGYMANAGEMENT')">
</app-fts-energy>
-->
