<div class="row" *ngIf="s7Data">
    <div class="col">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput value="{{s7Data.Name}}" readonly>
        </mat-form-field>
    </div>
    <div class="col">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>StartzeitMessfahrten</mat-label>
            <input matInput id="newBeginTime" placeholder="00:00:00" (input)="changeBeginTime($event)"
                   value="{{s7Data.S7TestdriveData.LetzterAnfang}}">
        </mat-form-field>
    </div>
    <div class="col">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>StartzeitMessfahrten</mat-label>
            <input matInput id="newEndTime" placeholder="00:00:00" (input)="changeEndTime($event)"
                   value="{{s7Data.S7TestdriveData.LetztesEnde}}">
        </mat-form-field>
    </div>
    <div class="col">
        <mat-form-field appearance="fill">
            <mat-label>StartzeitMessfahrtenAktive</mat-label>
            <mat-select (selectionChange)="changeActive($event)"
                        value="{{s7Data.S7TestdriveData.StartzeitMessfahrtenAktive}}" #newActive>
                <mat-option value="True">True</mat-option>
                <mat-option value="False">False</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="row" style="float: right">
    <div class="col">
        <button type="button" class="btn btn btn-danger" style="margin-right: 10px" (click)="onNoClick()">Abbrechen
        </button>
        <button type="button" class="btn btn btn-success" (click)="onSaveClick()">Speichern</button>
    </div>
</div>
