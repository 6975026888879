import {Component, Input, OnInit} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';

@Component({
    selector: 'app-conver-statistic',
    templateUrl: './conver-statistic.component.html',
    styleUrls: ['./conver-statistic.component.scss',
        './../card.scss']
})
export class ConverStatisticComponent extends BaseViewElement implements OnInit
{

    journeysSpecific = [8][8];

    @Input() OperatingHoursError: boolean;
    @Input() OperatingHoursErrorLevel: MonitoringLevel;
    @Input() OperatingHoursValue: LimitSizeArrayModel<any>[];

    @Input() JourneysSpecificError: boolean;
    @Input() JourneysSpecificErrorLevel: MonitoringLevel;
    @Input() JourneysSpecificValue: LimitSizeArrayModel<any>[];

    @Input() JourneysAllError: boolean;
    @Input() JourneysAllErrorLevel: MonitoringLevel;
    @Input() JourneysAllValue: LimitSizeArrayModel<any>[];


    constructor()
    {
        super('conver-statistic');
    }

    ngOnInit(): void
    {
        super.ngOnInit();
        if (this.JourneysSpecificValue == null) return;
        if (this.JourneysSpecificValue.length === 0) return;

        this.JourneysSpecificValue[0].valuePushed.subscribe((data) =>
        {
            if (data == null) return;
            this.journeysSpecific = JSON.parse(data.value);
        });
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

}
