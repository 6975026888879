<app-group-header [Heading]="'Statistik' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Einlagerungen' | translate"
                      [Error]="RollIn?.isError"
                      [ErrorLevel]="RollIn?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[RollIn?.TimeseriesData]"
                      [MaintenanceValue]="RollIn?.ValueLastService"
                      [Legend]="['Einlagerung' | translate, 'Stand Wartung' | translate]"
                      [Info]="'Anzahl der Einlagerungen'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Auslagerungen' | translate"
                      [Error]="RollOut?.isError"
                      [ErrorLevel]="RollOut?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[RollOut?.TimeseriesData]"
                      [MaintenanceValue]="RollOut?.ValueLastService"
                      [Legend]="['Auslagerung' | translate, 'Stand Wartung' | translate]"
                      [Info]="'Anzahl der Auslagerungen'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Umlagerung' | translate"
                      [Error]="Relocation?.isError"
                      [ErrorLevel]="Relocation?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Relocation?.TimeseriesData]"
                      [MaintenanceValue]="Relocation?.ValueLastService"
                      [Legend]="['Umlagerung' | translate, 'Stand Wartung' | translate]"
                      [Info]="'Anzahl der Umlagerungen'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
