<div class="row" id="footerRoot">
    <div class="col-lg-6 col-xs-12 col-sm-12">
        <h4>
            <img class="icon" src="../../../../icons/util/phone.svg" alt="Telefon Icon">
            07261-939111
        </h4>
    </div>
    <div class="col-lg-6 col-xs-12 col-sm-12">
        <h4>
            <img class="icon" src="../../../../icons/util/envelope-closed.svg" alt="Email Icon">
            service@gebhardt-group.com
        </h4>
    </div>
</div>