import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {PiechartOptions} from '../../../core/view/charts/options/piechart-options';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-good-bad-reads',
    templateUrl: './good-bad-reads.component.html',
    styleUrls: ['./good-bad-reads.component.scss',
        './../card.scss']
})
export class GoodBadReadsComponent extends BaseViewElement implements OnInit, OnChanges
{

    public loadOptionsPie =
        new PiechartOptions('Gut-/Fehl-Lesungen', '', '%',
            ['Gut-Lesungen', 'Fehl-Lesungen']);

    @Input() GeneralInformation: PropertySet;

    GoodReads: Property<any>;
    BadReads: Property<any>;

    constructor()
    {
        super('good-bad-reads');
        this.chart = true;
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.GeneralInformation != null)
        {
            this.GoodReads = this.GeneralInformation.properties.get('OK_READING_COUNT');
            this.BadReads = this.GeneralInformation.properties.get('BAD_READING_COUNT');
        }
    }

}
