<div *ngIf="locations">

    <app-thing-banner [items]="[['Landkarte', 'S0']]"></app-thing-banner>

    <div class="row">

        <div class="col-lg-12 subheading">
            <h2 id='S0'>{{'Landkarte' | translate}}</h2>
        </div>

        <div class="col-lg-12">
            <div class="tile">

                <div class="tileBody videoContainer">

                    <div [(leafletCenter)]="center" [leafletLayers]="markers" [leafletOptions]="options" class="map"
                         leaflet>
                    </div>

                    <!--<iframe src="../../../../assets/map/sinsheim.html" frameborder="0" scrolling="no" class="video"></iframe>-->
                    <!--
                    <agm-map [latitude]="data.activeLocation?.mapCoordinates.lat" [longitude]="data.activeLocation?.mapCoordinates.lng">
                      <agm-marker
                        *ngFor="let m of data.activeCompany?.locations"
                        [latitude]="m.mapCoordinates.lat"
                        [longitude]="m.mapCoordinates.lng"
                        [label]="m.mapCoordinates.label"
                        [markerDraggable]="false">
                      </agm-marker>
                    </agm-map>-->
                </div>
            </div>
        </div>
    </div>
</div>
