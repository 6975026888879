import {Component, Input, OnInit, Renderer2, ElementRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-health-card',
  templateUrl: './health-card.component.html',
  styleUrls: ['./health-card.component.scss']
})
export class HealthCardComponent implements OnInit {

    @Input() scaleFactor = 0.5;
    @Input() inData: any;
  constructor(
      private renderer: Renderer2,
      private el: ElementRef,
      private translate: TranslateService
  ) { }

  ngOnInit(): void {
      const scaleValue = `scale(${this.scaleFactor})`;
      this.renderer.setStyle(this.el.nativeElement, 'transform', scaleValue);

      // Apply scaling to fonts and sizes within the component
      const fontSize = `${this.scaleFactor * 16}px`;
      this.renderer.setStyle(this.el.nativeElement, 'font-size', fontSize);
  }

}
