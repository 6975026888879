<div *ngIf="company != null">

    <!--Banner-->
    <div class="row" #banner id="banner">
        <div *ngIf="isOneLine; else monitoringDropdown" class="col-lg-12 monitoringItem">
            <ul class="list-inline">
                <li class="list-inline-item">
                    <a (click)="nav.navigateBack('Customer')" [routerLink]="['/']">
                        {{'Unternehmen' | translate}}
                        ({{company.name}})
                    </a>
                </li>
                <li class="list-inline-item"> ></li>
                <li class="list-inline-item">{{'Standort' | translate}}</li>
            </ul>
        </div>

        <ng-template class="col-12" #monitoringDropdown>
            <button class="btn btn-secondary" type="button" style="margin-left: 10px;"
                    (click)="nav.navigateBack('Customer')" [routerLink]="['/']">
                <img [src]="environment.storageUrl + '/icons/keyboard_backspace-white-18dp.svg'"/>
            </button>
        </ng-template>
    </div>

    <div class="row">

        <div *ngFor="let loc of locations | orderBy:'name'"
             class="col-lg-2 col-md-3 col-sm-4 col-12 monitoring-card">

            <app-card-component [customerDetail]="loc"></app-card-component>
        </div>

        <app-locations class="col-lg-12"></app-locations>

        <div class="gap"></div>
    </div>
</div>
