<div class="row">

    <div class="col-lg-12 subheading">
        <div class="row">
            <h3 id="All">{{monGateService.activeGateway | gatewayStatusV2 : "provice-gateway-name"}}</h3>
        </div>

        <!--        <button (click)="socketTest()" class="btn btn-sm">Socket Test</button>-->
    </div>

    <div *ngIf="cpuOption" class="col-lg-4 gapBot">
        <app-gauge-tile [heading]="'CPU'"
                        [unit]="'(%)'"
                        [value]="[cpuValue.value]"
                        [options]="cpuOption">
        </app-gauge-tile>
    </div>

    <div *ngIf="ramOption" class="col-lg-4 gapBot">
        <app-gauge-tile [heading]="'RAM'"
                        [unit]="'(GB)'"
                        [value]="[ramValue.value]"
                        [options]="ramOption">
        </app-gauge-tile>
    </div>

    <!-- Wenn ein Gateway aktiv gesetzt wurde und mehr als einen Status hat, werden die Festplatten
         des neusten Status aufgelistet-->
    <div *ngIf="monGateService.activeGateway && monGateService.activeMachineInfo" class="col-lg-4">
        <div *ngFor="let option of driveOption; let i = index">
            <!--            <div style="padding-bottom: 20px">-->
            <!--                <app-custom-tile [heading]="drive.Name + ' ' + drive.Label" style="padding-bottom: 10px">-->
            <!--                    <div style="margin: 10px">{{freeInGb(drive)}} frei von {{sizeInGb(drive)}}</div>-->
            <!--                    <div style="padding: 10px">-->
            <!--                        <mat-progress-bar mode="determinate" value="{{usedInPercent(drive)}}" ></mat-progress-bar>-->
            <!--                    </div>-->
            <!--                </app-custom-tile>-->
            <!--            </div>-->
            <div class="gapBot">
                <app-gauge-tile [heading]="'HDD ' + (i + 1)"
                                [unit]="'(GB)'"
                                [value]="[driveValue[i].value]"
                                [options]="option">
                </app-gauge-tile>
            </div>
        </div>
    </div>

    <div *ngIf="monGateService.activeGateway && monGateService.activeMachineInfo" class="col-lg-4 gapBot">
        <app-custom-tile [heading]="'Programme'">
            <table class="table table-striped">
                <tr class="table-secondary">
                    <th>
                        Programm
                    </th>
                    <th>
                        Version
                    </th>
                </tr>
                <tr *ngFor="let program of monGateService.activePrograms | keyvalue">
                    <th>
                        {{program.key | titlecase}}
                    </th>
                    <td>
                        {{program.value.version}}
                    </td>
                </tr>
            </table>
        </app-custom-tile>
        <app-custom-tile [heading]="'OS Info'">
            <table class="table table-striped">
                <tr class="table-secondary">
                    <th>
                        Operating System
                    </th>
                    <th>
                        Version
                    </th>
                    <th>
                        Release
                    </th>
                </tr>
                <tr>
                    <th>
                        {{monGateService.activeMachineInfo.PlatformOS | titlecase }}
                    </th>
                    <th>
                        {{monGateService.activeMachineInfo.PlatformRelease | titlecase}}
                    </th>

                    <td>
                        {{monGateService.activeMachineInfo.PlatformVersion}}
                    </td>
                </tr>
            </table>
        </app-custom-tile>
        <!--{{monGateService.activePrograms | json}}-->
    </div>

    <div *ngIf="monGateService.activeGateway && monGateService.activeFlowInfo" class="col-lg-8 gapBot">
        <app-custom-tile [heading]="'Crosser Flows'">
            <table class="table table-striped">
                <tr class="table-secondary">
                    <th>
                        Name
                    </th>
                    <th>
                        Flow version
                    </th>
                    <th>
                        Last start time
                    </th>
                    <th>
                        Should be running
                    </th>
                    <th>
                        is running
                    </th>
                    <th>
                        is healthy
                    </th>
<!--                    <th>
                        Unhealthy Reason
                    </th>-->
                    <th>
                    </th>
                </tr>
                <tr *ngFor="let flow of monGateService.activeFlowInfo">
                    <th>
                        {{flow.Name}}
                    </th>
                    <th>
                        {{flow.FlowVersion}}
                    </th>
                    <th>
                        {{flow.LastStartTime}}
                    </th>
                    <th>
                        <fa-icon *ngIf="flow.IsEnabled" [icon]="icoOk"
                                 [styles]="{'stroke': 'green', 'color': 'green'}"></fa-icon>
                        <fa-icon *ngIf="!flow.IsEnabled" [icon]="icoError"
                                 [styles]="{'stroke': 'red', 'color': 'red'}"></fa-icon>

                    </th>
                    <th>
                        <fa-icon *ngIf="flow.IsRunning" [icon]="icoOk"
                                 [styles]="{'stroke': 'green', 'color': 'green'}"></fa-icon>
                        <fa-icon *ngIf="!flow.IsRunning" [icon]="icoError"
                                 [styles]="{'stroke': 'red', 'color': 'red'}"></fa-icon>

                    </th>
                    <th>
                        <fa-icon *ngIf="flow.Healthy" [icon]="icoOk"
                                 [styles]="{'stroke': 'green', 'color': 'green'}"></fa-icon>
                        <fa-icon *ngIf="!flow.Healthy" [icon]="icoError"
                                 [styles]="{'stroke': 'red', 'color': 'red'}"></fa-icon>
                    </th>
<!--                    <th>
                        {{flow.UnhealthyReason}}
                    </th>-->
                </tr>
            </table>
        </app-custom-tile>
    </div>

    <div *ngIf="monGateService.activeGateway && monGateService.activeTestdrive" class="col-lg-12 gapBot">
        <app-custom-tile [heading]="'SPS Data'">
            <table class="table table-striped">
                <tr class="table-secondary">
                    <th>
                        Name
                    </th>
                    <!--                    <th>-->
                    <!--                        Aktive-->
                    <!--                    </th>-->
                    <!--                    <th>-->
                    <!--                        Reset-->
                    <!--                    </th>-->
                    <th>
                        StartzeitMessfahrtenAktive
                    </th>
                    <th>
                        StartzeitMessfahrten
                    </th>
                    <th>
                        CpuZeit
                    </th>
                    <th>
                        LetzterAnfang
                    </th>
                    <th>
                        LetztesEnde
                    </th>
                    <th>
                        Aufzeichnung_Modus
                    </th>
                    <th>
                    </th>
                </tr>
                <tr *ngFor="let s7 of monGateService.activeTestdrive">
                    <th>
                        {{s7.Name}}
                    </th>
                    <!--                    <th>-->
                    <!--                        {{s7.S7TestdriveData.Aktive}}-->
                    <!--                    </th>-->
                    <!--                    <th>-->
                    <!--                        {{s7.S7TestdriveData.Reset}}-->
                    <!--                    </th>-->
                    <th>
                        {{s7.StartTimeTestrunActive | boolean}}
                    </th>
                    <th>
                        <div *ngIf="s7.StartTimeTestrun">
                            {{s7.StartTimeTestrun | date:'HH:mm:ss': '+0000'}}
                        </div>
                    </th>
                    <th>
                        {{s7.CpuTime * 1000  | date:'d.M.y HH:mm:ss' : '+0000'}}
                    </th>
                    <th>
                        {{s7.LastStart * 1000  | date:'d.M.y HH:mm:ss' : '+0000'}}
                    </th>
                    <th>
                        {{s7.LastEnd * 1000  | date:'d.M.y HH:mm:ss': '+0000'}}
                    </th>
                    <th>
                        {{s7.RecordingMode}}
                    </th>
                    <th>
                        <button (click)="setS7Data(s7)" class="btn">{{'Edit'}}</button>
                    </th>
<!--                <th>
                        <div *ngIf="!IsSpezialThing(s7); else sBtn">
                            <button (click)="setS7Data(s7)" class="btn">{{'Edit'}}</button>
                        </div>
                        <ng-template #sBtn>
                            <button (click)="setOtherS7Data(s7)" class="btn">{{'Edit'}}</button>
                        </ng-template>
                    </th>-->
                </tr>
            </table>
        </app-custom-tile>
    </div>

</div>
