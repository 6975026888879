import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';
import {DateService} from '../../../core/controller/services/date.service';
import {NavigationService} from '../../../core/controller/services/navigation.service';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {PiechartOptions} from '../../../core/view/charts/options/piechart-options';
import {BaseThing} from '../../base-thing';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../../core/controller/services/layout.service';
import {UserSettingsService} from '../../../core/controller/services/user-settings.service';

@Component({
    selector: 'app-stw',
    templateUrl: './storeware.component.html',
    styleUrls: [
        './storeware.component.scss',
        '../../../core/view/tile/tile.scss',
        '../../../../assets/css/template.scss']
})
// Storeware Seite
export class StorewareComponent extends BaseThing implements OnInit, OnDestroy
{
    deviceStatusAisleWms = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);
    deviceStatusOperationMode = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);
    deviceStatusCurrentError = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);

    aisleFillLevelAisleWms = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);
    aisleFillLevelValue = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);

    scannerStatisticsPlcSymbolName = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);
    scannerStatisticsSymbolType = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);
    scannerStatisticsValue = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);

    private lagerfuellstand: string;
    private LeBehaelter: string;
    private BeBehealter: string;

    public loadOptionsPie =
        new PiechartOptions('Lagerfüllstand', '', '',
            ['Leere Behälter', 'Befüllte Behälter']);

    constructor(public route: ActivatedRoute,
                private _router: Router,
                public nav: NavigationService,
                public data: DataProviderService,
                public date: DateService,
                private _translate: TranslateService,
                private _layout: LayoutService,
                private _user: UserSettingsService)
    {
        super(route, _router, nav, data, date, _translate, _layout, _user);

        this._translate.get('Lagerfüllstand').subscribe((x: string) =>
        {
            this.lagerfuellstand = x;
        });
        this._translate.get('Leere Behälter').subscribe((x: string) =>
        {
            this.LeBehaelter = x;
        });
        this._translate.get('Befüllte Behälter').subscribe((x: string) =>
        {
            this.BeBehealter = x;
            this.loadOptionsPie = new PiechartOptions(this.lagerfuellstand, '', '',
                [this.LeBehaelter, this.BeBehealter]);
        });
    }

    ngOnInit()
    {
        super.ngOnInit();

        if (this.thing == null) return;
        /*
                this.thing['STW_DEVICE_STATUS'].dataPulled.subscribe((x) =>
                {
                    if (!x) return;

                    this.deviceStatusAisleWms.pushDataPoint([new DataPoint('RBG', 0), new DataPoint('MLS', 0), new DataPoint('OLS 0202', 0)]); //= this.thing.STW_DEVICE_STATUS.deboxList(this.thing.STW_DEVICE_STATUS.AISLE_WMS.TimeseriesData);

                    this.deviceStatusOperationMode = this.thing['STW_DEVICE_STATUS'].deboxList(this.thing['STW_DEVICE_STATUS']['OPERATIONMODE'].TimeseriesData);
                    this.deviceStatusCurrentError = this.thing['STW_DEVICE_STATUS'].deboxList(this.thing['STW_DEVICE_STATUS']['CURRENT_ERROR'].TimeseriesData);
                });

                this.thing.STW_AISLE_FILL_LEVEL.dataPulled.subscribe((x) =>
                {
                    if (!x) return;

                    this.aisleFillLevelAisleWms.pushDataPoint([new DataPoint('Gasse 1', 0), new DataPoint('Gasse 4', 0), new DataPoint('Gasse 2 Ebene 1', 0), new DataPoint('Gasse 2 Ebene 2', 0)]);// = this.thing.STW_AISLE_FILL_LEVEL.deboxList(this.thing.STW_AISLE_FILL_LEVEL.AISLE_WMS.TimeseriesData);
                    this.aisleFillLevelValue = this.thing.STW_AISLE_FILL_LEVEL.deboxList(this.thing.STW_AISLE_FILL_LEVEL.VALUE.TimeseriesData);
                });

                this.thing.STW_SCANNER_STATISTICS.dataPulled.subscribe((x) =>
                {
                    if (!x) return;

                    this.scannerStatisticsPlcSymbolName = this.thing.STW_SCANNER_STATISTICS.deboxList(this.thing.STW_SCANNER_STATISTICS.PLC_SYMBOL_NAME.TimeseriesData);
                    this.scannerStatisticsSymbolType = this.thing.STW_SCANNER_STATISTICS.deboxList(this.thing.STW_SCANNER_STATISTICS.SYMBOL_TYPE.TimeseriesData);
                    this.scannerStatisticsValue = this.thing.STW_SCANNER_STATISTICS.deboxList(this.thing.STW_SCANNER_STATISTICS.VALUE.TimeseriesData);
                });*/
    }

    ngOnDestroy()
    {
        super.ngOnDestroy();
    }
}
