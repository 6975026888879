import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdminRoutingModule} from './admin-routing.module';
import {MonitoringGatewayComponent} from './monitoring-gateway/monitoring-gateway.component';
import {GatewayStatusPipe} from '../../core/controller/pipes/gateway-status.pipe';
import {MonitoringGatewayPageComponent} from './monitoring-gateway-page/monitoring-gateway-page.component';
import {TileModule} from '../../core/controller/modules/tile.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CacheComponent} from './cache/cache.component';
import {S7ChangeBoxComponent} from './monitoring-gateway-page/s7-change-box/s7-change-box.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {S7CustomChangeBoxComponent} from './monitoring-gateway-page/s7-custom-change-box/s7-custom-change-box.component';
import { PipesModule } from "../../core/controller/modules/pipes.module";
import {AppModule} from '../../app.module';
import { GatewayStatusV2Pipe } from '../../core/controller/pipes/gateway-status-v2.pipe';


@NgModule({
    imports: [
        CommonModule,
        AdminRoutingModule,
        TileModule,
        MatProgressBarModule,
        MatDialogModule,
        MatSelectModule,
        MatInputModule,
        FormsModule,
        FontAwesomeModule,
        PipesModule
    ],
    declarations: [
        MonitoringGatewayComponent,
        GatewayStatusPipe,
        MonitoringGatewayPageComponent,
        CacheComponent,
        S7ChangeBoxComponent,
        S7CustomChangeBoxComponent,
        GatewayStatusV2Pipe
    ],
    exports: [
        GatewayStatusPipe,
        GatewayStatusV2Pipe
    ]
})
export class AdminModule
{
}
