<app-group-header [Heading]="'Statistik Endstelle' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  [noInfo]="true"
                  [noText]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="table-responsive" style="height: calc(100% - 50px)">
    <table class="table table-bordered" style="height: 100%; padding: 0; margin: 0;">
        <thead>
        <tr>
            <th style="width: 25%" scope="col">Endstelle</th>
            <th style="width: 25%" scope="col">Ausschleusungen</th>
            <th style="width: 25%" scope="col">Voll</th>
            <th style="width: 25%" scope="col">Crash</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let es of Endstellen | orderBy:'displayName'">
            <th class="align-middle" scope="row">{{es.displayName}}</th>
            <td class="align-middle">
                <ng-container
                        *ngIf="es?.propertySets?.get('ENDSTELLE')?.properties.get('ANZ_AUSSCHLEUSUNGEN')?.TimeseriesData.currentDatapoint.value != null; else noData">
                    {{es?.propertySets.get('ENDSTELLE').properties.get('ANZ_AUSSCHLEUSUNGEN').TimeseriesData.currentDatapoint.value}}
                </ng-container>
            </td>
            <td class="align-middle">
                <ng-container
                        *ngIf="es?.propertySets?.get('ENDSTELLE')?.properties.get('ANZ_ENDSTELLE_VOLL')?.TimeseriesData.currentDatapoint.value != null; else noData">
                    {{es?.propertySets.get('ENDSTELLE').properties.get('ANZ_ENDSTELLE_VOLL').TimeseriesData.currentDatapoint.value}}
                </ng-container>
            </td>
            <td class="align-middle">
                <ng-container
                        *ngIf="es?.propertySets?.get('ENDSTELLE')?.properties.get('CRASH_ANZ')?.TimeseriesData.currentDatapoint.value != null; else noData">
                    {{es?.propertySets.get('ENDSTELLE').properties.get('CRASH_ANZ').TimeseriesData.currentDatapoint.value}}
                </ng-container>
            </td>
        </tr>
        </tbody>
    </table>
</div>


<ng-template #noData>
    <img title="Keine aktuellen Daten vorhanden!" class="img-fluid"
         src="../../../../assets/icons/cloud_off_black_18dp.png"/>
</ng-template>


