import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-movements',
    templateUrl: './movements.component.html',
    styleUrls: ['./movements.component.scss',
        './../card.scss']
})
export class MovementsComponent extends BaseViewElement implements OnInit, OnChanges
{

    @Input() Movements: PropertySet;

    TotalPicks: Property<any>;
    TotalDrops: Property<any>;
    TelescopeMovements: Property<any>;
    XAxisMovements: Property<any>;
    YAxisMovements: Property<any>;


    constructor()
    {
        super('movements');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Movements != null)
        {
            if (this.Movements.properties.get('TOTALPICKS') == null)
            {
                this.TotalPicks = this.Movements.properties.get('TOTAL_PICKS');
                this.TotalDrops = this.Movements.properties.get('TOTAL_DROPS');
                this.TelescopeMovements = this.Movements.properties.get('MOVEMENTS_TELESCOPE');
                this.XAxisMovements = this.Movements.properties.get('MOVEMENTS_X_AXIS');
            }
            else
            {
                this.TotalPicks = this.Movements.properties.get('TOTALPICKS');
                this.TotalDrops = this.Movements.properties.get('TOTALDROPS');
                this.XAxisMovements = this.Movements.properties.get('XAXIS_MOVEMENTS');
                this.YAxisMovements = this.Movements.properties.get('YAXIS_MOVEMENTS');
            }
        }
    }
}
