<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">
    <h2>GEBHARDT Insight</h2>


    <h3 class="subheading">{{'Menüstruktur' | translate}}</h3>
    <p>{{'Leistungsdaten Geräte, Lagerinformation….' | translate}}</p>
    <img id="menuStructure" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/GEBHARDT Insight/MenuStructure_de.png"
          class="embed-responsive mx-auto d-block" style="margin-bottom: 100px;"> 
    
     <h3 class="subheading">{{'Anlageninformationen' | translate}}</h3>
    <p>{{'Historische Daten wie die Anlageninformation werden nach Anzahl und Dauer in verschiedenen Dashboards angezeigt' | translate}}</p>
    <img id="menuStructure" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/GEBHARDT Insight/systemInformation_de.png"
          class="embed-responsive mx-auto d-block" style="margin-bottom: 100px;">

    <h3 class="subheading">{{'Datumfilter' | translate}}</h3>
    <p>{{'Das Datum wird auf dem ersten Insight Dashboard eingestellt und wird an alle anderen Dashboards weitergegeben' | translate}}</p>
    <img id="dateFilter" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/GEBHARDT Insight/dateFilter_de.png"
          class="embed-responsive mx-auto d-block" style="margin-bottom: 100px;">
    
    <h3 class="subheading">{{'Datumseingabe zurücksetzen' | translate}}</h3>
    <img id="resetDateEntry" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/GEBHARDT Insight/resetDateEntry_de.png"
          class="embed-responsive mx-auto d-block" style="margin-bottom: 100px;">

    <h3 class="subheading">{{'Übersicht / Allgemeine Funktionen' | translate}}</h3>
    <p>{{'Die GEBHARDT Insight stellt ausgewertete Daten der Anlage und der beinhalteteten Geräte dar.' | translate}}</p>
    <p>{{'Durch einen Zeitstrahl kann man den zu betrachteten Zeitraum vergrößern, bzw. verkleinern. Auch in den Grafiken lässt sich durch einen Click auf ein bestimmtest Datum ein Graph genauer hervorheben.' | translate}}</p>
    <p>{{'Hovert man über einen bestimmten Punkt im Graphen, kann man eine Detailansicht zu diesem Zeitpunkt sehen.' | translate}}</p>
    <img id="insightOverview" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/GEBHARDT Insight/insightOverview_de.png"
          class="w-100">



</div>
