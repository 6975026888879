import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ChartsModule} from '../../core/controller/modules/charts.module';
import {PipesModule} from '../../core/controller/modules/pipes.module';
import {PlatinumRoutingModule} from './platinum-routing.module';
import {TestdrivesComponent} from './testdrives/testdrives.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ThingHealthComponent} from './thing-health/thing-health.component';
import {TileModule} from '../../core/controller/modules/tile.module';
import {TranslateModule} from '@ngx-translate/core';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';
import {ThingHealthV2Component} from './thing-health-v2/thing-health-v2.component';
import {MaterialHealthDialogComponent} from './view/dialog/material-dialog-health/material-dialog.component';
import {PowerbiComponent} from '../../core/view/powerbi/powerbi.component';
import {TreeNodeComponent} from './view/tree/material-tree-health-node/tree-node/tree-node.component';
import { MaterialComponentViewDialogComponent } from './view/dialog/material-component-view-dialog/material-component-view-dialog.component';
import { AlertDialogComponent } from './view/dialog/alert-dialog/alert-dialog.component';
import { HierarchyDialogComponent } from './view/dialog/hierarchy-dialog/hierarchy-dialog.component';
import { HealthCardComponent } from './view/cards/health-card/health-card.component';
import { StatusButtonComponent } from './view/tree/table-tree/nodes/node-buttons/status-button/status-button.component';
import { KomponentenViewComponent } from './view/tree/table-tree/nodes/node-buttons/komponenten-view/komponenten-view.component';
import { HealthOverviewComponent } from './view/cards/health-overview/health-overview.component';
import { HealthDetailsComponent } from './view/cards/health-details/health-details.component';

@NgModule({
    imports: [
        CommonModule,
        PlatinumRoutingModule,
        PipesModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        MatTableModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ChartsModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDatepickerModule,
        MatDialogModule,
        TileModule,
        TranslateModule,
        MatTreeModule, MatIconModule,
        MatCardModule
    ],
    declarations: [
        TestdrivesComponent,
        ThingHealthComponent,
        ThingHealthV2Component,
        MaterialHealthDialogComponent,
        TreeNodeComponent,
        MaterialComponentViewDialogComponent,
        AlertDialogComponent,
        HierarchyDialogComponent,
        HealthCardComponent,
        StatusButtonComponent,
        KomponentenViewComponent,
        HealthOverviewComponent,
        HealthDetailsComponent
    ],
    exports: [
        BrowserAnimationsModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        ClipboardModule,
        DragDropModule,
        PortalModule,
        ScrollingModule,
        CdkTableModule,
        CdkStepperModule,
        CdkTreeModule,
        MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonToggleModule, MatCardModule, MatChipsModule, MatStepperModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatIconModule,
        MatListModule, MatMenuModule, MatNativeDateModule, MatRippleModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatSidenavModule, MatSliderModule, MatSnackBarModule, MatTabsModule,
        MatToolbarModule, MatTooltipModule, MatTreeModule, OverlayModule
    ]
})
export class PlatinumModule
{
}
