<app-group-header [Heading]="'Fehler und Kommentare' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <div *ngIf="CurrentValue != null">
        <app-default-card #card class="card" style="border: none; border-radius: unset;"
                          [Heading]="'Fehler Momentan' | translate"
                          [Error]="CurrentError"
                          [ErrorLevel]="CurrentErrorLevel"
                          [showChart]="chart"
                          [showInfo]="info"
                          [customTemplate]="errorCurrent"
                          [customNoData]="CurrentValue[0].currentDatapoint.value == null"
                          [Legend]="['Fehler Momentan' | translate]"
                          [Info]="'Anzeige, ob derzeit ein Fehler auftritt.'"
                          [resizeEvent]="childResizeEvent"></app-default-card>

        <ng-template #errorCurrent>
            {{CurrentValue[0].currentDatapoint.value | operatingMode: errorMap}}
        </ng-template>
    </div>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Kommentare' | translate"
                      [Error]="CommentError"
                      [ErrorLevel]="CommentErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="CommentValue"
                      [Legend]="['Kommentare' | translate]"
                      [Info]="'Kommentare zu den Fehlern werden hier aufgelistet.'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Fehler Historie' | translate"
                      [Error]="HistoryError"
                      [ErrorLevel]="HistoryErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [customTemplate]="errorHistoryTmp"
                      [ChartType]="'table'"
                      [Legend]="['Fehler Historie' | translate]"
                      [Info]="'Alle Fehler, die seit Inbetriebnahme/ Wartung aufgetreten sind.'"
                      [resizeEvent]="childResizeEvent"></app-default-card>


    <ng-template #errorHistoryTmp>
        <table class="table" [ngClass]="{'errorTile' : HistoryValue[0].currentDatapoint.isError}">
            <thead>
            <tr>
                <th class="table-spacing-auto">{{'Fehler' | translate}}</th>
                <th class="table-spacing-auto">{{'Anfang' | translate}}</th>
                <th class="table-spacing-auto">{{'Ende' | translate}}</th>
            </tr>
            </thead>
            <tbody *ngIf="HistoryValue[0].currentDatapoint.value != null; else noFaults">
            <!--Array wird nochmal gedreht das die neusten Daten immer oben stehen-->
            <tr *ngFor="let id of errorHistory; let i = index">
                <td class="table-spacing-auto">
                    {{errorHistory[i]['Fehler: ']}}
                </td>
                <td class="table-spacing-auto">
                    {{errorHistory[i]['Anfang: '] | date:'long' }}
                </td>
                <td class="table-spacing-auto">
                    {{errorHistory[i]['Ende: '] | date:'long' }}
                </td>
            </tr>
            </tbody>

            <ng-template #noFaults>

                <div class="tablePlaceholdText">
                    {{'Keine Fehler' | translate}}
                </div>
            </ng-template>
        </table>
    </ng-template>

</div>
