import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-ssdtm-clamping-general',
    templateUrl: './ssdtm-clamping-general.component.html',
    styleUrls: ['./ssdtm-clamping-general.component.scss', './../card.scss']
})
export class SsdtmClampingGeneralComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() Spannsystem: PropertySet;

    ClampingPressure: Property<any>;
    ClampingPressureTarget: Property<any>;
    PistonPosChange: Property<any>;
    PistonPosRef: Property<any>;

    @ViewChild('imgContainer', {read: ElementRef}) imgContainer: ElementRef;
    public img = environment.storageUrl + '/models3d/things/ssdtm/sms.png';
    @Input() width = 'auto';
    height = '100%';

    constructor()
    {
        super('ssdtm-clamping-general');
    }

    ngOnInit()
    {
        if (this.resizeEvent == null) return;
        this.resizeSub = this.resizeEvent.subscribe((widget) =>
        {
            if (this.imgContainer != null)
            {
                if (this.imgContainer.nativeElement.offsetWidth > this.imgContainer.nativeElement.offsetHeight)
                {
                    this.width = 'auto';
                    this.height = '100%';
                }
                else
                {
                    this.width = '100%';
                    this.height = 'auto';
                }

            }
            this.childResizeEvent.emit(widget);
        });
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Spannsystem == null) return;
        this.ClampingPressure = this.Spannsystem.properties.get('SPANNDRUCK_IST');
        this.ClampingPressureTarget = this.Spannsystem.properties.get('SPANNDRUCK_SOLL');
        this.PistonPosChange = this.Spannsystem.properties.get('KOLBENPOSITION_AENDERUNG');
        this.PistonPosRef = this.Spannsystem.properties.get('KOLBENPOSITION_REF');
    }

}
