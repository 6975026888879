<div class="tile" [ngClass]="{'errorTile' : error}">

    <div class="tileHead">
        {{heading}}
        <div class="unit">
            {{unit}}
        </div>
    </div>

    <div class="tileBody">
        <div *ngIf="value != null" class="tileBody">
            <div *ngIf="value[0] != null; else placeholdLine">
                <div style="height: 400px;" *ngIf="value[0][0] != null; else placeholdLine">
                    <app-linechart [legend]="legend"
                                   [value]="value"
                                   [setOptions]="options"
                                   [resizeEvent]="resizeEvent">
                    </app-linechart>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #placeholdLine>
    <app-placehold-linechart></app-placehold-linechart>
</ng-template>
