import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HelpPageComponent} from '../../help-page.component';
import {environment} from '../../../../../../environments/environment';

@Component({
    selector: 'app-help-page-favorite',
    templateUrl: './help-page-favorite.component.html',
    styleUrls: ['./help-page-favorite.component.scss', '../../helpPageModule.scss']
})
export class HelpPageFavoriteComponent extends HelpPageComponent implements OnInit
{
    curentLang: string;

    constructor(translate: TranslateService)
    {
        super(translate);
        this.curentLang = translate.currentLang;
    }

    ngOnInit(): void
    {
    }
    
 public readonly environment = environment;
}
