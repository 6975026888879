import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Configurations} from './configurations';
import {DataProviderService} from './core/controller/services/data-provider.service';
import {MonitoringService} from './core/controller/services/monitoring.service';
import {NavigationService} from './core/controller/services/navigation.service';
import {TabItem} from './core/model/helper-models/tab-item.model';
import {Thing} from './core/model/thing.model';
import {LastMaintenanceService} from './core/controller/services/last-maintenance.service';
import {ThresholdsService} from './core/controller/services/thresholds.service';
import {TranslateService} from '@ngx-translate/core';
import {UserSettingsService} from './core/controller/services/user-settings.service';
import {AuthenticationService} from './core/controller/services/authentication.service';

import {animate, state, style, transition, trigger} from '@angular/animations';
import {HttpClient} from '@angular/common/http';
import {FavoriteService} from './core/controller/services/favorite.service';
import {EventService} from './core/controller/services/event.service';
import {environment} from '../environments/environment';
import {ControlLightService} from './core/controller/services/control-light.service';


interface AisleAndState
{
    aisle: number;
    state: string;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        // Each unique animation requires its own trigger. The first argument of the trigger function is the name
        trigger('rotatedArrow', [
            state('default', style({transform: 'rotate(0)'})),
            state('rotated', style({transform: 'rotate(90deg)'})),
            transition('rotated => default', animate('50ms ease-out')),
            transition('default => rotated', animate('50ms ease-in'))
        ])
    ]
})

/**
 * Basis Komponent. Enthält Navigation, Routing und aufruf der anderen Komponents.
 **/
export class AppComponent implements OnInit, OnDestroy
{

    constructor(public data: DataProviderService,
                private _router: Router,
                private _route: ActivatedRoute,
                public translate: TranslateService,
                public nav: NavigationService,
                private _titleService: Title,
                public monitoring: MonitoringService,
                private toastr: ToastrService,
                private _lastMaintainance: LastMaintenanceService,
                private _thresholds: ThresholdsService,
                private _userSettings: UserSettingsService,
                private _favorite: FavoriteService,
                public auth: AuthenticationService,
                private http: HttpClient,
                private event: EventService,
                private controlLights: ControlLightService)
    {
    }

    title = 'Gebhardt Galileo IoT';


    searchText: string;


    Dashboard = [];
    Testfahrt = [];

    showFirstOLS = true;
    // zweidimensionales Array
    OLSAislesAndThings: Thing[][];
    OLSAisles: AisleAndState[];

    showFirstOLSE = true;
    // zweidimensionales Array
    OLSEAislesAndThings: Thing[][];
    OLSEAisles: AisleAndState[];

    showFirstFTS = true;

    showFirstOLPS = true;
    // zweidimensionales Array
    OLPSAislesAndThings: Thing[][];
    OLPSAisles: AisleAndState[];

    showFirstMLS = true;
    // zweidimensionales Array
    MLSAislesAndThings: Thing[][];
    MLSAisles: AisleAndState[];

    showFirstRBG = true;
    // zweidimensionales Array
    RBGAislesAndThings: Thing[][];
    RBGAisles: AisleAndState[];


    showFirstHCV = true;

    showFirstVCV = true;
    // zweidimensionales Array
    VCVAislesAndThings: Thing[][];
    VCVAisles: AisleAndState[];

    showFirstGDP = true;
    showFirstCONVER = true;
    showFirstLSC = true;
    showFirstSSAGM = true;

    showFirstSSDTM = true;
    // zweidimensionales Array
    SSDTMAislesAndThings: Thing[][];
    SSDTMAisles: AisleAndState[];

    showFirstSSIF = true;
    // zweidimensionales Array
    SSIFAislesAndThings: Thing[][];
    SSIFAisles: AisleAndState[];

    showFirstSSES = true;
    // zweidimensionales Array
    SSESAislesAndThings: Thing[][];
    SSESAisles: AisleAndState[];

    showFirstCARRIER = true;
    // zweidimensionales Array
    CARRIERAislesAndThings: Thing[];

    showFirstSTOREWARE = true;

    currShowThings = 5;
    addFive = 5;


    @ViewChild('tabul', {static: true}) tabs: ElementRef;

    @ViewChild('menu', {static: true}) navElement: ElementRef;
    private _loggerPrefix = '[AppComponent] ';

    private _monitoringTimer = null;


    OlsState = 'default';

    SscrrState = 'default';

    OlseState = 'default';


    MlsState = 'default';

    RbgState = 'default';

    VcvState = 'default';

    OlpsState = 'default';

    FtsState = 'default';

    HcvState = 'default';

    GdpState = 'default';

    ConverState = 'default';

    LscState = 'default';

    SsagmState = 'default';

    SsdtmState = 'default';

    SsifState = 'default';

    SsesState = 'default';

    StorewareState = 'default';


    /**
     * Berechnet beim Verstellen der Fenstergröße ob die Tabs noch in eine Reihe passen
     */
    @HostListener('window:resize', ['$event'])
    onResize(event)
    {
        this.nav.tabCalc();
    }

    /**
     * Schließt das Menü, wenn auf eine andere Stelle in der Seite gedrückt wird
     * @param event
     * @param target
     */
    @HostListener('document: click', ['$event', '$event.target'])
    // Schließt das Menü wenn auf eine andere Stelle in der Seite gedrückt wird
    @HostListener('document: click', ['$event', '$event.target'])

    onClick(event: Event, target: HTMLElement)
    {
        if (this.nav.NavOpen === 'hidden' || target.id === 'menu-button')
        {
            return;
        }

        let parentFound = false;
        const initTarget = target;

        while (target != null && !parentFound)
        {
            if (target === this.navElement.nativeElement)
            {
                parentFound = true;
            }
            target = target.parentElement;
        }

        if (!parentFound && initTarget.id !== 'nav-opener')
        {
            event.stopPropagation();
            this.nav.toggleNav();
        }
    }

    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * Startet das holen der Daten.
     */
    async ngOnInit()
    {

        this.http.get('./buildinfo.json', {
            responseType: 'text',
            headers: {'Content-Type': 'application/json'}
        }).subscribe(
            (next) =>
            {
                console.log(next);
            },
            (error) =>
            {
                console.log('Keine Buildinfo vorhanden.');
            });


        await this.auth.login();
        const header = await this.auth.getDefaultHttpHeaders();
        // this.setTitle(this.title);
        await this._userSettings.initUser();

        this.event.init(header);

        // this.delay(5000);

        await this.data.getCustomer();


        const activeUrl = decodeURI(this._router.url).split('/');

        // Setzt Route wenn nur ein Kunde/Standort/System vorhanden
        if (this.data.customer.length === 1 && activeUrl[1] === '')
        {
            let url = '';

            if (this.data.customer[0].locations.size === 1)
            {
                if (this.data.customer[0].locations.values().next().value.systems.size === 1)
                {
                    url = this.nav.buildSystemUrl(this.data.customer[0].locations.values().next().value.systems.values().next().value);
                }
                else
                {
                    url = this.nav.buildLocationUrl(this.data.customer[0].locations.values().next().value);
                }
            }
            else
            {
                url = this.nav.buildCompanyUrl(this.data.customer[0]);
            }

            this._router.navigateByUrl(url);
        }

        this.nav.setActiveCustomerDetails();

        this.nav.retrieveTabs();

        this.nav.tabCalc();

        this._favorite.readFavorite();

        this.controlLights.getStatus(this.data.things);
        // this._lastMaintainance.getLastMaintenance();

        Configurations.InitaliseComplete.next(true);

        // this.monitoring.updateThingActivity();

        this._monitoringTimer = setInterval(() =>
        {
            // this.monitoring.updateThingActivity().subscribe();
        }, 1000 * 60 * 10); // 10 Minuten

        let toastMsg = 'Daten erfolgreich abgerufen!';
        this.translate.get(toastMsg).subscribe(x => toastMsg = x);

        this.toastr.success(toastMsg, null, {positionClass: 'toast-bottom-right', timeOut: 2000});

        this.nav.onSomethingHappended(this.getAislesAndThings.bind(this));
    }


    getAisleFromAllThings(aisleArr, AisleAndThingArr, thingtype: string, pSearchText: string)
    {
        if (this.data.activeSystem.allThingTypes.has(thingtype))
        {
            for (let i = 0; i < this.data.activeSystem.allThingTypes.get(thingtype).length; i++)
            {
                if (aisleArr.map(function (e)
                    {
                        return e.aisle;
                    }).indexOf(+this.data.activeSystem.allThingTypes.get(thingtype)[i].aisle) < 0
                    && (this.data.activeSystem.allThingTypes.get(thingtype)[i].displayName.toUpperCase().includes(pSearchText) || pSearchText == null))
                {
                    aisleArr.push(
                        {
                            aisle: +this.data.activeSystem.allThingTypes.get(thingtype)[i].aisle,
                            state: 'default'
                        });
                }
            }
            // Sortieren der Gassen
            aisleArr.sort((n1, n2) => n1.aisle - n2.aisle);

            let counterFirstDimension = 0;
            // Thing aus den Gassen in das jeweilige (/multidimensionale) Array einfügen
            for (let aisle = 0; aisle <= Math.max(...aisleArr.map(o => o.aisle)); aisle++)
            {
                let counterSecondDimension = 0;

                if (aisleArr.map(function (e)
                {
                    return e.aisle;
                }).indexOf(aisle) >= 0)
                {
                    AisleAndThingArr[counterFirstDimension] = [];

                    for (let thing = 0; thing < this.data.activeSystem.allThingTypes.get(thingtype).length; thing++)
                    {

                        if (+this.data.activeSystem.allThingTypes.get(thingtype)[thing].aisle === aisle
                            && (this.data.activeSystem.allThingTypes.get(thingtype)[thing].displayName.toUpperCase().includes(pSearchText) || pSearchText == null))
                        {
                            if (this.data.activeSystem.allThingTypes.get(thingtype)[thing].displayName.indexOf(' ') > 0)
                            {
                                this.data.activeSystem.allThingTypes.get(thingtype)[thing].displayName = this.data.activeSystem.allThingTypes.get(thingtype)[thing].displayName.replace(' ', '');
                                AisleAndThingArr[counterFirstDimension][counterSecondDimension] = this.data.activeSystem.allThingTypes.get(thingtype)[thing];
                            }
                            else
                            {
                                AisleAndThingArr[counterFirstDimension][counterSecondDimension] = this.data.activeSystem.allThingTypes.get(thingtype)[thing];
                            }
                            counterSecondDimension++;
                        }
                    }
                    counterFirstDimension++;
                }
            }

            // Multidimensionales Array sortieren
            for (let i = 0; i < AisleAndThingArr.length; i++)
            {
                this.sortArray(AisleAndThingArr[i]);
            }
        }
    }

    public testinsight()
    {
        this.http.get('http://localhost:7071/api/insight/getLastTimestamp?SystemId=63c7cd4e898e7dd1e63d44cb&Table=MOVEMENTS&TimestampColumn=MOVEMENT_DATE').subscribe(x => {console.log(x); });
        this.http.get('http://localhost:7071/api/insight/getLastTimestamp?SystemId=63c7cd4e898e7dd1e63d44cb&Table=LE_MINILOAD&TimestampColumn=TIME_STAMP').subscribe(x => {console.log(x);});
        this.http.get('http://localhost:7071/api/insight/getLastTimestamp?SystemId=63c7cd4e898e7dd1e63d44cb&Table=OPEN_PICKS&TimestampColumn=TIME_STAMP').subscribe(x => {console.log(x); });
        this.http.get('http://localhost:7071/api/insight/getLastTimestamp?SystemId=63c7cd4e898e7dd1e63d44cb&Table=ORDERS_HOST_INTERNAL&TimestampColumn=TIME_STAMP').subscribe(x => {console.log(x); });
        this.http.get('http://localhost:7071/api/insight/getLastTimestamp?SystemId=63c7cd4e898e7dd1e63d44cb&Table=ORDERS_MINILOAD&TimestampColumn=TIME_STAMP').subscribe(x => {console.log(x); });
        this.http.get('http://localhost:7071/api/insight/getLastTimestamp?SystemId=63c7cd4e898e7dd1e63d44cb&Table=OUTGOING_GOODS&TimestampColumn=TIME_STAMP').subscribe(x => {console.log(x); });
        this.http.get('http://localhost:7071/api/insight/getLastTimestamp?SystemId=63c7cd4e898e7dd1e63d44cb&Table=PRINT_EVENTS&TimestampColumn=TIME_STAMP').subscribe(x => {console.log(x); });
        this.http.get('http://localhost:7071/api/insight/getLastTimestamp?SystemId=63c7cd4e898e7dd1e63d44cb&Table=TELEGRAMS_CONVEYOR&TimestampColumn=TIME_STAMP').subscribe(x => {console.log(x); });
        this.http.get('http://localhost:7071/api/insight/getLastTimestamp?SystemId=63c7cd4e898e7dd1e63d44cb&Table=VISU_ALARM_HISTORY&TimestampColumn=TIME_STAMP_END').subscribe(x => {console.log(x); });
        this.http.get('http://localhost:7071/api/insight/getLastTimestamp?SystemId=63c7cd4e898e7dd1e63d44cb&Table=ERRORS_SRC&TimestampColumn=TIME_STAMP').subscribe(x => {console.log(x); });
    }

    public getAislesAndThings(pSearchText?: string)
    {
        // reset all arrays:
        this.OLSAislesAndThings = [];
        this.OLSAisles = [];
        this.OLSEAislesAndThings = [];
        this.OLSEAisles = [];
        this.OLPSAislesAndThings = [];
        this.OLPSAisles = [];
        this.MLSAislesAndThings = [];
        this.MLSAisles = [];
        this.RBGAislesAndThings = [];
        this.RBGAisles = [];
        this.VCVAislesAndThings = [];
        this.VCVAisles = [];
        this.SSDTMAislesAndThings = [];
        this.SSDTMAisles = [];
        this.SSIFAislesAndThings = [];
        this.SSIFAisles = [];
        this.SSESAislesAndThings = [];
        this.SSESAisles = [];
        this.CARRIERAislesAndThings = [];
        this.showFirstOLS = true;
        this.showFirstOLSE = true;
        this.showFirstFTS = true;
        this.showFirstOLPS = true;
        this.showFirstMLS = true;
        this.showFirstRBG = true;
        this.showFirstHCV = true;
        this.showFirstVCV = true;
        this.showFirstGDP = true;
        this.showFirstCONVER = true;
        this.showFirstLSC = true;
        this.showFirstSSAGM = true;
        this.showFirstSSDTM = true;
        this.showFirstSSIF = true;
        this.showFirstSSES = true;
        this.showFirstSTOREWARE = true;
        this.showFirstCARRIER = true;


        if (this.data.activeSystem.dashboard.displayName.toUpperCase().includes(pSearchText) || pSearchText == null)
        {
            this.Dashboard.push(this.data.activeSystem.dashboard);
        }
        else
        {
            this.Dashboard = [];
        }

        if (this.data.activeSystem.testdrive.displayName.toUpperCase().includes(pSearchText) || pSearchText == null)
        {
            this.Testfahrt.push(this.data.activeSystem.testdrive);
        }
        else
        {
            this.Testfahrt = [];
        }


        // OLS:
        this.getAisleFromAllThings(this.OLSAisles, this.OLSAislesAndThings, 'OLS', pSearchText);

        // OLSE:
        this.getAisleFromAllThings(this.OLSEAisles, this.OLSEAislesAndThings, 'OLSE', pSearchText);

        // MLS:
        this.getAisleFromAllThings(this.MLSAisles, this.MLSAislesAndThings, 'MLS', pSearchText);

        // VCV:
        this.getAisleFromAllThings(this.VCVAisles, this.VCVAislesAndThings, 'VCV', pSearchText);

        // RBG:
        this.getAisleFromAllThings(this.RBGAisles, this.RBGAislesAndThings, 'RBG', pSearchText);

        // SSDTM:
        this.getAisleFromAllThings(this.SSDTMAisles, this.SSDTMAislesAndThings, 'SSDTM', pSearchText);

        // SSIF:
        this.getAisleFromAllThings(this.SSIFAisles, this.SSIFAislesAndThings, 'SSIF', pSearchText);

        // SSES:
        this.getAisleFromAllThings(this.SSESAisles, this.SSESAislesAndThings, 'SSES', pSearchText);

        // OLPS:
        this.getAisleFromAllThings(this.OLPSAisles, this.OLPSAislesAndThings, 'OLPS', pSearchText);

        // CARRIER:
        this.CARRIERAislesAndThings = [];
        if (this.data.activeSystem.allThingTypes.has('SSCRR'))
        {
            for (let start = 0; start < this.currShowThings; start++)
            {
                if (this.data.activeSystem.allThingTypes.get('SSCRR')[start].displayName.toUpperCase().includes(pSearchText)
                    || pSearchText == null)
                {
                    this.CARRIERAislesAndThings.push(this.data.activeSystem.allThingTypes.get('SSCRR')[start]);
                }
            }
            this.sortArray(this.CARRIERAislesAndThings);
        }

    }

    search()
    {
        if (this.searchText !== '')
        {
            const searchTextUpper = this.searchText.toUpperCase();
            this.getAislesAndThings(searchTextUpper);
        }
        else
        {
            this.getAislesAndThings();
        }
    }

    doAddFive()
    {
        this.currShowThings += this.addFive;
        if (typeof this.searchText !== 'undefined')
        {
            const searchTextUpper = this.searchText.toUpperCase();
            this.getAislesAndThings(searchTextUpper);
        }
        else
        {
            this.getAislesAndThings();
        }
    }

    doSetToFive()
    {
        this.currShowThings = this.addFive;
        if (typeof this.searchText !== 'undefined')
        {
            const searchTextUpper = this.searchText.toUpperCase();
            this.getAislesAndThings(searchTextUpper);
        }
        else
        {
            this.getAislesAndThings();
        }
    }

    rotate(index)
    {
        index.state = (index.state === 'default' ? 'rotated' : 'default');
    }

    rotateSscrr()
    {

        this.SscrrState = (this.SscrrState === 'default' ? 'rotated' : 'default');
        if (this.SscrrState === 'default')
        {
            this.doSetToFive();
        }
    }


    ngOnDestroy()
    {
        clearInterval(this._monitoringTimer);
        console.log(this._loggerPrefix + 'ngOnDestroy');
    }

    /**
     * Setzt den Browser Tab Title
     * @param pNewTitle
     */
    public setTitle(pNewTitle: string)
    {
        this._titleService.setTitle(pNewTitle);
    }

    /**
     * Löscht ein Tab
     * @param pTab
     */
    public deleteTab(pTab: TabItem)
    {
        const tabIndex = this.data.activeSystem.currentTabs.findIndex(tab => tab.path === pTab.path || tab.name === pTab.name);
        if (tabIndex > -1)
        {
            this.data.activeSystem.currentTabs.splice(tabIndex, 1);

            const indexOfTab = this.data.activeSystem.currentTabs.indexOf(pTab, 0);

            if (indexOfTab > -1)
            {
                this.data.activeSystem.currentTabs.splice(indexOfTab, 1);
            }
            if (this.data.activeSystem.currentTabs.length < 1)
            {
                this._router.navigate(['../../'], {relativeTo: this._route});
            }
            else if (pTab.path === decodeURI(this._router.url)
                || decodeURI(this._router.url).includes(pTab.name.toLowerCase()))
            {
                const previousTab = this.data.activeSystem.currentTabs[this.data.activeSystem.currentTabs.length - 1];
                this._router.navigate(['../../' + previousTab.path], {relativeTo: this._route});
            }

            this.nav.storeTabs();

            this.nav.tabCalc();
        }
    }

    /**
     * Wird ausgelöst wenn ein neuer Kunde ausgewählt wurde.
     * Prüft ob ein customer nur einen Standort und/oder eine Anlage hat und setzt diese falls ja.
     **/
    public selectCustomerOfIndex(pIndex: any, pChildShouldBeReseted: boolean)
    {
        if (pChildShouldBeReseted)
        {
            this.resetActiveLocation();
        }

        this.data.activeCompany = this.data.customer[pIndex];
        if (this.data.activeCompany == null)
        {
            console.log(this._loggerPrefix + 'no customer Selected');
            return;
        }

        this._router.navigateByUrl(this.nav.buildCompanyUrl(this.data.activeCompany));

        if (this.data.activeCompany.locations.size === 1)
        {
            this.selectLocationOfIndex(0, pChildShouldBeReseted);
        }
        else
        {
            console.log(this._loggerPrefix + 'More locations are available...');

        }
    }

    /**
     * Wird ausgelöst wenn ein neuer Standort ausgewählt wird.
     * Prüft ob der Standort nur eine Anlage hat und setzt diese falls ja.
     **/
    public selectLocationOfIndex(index: any, childShouldBeReseted: boolean)
    {
        if (childShouldBeReseted)
        {
            this.resetActiveLocation();
            this.resetActiveSystem();
        }

        this.data.activeLocation = this.data.activeCompany.locations[index];
        if (this.data.activeLocation == null)
        {
            console.log(this._loggerPrefix + 'no location Selected');
            return;
        }

        this._router.navigateByUrl(this.nav.buildLocationUrl(this.data.activeLocation));

        if (this.data.activeLocation.systems.size === 1)
        {
            this.selectSystemOfIndex(0);
        }
        else
        {
            console.log(this._loggerPrefix + 'More systems are available...');
        }
    }

    /**
     * Wird ausgelöst, wenn eine neue Anlage gewählt wird
     * @selectedIndex : index von ausgewähltem system
     **/
    public selectSystemOfIndex(index: any)
    {
        this.data.activeSystem = this.data.activeLocation.systems[index];
        if (this.data.activeSystem == null)
        {
            console.log(this._loggerPrefix + 'no system Selected');
            return;
        }

        // Configurations.ActiveSystemSelected.next(true);
        this._router.navigateByUrl(this.nav.buildSystemUrl(this.data.activeSystem));

        this.storeCurrentNavItems();
    }

    /**
     * Sortiert eine Liste von Things anhand des Display Names
     * @param pThings
     */
    public sortArray(pThings: Array<Thing>)
    {
        return pThings.sort((x, y) =>
        {
            if (x.displayName < y.displayName) return -1;
            if (x.displayName > y.displayName) return 1;
            return 0;
        });
    }

    /**
     * Drop Event für Drag and Drop List
     * Tableiste
     * @param event
     */
    public drop(event: CdkDragDrop<string[]>)
    {
        moveItemInArray(this.data.activeSystem.currentTabs, event.previousIndex, event.currentIndex);
    }

    // TODO: Wird der untere Block noch 100% benötigt???

    /**
     * Setzt die aktive Location zurück
     */
    public resetActiveLocation()
    {
        this.data.activeLocation = null;
        localStorage.removeItem(Configurations.LocStoActiveLocationName);
        this.resetActiveSystem();
        console.log(this._loggerPrefix + 'location reseted');
    }

    /**
     * Gibt an ob die gesetzte Location im Customer vorhanden ist
     */
    public isActiveLocationInActiveCustomer()
    {
        if (this.data.activeCompany == null || this.data.activeLocation == null)
        {
            return false;
        }
        return this.data.activeCompany.locations.get(this.data.activeLocation.id);
        //return this.data.activeCompany.locations.findIndex(l => l.name === this.data.activeLocation.name) !== -1;
    }

    /**
     * Gibt an ob das aktive System in der Location vorhanden ist
     */
    public isActiveSystemInActiveLocation()
    {
        if (this.data.activeCompany == null || this.data.activeLocation == null || this.data.activeSystem == null)
        {
            return false;
        }
        return this.data.activeLocation.systems.get(this.data.activeSystem.id);
        // return this.data.activeLocation.systems.findIndex(s => s.name === this.data.activeSystem.name) !== -1;
    }

    /**
     * Setzt das aktive System zurück
     */
    public resetActiveSystem()
    {
        this.data.activeSystem = null;
        console.log(this._loggerPrefix + 'system reseted');
    }

    /**
     * Speichert die aktive Company, Location, System
     */
    public storeCurrentNavItems()
    {
        localStorage.setItem(Configurations.LocStoActiveCustomerId, JSON.stringify(this.data.activeCompany.id));
        localStorage.setItem(Configurations.LocStoActiveLocationName, JSON.stringify(this.data.activeLocation.name));
        localStorage.setItem(Configurations.LocStoActiveSystemName, JSON.stringify(this.data.activeSystem.name));
        this.getAislesAndThings();
    }

    /**
     * Diese Methode fügt von jedem thingType ein thing in ein Array und gibt das Array zurück
     */
    public selectDistinctThingByTypeFromActiveSystem()
    {
        const distinctThingsByType = new Array<Thing>();
        if (this.data.activeSystem == null) return distinctThingsByType;

        this.data.activeSystem.getAllThings().forEach(t =>
        {
            const containsType = distinctThingsByType.findIndex(dt => dt.thingType === t.thingType) > -1;
            if (!containsType)
            {
                distinctThingsByType.push(t as Thing);
            }
        });
        return distinctThingsByType;
    }

    protected readonly environment = environment;
    protected readonly Array = Array;
}
