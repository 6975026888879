<div id="helpPageBanner">
    <div class="container">
        <!--Banner-->
        <div class="col-12 bannerItem">
            <button (click)="goBack(returnAddress)" routerLinkActive="active"
                    class="btn list-inline-item">
                <svg xmlns="http://www.w3.org/2000/svg" style="padding-bottom: 2px;" width="20" height="20"
                     fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                </svg>
            </button>
            <ul *ngIf="items == null" class="list-inline">
                <li *ngFor="let ite of itemLanguagID; let i = index" class="list-inline-item">
                    <a (click)="scroll(ite[1])">
                        {{ite[0]}}
                    </a>
                    <div *ngIf="(itemLanguagID.length-1) != i " class="list-inline-item">
                        |
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
