import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform
{

    transform(array: any, field: string): any[]
    {

        if (!Array.isArray(array))
        {
            return;
        }

        // toLowerCase um Groß/Kleinschreibung zu ignorieren
        array.sort((a: any, b: any) =>
        {
            if (a[field].toLowerCase() < b[field].toLowerCase())
            {
                return -1;
            }
            else if (a[field].toLowerCase() > b[field].toLowerCase())
            {
                return 1;
            }
            else
            {
                return 0;
            }
        });

        return array;
    }

}
