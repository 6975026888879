<div *ngIf="thing">

    <app-thing-banner [items]="[['Aktuelle Lagerstatistik', 'Statistics'], ['Geräte Status' , 'DeviceStatus'],
                        ['Gassenfüllstand - Lagerfüllstand', 'AisleFillLevel'], ['Scanner Statistik', 'ScannerStatistics'],
                        ['Wareneingang -/ Ausgang Statistik', 'StatisticsLine']]"></app-thing-banner>

    <!--Überschrift-->
    <div class="row">
        <div class="col-lg-12">
            <div class="heading">
                <h2>{{thing.displayName + ' ' + location.name + ' - ' + system.name}}</h2>
                <app-datepicker-popup></app-datepicker-popup>
            </div>
        </div>
    </div>

    <!--Aktuelle Lagerstatistik-->
    <div class="row">

        <div class="col-lg-12 subheading">
            <h3 id="Statistics">{{'Aktuelle Lagerstatistik' | translate}}</h3>
        </div>

        <div class="col-lg-2">
        </div>

        <app-text-tile class="col-lg-2"
                       heading="{{'Einlagerung (Offen)' | translate}}"
                       unit="{{'(Anzahl)' | translate}}"
                       [error]="thing.STW_ORDER_COUNT.INCOMING_GOODS_FINISHED.isError || thing.STW_ORDER_COUNT.INCOMING_GOODS_OPEN.isError"
                       directInput="{{thing.STW_ORDER_COUNT.INCOMING_GOODS_FINISHED.TimeseriesData.currentDatapoint?.value}} ({{thing.STW_ORDER_COUNT.INCOMING_GOODS_OPEN.TimeseriesData.currentDatapoint?.value}})">
        </app-text-tile>

        <app-text-tile class="col-lg-2"
                       heading="{{'Auslagerung (Offen)' | translate}}"
                       unit="{{'(Anzahl)' | translate}}"
                       [error]="thing.STW_ORDER_COUNT.OUTGOING_GOODS_FINISHED.isError || thing.STW_ORDER_COUNT.OUTGOING_GOODS_OPEN.isError"
                       directInput="{{thing.STW_ORDER_COUNT.OUTGOING_GOODS_FINISHED.TimeseriesData.currentDatapoint?.value}} ({{thing.STW_ORDER_COUNT.OUTGOING_GOODS_OPEN.TimeseriesData.currentDatapoint?.value}})">
        </app-text-tile>

        <app-text-tile class="col-lg-2"
                       heading="{{'Picks (Offen)' | translate}}"
                       unit="{{'(Anzahl)' | translate}}"
                       [error]="thing.STW_ORDER_COUNT.OPEN_PICKS_FINISHED.isError || thing.STW_ORDER_COUNT.OPEN_PICKS_OPEN.isError"
                       directInput="{{thing.STW_ORDER_COUNT.OPEN_PICKS_FINISHED.TimeseriesData.currentDatapoint?.value}} ({{thing.STW_ORDER_COUNT.OPEN_PICKS_OPEN.TimeseriesData.currentDatapoint?.value}})">
        </app-text-tile>

        <app-text-tile class="col-lg-2"
                       heading="{{'Fahraufträge (Offen)' | translate}}"
                       unit="{{'(Anzahl)' | translate}}"
                       [error]="thing.STW_ORDER_COUNT.ORDERS_MINILOAD_FINISHED.isError || thing.STW_ORDER_COUNT.ORDERS_MINILOAD_OPEN.isError"
                       directInput=" {{thing.STW_ORDER_COUNT.ORDERS_MINILOAD_FINISHED.TimeseriesData.currentDatapoint?.value}} ({{thing.STW_ORDER_COUNT.ORDERS_MINILOAD_OPEN.TimeseriesData.currentDatapoint?.value}})">
        </app-text-tile>

        <div class="col-lg-2">
        </div>
    </div>

    <!--Geräte Status-->
    <div class="row">

        <div class="col-lg-12 subheading">
            <h3 id="DeviceStatus">{{'Geräte Status' | translate}}</h3>
        </div>

        <div class="col-lg-12">
            <div class="tile">
                <div class="tileBody">
                    <table class="table" style="">
                        <thead>
                        <tr>
                            <th style="width: 33%;">{{'Gasse' | translate}}</th>
                            <th style="width: 33%;">{{'Betriebsart' | translate}}</th>
                            <th style="width: 33%;">{{'Aktueller Fehler' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody style="width: 100%;"
                               *ngIf="deviceStatusAisleWms.currentDatapoint.constructor.name === 'Array'">
                        <!--ToDo Quickfix entfernen-->
                        <tr style="width: 100%;"
                            *ngFor="let item of deviceStatusAisleWms.currentDatapoint; let i = index">
                            <td style="width: 33%;"
                                *ngIf="item != null && deviceStatusOperationMode.currentDatapoint[i]">{{item.value}}</td>
                            <td style="width: 33%;" *ngIf="deviceStatusOperationMode.currentDatapoint[i]">
                                {{deviceStatusOperationMode.currentDatapoint[i].value}}
                            </td>
                            <td style="width: 33%;" *ngIf="deviceStatusOperationMode.currentDatapoint[i]">
                                {{deviceStatusCurrentError.currentDatapoint[i].value | translate}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!--Gassenfüllstand-->
    <div class="row">

        <div class="col-lg-12 subheading">
            <h3 id="AisleFillLevel">{{'Gassenfüllstand - Lagerfüllstand' | translate}}</h3>
        </div>

        <div class="col-lg-6">
            <div class="tile">
                <div class="tileBody">
                    <table class="table" style="">
                        <thead>
                        <tr>
                            <th style="width: 50%;">{{'Gasse' | translate}}</th>
                            <th style="width: 50%;">{{'Füllstand (%)' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody style="width: 100%;"
                               *ngIf="aisleFillLevelAisleWms.currentDatapoint.constructor.name === 'Array'">
                        <!--ToDo Quickfix entfernen-->
                        <tr style="width: 100%"
                            *ngFor="let item of aisleFillLevelAisleWms.currentDatapoint; let i = index">
                            <td style="width: 50%;" *ngIf="aisleFillLevelValue.currentDatapoint[i]">{{item.value}}</td>
                            <td style="width: 50%;"
                                *ngIf="aisleFillLevelValue.currentDatapoint[i]">{{aisleFillLevelValue.currentDatapoint[i].value}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <app-pie-tile class="col-lg-6"
                      [heading]="'Lagerfüllstand' | translate"
                      [error]="thing.STW_LE_COUNT.EMPTY_LE_COUNT.isError || thing.STW_LE_COUNT.FULL_LE_COUNT.isError"
                      [options]="loadOptionsPie"
                      [value]="[thing.STW_LE_COUNT.EMPTY_LE_COUNT.TimeseriesData.currentDatapoint, thing.STW_LE_COUNT.FULL_LE_COUNT.TimeseriesData.currentDatapoint]">
        </app-pie-tile>
    </div>

    <!--Scanner Statistik-->
    <div class="row">

        <div class="col-lg-12 subheading">
            <h3 id="ScannerStatistics">{{'Scanner Statistik' | translate}}</h3>
        </div>

        <div class="col-lg-12">
            <div class="tile" [ngClass]="{'errorTile' : thing.STW_SCANNER_STATISTICS.isError}">
                <div class="tileBody">
                    <table class="table" style="">
                        <thead>
                        <tr>
                            <th style="width: 33%;">{{'Name' | translate}}</th>
                            <th style="width: 33%;">{{'Typ' | translate}}</th>
                            <th style="width: 33%;">{{'Wert Fehler' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody style="width: 100%;" *ngIf="scannerStatisticsPlcSymbolName.currentDatapoint.value != null &&
                        scannerStatisticsSymbolType.currentDatapoint.value != null &&
                        scannerStatisticsValue.currentDatapoint.value != null ">
                        <!--ToDo Quickfix enternen-->
                        <tr style="width: 100%;"
                            *ngFor="let item of scannerStatisticsPlcSymbolName.currentDatapoint; let i = index">
                            <td style="width: 33%;"
                                *ngIf="scannerStatisticsSymbolType.currentDatapoint[i]">{{item.value}}</td>
                            <td style="width: 33%;"
                                *ngIf="scannerStatisticsSymbolType.currentDatapoint[i]">{{scannerStatisticsSymbolType.currentDatapoint[i].value}}</td>
                            <td style="width: 33%;"
                                *ngIf="scannerStatisticsSymbolType.currentDatapoint[i]">{{scannerStatisticsValue.currentDatapoint[i].value}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!--Statistik Linecharts-->
    <div class="row">

        <div class="col-lg-12 subheading">
            <h3 id="StatisticsLine">{{'Wareneingang -/ Ausgang Statistik' | translate}}</h3>
        </div>

        <app-line-tile class="col-lg-6"
                       [heading]="'Wareneingang' | translate"
                       [error]="thing.STW_ORDER_COUNT.INCOMING_GOODS_FINISHED.isError || thing.STW_ORDER_COUNT.INCOMING_GOODS_OPEN.isError"
                       [legend]="['Abgeschlossen' | translate, 'Offen' | translate]"
                       [value]="[thing.STW_ORDER_COUNT.INCOMING_GOODS_FINISHED.TimeseriesData.values, thing.STW_ORDER_COUNT.INCOMING_GOODS_OPEN.TimeseriesData.values]">
        </app-line-tile>

        <app-line-tile class="col-lg-6"
                       [heading]="'Warenausgang' | translate"
                       [error]="thing.STW_ORDER_COUNT.OUTGOING_GOODS_FINISHED.isError || thing.STW_ORDER_COUNT.OUTGOING_GOODS_OPEN.isError"
                       [legend]="['Abgeschlossen' | translate, 'Offen' | translate]"
                       [value]="[thing.STW_ORDER_COUNT.OUTGOING_GOODS_FINISHED.TimeseriesData.values, thing.STW_ORDER_COUNT.OUTGOING_GOODS_OPEN.TimeseriesData.values]">
        </app-line-tile>
    </div>

    <div class="row gap"></div>
</div>

<app-monitoring-console *ngIf="data.showMonitoring && thing.errorCount > 0"
                        [criticalThingToShowInConsole]="[thing]"></app-monitoring-console>
