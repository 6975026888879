import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../interfaces/dialog-data';
import {AggregationIdentifier, EntityIdentifier} from '../../../interfaces/entity-identifier';
import {FlatTreeControl} from '@angular/cdk/tree';
import {L_FlatNode} from '../../../interfaces/tree-interfaces';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        public fullDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private translate: TranslateService
    ) { }



    private genEntityIdentifier(warner: AggregationIdentifier): EntityIdentifier {
        const e_id: EntityIdentifier = {};

        e_id[`${warner.subentity_type}_id`] = warner.subentity_id;
        e_id[`${warner.subentity_type}_instance`] = warner.subentity_instance;
        e_id[`${warner.subentity_type}_instance_description`] = warner.subentity_instance_description;
        e_id[`${warner.subentity_type}_name`] = warner.subentity_name;

        return e_id;
    }

    private expansionCriteria(entityId: EntityIdentifier, targetNode: L_FlatNode): boolean {
        if (entityId.hasOwnProperty(`${targetNode.data.entity_type}_id`)){
            const e_type = targetNode.data.entity_type;
            const e_id = `${e_type}_id`;
            const e_instance = `${e_type}_instance`;
            const e_instance_description = `${e_type}_instance_description`;
            const e_name = `${e_type}_name`;
            if (targetNode.data[`${e_name}_de`] === entityId[e_name]){
                if (targetNode.data[e_id] === entityId[e_id]){
                    if (targetNode.data[e_instance] === entityId[e_instance] && targetNode.data[e_instance_description] === entityId[e_instance_description]) return true;
                }
            }
        }
        return false;
    }

    /**
     * Iterate over each node in reverse order and return the first node that has a lower level than the passed node.
     */
    private getParent(node: L_FlatNode): L_FlatNode{
        const treeControl: FlatTreeControl<L_FlatNode> = this.data.treeInfo.treeControl;
        const currentLevel = treeControl.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = treeControl.dataNodes[i];

            if (treeControl.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
    }

    private expandNode(entityId: EntityIdentifier): void {
        const treeController: FlatTreeControl<L_FlatNode> = this.data.treeInfo.treeControl;
        const dataNodes: L_FlatNode[] = treeController.dataNodes;
        let targetNode: L_FlatNode;
        for (let i = 0; i < dataNodes.length; i++){
            if (this.expansionCriteria(entityId, dataNodes[i])){
                let parent: L_FlatNode = this.getParent(dataNodes[i]);
                while (parent !== null){
                    treeController.expand(parent);
                    parent = this.getParent(parent);
                }
                treeController.expand(dataNodes[i]);
                targetNode = dataNodes[i];
                break;
            }
        }
        this.dialogRef.close({
            sender: 'ALERT',
            result: targetNode,
            action: 'CLOSE_PARENT'
        });
    }

  ngOnInit(): void {
  }

  onWarnerClick(warner: any): void {
        this.expandNode(this.genEntityIdentifier(warner));
  }


    closeModal(): void {
        this.dialogRef.close();
    }

    closeAllDialogs(): void {
        // this.dialogRef.closeAll();
        this.fullDialog.closeAll();
    }


    onNoClick(): void {
        this.dialogRef.close();
    }

}
