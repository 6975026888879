import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'safe'
})
/**
 * Klasse der SafePipe
 */
export class SafePipe implements PipeTransform
{
    /**
     * Konstruktor
     * @param sanitizer
     */
    constructor(private sanitizer: DomSanitizer)
    {
    }

    /**
     * Erhält eine URL und "reinigt" diese, sodass
     * sich URL im Frontend aufrufen lässt z.B. Iframe
     * Für mehr Informationen "Angular Pipes"
     * @param pUrl
     */
    transform(pUrl: string)
    {
        return this.sanitizer.bypassSecurityTrustResourceUrl(pUrl);
    }
}
