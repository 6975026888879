import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-fts-chassis-km',
    templateUrl: './fts-chassis-km.component.html',
    styleUrls: ['./fts-chassis-km.component.scss',
        './../card.scss']
})
export class FtsChassisKmComponent extends BaseViewElement implements OnInit, OnChanges
{

    @Input() Fahrwerk: PropertySet;

    MOTOR_GEFAHRENE_KILOMETER: Property<any>;
    LENKROLLE_GEFAHRENE_KM: Property<any>;
    SCHWERLAST_ANTRIEBSRAT_GEFAHRENE_KM: Property<any>;
    GEBRAUCHSDAUER_KM: Property<any>;
    FLACHGETRIEBE_KM: Property<any>;

    constructor()
    {
        super('fts-chassis-km');
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Fahrwerk != null)
        {
            this.MOTOR_GEFAHRENE_KILOMETER = this.Fahrwerk.properties.get('MOTOR_GEFAHRENE_KILOMETER');
            this.LENKROLLE_GEFAHRENE_KM = this.Fahrwerk.properties.get('LENKROLLE_GEFAHRENE_KM');
            this.SCHWERLAST_ANTRIEBSRAT_GEFAHRENE_KM = this.Fahrwerk.properties.get('SCHWERLAST_ANTRIEBSRAT_GEFAHRENE_KM');
            this.GEBRAUCHSDAUER_KM = this.Fahrwerk.properties.get('GEBRAUCHSDAUER_KM');
            this.FLACHGETRIEBE_KM = this.Fahrwerk.properties.get('FLACHGETRIEBE_KM');
        }
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

}
