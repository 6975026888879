import {Component, Input, OnInit} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';

@Component({
    selector: 'app-health-prediction',
    templateUrl: './health-prediction.component.html',
    styleUrls: ['./health-prediction.component.scss',
        './../card.scss']
})
export class HealthPredictionComponent extends BaseViewElement implements OnInit
{

    public chartError: boolean;
    public predictionChartValues = new Array<Array<DataPoint<number>>>();
    PredictionValues = [];
    public actualRating: number;

    @Input() Error: boolean;
    @Input() ErrorLevel: MonitoringLevel;

    @Input()
    public set propSet(val: PropertySet)
    {
        if (val == null)
        {
            this.chartError = true;
            return;
        }
        else
        {
            this.chartError = false;
        }

        const tmp = val as any;
        // filling up the rating obj for line-chart
        const testdriveEvalObj = JSON.parse(tmp);
        this.actualRating = testdriveEvalObj.Ratings[testdriveEvalObj.Ratings.length - 1].value;

        const ratingDataPoints = new LimitSizeArrayModel<DataPoint<number>>(1000000);
        testdriveEvalObj.Ratings.forEach((rating) =>
        {
            ratingDataPoints.pushDataPoint(new DataPoint<number>(rating.value, new Date(rating._time)));
        });
        // filling up the prediction line which is also part of the line-chart

        // this.predictionChartValues.push(ratingDataPoints);
        const predictionDataPoints = new LimitSizeArrayModel<DataPoint<number>>(1000000);
        predictionDataPoints.pushDataPoint(new DataPoint<number>(
            testdriveEvalObj.Prediction.First_Value.value, new Date(testdriveEvalObj.Prediction.First_Value._time)));
        predictionDataPoints.pushDataPoint(new DataPoint<number>(
            testdriveEvalObj.Prediction.Last_Value.value, new Date(testdriveEvalObj.Prediction.Last_Value._time)));

        // this.predictionChartValues.push(predictionDataPoints);
        this.PredictionValues = [];
        this.PredictionValues.push(ratingDataPoints);
        this.PredictionValues.push(predictionDataPoints);
    }

    constructor()
    {
        super('health-prediction');
        this.chart = true;
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }
}
