import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
  selector: 'app-battery',
  templateUrl: './battery.component.html',
  styleUrls: ['./battery.component.scss',
    './../card.scss']
})
export class BatteryComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{

  @Input() ActualData: PropertySet;

  LADEZEIT: Property<any>;
  KAPAZITAET: Property<any>;
  KAPAZITAET_SPANNUNG: Property<any>;
  KAPAZITAET_TEMPERATUR: Property<any>;

  constructor()
  {
    super('battery');
  }

  ngOnChanges(changes: SimpleChanges): void
  {
    if (this.ActualData != null)
    {
      this.LADEZEIT = this.ActualData.properties.get('CHARGE_TIME');
      this.KAPAZITAET = this.ActualData.properties.get('CAPACITY');
      this.KAPAZITAET_SPANNUNG = this.ActualData.properties.get('CAP_VOLTAGE');
      this.KAPAZITAET_TEMPERATUR = this.ActualData.properties.get('CAP_TEMP');
    }

  }


  onResize(result: any)
  {
    this.childResizeEvent.emit(result);
  }

}