<div *ngIf="things != null && aisles != null" class="navItemRoot">

    <div *ngIf="things.length > 1 ; else single">

        <div *ngIf="aisles.length > 1; else singleAisle">

            <li routerLinkActive="active">
                <div (click)="openSub($event)">
                    <a>
                        {{name}}
                        <img [src]="environment.storageUrl + '/icons/right.png'"/>
                    </a>
                </div>
                <ul>
                    <li *ngFor="let aisle of aisles" routerLinkActive="active">
                        <div (click)="openSub($event)">
                            <a>
                                {{'Gasse' | translate}} {{aisle.name}}
                                <img [src]="environment.storageUrl + '/icons/right.png'"/>
                            </a>
                        </div>
                        <ul>
                            <div *ngIf="aisle.things">
                                <li *ngFor="let thing of aisle.things" routerLinkActive="active">
                                    <a (click)="nav.navigateToThing(thing)">{{thing.displayName}}</a>
                                </li>
                            </div>
                        </ul>
                    </li>
                </ul>
            </li>
        </div>
    </div>
</div>

<!--Wird verwendet wenn nur ein thing vorhanden ist-->
<ng-template #single>

    <li routerLinkActive="active">
        <a (click)="nav.navigateToThing(things[0])">
            {{name}}
        </a>
    </li>
</ng-template>

<!--Wird verwendet wenn nur ein AisleModel vorhanden ist-->
<ng-template #singleAisle>

    <li routerLinkActive="active">
        <a (click)="openSub($event)">{{name}} <img [src]="environment.storageUrl + '/icons/right.png'"></a>
        <ul>
            <li routerLinkActive="active" *ngFor="let thing of aisles[0].things">
                <a (click)="nav.navigateToThing(thing)">
                    {{thing.displayName}}
                </a>
            </li>
        </ul>
    </li>
</ng-template>
