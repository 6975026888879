import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {DataProviderService} from '../../controller/services/data-provider.service';
import {NavigationService} from '../../controller/services/navigation.service';
import {AisleModel} from '../../model/helper-models/aisle.model';
import {Thing} from '../../model/thing.model';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-navigation-left',
    templateUrl: './navigation-left.component.html',
    styleUrls: ['./navigation-left.component.scss']
})

/**
 * Diese Komponente erstellt die Unterpunkte der Navigation
 **/
export class NavigationLeftComponent implements OnInit, OnChanges
{
    @Input() things: Array<Thing>;
    @Input() name: string;
    @Input() thingRoute: string;

    public aisles: Array<AisleModel>;

    constructor(public data: DataProviderService,
                public nav: NavigationService)
    {
    }

    ngOnInit()
    {
    }

    /**
     * Wenn sich etwas an der Menü Struktur ändert (z. B. anderer Kunde)
     * werden die Gassen neu berechnet
     **/
    ngOnChanges()
    {
        this.aisles = this.countAisles(this.things);
    }

    /**
     * Öffnet einen unter Reiter in der Navigation links.
     **/
    public openSub(event)
    {
        const target = event.currentTarget || event.srcElement;

        let relativeSubs: any[];

        relativeSubs = Array.from(target.offsetParent.getElementsByTagName('UL'));

        relativeSubs.forEach(function (element)
        {
            if (element !== target.nextElementSibling)
            {
                element.style.left = '-200px';
            }
        });

        if (target.nextElementSibling.style.left === '0px'
            || target.nextElementSibling.style.left === ''
            || target.nextElementSibling.style.left === '-200px')
        {
            target.nextElementSibling.style.left = '200px';
            // target.style.background = '#d4d4d4';
        }
        else
        {
            target.nextElementSibling.style.left = '-200px';
        }
    }

    /**
     * Zählt den Gang der ThingTypes und baut daraus die Navigation
     **/
    public countAisles(pThingType: Array<Thing>): Array<AisleModel>
    {
        if (pThingType == null)
        {
            return;
        }

        const aisles = [];
        const allAisles = [];

        for (const thing of pThingType)
        {
            if (!allAisles.includes(thing.aisle))
            {
                allAisles.push(thing.aisle);
            }
        }

        for (const singleAisleName of allAisles)
        {
            aisles.push(new AisleModel(singleAisleName));
        }

        for (const thing of pThingType)
        {
            for (const aisle of aisles)
            {
                if (thing.aisle === aisle.name)
                {
                    aisle.things.push(thing);
                }
            }
        }

        return aisles;
    }

    protected readonly environment = environment;
}
