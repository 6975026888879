import {Injectable} from '@angular/core';
import {CanActivate, CanLoad} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
/**
 * Regelt die Authentifizierung für Gold User
 */
export class SilverGuard implements CanActivate, CanLoad
{
    constructor(private authenticationService: AuthenticationService)
    {
    }

    /**
     * Gibt einen bool zurück der angibt ob der User das Element aktivieren darf
     */
    canActivate(): boolean
    {
        return this.authenticationService.isSilver();
    }

    /**
     * Gibt einen bool zurück der angibt ob der User das Element laden darf
     */
    canLoad(): boolean
    {
        return this.authenticationService.isSilver();
    }
}
