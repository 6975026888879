import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BronzeGuard} from './core/controller/guards/bronze.guard';
import {SilverGuard} from './core/controller/guards/silver.guard';
import {PlatinumGuard} from './core/controller/guards/platinum.guard';
import {FavoriteComponent} from './core/view/favorite/favorite.component';
import {MonitoringCustomerComponent} from './core/view/monitoring/monitoring-customer/monitoring-customer.component';
import {MonitoringLocationComponent} from './core/view/monitoring/monitoring-location/monitoring-location.component';
import {MonitoringSystemComponent} from './core/view/monitoring/monitoring-system/monitoring-system.component';
import {MonitoringThingComponent} from './core/view/monitoring/monitoring-thing/monitoring-thing.component';
import {PowerbiComponent} from './core/view/powerbi/powerbi.component';
import {DashboardComponent} from './packages/bronze/dashboard/dashboard.component';
import {PageNotFoundComponent} from './packages/bronze/page-not-found/page-not-found.component';
import {StorewareComponent} from './packages/bronze/storeware/storeware.component';
import {TestdrivesComponent} from './packages/platinum/testdrives/testdrives.component';
import {ConverComponent} from './packages/silver/conver/conver.component';
import {FtsComponent} from './packages/silver/fts/fts.component';
import {GdpComponent} from './packages/silver/gdp/gdp.component';
import {HcvComponent} from './packages/silver/hcv/hcv.component';
import {LscComponent} from './packages/silver/lsc/lsc.component';
import {MlsComponent} from './packages/silver/mls/mls.component';
import {OlpsComponent} from './packages/silver/olps/olps.component';
import {OlsComponent} from './packages/silver/ols/ols.component';
import {RbgComponent} from './packages/silver/rbg/rbg.component';
import {VcvComponent} from './packages/silver/vcv/vcv.component';
import {ThingHealthComponent} from './packages/platinum/thing-health/thing-health.component';
import {PrbgComponent} from './packages/silver/prbg/prbg.component';
import {OlseComponent} from './packages/silver/olse/olse.component';
import {MonitoringGatewayComponent} from './packages/admin/monitoring-gateway/monitoring-gateway.component';
import {CacheComponent} from './packages/admin/cache/cache.component';
import {AdminGuard} from './core/controller/guards/admin.guard';
import {CthCustomComponent} from './packages/silver/cth-custom/cth-custom.component';
import {MonitoringGatewayPageComponent} from './packages/admin/monitoring-gateway-page/monitoring-gateway-page.component';
import {HelpPageModule} from './core/view/help-page/help-page.module';
import {SsagmComponent} from './packages/silver/ssagm/ssagm.component';
import {SsdtmComponent} from './packages/silver/ssdtm/ssdtm.component';
import {SsesComponent} from './packages/silver/sses/sses.component';
import {SsifComponent} from './packages/silver/ssif/ssif.component';
import {CarrierOverviewComponent} from './core/view/monitoring/carrier-overview/carrier-overview.component';
import {SscrrComponent} from './packages/silver/sscrr/sscrr.component';
import {MsalGuard, MsalRedirectComponent} from '@azure/msal-angular';
import {BotComponent} from './packages/silver/bot/bot.component';
import {OlsxComponent} from './packages/silver/olsx/olsx.component';
import {ThingHealthV2Component} from './packages/platinum/thing-health-v2/thing-health-v2.component';
import {SeqComponent} from './packages/silver/seq/seq.component';

const routes: Routes = [
    // Reihenfolge beachten

    {path: 'favorites', component: FavoriteComponent, canActivate: [BronzeGuard, MsalGuard]},
    // ToDo AdminViewComponent erstellen
    {path: 'gateway', component: MonitoringGatewayComponent, canActivate: [AdminGuard, MsalGuard]},
    {path: 'gateway/cache', component: CacheComponent, canActivate: [AdminGuard, MsalGuard]},
    {path: 'gateway/:gateway', component: MonitoringGatewayPageComponent, canActivate: [AdminGuard, MsalGuard]},
    {path: ':company', component: MonitoringLocationComponent, canActivate: [BronzeGuard, MsalGuard]},
    {path: ':company/:location', component: MonitoringSystemComponent, canActivate: [BronzeGuard, MsalGuard]},
    {path: ':company/:location/:system', component: MonitoringThingComponent, canActivate: [BronzeGuard, MsalGuard]},
    {path: ':company/:location/:system/dashboard', component: DashboardComponent, canActivate: [BronzeGuard, MsalGuard]},
    {path: ':company/:location/:system/insight', component: PowerbiComponent, canActivate: [BronzeGuard, MsalGuard]},
    {path: ':company/:location/:system/insightdrive', component: PowerbiComponent, canActivate: [BronzeGuard, MsalGuard]},
    {path: ':company/:location/:system/STOREWARE/:id', component: StorewareComponent, canActivate: [BronzeGuard, MsalGuard]},
    {path: ':company/:location/:system/OLS/:id', component: OlsComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/OLSX/:id', component: OlsxComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/OLSE/:id', component: OlseComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/OLPS/:id', component: OlpsComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/MLS/:id', component: MlsComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/FTS/:id', component: FtsComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/FTSF/:id', component: FtsComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/FTSA/:id', component: FtsComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/RBG/:id', component: RbgComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/PRBG/:id', component: PrbgComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/PRBG_CUSTOM/:id', component: CthCustomComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/VCV/:id', component: VcvComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/SEQ/:id', component: SeqComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/HCV/:id', component: HcvComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/GDP/:id', component: GdpComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/LSC/:id', component: LscComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/CONVER/:id', component: ConverComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/SSAGM/:id', component: SsagmComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/SSDTM/:id', component: SsdtmComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/SSES/:id', component: SsesComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/SSIF/:id', component: SsifComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/SSCRR/:id', component: SscrrComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/BOT/:id', component: BotComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: ':company/:location/:system/healthPbi', component: PowerbiComponent, canActivate: [PlatinumGuard, MsalGuard]},
    {path: ':company/:location/:system/:Thingtype/:id/Health', component: ThingHealthV2Component, canActivate: [PlatinumGuard, MsalGuard]},
    {path: ':company/:location/:system/Health/:Thingtype/:id', component: ThingHealthV2Component, canActivate: [PlatinumGuard, MsalGuard]},
    // {path: ':company/:location/:system/:Thingtype/:id/Health/test', component: ThingHealthComponent, canActivate: [PlatinumGuard, MsalGuard]},
    // {path: ':company/:location/:system/Health/:Thingtype/:id/test', component: ThingHealthComponent, canActivate: [PlatinumGuard, MsalGuard]},
    {path: ':company/:location/:system/testdrive', component: TestdrivesComponent, canActivate: [PlatinumGuard, MsalGuard]},
    {path: ':company/:location/:system/CarrierOverview/:page', component: CarrierOverviewComponent, canActivate: [SilverGuard, MsalGuard]},
    {path: '', component: MonitoringCustomerComponent, canActivate: [MsalGuard]},

    {path: 'auth', component: MsalRedirectComponent},

    // bei allen anderen Routes redirectTo PageNotFound
    {path: '**', component: PageNotFoundComponent, pathMatch: 'full'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
        }), HelpPageModule],
    exports: [RouterModule]

})
export class AppRoutingModule
{
}
