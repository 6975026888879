import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-conver-status',
    templateUrl: './conver-status.component.html',
    styleUrls: ['./conver-status.component.scss',
        './../card.scss']
})
export class ConverStatusComponent extends BaseViewElement implements OnInit
{

    /**
     * Übersetzung der Fahrtencodes
     */
    driveMessages = ['Stop', 'Stop V1', 'Stop V2', 'Stop V3', 'Stop V4', 'Stop Ve', 'Stop Vr', 'Stop Vi', 'Stop Vn',
        'Halt', 'Halt V1', 'Halt V2', 'Halt V3', 'Halt V4', 'Halt Ve', 'Halt Vr', 'Halt Vi', 'Halt Vn',
        'Auf', 'Auf V1', 'Auf V2', 'Auf V3', 'Auf V4', 'Auf Ve', 'Auf Vr', 'Auf Vi', 'Auf Vn',
        'Ab', 'Ab V1', 'Ab V2', 'Ab V3', 'Ab V4', 'Ab Ve', 'Ab Vr', 'Ab Vi', 'Ab Vn',
        'NachAuf', 'NachAuf V1', 'NachAuf V2', 'NachAuf V3', 'NachAuf V4', 'NachAuf Ve', 'NachAuf Vr', 'NachAuf Vi', 'NachAuf Vn',
        'NachAb', 'NachAb V1', 'NachAb V2', 'NachAb V3', 'NachAb V4', 'NachAb Ve', 'NachAb Vr', 'NachAb Vi', 'NachAb Vn',
        'EvakEin', 'EvakEin V1', 'EvakEin V2', 'EvakEin V3', 'EvakEin V4', 'EvakEin Ve', 'EvakEin Vr', 'EvakEin Vi', 'EvakEin Vn',
        'EvakAus', 'EvakAus V1', 'EvakAus V2', 'EvakAus V3', 'EvakAus V4', 'EvakAus Ve', 'EvakAus Vr', 'EvakAus Vi', 'EvakAus Vn'];

    /**
     * Zum verbinden der Fahrtennummern mit Meldungen
     */
    driveMap = new Map<number, string>();

    operatingCondition = null;

    @Input() PositionError: boolean;
    @Input() PositionErrorLevel: MonitoringLevel;
    @Input() PositionValue: LimitSizeArrayModel<any>[];

    @Input() DestinationError: boolean;
    @Input() DestinationErrorLevel: MonitoringLevel;
    @Input() DestinationValue: LimitSizeArrayModel<any>[];

    @Input() DriveError: boolean;
    @Input() DriveErrorLevel: MonitoringLevel;
    @Input() DriveValue: LimitSizeArrayModel<any>[];

    @Input() OperatingConditionError: boolean;
    @Input() OperatingConditionErrorLevel: MonitoringLevel;
    @Input() OperatingConditionValue: LimitSizeArrayModel<any>[];

    @ViewChild('card', {read: ElementRef}) card: ElementRef;
    width = 'auto';
    height = '100%';

    constructor(private _translate: TranslateService)
    {
        super('conver-status');

        let counter = 0;
        for (let i = 0; i <= 7; i++)
        {
            for (let j = 0; j <= 8; j++)
            {
                this.driveMap.set(i + j * 10, this.driveMessages[counter]);
                counter++;
            }
        }
    }

    ngOnInit(): void
    {
        super.ngOnInit();

        if (this.OperatingConditionValue == null) return;

        if (this.OperatingConditionValue[0] == null) return;

        this.OperatingConditionValue[0].valuePushed.subscribe((data) =>
        {
            if (data == null) return;
            this._translate.get(this.calculateOperatingCondition(+data.value)).subscribe(x => this.operatingCondition = x);
        });
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }


    /**
     * Übersetzt die 4 Status bits in Zustände
     * @param pValue
     */
    private calculateOperatingCondition(pValue: number): string
    {
        // Wird benötigt um immer 4 Stellen zu bekommen, sonst werden 0en weggekürzt
        const bits = ('0000' + pValue.toString(2)).slice(-4);

        switch (bits)
        {
            case '0000':
                return 'Kritischer Fehler(0000)';
            case '0001':
                return 'Kritischer Fehler(0001) (Ungültiger Zustand, sollte nicht passieren)';
            case '0010':
                return 'Motor zu heiß';
            case '0011':
                return 'Motor zu heiß';
            case '0100':
                return 'Tür Auf';
            case '0101':
                return 'Kritischer Fehler(0101) (Ungültiger Zustand, sollte nicht passieren)';
            case '0110':
                return 'Tür nicht verriegelt';
            case '0111':
                return 'Fahrbereit';
            case '1000':
                return 'Kritischer Fehler(1000) (Ungültiger Zustand, sollte nicht passieren)';
            case '1001':
                return 'Kritischer Fehler(1001) (Ungültiger Zustand, sollte nicht passieren)';
            case '1010':
                return 'Fährt + Motor zu heiß';
            case '1011':
                return 'Kritischer Fehler(1011) (Ungültiger Zustand, sollte nicht passieren)';
            case '1100':
                return 'Kritischer Fehler(1100)';
            case '1101':
                return 'Kritischer Fehler(1101) (Ungültiger Zustand, sollte nicht passieren)';
            case '1110':
                return 'Fährt';
            case '1111':
                return 'Kritischer Fehler(1111)';
            default:
                return 'Betriebszustand nicht erkannt';
        }
    }
}
