import {BaseOptions} from './base-options';

/**
 * Optionen für Gaugecharts
 */
export class WarningGaugechartOptions extends BaseOptions
{
    /**
     * Untere Grenze
     */
    min = 0;

    /**
     * Obere Grenze
     */
    max = 100;

    /**
     * Color stop scheme
     * */
    axisLine = {
        lineStyle: {
            width: 30,
            color: [
                [0.33, '#ff0050'],
                [0.66, '#00eeff'],
                [1, '#50ff88']
                ],
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 10
        }
    };

    /**
     * Konstruktor
     * @param pName
     * @param pTitle
     * @param pUnit
     * @param pMin
     * @param pMax
     */
    constructor(pName: string, pTitle: string, pUnit: string, pMin: number, pMax: number)
    {
        super(pName, pTitle, pUnit);
        this.min = pMin;
        this.max = pMax;
    }
}
