import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-placeholder-card',
    templateUrl: './placeholder-card.component.html',
    styleUrls: ['./placeholder-card.component.scss']
})
export class PlaceholderCardComponent implements OnInit
{

    constructor()
    {
    }

    ngOnInit(): void
    {
    }

    protected readonly environment = environment;
}
