import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class WebsocketService
{

    constructor()
    {

    }


}
