import {Component, Input, OnInit} from '@angular/core';
import {DataProviderService} from '../../controller/services/data-provider.service';
import {MonitoringService} from '../../controller/services/monitoring.service';
import {NavigationService} from '../../controller/services/navigation.service';
import {Thing} from '../../model/thing.model';
import {FavoriteService} from '../../controller/services/favorite.service';
import {environment} from '../../../../environments/environment';
import {NgxQrcodeElementTypes} from '@techiediaries/ngx-qrcode';

@Component({
    selector: 'app-card-component',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})

export class CardComponent implements OnInit
{

    constructor(public data: DataProviderService,
                public nav: NavigationService,
                public monitoring: MonitoringService,
                private favorites: FavoriteService)
    {
    }
    // Wird übergeben wenn es sich um Company, location, system handelt
    @Input() public customerDetail: any;
    // Wird übergeben wenn es sich um things handelt
    @Input() public thing: Thing;

    public detailRouterLink: string;
    
    public backgroundColor = '#f3f3f3';

    protected readonly environment = environment;

    public elementType = NgxQrcodeElementTypes.IMG;

    /**
     * Baut Url wenn es sich um ein customerDetail handelt.
     */
    ngOnInit(): void
    {
        if (this.customerDetail != null) this.detailRouterLink = this.nav.buildCustomerDetailURLs(this.customerDetail);
        this.setReplacement();
    }

    /**
     * Fügt die ausgewählten CustomerDetails / things zu den Favoriten hinzu
     * @param pObject
     */
    public setFavorite(pObject)
    {
        pObject.isFavorite = true;
        this.favorites.addFavorite(pObject);
    }

    /**
     * Löscht die ausgewählten CustomerDetails / things aus den Favoriten
     * @param pObject
     */
    public unsetFavorite(pObject)
    {
        pObject.isFavorite = false;

        this.favorites.deleteFavorite(pObject);
    }

    /**
     * Setzt das ausgwählte thing auf die Observe Liste oder entfernt es wieder
     * @param pThing
     */
    public setObserve(pThing: Thing)
    {
        if (!pThing.isObserved)
        {
            this.monitoring.MonitoredThings.push(pThing);
        }
        else
        {
            const index = this.monitoring.MonitoredThings.indexOf(pThing, 0);
            if (index > -1)
            {
                this.monitoring.MonitoredThings.splice(index, 1);
            }
        }

        pThing.isObserved = !pThing.isObserved;
    }

    private setReplacement()
    {
        if (this.thing == null) return;

        if (this.data.activeCompany.name === 'Häcker Küchen GmbH & Co. KG')
        {
            if (this.thing.errorCount === 52 && this.thing.thingType === 'OLS'
                || this.thing.errorCount === 66 && this.thing.thingType === 'MLS')
            {
                this.thing.isReplacement = true;
            }
        }
    }

}
