<app-help-page-banner [items]=""
                      [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">
    <!-- StartPage -->

    <div *ngIf="curentLang == 'de'">
        <h2>Häufige Fragen </h2>
        <div id="accordionDe">
            <div *ngFor="let item of questionsArray">
                <!-- Accordion -->
                <div class="card">
                    <div class="card-header">
                        <a class="card-link" data-toggle="collapse" href="#collapseDe{{item.getId}}">
                            {{item.getQuestion_de}}
                        </a>
                    </div>

                    <div *ngIf="item.getId === 1; else notFirst">
                        <div id="collapseDe1" class="collapse show" data-parent="#accordionDe">
                            <div class="card-body">
                                {{item.getAnswer_de}}
                            </div>
                        </div>
                    </div>
                    <ng-template #notFirst>
                        <div id="collapseDe{{item.getId}}" class="collapse" data-parent="#accordionDe">
                            <div class="card-body">
                                {{item.getAnswer_de}}
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="curentLang == 'en'">
        <h2>Frequent questions</h2>

        <div id="accordionEn">
            <div *ngFor="let item of questionsArray">
                <!-- Accordion -->
                <div class="card">
                    <div class="card-header">
                        <a class="card-link" data-toggle="collapse" href="#collapseEn{{item.getId}}">
                            {{item.getQuestion_en}}
                        </a>
                    </div>

                    <div *ngIf="item.getId === 1; else notFirst">
                        <div id="collapseEn1" class="collapse show" data-parent="#accordionEn">
                            <div class="card-body">
                                {{item.getAnswer_en}}
                            </div>
                        </div>
                    </div>
                    <ng-template #notFirst>
                        <div id="collapseEn{{item.getId}}" class="collapse" data-parent="#accordionEn">
                            <div class="card-body">
                                {{item.getAnswer_en}}
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
