<app-group-header [Heading]="'Klappenbewegungen' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Klappenbewegungen KL1' | translate"
                      [Error]="FlapMovements1?.isError"
                      [ErrorLevel]="FlapMovements1?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[FlapMovements1?.TimeseriesData]"
                      [Legend]="['Klappenbewegungen KL1' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Klappenbewegungen KL2' | translate"
                      [Error]="FlapMovements2?.isError"
                      [ErrorLevel]="FlapMovements2?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[FlapMovements2?.TimeseriesData]"
                      [Legend]="['Klappenbewegungen KL2' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Klappenbewegungen KL3' | translate"
                      [Error]="FlapMovements3?.isError"
                      [ErrorLevel]="FlapMovements3?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[FlapMovements3?.TimeseriesData]"
                      [Legend]="['Klappenbewegungen KL3' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Klappenbewegungen KL4' | translate"
                      [Error]="FlapMovements4?.isError"
                      [ErrorLevel]="FlapMovements4?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[FlapMovements4?.TimeseriesData]"
                      [Legend]="['Klappenbewegungen KL4' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>
</div>
