<div *ngIf="data.activeSystem != null" id="primaryWindow">

    <app-thing-banner *ngIf="data.activeLocation.name === 'Sinsheim'"
                      [items]="[]"></app-thing-banner>

    <!--Überschrift-->
    <div class="row">
        <div class="col-lg-12">
            <div class="">
                <h2>{{ data.activeLocation.name }} - {{ data.activeSystem.name }}</h2>
            </div>
        </div>
    </div>

    <!--3D Darstellung-->
    <div class="row">

        <div class="col-lg-12 subheading">
            <h3>{{'3D Layout' | translate}}</h3>
        </div>

        <div class="col-lg-1"></div>

        <div class="col-lg-10">
            <div class="tile">
                <div class="tileBody videoContainer">
                    <iframe id="3dframe" allowfullscreen=true webkitallowfullscreen=true mozallowfullscreen=true
                            #unityIframe
                            [src]="frameSrc | safe" class="video"></iframe>
                </div>
            </div>
        </div>

        <div class="col-lg-1"></div>
    </div>

    <!--Aktuelle Lagerstatistik-->
    <div class="row" *ngIf="false">
        <div class="col-lg-12 subheading">
            <h3 id="StorageStatistic">{{'Aktuelle Lagerstatistik' | translate}}</h3>
        </div>

        <div class="col-lg-2">
        </div>

        <app-text-tile class="col-lg-2"
                       [heading]="'Einlagerung (Offen)' | translate"
                       [unit]="'(Anzahl/Tag)' | translate"
                       [error]="storeware.STW_ORDER_COUNT.INCOMING_GOODS_FINISHED.isError || storeware.STW_ORDER_COUNT.INCOMING_GOODS_OPEN.isError"
                       directInput="{{storeware.STW_ORDER_COUNT.INCOMING_GOODS_FINISHED.TimeseriesData.currentDatapoint?.value}} ({{storeware.STW_ORDER_COUNT.INCOMING_GOODS_OPEN.TimeseriesData.currentDatapoint?.value}})">
        </app-text-tile>

        <app-text-tile class="col-lg-2"
                       [heading]="'Auslagerung (Offen)' | translate"
                       [unit]="'(Anzahl/Tag)' | translate"
                       [error]="storeware.STW_ORDER_COUNT.OUTGOING_GOODS_FINISHED.isError || storeware.STW_ORDER_COUNT.OUTGOING_GOODS_OPEN.isError"
                       directInput="{{storeware.STW_ORDER_COUNT.OUTGOING_GOODS_FINISHED.TimeseriesData.currentDatapoint?.value}} ({{storeware.STW_ORDER_COUNT.OUTGOING_GOODS_OPEN.TimeseriesData.currentDatapoint?.value}})">
        </app-text-tile>

        <app-text-tile class="col-lg-2"
                       [heading]="'Picks (Offen)' | translate"
                       [unit]="'(Anzahl/Tag)' | translate"
                       [error]="storeware.STW_ORDER_COUNT.OPEN_PICKS_FINISHED.isError || storeware.STW_ORDER_COUNT.OPEN_PICKS_OPEN.isError"
                       directInput="{{storeware.STW_ORDER_COUNT.OPEN_PICKS_FINISHED.TimeseriesData.currentDatapoint?.value}} ({{storeware.STW_ORDER_COUNT.OPEN_PICKS_OPEN.TimeseriesData.currentDatapoint?.value}})">
        </app-text-tile>

        <app-text-tile class="col-lg-2"
                       [heading]="'Fahraufträge (Offen)' | translate"
                       [unit]="'(Anzahl/Tag)' | translate"
                       [error]="storeware.STW_ORDER_COUNT.ORDERS_MINILOAD_FINISHED.isError || storeware.STW_ORDER_COUNT.ORDERS_MINILOAD_OPEN.isError"
                       directInput=" {{storeware.STW_ORDER_COUNT.ORDERS_MINILOAD_FINISHED.TimeseriesData.currentDatapoint?.value}} ({{storeware.STW_ORDER_COUNT.ORDERS_MINILOAD_OPEN.TimeseriesData.currentDatapoint?.value}})">
        </app-text-tile>

        <div class="col-lg-2">
        </div>
    </div>

    <!--Gassenfüllstand-->
    <div class="row" *ngIf="false">

        <div class="col-lg-12 subheading">
            <h3 id="AisleFillLevel">{{'Gassenfüllstand - Lagerfüllstand' | translate}}</h3>
        </div>

        <div class="col-lg-6">
            <div class="tile">
                <div class="tileBody">
                    <table class="table" style="">
                        <thead>
                        <tr>
                            <th style="width: 50%;">{{'Gasse' | translate}}</th>
                            <th style="width: 50%;">{{'Füllstand (%)' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody style="width: 100%;"
                               *ngIf="aisleFillLevelAisleWms.currentDatapoint.constructor.name === 'Array'">

                        <tr style="width: 100%"
                            *ngFor="let item of aisleFillLevelAisleWms.currentDatapoint; let i = index">
                            <td style="width: 50%;">{{item.value}}</td>
                            <td style="width: 50%;">{{aisleFillLevelValue.currentDatapoint[i].value}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <app-pie-tile class="col-lg-6"
                      [heading]="'Lagerfüllstand' | translate"
                      [error]="storeware.STW_LE_COUNT.EMPTY_LE_COUNT.isError || storeware.STW_LE_COUNT.FULL_LE_COUNT.isError"
                      [options]="loadOptionsPie"
                      [value]="[storeware.STW_LE_COUNT.EMPTY_LE_COUNT.TimeseriesData.currentDatapoint, storeware.STW_LE_COUNT.FULL_LE_COUNT.TimeseriesData.currentDatapoint]">
        </app-pie-tile>
    </div>

    <div class="gap"></div>
</div>
