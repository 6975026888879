<app-group-header [Heading]="'Berechnete Testfahrtwerte' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">
    <div class="card rounded-0"
         #card
         [class.border-danger]="Error && ErrorLevel === monitoringLevel.Error"
         [class.border-warning]="Error && ErrorLevel === monitoringLevel.Warning"
         [class.border-info]="Error && ErrorLevel === monitoringLevel.Info"
         style="height: calc(100% - 50px);">
        <ng-container *ngIf="!chart && !info">
            <ng-container *ngIf="!noData; else noData">
                <div *ngFor="let tabledata of testdrivesXandY">
                    <div class="card-header">
                        {{tabledata.header}}
                    </div>
                    <div class="card-body table-wrapper-scroll-y my-custom-scrollbar">
                        <table class="table" *ngIf="tabledata.content != null; else noData">
                            <thead>
                            <tr>
                                <th scope="col">{{'Typ' | translate}}</th>
                                <th scope="col">{{'Name' | translate}}</th>
                                <th scope="col">{{'Wert' | translate}}</th>
                            </tr>
                            </thead>

                            <ng-container *ngFor="let content of tabledata.content; let i = index">
                                <tr data-toggle="collapse" (click)="openInfo(content.type + content.direction + i)"
                                    role="button"
                                    [ngClass]="content.value | errorColor: content.upperupperThreshold : content.upperThreshold : content.lowerThreshold : content.lowerlowerThreshold">
                                    <td>{{content.type}}</td>
                                    <td>{{content.name}}</td>
                                    <td>{{content.value}}</td>
                                </tr>
                                <tr class="collapse" id="{{content.type + content.direction + i}}">
                                    <td colspan="3">
                                        <table class="table">
                                            <thead style="text-align: center;">
                                            <tr>
                                                <th scope="col">{{'Grenzwertfehler unten' | translate}}</th>
                                                <th scope="col">{{'Untere Warnung' | translate}}</th>
                                                <th scope="col">{{'aktueller Wert' | translate}}</th>
                                                <th scope="col">{{'Obere Warnung' | translate}}</th>
                                                <th scope="col">{{'Grenzwertfehler oben' | translate}}</th>
                                            </tr>
                                            </thead>
                                            <tbody style="text-align: center;">
                                            <tr>
                                                <td [ngClass]="content.value | errorColor: numberMax : numberMax : numberMin : content.lowerlowerThreshold">
                                                    {{content.lowerlowerThreshold}}
                                                </td>
                                                <td [ngClass]="content.value | errorColor: numberMax : numberMax : content.lowerThreshold : numberMin">
                                                    {{content.lowerThreshold}}
                                                </td>
                                                <td [ngClass]="content.value | errorColor: content.upperupperThreshold : content.upperThreshold : content.lowerThreshold : content.lowerlowerThreshold">
                                                    {{content.value}}
                                                </td>
                                                <td [ngClass]="content.value | errorColor: numberMax : content.upperThreshold : numberMin : numberMin">
                                                    {{content.upperThreshold}}
                                                </td>
                                                <td [ngClass]="content.value | errorColor: content.upperupperThreshold : numberMax : numberMin : numberMin">
                                                    in
                                                    {{content.upperupperThreshold}}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                            </ng-container>

                        </table>

                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="info">
        <div class="card rounded-0"
             [class.border-danger]="Error && ErrorLevel === monitoringLevel.Error"
             [class.border-warning]="Error && ErrorLevel === monitoringLevel.Warning"
             [class.border-info]="Error && ErrorLevel === monitoringLevel.Info">
            <div class="card-body">
                Werte werden nach jeder Testfahrt berechnet und mit festgelegten Grenzwerten verglichen.
            </div>
        </div>
    </ng-container>

    <ng-template #noData>
        <div class="text-center" [style.width]="width" [style.height]="height">
            <img title="Keine aktuellen Daten vorhanden!" [style.width]="width" [style.height]="height"
                 src="../../../../assets/icons/cloud_off_black_18dp.png"/>
        </div>
    </ng-template>

</div>

<!--

<div class="card rounded-0" *ngFor="let tabledata of testdrivesXandY">
            <div class="card-header">
                {{tabledata.header}}
            </div>
            <div class="card-body">
                <table mat-table
                       *ngIf="tabledata.content != null; else noFaults"
                       [dataSource]="tabledata.content"
                       multiTemplateDataRows>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef style="font-size: 16px;">{{'Typ' | translate}}</th>
                        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef style="font-size: 16px;">{{'Name' | translate}}</th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef style="font-size: 16px;">{{'Wert' | translate}}</th>
                        <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                    </ng-container>


<ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail"
             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="example-element-description" style="width: 100%">
                <table class="table">
                    <thead style="text-align: center;">
                        <tr>
                            <th scope="col">{{'Grenzwertfehler unten' | translate}}</th>
                            <th scope="col">{{'Untere Warnung' | translate}}</th>
                            <th scope="col">{{'aktueller Wert' | translate}}</th>
                            <th scope="col">{{'Obere Warnung' | translate}}</th>
                            <th scope="col">{{'Grenzwertfehler oben' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody style="text-align: center;">
                        <tr>
                            <td [ngClass]="element.value | errorColor: numberMax : numberMax : numberMin : element.lowerlowerThreshold">
                                {{element.lowerlowerThreshold}}
                            </td>
                            <td [ngClass]="element.value | errorColor: numberMax : numberMax : element.lowerThreshold : numberMin">
                                {{element.lowerThreshold}}
                            </td>
                            <td [ngClass]="element.value | errorColor: element.upperupperThreshold : element.upperThreshold : element.lowerThreshold : element.lowerlowerThreshold">
                                {{element.value}}
                            </td>
                            <td [ngClass]="element.value | errorColor: numberMax : element.upperThreshold : numberMin : numberMin">
                                {{element.upperThreshold}}
                            </td>
                            <td [ngClass]="element.value | errorColor: element.upperupperThreshold : numberMax : numberMin : numberMin">
                                {{element.upperupperThreshold}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row
    *matRowDef="let row; columns: displayedColumns;"
    [ngClass]="row.value | errorColor: row.upperupperThreshold : row.upperThreshold : row.lowerThreshold : row.lowerlowerThreshold"
    class="example-element-row"
    [class.example-expanded-row]="expandedElement === row"
    (click)="expandedElement = expandedElement === row ? null : row">
</tr>
<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
            </div>
        </div>
-->
