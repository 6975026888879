import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-time',
    templateUrl: './time.component.html',
    styleUrls: ['./time.component.scss',
        './../card.scss']
})
export class TimeComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() Distance: PropertySet;
    @Input() TimeGeneral: PropertySet;

    OperationTime: Property<any>;
    DrivingTime: Property<any>;
    WaitTime: Property<any>;
    OffTime: Property<any>;
    TimeBetweenErrors: Property<any>;
    TimeIntoLift: Property<any>;
    TimeIntoRack: Property<any>;
    KmCounterX: Property<any>;
    KmCounterZ: Property<any>;
    TelescopeDistance: Property<any>;

    constructor()
    {
        super('time');
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Distance != null)
        {
            if (this.Distance.properties.get('KM_COUNTER_X') == null)
            {
                this.KmCounterX = this.Distance.properties.get('KM_COUNTER_X_AXIS');
                this.TelescopeDistance = this.Distance.properties.get('TELESCOPE_DISTANCE_Z_AXIS');
            }
            else
            {
                this.KmCounterX = this.Distance.properties.get('KM_COUNTER_X');
                this.KmCounterZ = this.Distance.properties.get('KM_COUNTER_Z');
            }
        }

        if (this.TimeGeneral != null)
        {
            if (this.TimeGeneral.properties.get('OPERATING_TIME') == null)
            {
                this.OperationTime = this.TimeGeneral.properties.get('OPERATION_TIME');
                this.DrivingTime = this.TimeGeneral.properties.get('DRIVING_TIME');
                this.WaitTime = this.TimeGeneral.properties.get('WAIT_TIME');
                this.OffTime = this.TimeGeneral.properties.get('OFF_TIME');
                this.TimeBetweenErrors = this.TimeGeneral.properties.get('TIME_BETWEEN_ERRORS');
                this.TimeIntoLift = this.TimeGeneral.properties.get('TIME_DRIVE_INTO_LIFT');
                this.TimeIntoRack = this.TimeGeneral.properties.get('TIME_DRIVE_INTO_RACK');
            }
            else
            {
                this.OperationTime = this.TimeGeneral.properties.get('OPERATING_TIME');
                this.DrivingTime = this.TimeGeneral.properties.get('DRIVING_TIME');
                this.WaitTime = this.TimeGeneral.properties.get('WAIT_TIME');
                this.OffTime = this.TimeGeneral.properties.get('OFF_TIME');
            }
        }
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }
}
