<!-- <app-thing-banner [items]="[['Bedienung über Kacheln','#OperIcons'], ['Bedienung über Dropdownlisten','#OperDropDown']]"></app-thing-banner> -->
<app-help-page-banner [items]="[['Bedienung über Kacheln', 'Operation via icons', 'OperIcons'],
['Bedienung über Dropdownlisten', 'Operation via drop-down lists', 'OperDropDown']]" [returnAddress]="'/HelpPage'">
</app-help-page-banner>
<div class="container">
    <!-- StartPage -->
    <h2>{{'Menüführung' | translate}}</h2>
    <h3 class="subheading" id="OperIcons">{{'Bedienung über Kacheln' | translate}}</h3>
    <img id="imageOperIcons"
         src="{{environment.storageUrl}}/models3d/general\helpPage\introduction\MenuNavigation/Operation ViaIcons_{{urlLanguage}}.png"
         class="embed-responsive mx-auto d-block" style="width: 45%;">

    <h3 class="subheading" id="OperDropDown">{{'Bedienung über Dropdownlisten' | translate}}</h3>
    <img id="imageOperDropDown"
         src="{{environment.storageUrl}}/models3d/general\helpPage\introduction\MenuNavigation/OperationViaDropDown_{{urlLanguage}}.png"
         class="embed-responsive mx-auto d-block" style="width: 45%; margin-bottom: 100px;">
</div>
