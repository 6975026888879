import {Component, Input, OnChanges} from '@angular/core';
import {Tile} from '../tile';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
    selector: 'app-image-tile',
    templateUrl: './image-tile.component.html',
    styleUrls: ['./image-tile.component.scss',
        '../tile.scss']
})
/**
 * Bildkachel
 */
export class ImageTileComponent extends Tile implements OnChanges
{
    /**
     * Pfad zum Bild
     */
    @Input() src: string;

    /**
     * Bildbeschreibung
     */
    @Input() alt: string;

    /**
     * Equipment Number
     */
    @Input() equipmentNumber: string;

    /**
     * AIN Link
     */
    @Input() ain: string;

    /**
     * Breite
     * Kann benötigt werden wenn Bild nicht das Format der Kachel hat
     */
    @Input() width = '100%';

    /**
     *
     */
    constructor(private _http: HttpClient)
    {
        super();
    }

    async ngOnChanges(changes: import('@angular/core').SimpleChanges)
    {
        console.log('fired');
        console.log(this.equipmentNumber);
        console.log(this.ain);
        if (this.equipmentNumber != null && this.ain == null)
        {
            console.log('init');
            this.ain = String(await this.getLink());
        }
    }

    async getLink()
    {
        return new Promise((resolve, reject) =>
        {
            const header: HttpHeaders = new HttpHeaders({'equipmentNumber': this.equipmentNumber});

            this._http.get('/backend/api/ain-link', {headers: header}).subscribe(next =>
            {
                console.log(next);
                resolve(next['ainLink']);
            });
        });
    }

    // async openAinLink()
    // {
    //     if (this.ain && this.ain.startsWith('https://'))
    //     {
    //         window.open(this.ain, '_blank');
    //     }
    //     else
    //     {
    //         await this.openLink();
    //         // window.open(String(ainLink), '_blank');
    //     }
    // }

    // async openLink()
    // {
    //     let newWindow = window.open();
    //     return new Promise((resolve, reject) =>{
    //         const header: HttpHeaders = new HttpHeaders({'equipmentNumber': this.equipmentNumber});
    //
    //         this._http.get('/backend/api/ain-link', {headers: header}).subscribe(next =>
    //         {
    //             console.log(next);
    //             // resolve(next['ainLink']);
    //             newWindow.location.href = next['ainLink'];
    //         });
    //     });
    // }
}
