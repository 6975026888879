import {Component, OnInit} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';
import {LimitSizeArrayModel} from '../../../../core/model/helper-models/limit-size-array.model';
import {DataPoint} from '../../../../core/model/helper-models/datapoint.model';

@Component({
    selector: 'app-ssdtm-subcomp-parent',
    templateUrl: './ssdtm-subcomp-parent.component.html',
    styleUrls: ['./ssdtm-subcomp-parent.component.scss']
})
export class SsdtmSubcompParentComponent extends BaseSubcomp
{


}

