import {Component, Input} from '@angular/core';
import {Tile} from '../tile';
import {GaugechartOptions} from '../../charts/options/gaugechart-options';
import {DataPoint} from '../../../model/helper-models/datapoint.model';

@Component({
    selector: 'app-gauge-tile',
    templateUrl: './gauge-tile.component.html',
    styleUrls: ['./gauge-tile.component.scss',
        '../tile.scss']
})
/**
 * Tachodiagramm Kachel
 */
export class GaugeTileComponent extends Tile
{
    /**
     * Wert
     */
    @Input() value: DataPoint<number>[];

    /**
     * Optionen
     */
    @Input() options: GaugechartOptions;

    /**
     *
     */
    constructor()
    {
        super();
    }
}
