import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-finger-movements',
    templateUrl: './finger-movements.component.html',
    styleUrls: ['./finger-movements.component.scss',
        './../card.scss']
})
export class FingerMovementsComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() Finger11: PropertySet;
    @Input() Finger12: PropertySet;
    @Input() Finger21: PropertySet;
    @Input() Finger22: PropertySet;
    @Input() Finger31: PropertySet;
    @Input() Finger32: PropertySet;


    Movements11: Property<any>;
    Movements12: Property<any>;
    Movements21: Property<any>;
    Movements22: Property<any>;
    Movements31: Property<any>;
    Movements32: Property<any>;

    constructor()
    {
        super('finger-movements');
        this.chart = true;
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Finger11 != null)
        {
            this.Movements11 = this.Finger11.properties.get('MOVEMENTS');
        }
        if (this.Finger12 != null)
        {
            this.Movements12 = this.Finger12.properties.get('MOVEMENTS');
        }
        if (this.Finger21 != null)
        {
            this.Movements21 = this.Finger21.properties.get('MOVEMENTS');
        }
        if (this.Finger22 != null)
        {
            this.Movements22 = this.Finger22.properties.get('MOVEMENTS');
        }
        if (this.Finger31 != null)
        {
            this.Movements31 = this.Finger31.properties.get('MOVEMENTS');
        }
        if (this.Finger32 != null)
        {
            this.Movements32 = this.Finger32.properties.get('MOVEMENTS');
        }
    }

}
