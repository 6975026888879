<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Infeed' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Infeed' | translate"
           [width]="'auto'">
</app-image>

<app-ssif-statistic *ngIf="widget === 'ssif-statistic'"
                    [resizeEvent]="resizeEvent"
                    [Infeed]="Thing.propertySets.get('INFEED')"
                    [Crash]="Thing.propertySets.get('CRASH')">
</app-ssif-statistic>

<app-image *ngIf="widget === 'image-transfer-belt'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Übergabeband' | translate"
           [src]="'/models3d/things/ssif/ssif-transfer-belt.png'"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Infeed' | translate"
           [ain]="Thing.ainUrl['Übergabeband']"
           [width]="'auto'">
</app-image>

<app-ssif-belt *ngIf="widget === 'ssif-transfer-belt'"
               [resizeEvent]="resizeEvent"
               [type]="'ssif-transfer-belt'"
               [Title]="'Übergabeband'"
               [Band]="Thing.propertySets.get('UEBERGABEBAND')">
</app-ssif-belt>

<app-image *ngIf="widget === 'image-takeover-belt'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Übernahmeband' | translate"
           [src]="'/models3d/things/ssif/ssif-cycle-belt.png'"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Infeed' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>

<app-ssif-belt *ngIf="widget === 'ssif-takeover-belt'"
               [resizeEvent]="resizeEvent"
               [type]="'ssif-takeover-belt'"
               [Title]="'Übernahmeband'"
               [Band]="Thing.propertySets.get('UEBERNAHMEBAND')">
</app-ssif-belt>

<app-image *ngIf="widget === 'image-cycle-belt1'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Taktband 1' | translate"
           [src]="'/models3d/things/ssif/ssif-cycle-belt.png'"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Taktband 1' | translate"
           [ain]="Thing.ainUrl['Taktband 1']"
           [width]="'auto'">
</app-image>

<app-ssif-belt *ngIf="widget === 'ssif-cycle-belt1'"
               [resizeEvent]="resizeEvent"
               [type]="'ssif-cycle-belt1'"
               [Title]="'Taktband 1'"
               [Band]="Thing.propertySets.get('TAKTBAND_1')">
</app-ssif-belt>

<app-image *ngIf="widget === 'image-cycle-belt2'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Taktband 2' | translate"
           [src]="'/models3d/things/ssif/ssif-cycle-belt.png'"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Taktband 2' | translate"
           [ain]="Thing.ainUrl['Taktband 2']"
           [width]="'auto'">
</app-image>

<app-ssif-belt *ngIf="widget === 'ssif-cycle-belt2'"
               [resizeEvent]="resizeEvent"
               [type]="'ssif-cycle-belt2'"
               [Title]="'Taktband 2'"
               [Band]="Thing.propertySets.get('TAKTBAND_2')">
</app-ssif-belt>

<app-image *ngIf="widget === 'image-merge-belt'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Merge' | translate"
           [src]="'/models3d/things/ssif/ssif-merge-belt.png'"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Merge' | translate"
           [ain]="Thing.ainUrl['Merge']"
           [width]="'auto'">
</app-image>

<app-ssif-belt *ngIf="widget === 'ssif-merge-belt'"
               [resizeEvent]="resizeEvent"
               [type]="'ssif-merge-belt'"
               [Title]="'Merge'"
               [Band]="Thing.propertySets.get('MERGE')">
</app-ssif-belt>
