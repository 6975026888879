import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-ssdtm-lubrication-general',
    templateUrl: './ssdtm-lubrication-general.component.html',
    styleUrls: ['./ssdtm-lubrication-general.component.scss', './../card.scss']
})
export class SsdtmLubricationGeneralComponent extends BaseViewElement implements OnChanges
{

    @Input() Schmiersystem: PropertySet;

    PumpsAll: Property<any>;
    PumpsCartridge: Property<any>;
    LubricationCycles: Property<any>;


    constructor()
    {
        super('ssdtm-lubrication-general');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Schmiersystem == null) return;
        this.PumpsAll = this.Schmiersystem.properties.get('PUMPSTOESSE_GESAMT');
        this.PumpsCartridge = this.Schmiersystem.properties.get('PUMPSTOESSE_KARTUSCHE');
        this.LubricationCycles = this.Schmiersystem.properties.get('SCHMIERZYKLEN');
    }

}
