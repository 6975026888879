import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-sscrr-cylinder',
    templateUrl: './sscrr-cylinder.component.html',
    styleUrls: ['./sscrr-cylinder.component.scss', './../card.scss']
})
export class SscrrCylinderComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() ZylinderStatus: PropertySet;

    MaxCylinderPosition: Property<any>;
    MinCylinderPosition: Property<any>;
    TimestampStart: Property<any>;
    TimestampStop: Property<any>;


    constructor()
    {
        super('sscrr-cylinder');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.ZylinderStatus == null) return;
        this.MaxCylinderPosition = this.ZylinderStatus.properties.get('MAXIMALE_POSITION_DES_ZYLINDERS_DER_KLAPPE');
        this.MinCylinderPosition = this.ZylinderStatus.properties.get('MINIMALE_POSITION_DES_ZYLINDERS_DER_KLAPPE');
        this.TimestampStart = this.ZylinderStatus.properties.get('ZEITPUNKT_START_MESSUNG');
        this.TimestampStop = this.ZylinderStatus.properties.get('ZEITPUNKT_STOP_MESSUNG');
    }
}
