<app-group-header [Heading]="'Statistik DTM' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  [noInfo]="true"
                  [noText]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="table-responsive" style="height: calc(100% - 50px)">
    <table class="table table-bordered" style="height: 100%; padding: 0; margin: 0;">
        <thead>
        <tr>
            <th style="width: 20%" scope="col">DTM</th>
            <th style="width: 20%" scope="col">Ölstand</th>
            <th style="width: 20%" scope="col">Pumpstöße Kartusche</th>
            <th style="width: 20%" scope="col">Kolbenpositionsänderung</th>
            <th style="width: 20%" scope="col">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let dtm of DTM | orderBy:'displayName'">
            <th class="align-middle" scope="row">{{dtm.displayName}}</th>
            <td class="align-middle">
                <ng-container
                        *ngIf="dtm?.propertySets?.get('SCHMIERSYSTEM')?.properties.get('KARTUSCHEN_OELSTAND')?.TimeseriesData.currentDatapoint.value != null; else noData">
                    {{dtm?.propertySets?.get('SCHMIERSYSTEM')?.properties.get('KARTUSCHEN_OELSTAND')?.TimeseriesData.currentDatapoint.value}} %
                </ng-container>
            </td>
            <td class="align-middle">
                <ng-container
                        *ngIf="dtm?.propertySets?.get('SCHMIERSYSTEM')?.properties.get('PUMPSTOESSE_KARTUSCHE')?.TimeseriesData.currentDatapoint.value != null; else noData">
                    {{dtm?.propertySets?.get('SCHMIERSYSTEM')?.properties.get('PUMPSTOESSE_KARTUSCHE')?.TimeseriesData.currentDatapoint.value}}
                </ng-container>
            </td>
            <td class="align-middle">
                <ng-container
                        *ngIf="dtm?.propertySets?.get('SPANNSYSTEM')?.properties.get('KOLBENPOSITION_AENDERUNG')?.TimeseriesData.currentDatapoint.value != null; else noData">
                    {{dtm?.propertySets.get('SPANNSYSTEM').properties.get('KOLBENPOSITION_AENDERUNG').TimeseriesData.currentDatapoint.value}}
                </ng-container>
            </td>
            <td class="align-middle" style="text-align: center; color: white;">
                <ng-container
                        *ngIf="dtm?.propertySets?.get('LED_PANEL')?.properties.get('FARBE')?.TimeseriesData.currentDatapoint.value != null; else noData">
                    <div class="ledelement"
                         *ngIf="dtm?.propertySets?.get('LED_PANEL')?.properties.get('FARBE')?.TimeseriesData.currentDatapoint.value === 1"
                         style="background: green;">OK
                    </div>
                    <div class="ledelement"
                         *ngIf="dtm?.propertySets?.get('LED_PANEL')?.properties.get('FARBE')?.TimeseriesData.currentDatapoint.value === 2"
                         style="background: blue;">Warnung
                    </div>
                    <div class="ledelement"
                         *ngIf="dtm?.propertySets?.get('LED_PANEL')?.properties.get('FARBE')?.TimeseriesData.currentDatapoint.value === 3"
                         style="background: red;">Fehler
                    </div>
                </ng-container>
            </td>
        </tr>
        </tbody>
    </table>
</div>


<ng-template #noData>
    <img title="Keine aktuellen Daten vorhanden!" class="img-fluid"
         src="../../../../assets/icons/cloud_off_black_18dp.png"/>
</ng-template>


