<app-help-page-banner [items]=""
                      [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">
    <!-- StartPage -->
    <h2>{{'Favoriten' | translate}}</h2>
    <table class="table table-striped table-bordered" style="margin-top: 20px;">
        <tbody>
        <tr>
            <td><strong>{{'Favoriten setzen' | translate}} </strong></td>
            <td>{{'Um einen Favoriten zu setzen, muss man auf den Stern des zu favorisierenden Objekts klicken:' | translate}}</td>
            <td><img id="imageFavoriteSet" src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/Favorite/FavoriteSet.png">
            </td>
        </tr>
        <tr>
            <td><strong> {{'Favoriten löschen' | translate}}</strong></td>
            <td>{{'Um einen Favoriten zu löschen, muss man auf den Stern des nicht mehr zu favorisierenden Objekts klicken:' | translate}}</td>
            <td><img id="imageFavoriteDelete"
                     src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/Favorite/FavoriteDelete.png"></td>
        </tr>
        <tr>
            <td><strong>{{'Favoriten ansehen' | translate}}</strong></td>
            <td>{{'Die Favoriten kann man sich unter folgendem Reiter anschauen:' | translate}} </td>
            <td><img id="imageFavoriteView" src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/Favorite/FavoriteView.png">
            </td>
        </tr>
        </tbody>
    </table>
</div>
