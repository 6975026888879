<app-group-header [Heading]="'Feinpositionierung' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Error]="Positioning?.isError"
                      [ErrorLevel]="Positioning?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Positioning?.TimeseriesData]"
                      [Unit]="'ms'"
                      [Legend]="['Feinpositionierung' | translate]"
                      [Info]="'Zeit die benötigt wird um sich vor einem Lagerfach zu positionieren.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
