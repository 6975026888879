<app-group-header [Heading]="Title | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Betriebsstunden' | translate"
                      [Error]="OperatingHours?.isError"
                      [ErrorLevel]="OperatingHours?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[OperatingHours?.TimeseriesData]"
                      [Unit]="'h'"
                      [Legend]="['Betriebsstunden' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Strom' | translate"
                      [Error]="Current?.isError"
                      [ErrorLevel]="Current?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Current?.TimeseriesData]"
                      [Unit]="'A'"
                      [Legend]="['Strom' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Wirkarbeit' | translate"
                      [Error]="RealEnergy?.isError"
                      [ErrorLevel]="RealEnergy?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[RealEnergy?.TimeseriesData]"
                      [Unit]="'kWh'"
                      [Legend]="['Wirkarbeit' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset; flex-grow:2;"
                      [Heading]="'Temperatur' | translate"
                      [Error]="Temperature?.isError"
                      [ErrorLevel]="Temperature?.status"
                      [showChart]="true"
                      [showInfo]="info"
                      [Values]="[Temperature?.TimeseriesData]"
                      [ChartType]="'gauge'"
                      [Unit]="'°C'"
                      [Legend]="['Temperatur' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
