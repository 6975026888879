<div class="container-fluid" id="root" xmlns="">
    <div class="headerContainer" *ngIf="nav.ShowHeader">
        <div class="row" id="headerRow">
            <div class="col-lg-12" id="header">
                <app-header [selectedLanguage]="translate.currentLang"></app-header>
            </div>

        </div>

        <!--Tableiste-->
        <div class="row">
            <div class="col-lg-12" id="navigation">
<!--
                <button style="width:100px; height: 100px;" (click)="testinsight()">
testz
                </button>
                -->
                <div #tabul class="nav nav-tabs" id="tabbar">

                    <button (click)="nav.toggleNav()" class="nav-button" id="nav-opener" title="Menü">
                        <img class="icon" id="menu-button" [src]="environment.storageUrl + '/icons/menu.png'" alt="Menü Symbol"/>
                    </button>

                    <button (click)="nav.navigateToCard()"
                            [className]="nav.MonitoringNavigation ? 'active nav-button': 'nav-button'" id="card-opener"
                            title="Monitoring">
                        <img class="icon" id="tile-button" [src]="environment.storageUrl + '/icons/cards.png'" alt="Karten Symbol"/>
                    </button>

                    <button [routerLink]="'/favorites'" routerLinkActive="active" class="nav-button"
                            id="favorite-opener"
                            title="Favoriten">
                        <img class="icon" id="favorite-button" [src]="environment.storageUrl + '/icons/favorite.png'" alt="Favoriten Symbol"/>
                    </button>

                    <div *ngIf="auth.isAdmin()">
                        <button [routerLink]="'/gateway'" routerLinkActive="active" class="nav-button"
                                id="gateway-opener"
                                title="Gateways">
                            <img class="icon" id="gateway-button" [src]="environment.storageUrl + '/icons/gateway.png'" style="height: 24px"
                                 alt="Gateway Symbol"/>
                        </button>
                    </div>


                    <div *ngFor="let link of data?.activeSystem?.currentTabs, let lastTab = last; let i = index"
                         routerLinkActive="active" class="tab" [ngClass]="{last: lastTab}"
                         [routerLink]="link.path"
                         [routerLinkActiveOptions]="{exact: true}">
                        <div *ngIf="i < nav.possibleOneRow">
                            <a class="tab-link" title="{{link.path}}">
                                {{link.name}}
                            </a>

                            <div *ngIf="link.name != 'Dashboard'" class="close-button-div">
                                <a (click)="deleteTab(link)" class="close close-button-div-a">X</a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="data?.activeSystem?.currentTabs?.length > nav.possibleOneRow">
                        <div style="width: auto !important;" class="dropdown">
                            <a aria-expanded="false" aria-haspopup="true" class="btn dropdown-toggle"
                               data-toggle="dropdown"
                               id="dropdownMenuLink" role="button" routerLinkActive="active">
                            </a>
                            <div aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                <div *ngFor="let link of data?.activeSystem?.currentTabs; let i = index">
                                    <div style="width: auto !important;" *ngIf="i >= nav.possibleOneRow">

                                        <div class="dropdown-item" routerLinkActive="active">

                                            <a routerLink={{link.path}}
                                               title="{{link.location}}&#10;{{link.system}}"> {{link.name}} </a>
                                            <div *ngIf="link.path != 'dashboard'" class="dropdown-item-div">
                                                <a (click)="deleteTab(link)" class="close dropdown-item-div-a">X</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" [ngClass]="{'main': nav.ShowHeader}" id="main">

        <div class="col-lg-12" id="contentFooter" [ngClass]="{'noHeader': !nav.ShowHeader}">
            <div class="row" id="contentRow">
                <div class="col-lg-12" id="content" [ngClass]="{'noPadding': !nav.ShowHeader}">


                    <nav #menu [style.visibility]="nav.NavOpen" id="menu">

                        <ul class="parent-menu" id="ul">
                            <!--
                            <div class="form-group">
                                <input type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="Suchen..." title="Suchbegriff eingeben." class="form-control">
                            </div>
                            -->

                            <!--Auswahl Kunde/Standort/Anlage-->

                            <li>
                                <div *ngIf="data?.customer?.length > 0">
                                    <div class="form-group">
                                        <label for="details">{{'Unternehmen' | translate}}: </label>
                                        <div>
                                            <select [(ngModel)]="data.activeCompany"
                                                    (change)="selectCustomerOfIndex($event.target.selectedIndex, true)"
                                                    class="form-control" id="details" name="customer">
                                                <option *ngFor="let customer of data.customer" [ngValue]="customer">
                                                    {{customer.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div *ngIf="data.activeCompany?.locations?.size > 0">
                                    <div class="form-group">
                                        <label for="loc">{{'Standort' | translate}}: </label>
                                        <div *ngIf="(data.activeLocation != null)">
                                            <select [(ngModel)]="data.activeLocation"
                                                    (change)="selectLocationOfIndex($event.target.selectedIndex, true)"
                                                    class="form-control" id="loc" name="location">
                                                <option *ngFor="let location of Array.from(data.activeCompany.locations.values())"
                                                        [ngValue]="location">
                                                    {{location.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li style="border-bottom: 1px solid lightgray">
                                <div *ngIf="data.activeLocation?.systems?.size > 0">
                                    <div class="form-group">
                                        <label for="sys" i18n>{{'Anlage' | translate}}: </label>
                                        <div *ngIf="data.activeSystem != null">

                                            <select [(ngModel)]="data.activeSystem"
                                                    (change)="selectSystemOfIndex($event.target.selectedIndex)"
                                                    class="form-control" id="sys" name="system">
                                                <option *ngFor="let system of Array.from(data.activeLocation.systems.values())"
                                                        [ngValue]="system">
                                                    {{system.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!--Navigation Links-->

                            <div *ngIf="data.activeSystem != null" id="dynamicNav">

                                <li *ngIf="auth.isBronze() && data?.activeSystem != null" routerLinkActive="active">
                                    <a (click)="nav.navigateToThing(data.activeSystem.dashboard)">Dashboard</a>
                                </li>

                                <app-navigation-left
                                        *ngIf="auth.isSilver()  && data?.activeSystem?.allThingTypes.has('OLS')"
                                        [name]="'One level Shuttle'"
                                        [thingRoute]="'OLS/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('OLS'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver()  && data?.activeSystem?.allThingTypes.has('OLSE')"
                                        [name]="'One level Shuttle - E'"
                                        [thingRoute]="'OLSE/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('OLSE'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver()  && data?.activeSystem?.allThingTypes.has('OLSX')"
                                        [name]="'One level Shuttle X'"
                                        [thingRoute]="'OLSX/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('OLSX'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('OLPS')"
                                        [name]="'StoreBiter 500'"
                                        [thingRoute]="'OLPS/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('OLPS'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('MLS')"
                                        [name]="'Multi level Shuttle'"
                                        [thingRoute]="'MLS/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('MLS'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('FTS')"
                                        [name]="'Fahrerloses Transport System'"
                                        [thingRoute]="'FTS/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('FTS'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('RBG')"
                                        [name]="'Regalbediengerät' | translate"
                                        [thingRoute]="'RBG/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('RBG'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('HCV')"
                                        [name]="'Horizontalförderer'"
                                        [thingRoute]="'HCV/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('HCV'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('VCV')"
                                        [name]="'Heber' | translate"
                                        [thingRoute]="'VCV/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('VCV'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('GDP')"
                                        [name]="'GridPick'"
                                        [thingRoute]="'GDP/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('GDP'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('CONVER')"
                                        [name]="'Conver'"
                                        [thingRoute]="'CONVER/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('CONVER'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('LSC')"
                                        [name]="'Leuze Scanner'"
                                        [thingRoute]="'LSC/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('LSC'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('SSAGM')"
                                        [name]="'Speedsorter Allgemein'"
                                        [thingRoute]="'SSAGM/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('SSAGM'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('SSDTM')"
                                        [name]="'Speedsorter DTM'"
                                        [thingRoute]="'SSDTM/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('SSDTM'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('SSIF')"
                                        [name]="'Speedsorter Infeed'"
                                        [thingRoute]="'SSIF/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('SSIF'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('SSES')"
                                        [name]="'Speedsorter Endstelle'"
                                        [thingRoute]="'SSES/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('SSES'))"></app-navigation-left>

                                <app-navigation-left
                                        *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('BOT')"
                                        [name]="'Gridstore Bot'"
                                        [thingRoute]="'BOT/'"
                                        [things]="sortArray(data?.activeSystem?.allThingTypes.get('BOT'))"></app-navigation-left>

                                <li *ngIf="auth.isPlatinum() && data?.activeSystem?.PowerBiConfig?.Testdrive != null"
                                    routerLinkActive="active">
                                    <a (click)="nav.navigateToTestdrive()">{{'Testfahrt' | translate}}</a>
                                </li>

                                <!--
                                                                <li *ngIf="auth.isBronze() && data?.activeSystem != null && Dashboard.length > 0" routerLinkActive="active">
                                                                    <a (click)="nav.navigateToThing(data.activeSystem.dashboard)">Dashboard</a>
                                                                </li>

                                                                <li *ngIf="data?.activeSystem?.allThingTypes.has('OLS') && OLSAislesAndThings?.length > 0">
                                                                    <div [ngClass]="OlsState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('OLS').length > 1; else singleOLS">
                                                                        <a class="d-flex align-items-center"  href="#aisleCollapse" role="button" data-bs-toggle="collapse" (click)="OlsState = OlsState === 'default' ? 'rotated' : 'default';" data-bs-target="#aisleCollapse" aria-expanded="false" aria-controls="#aisleCollapse">
                                                                            One Level Shuttle
                                                                            <svg [@rotatedArrow]='OlsState' xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right  ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="aisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let aisleLoop of OLSAisles; let i = index">
                                                                                    <div *ngIf="OLSAislesAndThings.length > 1; else OLSLessThanTwo">
                                                                                        <a *ngIf="OLSAisles.length > 1" class="d-flex align-items-center ms-4" href="#listCollapse{{i}}" role="button" (click)="rotate(aisleLoop)" data-bs-toggle="collapse" data-bs-target="#listCollapse{{i}}" aria-expanded="false" aria-controls="#listCollapse{{i}}">Gasse {{aisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='aisleLoop.state' id="arrowOlsSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>

                                                                                        <div [ngClass]="OLSAisles.length > 1 ? 'collapse' : ''" id="listCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of OLSAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #OLSLessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of OLSAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleOLS>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('OLS')[0])">
                                                                            One Level Shuttle
                                                                        </a>
                                                                    </ng-template>
                                                                </li>

                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('OLSE') && OLSEAislesAndThings?.length > 0 ">
                                                                    <div [ngClass]="OlseState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('OLSE').length > 1; else singleOLSE">
                                                                        <a class="d-flex align-items-center" href="#OlseAisleCollapse" role="button" data-bs-toggle="collapse" (click)="OlseState = OlseState === 'default' ? 'rotated' : 'default';" data-bs-target="#OlseAisleCollapse" aria-expanded="false" aria-controls="#OlseAisleCollapse">
                                                                            One Level Shuttle E
                                                                            <svg [@rotatedArrow]='OlseState' id="arrowOLSE{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-2 ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="OlseAisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let OlseAisleLoop of OLSEAisles; let i = index">
                                                                                    <div  *ngIf="OLSEAislesAndThings.length > 1; else OLSELessThanTwo">
                                                                                        <a *ngIf="OLSEAisles.length > 1" class="d-flex align-items-center ms-4" href="#OlseListCollapse{{i}}" role="button" (click)="rotate(OlseAisleLoop)" data-bs-toggle="collapse" data-bs-target="#OlseListCollapse{{i}}" aria-expanded="false" aria-controls="#OlseListCollapse{{i}}">Gasse {{OlseAisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='OlseAisleLoop.state' id="arrowOlseSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>

                                                                                        <div [ngClass]="OLSEAisles.length > 1 ? 'collapse' : ''" id="OlseListCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of OLSEAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #OLSELessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of OLSEAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleOLSE>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('OLSE')[0])">
                                                                            One Level Shuttle E
                                                                        </a>
                                                                    </ng-template>
                                                                </li>

                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('OLPS') && OLPSAislesAndThings?.length > 0">
                                                                    <div [ngClass]="OlpsState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('OLPS').length > 1; else singleOLPS">
                                                                        <a class="d-flex align-items-center" href="#OlpsAisleCollapse" role="button" data-bs-toggle="collapse" (click)="OlpsState = OlpsState === 'default' ? 'rotated' : 'default';" data-bs-target="#OlpsAisleCollapse" aria-expanded="false" aria-controls="#OlpsAisleCollapse">
                                                                            One Level Pallet System
                                                                            <svg [@rotatedArrow]='OlpsState' id="arrowOLPS{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="OlpsAisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let OlpsAisleLoop of OLPSAisles; let i = index">
                                                                                    <div *ngIf="OLPSAislesAndThings.length > 1; else OLPSLessThanTwo">
                                                                                        <a *ngIf="OLPSAisles.length > 1" class="d-flex align-items-center ms-4" href="#OlpsListCollapse{{i}}" role="button" (click)="rotate(OlpsAisleLoop)" data-bs-toggle="collapse" data-bs-target="#OlpsListCollapse{{i}}" aria-expanded="false" aria-controls="#OlpsListCollapse{{i}}">Gasse {{OlpsAisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='OlpsAisleLoop.state' id="arrowOlpsSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>
                                                                                        <div [ngClass]="OLPSAisles.length > 1 ? 'collapse' : ''" id="OlpsListCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of OLPSAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #OLPSLessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of OLPSAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleOLPS>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('OLPS')[0])">
                                                                            One Level Pallet System
                                                                        </a>
                                                                    </ng-template>
                                                                </li>

                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('MLS') && MLSAislesAndThings?.length > 0">
                                                                    <div [ngClass]="MlsState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('MLS').length > 1; else singleMLS">
                                                                        <a class="d-flex align-items-center" href="#MlsAisleCollapse" role="button" data-bs-toggle="collapse" (click)="MlsState = MlsState === 'default' ? 'rotated' : 'default';" data-bs-target="#MlsAisleCollapse" aria-expanded="false" aria-controls="#MlsAisleCollapse">
                                                                            Multi Level Shuttle
                                                                            <svg [@rotatedArrow]='MlsState' id="arrowMLS{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="MlsAisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let MlsAisleLoop of MLSAisles; let i = index">
                                                                                    <div *ngIf="MLSAislesAndThings.length > 1; else MLSLessThanTwo">
                                                                                        <a *ngIf="MLSAisles.length > 1" class="d-flex align-items-center ms-4" href="#MlsListCollapse{{i}}" role="button" (click)="rotate(MlsAisleLoop)" data-bs-toggle="collapse" data-bs-target="#MlsListCollapse{{i}}" aria-expanded="false" aria-controls="#MlsListCollapse{{i}}">Gasse {{MlsAisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='MlsAisleLoop.state' id="arrowMlsSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>

                                                                                        <div [ngClass]="MLSAisles.length > 1 ? 'collapse' : ''" id="MlsListCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of MLSAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #MLSLessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of MLSAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>

                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleMLS>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('MLS')[0])">
                                                                            Multi Level Shuttle
                                                                        </a>
                                                                    </ng-template>
                                                                </li>


                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('FTS') && FTSAislesAndThings?.length > 0">
                                                                    <a class="d-flex align-items-center" (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('FTS')[0])">Fahrerloses Transportsystem</a>
                                                                </li>

                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('RBG') && RBGAislesAndThings?.length > 0">
                                                                    <div [ngClass]="RbgState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('RBG').length > 1; else singleRBG">
                                                                        <a class="d-flex align-items-center" href="#RbgAisleCollapse" role="button" data-bs-toggle="collapse" (click)="RbgState = RbgState === 'default' ? 'rotated' : 'default';" data-bs-target="#RbgAisleCollapse" aria-expanded="false" aria-controls="#RbgAisleCollapse">
                                                                            Regalbediengerät
                                                                            <svg [@rotatedArrow]='RbgState' id="arrowRBG{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="RbgAisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let RbgAisleLoop of RBGAisles; let i = index">
                                                                                    <div *ngIf="RBGAislesAndThings.length > 1; else RBGLessThanTwo">
                                                                                        <a *ngIf="RBGAisles.length > 1" class="d-flex align-items-center ms-4" href="#RbgListCollapse{{i}}" role="button" (click)="rotate(RbgAisleLoop)" data-bs-toggle="collapse" data-bs-target="#RbgListCollapse{{i}}" aria-expanded="false" aria-controls="#RbgListCollapse{{i}}">Gasse {{RbgAisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='RbgAisleLoop.state' id="arrowRbgSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>

                                                                                        <div [ngClass]="RBGAisles.length > 1 ? 'collapse' : ''" id="RbgListCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of RBGAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #RBGLessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of RBGAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleRBG>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('RBG')[0])">
                                                                            Regalbediengerät
                                                                        </a>
                                                                    </ng-template>
                                                                </li>

                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('HCV') && HCVAislesAndThings?.length > 0">
                                                                    <div [ngClass]="HcvState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('HCV').length > 1; else singleHCV">
                                                                        <a class="d-flex align-items-center" href="#HcvAisleCollapse" role="button" data-bs-toggle="collapse" (click)="HcvState = HcvState === 'default' ? 'rotated' : 'default';" data-bs-target="#HcvAisleCollapse" aria-expanded="false" aria-controls="#HcvAisleCollapse">
                                                                            Horizontalförderer
                                                                            <svg [@rotatedArrow]='MlsState' id="arrowHCV{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="HcvAisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let HcvAisleLoop of HCVAisles; let i = index">
                                                                                    <div *ngIf="HCVAislesAndThings.length > 1; else HCVLessThanTwo">
                                                                                        <a *ngIf="HCVAisles.length > 1" class="d-flex align-items-center ms-4" href="#HcvListCollapse{{i}}" role="button" (click)="rotate(HcvAisleLoop)" data-bs-toggle="collapse" data-bs-target="#HcvListCollapse{{i}}" aria-expanded="false" aria-controls="#HcvListCollapse{{i}}">Gasse {{HcvAisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='HcvAisleLoop.state' id="arrowHcvSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>

                                                                                        <div [ngClass]="HCVAisles.length > 1 ? 'collapse' : ''" id="HcvListCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of HCVAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #HCVLessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of HCVAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleHCV>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('HCV')[0])">
                                                                            Horizontalförderer
                                                                        </a>
                                                                    </ng-template>
                                                                </li>

                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('VCV') && VCVAislesAndThings?.length > 0">
                                                                    <div [ngClass]="VcvState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('VCV').length > 1; else singleVCV">
                                                                        <a class="d-flex align-items-center" href="#VcvAisleCollapse" role="button" data-bs-toggle="collapse" (click)="VcvState = VcvState === 'default' ? 'rotated' : 'default';" data-bs-target="#VcvAisleCollapse" aria-expanded="false" aria-controls="#VcvAisleCollapse">
                                                                            Heber
                                                                            <svg [@rotatedArrow]='VcvState' id="arrowVCV{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="VcvAisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let VcvAisleLoop of VCVAisles; let i = index">
                                                                                    <div *ngIf="VCVAislesAndThings.length > 1; else VCVLessThanTwo">
                                                                                        <a *ngIf="VCVAisles.length > 1" class="d-flex align-items-center ms-4" href="#VcvListCollapse{{i}}" role="button" (click)="rotate(VcvAisleLoop)" data-bs-toggle="collapse" data-bs-target="#VcvListCollapse{{i}}" aria-expanded="false" aria-controls="#VcvListCollapse{{i}}">Gasse {{VcvAisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='VcvAisleLoop.state' id="arrowVcvSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>

                                                                                        <div [ngClass]="VCVAisles.length > 1 ? 'collapse' : ''"  id="VcvListCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of VCVAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #VCVLessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of VCVAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleVCV>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('VCV')[0])">
                                                                            Heber
                                                                        </a>
                                                                    </ng-template>
                                                                </li>

                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('GDP') && GDPAislesAndThings?.length > 0">
                                                                    <div [ngClass]="GdpState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('GDP').length > 1; else singleGDP">
                                                                        <a class="d-flex align-items-center" href="#GdpAisleCollapse" role="button" data-bs-toggle="collapse" (click)="GdpState = GdpState === 'default' ? 'rotated' : 'default';" data-bs-target="#GdpAisleCollapse" aria-expanded="false" aria-controls="#GdpAisleCollapse">
                                                                            GridPick
                                                                            <svg [@rotatedArrow]='GdpState' id="arrowGDP{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="GdpAisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let GdpAisleLoop of GDPAisles; let i = index">
                                                                                    <div *ngIf="GDPAislesAndThings.length > 1; else GDPLessThanTwo">
                                                                                        <a *ngIf="GDPAisles.length > 1" class="d-flex align-items-center ms-4" href="#GdpListCollapse{{i}}" role="button" (click)="rotate(GdpAisleLoop)" data-bs-toggle="collapse" data-bs-target="#GdpListCollapse{{i}}" aria-expanded="false" aria-controls="#GdpListCollapse{{i}}">Gasse {{GdpAisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='GdpAisleLoop.state' id="arrowGdpSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>

                                                                                        <div [ngClass]="GDPAisles.length > 1 ? 'collapse' : ''"  id="GdpListCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of GDPAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #GDPLessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of GDPAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleGDP>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('GDP')[0])">
                                                                            GridPick
                                                                        </a>
                                                                    </ng-template>
                                                                </li>


                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('CONVER') != null && CONVERAislesAndThings?.length > 0">
                                                                    <a class="d-flex align-items-center" (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('CONVER')[0])">ConVer</a>
                                                                </li>


                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('LSC') && LSCAislesAndThings?.length > 0">
                                                                    <a class="d-flex align-items-center" (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('LSC')[0])">Leuze Scanner</a>
                                                                </li>


                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('SSAGM') && SSAGMAislesAndThings?.length > 0">
                                                                    <a class="d-flex align-items-center" (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('SSAGM')[0])">Speedsorter Allgemein</a>
                                                                </li>

                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('SSDTM') && SSDTMAislesAndThings?.length > 0">
                                                                    <div [ngClass]="SsdtmState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('SSDTM').length > 1; else singleSSDTM">
                                                                        <a class="d-flex align-items-center" href="#SsdtmAisleCollapse" role="button" data-bs-toggle="collapse" (click)="SsdtmState = SsdtmState === 'default' ? 'rotated' : 'default';" data-bs-target="#SsdtmAisleCollapse" aria-expanded="false" aria-controls="#SsdtmAisleCollapse">
                                                                            Speedsorter DTM
                                                                            <svg [@rotatedArrow]='SsdtmState' id="arrowSSDTM{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="SsdtmAisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let SsdtmAisleLoop of SSDTMAisles; let i = index">
                                                                                    <div *ngIf="SSDTMAislesAndThings.length > 1; else SSDTMLessThanTwo">
                                                                                        <a *ngIf="SSDTMAisles.length > 1" class="d-flex align-items-center ms-4" href="#SsdtmListCollapse{{i}}" role="button" (click)="rotate(SsdtmAisleLoop)" data-bs-toggle="collapse" data-bs-target="#SsdtmListCollapse{{i}}" aria-expanded="false" aria-controls="#SsdtmListCollapse{{i}}">Gasse {{SsdtmAisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='SsdtmAisleLoop.state' id="arrowSsdtmSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>

                                                                                        <div [ngClass]="SSDTMAisles.length > 1 ? 'collapse' : ''"  id="SsdtmListCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of SSDTMAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #SSDTMLessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of SSDTMAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleSSDTM>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('SSDTM')[0])">
                                                                            Speedsorter DTM
                                                                        </a>
                                                                    </ng-template>
                                                                </li>

                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('SSIF') && SSIFAislesAndThings?.length  > 0">
                                                                    <div [ngClass]="SsifState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('SSIF').length > 1; else singleSSIF">
                                                                        <a class="d-flex align-items-center" href="#SsifAisleCollapse" role="button" data-bs-toggle="collapse" (click)="SsifState = SsifState === 'default' ? 'rotated' : 'default';" data-bs-target="#SsifAisleCollapse" aria-expanded="false" aria-controls="#SsifAisleCollapse">
                                                                            Speedsorter Infeed
                                                                            <svg [@rotatedArrow]='SsifState' id="arrowSSIF{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="SsifAisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let SsifAisleLoop of SSIFAisles; let i = index">
                                                                                    <div *ngIf="SSIFAislesAndThings.length > 1; else SSIFLessThanTwo">
                                                                                        <a *ngIf="SSIFAisles.length > 1" class="d-flex align-items-center ms-4" href="#SsifListCollapse{{i}}" role="button" (click)="rotate(SsifAisleLoop)" data-bs-toggle="collapse" data-bs-target="#SsifListCollapse{{i}}" aria-expanded="false" aria-controls="#SsifListCollapse{{i}}">Gasse {{SsifAisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='SsifAisleLoop.state' id="arrowSsifSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>

                                                                                        <div [ngClass]="SSIFAisles.length > 1 ? 'collapse' : ''"  id="SsifListCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of SSIFAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #SSIFLessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of SSIFAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleSSIF>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('SSIF')[0])">
                                                                            Speedsorter Infeed
                                                                        </a>
                                                                    </ng-template>
                                                                </li>

                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('SSES') && SSESAislesAndThings?.length > 0">
                                                                    <div [ngClass]="SsesState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('SSES').length > 1; else singleSSES">
                                                                        <a class="d-flex align-items-center" href="#SsesAisleCollapse" role="button" data-bs-toggle="collapse" (click)="SsesState = SsesState === 'default' ? 'rotated' : 'default';" data-bs-target="#SsesAisleCollapse" aria-expanded="false" aria-controls="#SsesAisleCollapse">
                                                                            Speedsorter Endstelle
                                                                            <svg [@rotatedArrow]='SsesState' id="arrowSSES{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="SsesAisleCollapse">
                                                                            <ul>
                                                                                <li *ngFor="let SsesAisleLoop of SSESAisles; let i = index">
                                                                                    <div *ngIf="SSESAislesAndThings.length > 1; else SSESLessThanTwo">
                                                                                        <a *ngIf="SSESAisles.length > 1" class="d-flex align-items-center ms-4" href="#SsesListCollapse{{i}}" role="button" (click)="rotate(SsesAisleLoop)" data-bs-toggle="collapse" data-bs-target="#SsesListCollapse{{i}}" aria-expanded="false" aria-controls="#SsifListCollapse{{i}}">Gasse {{SsifAisleLoop?.aisle}}
                                                                                            <svg [@rotatedArrow]='SsesAisleLoop.state' id="arrowSsesSub{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto me-4" viewBox="0 0 16 16">
                                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </a>

                                                                                        <div [ngClass]="SSESAisles.length > 1 ? 'collapse' : ''"  id="SsesListCollapse{{i}}">
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of SSESAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ng-template #SSESLessThanTwo>
                                                                                        <div>
                                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                                <li style="margin-right: 25px;" *ngFor="let thingInAisle of SSESAislesAndThings?.[i]">
                                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <ng-template  #singleSSES>
                                                                        <a (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('SSES')[0])">
                                                                            Speedsorter Endstelle
                                                                        </a>
                                                                    </ng-template>
                                                                </li>


                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('SSCRR') && CARRIERAislesAndThings?.length > 0">
                                                                    <div [ngClass]="SscrrState==='rotated' ? 'activeSubmenu':''" *ngIf="data?.activeSystem?.allThingTypes.get('SSCRR').length > 1; else singleSSCRR">
                                                                        <a class="d-flex align-items-center" href="#SscrrAisleCollapse" role="button" data-bs-toggle="collapse" (click)="rotateSscrr()" data-bs-target="#SscrrAisleCollapse" aria-expanded="false" aria-controls="#SscrrAisleCollapse">
                                                                            Carrier
                                                                            <svg [@rotatedArrow]='SscrrState' id="arrowSSCRR{{i}}" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-right ms-auto" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </a>
                                                                        <div class="collapse" id="SscrrAisleCollapse">
                                                                            <ul class="ms-4 text-center" style="padding-bottom: 15px;">
                                                                                <li *ngFor="let thingInAisle of CARRIERAislesAndThings | slice:0:currShowThings" style="margin-right: 25px;">
                                                                                    <a (click)="nav.navigateToThing(thingInAisle)">{{thingInAisle.displayName}}</a>
                                                                                </li>

                                                                            </ul>
                                                                            <button (click)="doAddFive()" style="width: 100%; border-width: thin;">Show more...</button>
                                                                        </div>
                                                                    </div>
                                                                </li>


                                                                <li *ngIf="auth.isSilver() && data?.activeSystem?.allThingTypes.has('STOREWARE') && STOREWAREAislesAndThings?.length > 0">
                                                                    <a class="d-flex align-items-center" (click)="nav.navigateToThing(data?.activeSystem?.allThingTypes.get('STOREWARE')[0])">Storeware</a>
                                                                </li>



                                                                <li *ngIf="auth.isPlatinum() && data?.activeSystem?.testdrive != null" routerLinkActive="active">
                                                                    <a (click)="nav.navigateToThing(data.activeSystem.testdrive)">{{'Testfahrt' | translate}}</a>
                                                                </li>
                                                                -->
                            </div>

                            <!--
                            <li style="position: absolute; bottom: -6px;">
                                <input type="checkbox"
                                       [checked]="data.showMonitoring"
                                       (change)="data.showMonitoring = !data.showMonitoring"/>
                            </li>
                            -->
                        </ul>
                    </nav>

                    <div *ngIf="data.customer != null; else loading">
                        <router-outlet id="outlet"></router-outlet>
                    </div>

                    <!-- <app-footer></app-footer> -->
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading [Colour]="'red'" [Text]="'Daten werden abgerufen'"></app-loading>
</ng-template>
