import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MonitoringLevel} from '../../../model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../model/helper-models/limit-size-array.model';
import {DataPoint} from '../../../model/helper-models/datapoint.model';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs/internal/Subscription';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-triple-card',
    templateUrl: './triple-card.component.html',
    styleUrls: ['./triple-card.component.scss']
})
export class TripleCardComponent implements OnInit, OnDestroy
{
    @ViewChild('card', {read: ElementRef}) card: ElementRef;
    @ViewChild('cardfull', {read: ElementRef}) cardfull: ElementRef;

    @Input() Heading: string;
    @Input() groupHeaderHeight = '50px';
    @Input() Error = false;
    @Input() ErrorLevel: MonitoringLevel;
    // Um Enum in der View zu checken
    monitoringLevel = MonitoringLevel;

    @Input() showChart = false;
    @Input() showInfo = false;

    @Input() Legend: string[];
    @Input() Type = 'number';

    @Input() Value: LimitSizeArrayModel<any>[];
    @Input() MaintenanceValue: string;

    private _values;

    @Input()
    get Values(): LimitSizeArrayModel<any>[]
    {
        return this._values;
    }

    set Values(values: LimitSizeArrayModel<any>[])
    {
        for (let i = 0; i < values.length; i++)
        {
            this.chartData.push(values[i].values);

            if (values[i].currentDatapoint != null)
            {
                this.textData.push(values[i].currentDatapoint);
            }
        }
        this._values = values;
    }

    chartData: DataPoint<any>[][] = [];
    textData: DataPoint<any>[] = [];
    @Input() Unit: string;
    @Input() Info: string;

    @Input() customTemplate: TemplateRef<any> = null;

    @Input() resizeEvent: EventEmitter<GridsterItem>;

    resizeSub: Subscription;

    childResizeEvent: EventEmitter<any> = new EventEmitter<any>();

    fontsize = '30px';
    fontsizefull = '30px';
    width = 'auto';
    height = '100%';

    ngOnInit(): void
    {
        if (this.resizeEvent == null) return;
        this.resizeSub = this.resizeEvent.subscribe((widget) =>
        {
            if (this.card != null)
            {
                const fontSizeWidth = this.card.nativeElement.offsetWidth * 0.13;
                this.fontsize = fontSizeWidth + 'px';

                if (this.card.nativeElement.offsetWidth > this.card.nativeElement.offsetHeight)
                {
                    this.width = 'auto';
                    this.height = '100%';
                }
                else
                {
                    this.width = '100%';
                    this.height = 'auto';
                }

                this.childResizeEvent.emit();
            }
            else if (this.cardfull != null)
            {
                const fontSizeFullWidth = this.cardfull.nativeElement.offsetWidth * 0.07;
                this.fontsizefull = fontSizeFullWidth + 'px';

                if (this.cardfull.nativeElement.offsetWidth > this.cardfull.nativeElement.offsetHeight)
                {
                    this.width = 'auto';
                    this.height = '100%';
                }
                else
                {
                    this.width = '100%';
                    this.height = 'auto';
                }
            }

        });
    }

    ngOnDestroy()
    {
        this.resizeSub.unsubscribe();
    }

    protected readonly environment = environment;
}
