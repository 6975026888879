import {Component, Input, OnInit} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {Thing} from '../../../core/model/thing.model';

@Component({
    selector: 'app-ssagm-stat-dtm',
    templateUrl: './ssagm-stat-dtm.component.html',
    styleUrls: ['./ssagm-stat-dtm.component.scss']
})
export class SsagmStatDtmComponent extends BaseViewElement implements OnInit
{

    @Input() DTM: Array<Thing>;

    constructor()
    {
        super('ssagm-stat-dtm');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

}
