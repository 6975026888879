import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-lsc-scan-information',
    templateUrl: './lsc-scan-information.component.html',
    styleUrls: ['./lsc-scan-information.component.scss',
        './../card.scss']
})
export class LscScanInformationComponent extends BaseViewElement implements OnInit, OnChanges
{
    operationModes = new Map<number, string>([[0, 'Untätig'], [1, 'Fehler'], [2, 'Scan'], [3, 'Beschäftigt']]);

    @Input() GeneralInformation: PropertySet;

    AverageReadSinceDelivery: Property<any>;
    AverageReadSinceReset: Property<any>;
    Status: Property<any>;
    Serialnumber: Property<any>;


    constructor()
    {
        super('lsc-scan-information');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.GeneralInformation != null)
        {
            this.AverageReadSinceDelivery = this.GeneralInformation.properties.get('AVERAGE_SINCE_DELIVERY');
            this.AverageReadSinceReset = this.GeneralInformation.properties.get('AVERAGE_SINCE_RESET');
            this.Status = this.GeneralInformation.properties.get('DEVICE_STATUS');
            this.Serialnumber = this.GeneralInformation.properties.get('SERIAL_NUMBER');
        }
    }

}