import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-last-scan',
    templateUrl: './last-scan.component.html',
    styleUrls: ['./last-scan.component.scss',
        './../card.scss']
})
export class LastScanComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() GeneralInformation: PropertySet;

    StartPosition: Property<any>;
    EndPosition: Property<any>;
    ScanQuality: Property<any>;

    constructor()
    {
        super('last-scan');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.GeneralInformation != null)
        {
            this.StartPosition = this.GeneralInformation.properties.get('LAST_SCAN_START_POSITION_DEGREE');
            this.EndPosition = this.GeneralInformation.properties.get('LAST_SCAN_STOP_POSITION_DEGREE');
            this.ScanQuality = this.GeneralInformation.properties.get('LAST_SCAN_BARCODE_QUALITY');
        }
    }
}
