import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-roll-in-out',
    templateUrl: './roll-in-out.component.html',
    styleUrls: ['./roll-in-out.component.scss',
        './../card.scss']
})
export class RollInOutComponent extends BaseViewElement implements OnInit, OnChanges
{

    @ViewChild('card', {read: ElementRef}) card: ElementRef;

    @Input() Statistic: PropertySet;

    RollIn: Property<any>;
    RollOut: Property<any>;
    Relocation: Property<any>;

    width = 'auto';
    height = '100%';
    groupHeaderHeight = '50px';

    constructor()
    {
        super('roll-in-out');
    }

    onResize(result: any)
    {

        if (this.card == null) return;

        if (this.card.nativeElement.offsetWidth > this.card.nativeElement.offsetHeight)
        {
            this.width = 'auto';
            this.height = '100%';
        }
        else
        {
            this.width = '100%';
            this.height = 'auto';
        }

        this.childResizeEvent.emit(result);
    }

    ngOnInit()
    {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Statistic != null)
        {
            this.RollIn = this.Statistic.properties.get('ROLL_IN');
            this.RollOut = this.Statistic.properties.get('ROLL_OUT');
            this.Relocation = this.Statistic.properties.get('RELOCATION');
        }
    }

    protected readonly environment = environment;
}
