<app-group-header [Heading]="'Positionierung' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card *ngIf="XPosition != null" class="card" style="border: none; border-radius: unset;"
                      [Heading]="'X-Position' | translate"
                      [Error]="XPosition?.isError"
                      [ErrorLevel]="XPosition?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[XPosition?.TimeseriesData]"
                      [Legend]="['X-Position' | translate]"
                      [Info]="'Motor X Position'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card *ngIf="YPosition != null" class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Y-Position' | translate"
                      [Error]="YPosition?.isError"
                      [ErrorLevel]="YPosition?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[YPosition?.TimeseriesData]"
                      [Legend]="['Y-Position' | translate]"
                      [Info]="'Motor Y Position'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card *ngIf="ZPosition != null" class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Z-Position' | translate"
                      [Error]="ZPosition?.isError"
                      [ErrorLevel]="ZPosition?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ZPosition?.TimeseriesData]"
                      [Legend]="['Z-Position' | translate]"
                      [Info]="'Motor Z Position'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card *ngIf="LastEnginePosition != null" class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Letzte Motorposition' | translate"
                      [Error]="LastEnginePosition?.isError"
                      [ErrorLevel]="LastEnginePosition?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[LastEnginePosition?.TimeseriesData]"
                      [Unit]="'mm'"
                      [Legend]="['Letzte Motorposition' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card *ngIf="LastEnginePositionZ != null" class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Letzte Motorposition Z' | translate"
                      [Error]="LastEnginePositionZ?.isError"
                      [ErrorLevel]="LastEnginePositionZ?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[LastEnginePositionZ?.TimeseriesData]"
                      [Unit]="'mm'"
                      [Legend]="['Letzte Motorposition' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
