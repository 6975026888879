<app-group-header [Heading]="'Elektrische Leistung' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Spannung' | translate"
                      [Error]="Voltage1?.isError || Voltage2?.isError || Voltage3?.isError"
                      [ErrorLevel]="Voltage1?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Voltage1?.TimeseriesData, Voltage2?.TimeseriesData, Voltage3?.TimeseriesData]"
                      [Unit]="'V'"
                      [Legend]="['Spannung Phase 1' | translate, 'Spannung Phase 2' | translate, 'Spannung Phase 3' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <!--
     <app-default-card *ngIf="this.Currentmeter.properties.get('VOLTAGE_UL1_N') != null" class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Spannung' | translate"
                      [Error]="this.Currentmeter.properties.get('VOLTAGE_UL1_N').isError || this.Currentmeter.properties.get('VOLTAGE_UL2_N').isError || this.Currentmeter.properties.get('VOLTAGE_UL3_N').isError"
                      [ErrorLevel]="this.Currentmeter.properties.get('VOLTAGE_UL1_N').status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[this.Currentmeter.properties.get('VOLTAGE_UL1_N').TimeseriesData, this.Currentmeter.properties.get('VOLTAGE_UL2_N').TimeseriesData, this.Currentmeter.properties.get('VOLTAGE_UL3_N').TimeseriesData]"
                      [Unit]="'V'"
                      [Legend]="['Spannung Phase 1' | translate, 'Spannung Phase 2' | translate, 'Spannung Phase 3' | translate]"

 -->
    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Stromstärke' | translate"
                      [Error]="Current1?.isError || Current2?.isError || Current3?.isError"
                      [ErrorLevel]="Current1?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Current1?.TimeseriesData, Current2?.TimeseriesData, Current3?.TimeseriesData]"
                      [Unit]="'A'"
                      [Legend]="['Stromstärke Phase 1' | translate, 'Stromstärke Phase 2' | translate, 'Stromstärke Phase 3' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>
    <!--
        <app-default-card *ngIf="this.PowerValue != null" class="card" style="border: none; border-radius: unset;"
                          [Heading]="'Leistung' | translate"
                          [Error]="PowerError"
                          [ErrorLevel]="PowerErrorLevel"
                          [showChart]="chart"
                          [showInfo]="info"
                          [Values]="PowerValue"
                          [Unit]="'W'"
                          [Legend]="['Leistung Phase 1' | translate, 'Leistung Phase 2' | translate, 'Leistung Phase 3' | translate]"
                          [resizeEvent]="childResizeEvent"></app-default-card>
                         -->
</div>
