import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Configurations} from '../../../../../configurations';
import {DateService} from '../../../../controller/services/date.service';
import {DataPoint} from '../../../../model/helper-models/datapoint.model';
import {LinechartOptions} from '../../options/linechart-options';

@Component({
    selector: 'app-linechart',
    template:
        `
        <div #chart style="height: 100%; width: auto;" echarts (chartInit)="onChartInit($event)" [options]="options" [merge]="updateOptions"></div>
    `
})
/**
 * Liniendiagramm
 */
export class LinechartComponent implements OnInit, OnDestroy
{
    /**
     * Zweidimensionales Array mit Daten
     */
    @Input() value: Array<Array<DataPoint<number>>>;

    /**
     * Optionen (kann weggelassen werden wenn legend übergeben wird)
     */
    @Input() setOptions: LinechartOptions;

    /**
     * Chart Title (Optional)
     */
    @Input() title: string;

    @Input() unit = '';

    /**
     * Kann anstelle von setOptions übergeben werden um Aufruf zu vereinfachen
     * Benötigt genau soviele Strings wie es Arrays gibt
     */
    @Input() legend: Array<string>;

    /**
     * Wird für TestdriveEvent benötigt
     */
    @Input() confidencebands: any;

    @Input() resizeEvent: EventEmitter<any>;

    chartInstance: any;

    @ViewChild('chart', {read: ElementRef}) chart: ElementRef;

    /**
     * Optionen die an die Chart gebindet werden
     */
    options: any;

    /**
     * Optionen die in jedem Intervall übergeben werden um die Chart upzudaten
     */
    updateOptions: any;

    /**
     * Intervall zum Chart Updaten
     */
    timer: any;

    /**
     * Standardfarben
     */
    public color_default = ['#20818C', '#1BA68C', '#18855A', '#A4D97E', '#6EA61B', '#599225', '#9C9828', '#857129', '#9C6E28', '#924922'];

    /**
     * Neues Farbschema
     */
    public color_new = ['#3EC2CF', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'];

    /**
     * Standard Optionen falls nur die Legende übergeben wird
     */
    standardOptions = {
        title: {
            text: ''
        },
        xAxis: {
            type: 'time',
            show: true,
            splitLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            show: true,
            boundaryGap: [0, '100%'],
            splitLine: {
                show: false
            }
        }
    };

    /**
     * Konstruktor
     * @param Date
     */
    constructor(private Date: DateService)
    {
        Configurations.PullType.subscribe(x =>
        {
            if (x === 'historic')
            {
                this.updateOptions = {series: []};

            }
        });
    }

    onChartInit(e: any)
    {

        const initInterval = setInterval(() =>
        {
            if (this.chart.nativeElement.offsetHeight !== 0)
            {
                this.chartInstance = e;

                if (this.resizeEvent != null)
                {
                    this.resizeEvent.subscribe((x) =>
                    {
                        this.chartInstance.resize(x);
                    });
                }

                clearInterval(initInterval);
            }
        }, 1000);

    }

    /**
     * Setzt Optionen und Initalisiert Chart
     */
    ngOnInit()
    {
        if (this.setOptions == null)
        {
            this.setOptions = new LinechartOptions(null, null);
        }

        if (this.title != null)
        {
            this.setOptions.title = this.title;
        }

        if (this.legend != null)
        {
            this.setOptions.legend = this.legend;
        }

        if (this.value == null)
        {
            this.options = this.standardOptions;
            return;
        }

        const localUnit = this.unit;

        // Setzt Übergebene Optionen (setOptions)
        this.options = {
            title: {
                text: this.setOptions.title
            },

            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(245, 245, 245, 0.8)',
                borderWidth: 1,
                borderColor: '#ccc',
                padding: 10,
                textStyle: {
                    color: '#000'
                },
                position: function (pos, params, el, elRect, size)
                {
                    const obj = {
                        top: 10
                    };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                formatter: function (params)
                {
                    let tooltip = '';
                    tooltip = params[0].data[0].toLocaleString('de-DE') + '<br />';

                    for (const param of params)
                    {
                        tooltip += param.seriesName + ': ' + param.data[1] + ' ' + localUnit + '<br />';
                    }
                    return tooltip;
                },
            },
            legend: {
                left: 'left',
                data: this.setOptions.legend
            },

            xAxis: {
                type: 'time',
                show: true,
                axisLine: {
                    show: true
                },
                axisLabel: {
                    formatter: {
                        year: '{yyyy}',
                        month: '{MMM}',
                        day: '{d}',
                        hour: '{HH}:{mm}',
                        minute: '{HH}:{mm}',
                        second: '{mm}:{ss}',
                        millisecond: '{ss}s',
                        none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss}'
                    }

                },
                axisTick: {
                    alignWithLabel: true
                },
                axisPointer: {
                    label: {
                        show: true,
                        formatter: function (params)
                        {
                            return params.seriesData[0].data[0].toLocaleString('de-DE');
                        }
                    }

                }
            },

            yAxis: {
                type: 'value',
                show: true,
                min: this.setOptions.yMin,
                max: this.setOptions.yMax,
                boundaryGap: [0, '100%'],
                axisLine: {
                    show: true
                }
            },

            dataZoom: [
                {
                    type: 'slider',
                    start: this.setOptions.dataZoomStart,
                    end: this.setOptions.dataZoomEnd,
                    filterMode: 'none',
                    minValueSpan: 5
                },
            ]
        };

        // Erzeugt series im options Objekt anhand des übergebenen value Arrays
        this.options.series = this.createSeries();

        let newOptions = {series: []};

        if (this.value == null)
        {
            return;
        }

        let res = this.parseAllData(this.value);

        res.forEach(arr =>
        {
            arr.forEach(newData =>
            {
                newOptions.series.push(
                    {
                        type: 'line',
                        data: newData
                    });
            });
        });

        // add confidencebands to the series
        if (this.confidencebands != null)
        {
            // uncomment for additional info in the console
            // console.log('confidencebands in linechartscomponent');
            // console.log(JSON.parse(this.confidencebands.data));
            const tmpConfidenceBand = JSON.parse(this.confidencebands.data);
            const minSeries = [];
            const maxSeries = [];
            let minIterator = 0;
            tmpConfidenceBand.valuesMin.forEach(element =>
            {
                minSeries.push([newOptions.series[0].data[minIterator][0], element.value]);
                minIterator++;
            });
            let maxIterator = 0;
            tmpConfidenceBand.valuesMax.forEach(element =>
            {
                // maxSeries.push([newOptions.series[0].data[maxIterator][0], element.value - Math.abs(minSeries[maxIterator][1])]);
                maxSeries.push([newOptions.series[0].data[maxIterator][0], element.value]);
                maxIterator++;
            });

            newOptions.series.push({
                name: 'Lower Threshold',
                data: minSeries,
                color: 'red',
                type: 'line',
                smooth: true,
                symbol: 'none',
                lineStyle: {
                    normal: {
                        opacity: 100,
                        type: 'dotted'
                    }
                }
            });
            newOptions.series.push({
                name: 'Upper Threshold',
                data: maxSeries,
                color: 'red',
                type: 'line',
                smooth: true,
                symbol: 'none',
                lineStyle: {
                    normal: {
                        opacity: 100,
                        type: 'dotted'
                    }
                }
            });
        }

        this.updateOptions = newOptions;

        this.timer = setInterval(() =>
        {
            if (this.Date.auto)
            {
                newOptions = {series: []};

                if (this.value == null) return;

                res = this.parseAllData(this.value);

                res.forEach(arr =>
                {
                    arr.forEach(newData =>
                    {
                        newOptions.series.push(
                            {
                                type: 'line',
                                data: newData
                            });
                    });
                });


                // add confidencebands to the series
                if (this.confidencebands != null)
                {
                    // uncomment for additional info in the console
                    // console.log('confidencebands in linechartscomponent');
                    // console.log(JSON.parse(this.confidencebands.data));
                    const tmpConfidenceBand = JSON.parse(this.confidencebands.data);
                    const minSeries = [];
                    const maxSeries = [];
                    let minIterator = 0;
                    tmpConfidenceBand.valuesMin.forEach(element =>
                    {
                        minSeries.push([newOptions.series[0].data[minIterator][0], element.value]);
                        minIterator++;
                    });
                    let maxIterator = 0;
                    tmpConfidenceBand.valuesMax.forEach(element =>
                    {
                        // maxSeries.push([newOptions.series[0].data[maxIterator][0], element.value - Math.abs(minSeries[maxIterator][1])]);
                        maxSeries.push([newOptions.series[0].data[maxIterator][0], element.value]);
                        maxIterator++;
                    });

                    newOptions.series.push({
                        name: 'Lower Threshold',
                        data: minSeries,
                        color: 'red',
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        lineStyle: {
                            normal: {
                                opacity: 100,
                                type: 'dotted'
                            }
                        }
                    });
                    newOptions.series.push({
                        name: 'Upper Threshold',
                        data: maxSeries,
                        color: 'red',
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        lineStyle: {
                            normal: {
                                opacity: 100,
                                type: 'dotted'
                            }
                        }
                    });
                }

                // this.updateOptions = {series: []};

                this.updateOptions = newOptions;

            }
            else
            {
                clearInterval(this.timer);
            }
        }, Configurations.chartUpdateTime);
    }

    /**
     * Löscht Daten von Chart und stoppt den Timer
     */
    ngOnDestroy()
    {
        this.value = [];
        this.options = this.standardOptions;
        this.setOptions = new LinechartOptions(null, null);
        this.updateOptions = {series: []};
        clearInterval(this.timer);
    }

    /**
     * Wandelt einen DataPoint in ein Objekt um das von der Linechart angenommen wird.
     */
    private parseData<T>(pDataPoint: DataPoint<T>)
    {
        return [pDataPoint.timestamp, pDataPoint.value];
    }

    /**
     * Erzeugt neue Series Objekte in options anhand der Länge des übergebenen Arrays.
     * Wandelt ein Array von Arrays mit parseData() um.
     */
    private parseAllData(pConnectedArray: Array<Array<DataPoint<number>>>): Array<Array<any>>
    {
        let tmp = [];
        const res = [];

        pConnectedArray.forEach(arr =>
        {
            arr.forEach(datapoint =>
            {
                tmp.push(this.parseData(datapoint));
            });
            res.push(new Array<any>(tmp));
            tmp = [];
        });

        return res;
    }

    /**
     * Unterteilt das zweidimensionale Array in Format für die Linechart
     * Wird zum initalisieren benötigt.
     */
    private createSeries(): any
    {
        const tmpOptions = [];

        if (this.value == null)
        {
            return;
        }

        const res = this.parseAllData(this.value);

        for (let i = 0; i < res.length; i++)
        {
            res[i].forEach(newData =>
            {
                tmpOptions.push(
                    {
                        name: this.setOptions.legend[i],
                        color: this.color_new[i],
                        animation: false,
                        type: 'line',
                        showSymbol: false,
                        data: newData,
                        markLine:
                            {
                                silent: true,
                                data: []
                            }
                    });
            });
            if (this.setOptions.upperUpperThreshold != null)
            {
                tmpOptions[i].markLine.data.push(
                    {
                        name: 'Höchstwert',
                        yAxis: this.setOptions.upperUpperThreshold
                    });
            }
            if (this.setOptions.upperThreshold != null)
            {
                tmpOptions[i].markLine.data.push(
                    {
                        name: 'Oberer Schwellwert',
                        yAxis: this.setOptions.upperThreshold
                    });
            }
            if (this.setOptions.lowerThreshold != null)
            {
                tmpOptions[i].markLine.data.push(
                    {
                        name: 'Unterer Schwellwert',
                        yAxis: this.setOptions.lowerThreshold
                        // ,color: color_critical);
                    });
            }
            if (this.setOptions.lowerLowerThreshold != null)
            {
                tmpOptions[i].markLine.data.push(
                    {
                        name: 'Tiefstwert',
                        yAxis: this.setOptions.lowerLowerThreshold
                        // ,color: color_critical);
                    });
            }
        }

        return tmpOptions;
    }
}
