<div class="row">

    <div class="col-lg-12 subheading">
        <h3>{{path}}</h3>
    </div>

    <!--Baut Navigation für den Report-->
    <div class="col-1"
         style="padding-right: 0; padding-left: 0; background-color: white; border-right: 1px solid lightgray;">
        <ul #rootNav style="list-style-type: none; padding-left: 0;">
            <li *ngFor="let firstLevelNode of root?.nodes" class="dropend">

                <div *ngIf="firstLevelNode.nodes.length === 0; else firstLevel">
                    <a (click)="navigateToReport(firstLevelNode.value)"><span>{{firstLevelNode.displayName}}</span></a>
                </div>

                <ng-template #firstLevel>
                    <a tabindex="-1" (click)="clickSubmenu($event)"><span>{{firstLevelNode.displayName}}
                        <img [src]="environment.storageUrl + '/icons/right.png'"/></span></a>
                    <ul class="dropdown-menu">
                        <li *ngFor="let secondLevelNode of firstLevelNode?.nodes" class="dropend">

                            <div class="ifdiv" *ngIf="secondLevelNode.nodes.length === 0; else secondLevel">
                                <a (click)="navigateToReport(secondLevelNode.value)"><span>{{secondLevelNode.displayName}}</span></a>
                            </div>

                            <ng-template #secondLevel>
                                <a class="test" tabindex="-1"
                                   (click)="clickSubmenu($event)"><span>{{secondLevelNode.displayName}} <img
                                        [src]="environment.storageUrl + '/icons/right.png'"/></span></a>
                                <ul class="dropdown-menu">

                                    <li *ngFor="let thirdLevelNode of secondLevelNode?.nodes" class="dropend">

                                        <div class="ifdiv" *ngIf="thirdLevelNode.nodes.length === 0; else thirdLevel">
                                            <a (click)="navigateToReport(thirdLevelNode.value)"><span>{{thirdLevelNode.displayName}}</span></a>
                                        </div>

                                        <ng-template #thirdLevel>
                                            <a class="test" tabindex="-1"
                                               (click)="clickSubmenu($event)"><span>{{thirdLevelNode.displayName}} <img
                                                    [src]="environment.storageUrl + '/icons/right.png'"/></span></a>
                                            <ul class="dropdown-menu">

                                                <li *ngFor="let fourthLevelNode of thirdLevelNode?.nodes"
                                                    class="dropend">

                                                    <div class="ifdiv"
                                                         *ngIf="fourthLevelNode.nodes.length === 0; else fourthLevel">
                                                        <a (click)="navigateToReport(fourthLevelNode.value)"><span>{{fourthLevelNode.displayName}}</span></a>
                                                    </div>

                                                    <ng-template #fourthLevel>
                                                        <a class="test" tabindex="-1"
                                                           (click)="clickSubmenu($event)"><span>{{fourthLevelNode.displayName}}
                                                            <img [src]="environment.storageUrl + '/icons/right.png'"/></span></a>
                                                        <ul class="dropdown-menu">

                                                            <li *ngFor="let fifthLevelNode of fourthLevelNode?.nodes"
                                                                class="dropend">

                                                                <div class="ifdiv"
                                                                     *ngIf="fifthLevelNode.nodes.length === 0; else fifthLevel">
                                                                    <a (click)="navigateToReport(fifthLevelNode.value)"><span>{{fifthLevelNode.displayName}}</span></a>
                                                                </div>

                                                                <ng-template #fifthLevel>
                                                                    <a class="test" tabindex="-1"
                                                                       (click)="clickSubmenu($event)"><span>{{fifthLevelNode.displayName}}
                                                                        <img [src]="environment.storageUrl + '/icons/right.png'"/></span></a>
                                                                    <ul class="dropdown-menu">
                                                                        <li *ngFor="let sixthLevelNode of fifthLevelNode?.nodes"
                                                                            class="dropend">

                                                                            <div class="ifdiv"
                                                                                 *ngIf="sixthLevelNode.nodes.length === 0; else sixthLevel">
                                                                                <a (click)="navigateToReport(sixthLevelNode.value)"><span>{{sixthLevelNode.displayName}}</span></a>
                                                                            </div>

                                                                            <ng-template #sixthLevel>
                                                                                <a class="test" tabindex="-1"
                                                                                   (click)="clickSubmenu($event)"><span>{{sixthLevelNode.displayName}}
                                                                                    <img src="/assets/icons/right.png"/></span></a>
                                                                                <ul class="dropdown-menu">

                                                                                    <li *ngFor="let sevenLevelNode of sixthLevelNode?.nodes"
                                                                                        class="dropend">

                                                                                        <div class="ifdiv"
                                                                                             *ngIf="sevenLevelNode.nodes.length === 0; else sevenLevel">
                                                                                            <a (click)="navigateToReport(sevenLevelNode.value)"><span>{{sevenLevelNode.displayName}}</span></a>
                                                                                        </div>

                                                                                        <ng-template #sevenLevel>
                                                                                            <a class="test" tabindex="-1"
                                                                                               (click)="clickSubmenu($event)"><span>{{sevenLevelNode.displayName}}
                                                                                                <img src="/assets/icons/right.png"/></span></a>
                                                                                            <ul class="dropdown-menu">
                                                                                                <li><a href="#">should not be visible</a></li>
                                                                                            </ul>
                                                                                        </ng-template>
                                                                                    </li>
                                                                                </ul>
                                                                            </ng-template>
                                                                        </li>
                                                                    </ul>
                                                                </ng-template>
                                                            </li>
                                                        </ul>
                                                    </ng-template>
                                                </li>
                                            </ul>
                                        </ng-template>
                                    </li>
                                </ul>
                            </ng-template>
                        </li>
                    </ul>
                </ng-template>
            </li>
        </ul>
    </div>

    <div class="col-11" style="padding-right: 0; padding-left: 0;">
        <div id="pbi-containerNew">
            <powerbi-report class="report" [embedConfig]="config"
                            [phasedEmbedding]="false"
                            [eventHandlers]="ContainerEvents">
            </powerbi-report>
        </div>
    </div>
</div>

<ng-template #oldPB>
    <div class="row">
        <div class="col-12" style="padding-right: 0; padding-left: 0;">
            <div id="pbi-containerOld"></div>
        </div>
    </div>
</ng-template>
