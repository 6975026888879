<div class="row">
    <div class="col-lg-12 subheading">
        <h3>Carrier Übersicht</h3>
    </div>

    <div *ngFor="let sscrr of Carrier; let i=index" class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
        <div *ngIf="i >= startIndex && i < endIndex">
            <app-card-component [thing]="sscrr"></app-card-component>
        </div>
    </div>

    <div class="col-12">

        <ul class="pagination" style="margin-top: 20px;">
            <li>
                <button class="btn btn-outline-secondary" [disabled]="previousPageDisable"
                        [class.disabled]="previousPageDisable" (click)="navigate(previousPage)" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="visually-hidden">Previous</span>
                </button>
            </li>
            <li *ngFor="let number of maxPages" style="padding-left: 1px; padding-right: 1px">
                <button class="btn" [class.btn-secondary]="previousPage + 1 === number"
                        [class.btn-outline-secondary]="previousPage + 1 !== number"
                        (click)="navigate(number)">{{number}}</button>
            </li>
            <li class="">
                <button [disabled]="nextPageDisable" [class.disabled]="nextPageDisable" (click)="navigate(nextPage)"
                        class="btn btn-outline-secondary" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="visually-hidden">Next</span>
                </button>
            </li>
        </ul>

    </div>
</div>
