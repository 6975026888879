<app-group-header [Heading]="'Status' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Betriebsdauer' | translate"
                      [Error]="BETRIEBSDAUER?.isError"
                      [ErrorLevel]="BETRIEBSDAUER?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[BETRIEBSDAUER?.TimeseriesData]"
                      [Unit]="'h'"
                      [Legend]="['Betriebsdauer' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Einschaltdauer' | translate"
                      [Error]="EINSCHALTDAUER?.isError"
                      [ErrorLevel]="EINSCHALTDAUER?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[EINSCHALTDAUER?.TimeseriesData]"
                      [MaintenanceValue]="EINSCHALTDAUER?.ValueLastService"
                      [Unit]="'h'"
                      [Legend]="['Einschaltdauer' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Ladezyklen' | translate"
                      [Error]="LADEZYKLEN?.isError"
                      [ErrorLevel]="LADEZYKLEN?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[LADEZYKLEN?.TimeseriesData]"
                      [MaintenanceValue]="LADEZYKLEN?.ValueLastService"
                      [Unit]="''"
                      [Legend]="['LADEZYKLEN' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Kapazität' | translate"
                      [Error]="KAPAZITAET?.isError"
                      [ErrorLevel]="KAPAZITAET?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[KAPAZITAET?.TimeseriesData]"
                      [MaintenanceValue]="KAPAZITAET?.ValueLastService"
                      [Unit]="'F?'"
                      [Legend]="['Kapazität' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>
</div>
