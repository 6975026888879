import {Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Configurations} from '../configurations';
import {DataProviderService} from '../core/controller/services/data-provider.service';
import {DateService} from '../core/controller/services/date.service';
import {NavigationService} from '../core/controller/services/navigation.service';
import {Company} from '../core/model/company.model';
import {DataPoint} from '../core/model/helper-models/datapoint.model';
import {LimitSizeArrayModel} from '../core/model/helper-models/limit-size-array.model';
import {CLocation} from '../core/model/location.model';
import {CSystem} from '../core/model/system.model';
import {Thing} from '../core/model/thing.model';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {CompactType, DisplayGrid, GridsterConfig, GridsterItem, GridType} from 'angular-gridster2';
import {LayoutService} from '../core/controller/services/layout.service';
import {UserSettingsService} from '../core/controller/services/user-settings.service';


@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseThing implements OnInit, OnDestroy
{
    @Input() thing: Thing;

    public horizontalDate: any;
    public verticalDate: any;

    public company: Company;
    public location: CLocation;
    public system: CSystem;

    private subscription: Subscription;

    public options: GridsterConfig;
    public dashboard: Array<GridsterItem>;
    public resizeEvent: EventEmitter<GridsterItem> = new EventEmitter<GridsterItem>();

    private init = false;

    private _loggerPrefix = '[BaseThing] ';

    private saveLayoutTrigger = null;


    private tmpUserLayout;
    private tmpGeneralLayout;

    constructor(public route: ActivatedRoute,
                private router: Router,
                public nav: NavigationService,
                public data: DataProviderService,
                public date: DateService,
                public translate: TranslateService,
                public layout: LayoutService,
                public user: UserSettingsService)
    {
        if (this.translate.onLangChange == null) return;
        this.subscription = this.translate.onLangChange.subscribe(() =>
        {
            //location.reload();
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event)
    {
        this.resizeEvent.emit({cols: -1, rows: -1, y: -1, x: -1, type: 'all'});
    }


    ngOnInit()
    {
        const tmp = [];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        Configurations.InitaliseComplete.subscribe(async x =>
        {
            if (x)
            {
                this.nav.setActiveCustomerDetails();
                this.thing = this.data.pullThing<Thing>(this.route.snapshot.params.id);
                this.setMetaInfos();




                    this.options = {
                        gridType: GridType.ScrollVertical,
                        maxCols: 25,
                        fixedColWidth: 50,
                        fixedRowHeight: 50,
                        outerMarginBottom: 20,
                        compactType: CompactType.CompactUp,
                        displayGrid: DisplayGrid.Always,
                        pushItems: true,
                        disablePushOnDrag: false,
                        pushResizeItems: true,
                        swap: true,
                        swapWhileDragging: true,
                        draggable: {
                            delayStart: 100,
                            enabled: false
                        },
                        resizable: {
                            enabled: true,
                            handles: {n: false, e: true, s: true, w: true, ne: false, nw: false, se: true, sw: true}
                        },
                        initCallback: () =>
                        {
                            this.init = true;
                        },
                        itemResizeCallback: (item) =>
                        {
                            if (this.init)
                            {
                                // update DB with new size
                                // send the update to widgets
                                this.resizeEvent.emit(item);
                            }
                        },
                    };


                try
                {
                    if (this.dashboard == null)
                    {
                        // workaround deep copy
                        const basicLayout = JSON.parse(JSON.stringify(this.thing.layout));
                        this.dashboard = [];

                        this.dashboard = basicLayout as Array<GridsterItem>;

/*

                        let userLayout;
                        if (this.user.User != null)
                        {
                            userLayout = this.user.User.customLayouts.find(thing => thing.thingId === this.thing.id);
                        }

                        if (userLayout == null)
                        {
                            this.dashboard = basicLayout as Array<GridsterItem>;
                        }
                        else
                        {
                            this.dashboard = userLayout.layout as Array<GridsterItem>;
                        }

                        console.log(this.dashboard);

                        if (userLayout != null)
                        {
                            this.tmpUserLayout = JSON.stringify(userLayout.layout.sort((a, b) => a.type.localeCompare(b.type)));
                        }
                        this.tmpGeneralLayout = JSON.stringify(this.dashboard.sort((a, b) => a.type.localeCompare(b.type)));
*/
                    }
                } catch (e)
                {
                    console.log(e);
                }

            }
        });
    }


    /**
     * Schlechter Workaround. Bei Möglichkeit Refactoren.
     * Timeout wird verwendet um abzuwarten bis alle itemChangeCallbacks ausgeführt wurden.
     * Am Ende wird das User Layout gespeichert.
     */
    saveLayout()
    {
        if (this.saveLayoutTrigger != null) return;

        this.saveLayoutTrigger = 1;

        setTimeout(() =>
        {
            this.layout.setLayout(this.thing.id, this.user.User.azureId, this.dashboard).then();
            this.saveLayoutTrigger = null;
        }, 100);
    }

    /***
     * Setzt die Company, Location und System anhand des Things der Komponente
     */
    setMetaInfos()
    {
        if (this.thing == null)
        {
            console.log(this._loggerPrefix + 'thing is undefined!!!');
            return;
        }

        this.data.customer.forEach(company =>
        {
            company.locations.forEach(location =>
            {
                location.systems.forEach(system =>
                {
                    const thingFound = system.getAllThings().findIndex(thing => thing.id === this.thing.id) > -1;
                    if (thingFound)
                    {
                        this.company = company;
                        this.location = location;
                        this.system = system;
                    }
                });
            });
        });
    }

    /**
     * Holt das Datum des letzten Timestamps im Array
     * @param pSet
     */
    public getDate(pSet: LimitSizeArrayModel<DataPoint<any>>): string
    {
        return this.data.getTestdriveTimestamp(pSet);
    }

    public async resetLayout(value: string)
    {
        this.dashboard = this.thing.layout as Array<GridsterItem>;
    }

    /**
     * Stoppt timer und löscht Daten von aktuellem thing
     */
    ngOnDestroy()
    {
        console.log('on destroy');
        this.subscription.unsubscribe();

        this.data.destroyComponent(this.thing);
        this.thing.lastPull = null;
    }
}
