import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HelpPageComponent} from '../../help-page.component';
import {environment} from '../../../../../../environments/environment';

@Component({
    selector: 'app-help-page-menu-navigation',
    templateUrl: './help-page-menu-navigation.component.html',
    styleUrls: ['./help-page-menu-navigation.component.scss', '../../helpPageModule.scss']
})
export class HelpPageMenuNavigationComponent extends HelpPageComponent implements OnInit
{
    urlLanguage: string;

    constructor(public translate: TranslateService)
    {
        super(translate);
    }

    ngOnInit(): void
    {
        this.urlLanguage = this.translate.currentLang;
    }
    public readonly environment = environment;
}
