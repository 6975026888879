import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MonitoringGatewayComponent} from './monitoring-gateway/monitoring-gateway.component';
import {AdminGuard} from '../../core/controller/guards/admin.guard';


const routes: Routes = [
    {path: 'gateway', component: MonitoringGatewayComponent, canActivate: [AdminGuard]},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule
{
}
