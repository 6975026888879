import { Injectable } from '@angular/core';
import {OperationState, Thing} from '../../model/thing.model';
import {environment} from '../../../../environments/environment';
import {lastValueFrom} from 'rxjs/internal/lastValueFrom';
import {AuthenticationService} from './authentication.service';
import {HttpClient} from '@angular/common/http';
import {PropertySet} from '../../model/helper-models/property-set.model';

@Injectable({
  providedIn: 'root'
})
export class ControlLightService {

  controlCabinets = new Map<string, Thing>();
  constructor(private _auth: AuthenticationService, private _http: HttpClient) { }

  async getStatus(things: Thing[])
  {
    for (const thing of things)
    {
      if (thing.controlCabinet.id != null)
      {
        if (!this.controlCabinets.has(thing.controlCabinet.id))
        {
          this.controlCabinets.set(thing.controlCabinet.id, thing.controlCabinet);
        }
      }
    }

    console.log(this.controlCabinets);

    const url = environment.apiUrl + environment.apiVersion;
    const header = await this._auth.getDefaultHttpHeaders();


    for (const controlCabinetId of this.controlCabinets.keys())
    {
      const result = await lastValueFrom(this._http.get(url + '/thing/snapshot?ThingId=' + controlCabinetId + '&PropertySet=CONTROL_LIGHT', {headers: header})) as any;
      if (result['sensor']['CONTROL_LIGHT'] != null)
      {
        const lights = {'CONTROL_LIGHT': result['sensor']['CONTROL_LIGHT']};

        this.controlCabinets.get(controlCabinetId).insertData(new Map(Object.entries(lights)));

        if (lights.CONTROL_LIGHT['RED'][0].value === 1)
        {
          this.controlCabinets.get(controlCabinetId).status = OperationState.STOP;
          continue;
        }
        else if (lights.CONTROL_LIGHT['ORANGE'][0].value === 1)
        {
          this.controlCabinets.get(controlCabinetId).status = OperationState.YELLOWSTATE;
          continue;
        }
        else if (lights.CONTROL_LIGHT['BLUE'][0].value === 1)
        {
          this.controlCabinets.get(controlCabinetId).status = OperationState.HAND;
          continue;
        }
        else if (lights.CONTROL_LIGHT['GREEN'][0].value === 1)
        {
          this.controlCabinets.get(controlCabinetId).status = OperationState.AUTOMATIC;
          continue;
        }
        else if (lights.CONTROL_LIGHT['WHITE'][0].value === 1)
        {
          this.controlCabinets.get(controlCabinetId).status = OperationState.UNDEFINED;
          continue;
        }

        this.controlCabinets.get(controlCabinetId).status = OperationState.UNDEFINED;
      }
    }


  }
}
