import {BehaviorSubject} from 'rxjs';
import {DataPoint} from './datapoint.model';
import {LimitSizeArrayModel} from './limit-size-array.model';
import {Property} from './property.model';
import {MonitoringLevel} from '../MonitoringLevels.enum';

/**
 * Abstrakte Klasse für ein PropertySet
 */
export class PropertySet
{
    /**
     * Name des Propertie Sets
     * Anhand dieses Namens wird die Abfrage ans AE gestellt
     */
    public name: string;

    public isError: boolean;

    /**
     * lastPullDate ist der Zeitpunkt an dem das letzte mal Daten für dieses
     * PropertySet geholt wurden.
     * Beim Initalisieren ist der Wert null.
     */
    public lastPullDate: Date;

    /**
     * Ermöglicht das Manuelle setzen einer Timerange zum pullen der Daten
     */
    public initPullTimerange = 86400;

    /**
     * Status wie schwerwiegend es ist wenn keine Daten für das PropertySet kommen
     */
    public status: MonitoringLevel = MonitoringLevel.Error;

    /**
     * Gibt an ob Status angezeigt werden soll (wenn keine Daten kommen)
     */
    public statusActive = false;

    /**
     * Event das jedesmal ausgelöst wird wenn neue Daten geholt wurden
     */
    public dataPulled = new BehaviorSubject<boolean>(false);

    /**
     *
     */
    public maxLengthProperties = 0;

    /**
     * Alle Properites des PropertySets
     */
    public properties: Map<string, Property<any>>;

    public constructor(pName: string)
    {
        this.name = pName;
        this.properties = new Map<string, Property<any>>();
    }

    public insertData(pValue: any)
    {
        const propertieMap = new Map(Object.entries(pValue));

        propertieMap.forEach((value: any, key: string) =>
        {
            if (!this.properties.has(key))
            {
                this.properties.set(key, new Property<any>(1000));
            }

            this.properties.get(key).insertData(value.reverse());
        });
    }

    /**
     * Methode zum einfügen der geholten Daten in die einzelnen Properties.
     * Vergleicht die Keys im PropertySet mit den Keys der geholten Daten.
     * Sind die Keys gleich werden die neuen Daten in das richtige Property im PropertySet gepushed.
     * @param pCallback
     */
    public setProperties(pCallback: any): void
    {
        const propsetKeys = Object.keys(this);

        pCallback.value.reverse();

        pCallback.value.forEach((x: any) =>
        {
            const callbackKeys = Object.keys(x);

            const availableKeys = propsetKeys.filter(key => callbackKeys.indexOf(key) >= 0);

            availableKeys.forEach(key =>
            {
                if (x[key] != null)
                {
                    if (!isNaN(x[key]) && typeof x[key].toFixed === 'function' && x[key] % 1 !== 0)
                    {
                        x[key] = x[key].toFixed(2);
                    }
                    this[key].TimeseriesData.pushDataPoint(new DataPoint(x[key], new Date(x['_time'])));
                }

            });
        });
    }

    /**
     * Deserialisiert eine durch ':' getrennte Liste in einem String
     * Wird bei StoreWare verwendet
     * @param pList
     */
    public deboxList(pList: LimitSizeArrayModel<DataPoint<string>>)
    {
        const valueList = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);
        for (const datapoint of pList.values)
        {
            if (datapoint.value == null) continue;
            const datapointValues = datapoint.value.split(':');
            const someList = new Array<DataPoint<string>>();
            for (const item of datapointValues)
            {
                someList.push(new DataPoint(item, datapoint.timestamp));
            }
            valueList.pushDataPoint(someList);
        }

        return valueList;
    }

}
