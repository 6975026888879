<app-group-header [Heading]="'Zylinder' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Maximale Position des Zylinders der Klappe' | translate"
                      [Error]="MaxCylinderPosition?.isError"
                      [ErrorLevel]="MaxCylinderPosition?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[MaxCylinderPosition?.TimeseriesData]"
                      [Legend]="['Maximale Position des Zylinders der Klappe' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Minimale Position des Zylinders der Klappe' | translate"
                      [Error]="MinCylinderPosition?.isError"
                      [ErrorLevel]="MinCylinderPosition?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[MinCylinderPosition?.TimeseriesData]"
                      [Legend]="['Minimale Position des Zylinders der Klappe' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Startzeitpunkt der Messung' | translate"
                      [Error]="TimestampStart?.isError"
                      [ErrorLevel]="TimestampStart?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TimestampStart?.TimeseriesData]"
                      [Type]="'date'"
                      [Legend]="['Startzeitpunkt der Messung' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Endzeitpunkt der Messung' | translate"
                      [Error]="TimestampStop?.isError"
                      [ErrorLevel]="TimestampStop?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TimestampStop?.TimeseriesData]"
                      [Type]="'date'"
                      [Legend]="['Endzeitpunkt der Messung' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
