<div class="row">
    <div class="col-12">
        <div class="d-flex justify-content-center" style="margin: 50px;">
            <div [class.text-danger]="Colour === 'red'"
                 [class.text-success]="Colour === 'green'"
                 class="spinner-border" style="width: 10rem; height: 10rem;"
                 role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
    <div class="col-12 text-center">
        <span style="font-size: 30px;">
            {{Text | translate}}
        </span>
    </div>
</div>
