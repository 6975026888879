import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-ssdtm-info',
    templateUrl: './ssdtm-info.component.html',
    styleUrls: ['./ssdtm-info.component.scss', './../card.scss']
})
export class SsdtmInfoComponent extends BaseViewElement implements OnChanges
{
    @Input() Motor: PropertySet;
    @Input() LedPanel: PropertySet;

    RealWork: Property<any>;
    LEDWork: Property<any>;
    OperatingHours: Property<any>;

    constructor()
    {
        super('ssdtm-info');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Motor != null)
        {
            this.RealWork = this.Motor.properties.get('WIRKARBEIT');
            this.OperatingHours = this.Motor.properties.get('BETRIEBSSTUNDEN');
        }

        if (this.LedPanel != null)
        {
            this.LEDWork = this.LedPanel.properties.get('FARBE');
        }
    }

}
