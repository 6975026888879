import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-bot-volcurtemp',
    templateUrl: './bot-volcurtemp.component.html',
    styleUrls: ['./bot-volcurtemp.component.scss',
        './../card.scss']
})
export class BotVolcurtempComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{

    @Input() ActualData: PropertySet;

    STROM: Property<any>;
    SPANNUNG: Property<any>;
    TEMPERATUR: Property<any>;

    constructor()
    {
        super('bot-volcurtemp');
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.ActualData != null)
        {
            this.STROM = this.ActualData.properties.get('STROM');
            this.SPANNUNG = this.ActualData.properties.get('SPANNUNG');
            this.TEMPERATUR = this.ActualData.properties.get('TEMPERATUR');
        }
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

}
