import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';


import {RouterModule, Routes} from '@angular/router';
import {HelpStartPageComponent} from './help-start-page/help-start-page.component';
import {HelpPageLogInOutComponent} from './introduction/help-page-log-in-out/help-page-log-in-out.component';
import {HelpPageDashboardComponent} from './equipmentGeneral/help-page-dashboard/help-page-dashboard.component';
import {BannerModule} from '../../controller/modules/banner.module';
import {HelpPageMenuNavigationComponent} from './introduction/help-page-menu-navigation/help-page-menu-navigation.component';
import {HelpPageFavoriteComponent} from './introduction/help-page-favorite/help-page-favorite.component';
import {HelpPageBannerComponent} from './help-page-banner/help-page-banner.component';


import {HelpPageShortcutComponent} from './introduction/help-page-shortcut/help-page-shortcut.component';
import {HelpPageGraphicsComponent} from './introduction/help-page-graphics/help-page-graphics.component';
import {
    HelpPageActivityThingMonitorComponent
} from './introduction/help-page-activity-thing-monitor/help-page-activity-thing-monitor.component';
import {HelpPageLinkCostomerPortalComponent} from './introduction/help-page-link-costomer-portal/help-page-link-costomer-portal.component';
import {HelpPageSetDateComponent} from './introduction/help-page-set-date/help-page-set-date.component';
import {HelpPageTestdriveComponent} from './equipmentGeneral/help-page-testdrive/help-page-testdrive.component';
import {HelpPageHealthPageComponent} from './equipmentGeneral/help-page-health-page/help-page-health-page.component';
import {HelpPageGebhardtInsightComponent} from './equipmentGeneral/help-page-gebhardt-insight/help-page-gebhardt-insight.component';
import {MonitoringModule} from '../../controller/modules/monitoring.module';
import {
    HelpPageFrequentQuestionsComponent
} from './frequestQuestContact/help-page-frequent-questions/help-page-frequent-questions.component';
import {HelpPageContactComponent} from './frequestQuestContact/help-page-contact/help-page-contact.component';
import {
    HelpPageEquipmentConverComponent
} from './equipment/help-page-eqipment/help-page-equipment-conver/help-page-equipment-conver.component';
import {TranslateModule} from '@ngx-translate/core';
import {HelpPageEquipmentMlsComponent} from './equipment/help-page-eqipment/help-page-equipment-mls/help-page-equipment-mls.component';
import {HelpPageEquipmentCthComponent} from './equipment/help-page-eqipment/help-page-equipment-cth/help-page-equipment-cth.component';
import {HelpPageEquipmentLscComponent} from './equipment/help-page-eqipment/help-page-equipment-lsc/help-page-equipment-lsc.component';
import {HelpPageEquipmentOlsComponent} from './equipment/help-page-eqipment/help-page-equipment-ols/help-page-equipment-ols.component';
import {HelpPageEquipmentOlseComponent} from './equipment/help-page-eqipment/help-page-equipment-olse/help-page-equipment-olse.component';
import {HelpPageEquipmentRbgComponent} from './equipment/help-page-eqipment/help-page-equipment-rbg/help-page-equipment-rbg.component';
import {HelpPageEquipmentVcvComponent} from './equipment/help-page-eqipment/help-page-equipment-vcv/help-page-equipment-vcv.component';


// import { HelpPageComponent } from './help-page.component';


const helpPageRouten: Routes = [
    {path: 'HelpPage', component: HelpStartPageComponent},
    {path: 'HelpPage/Introduction/Log_in_Log_out', component: HelpPageLogInOutComponent},
    {path: 'HelpPage/Introduction/Menu_navigation', component: HelpPageMenuNavigationComponent},
    {path: 'HelpPage/Introduction/Favorites', component: HelpPageFavoriteComponent},
    {path: 'HelpPage/Introduction/Shortcuts', component: HelpPageShortcutComponent},
    {path: 'HelpPage/Introduction/Graphics', component: HelpPageGraphicsComponent},
    {path: 'HelpPage/Introduction/Activity_Thing_Monitor', component: HelpPageActivityThingMonitorComponent},
    {path: 'HelpPage/Introduction/Link_customer_portal', component: HelpPageLinkCostomerPortalComponent},
    {path: 'HelpPage/Introduction/Set_date', component: HelpPageSetDateComponent},
    {path: 'HelpPage/Equipment_general/Dashboard', component: HelpPageDashboardComponent},
    {path: 'HelpPage/Equipment_general/Testdrive', component: HelpPageTestdriveComponent},
    {path: 'HelpPage/Equipment_general/Health_Page', component: HelpPageHealthPageComponent},
    {path: 'HelpPage/Equipment_general/GEBHARDT_Insight', component: HelpPageGebhardtInsightComponent},
    {path: 'HelpPage/Frequent_questions_&_contact/Frequent_questions', component: HelpPageFrequentQuestionsComponent},
    {path: 'HelpPage/Frequent_questions_&_contact/Contact', component: HelpPageContactComponent},
    // --- //
    {path: 'HelpPage/Equipment/CONVER', component: HelpPageEquipmentConverComponent},
    {path: 'HelpPage/Equipment/CTH', component: HelpPageEquipmentCthComponent},
    {path: 'HelpPage/Equipment/LSC', component: HelpPageEquipmentLscComponent},
    {path: 'HelpPage/Equipment/MLS', component: HelpPageEquipmentMlsComponent},
    {path: 'HelpPage/Equipment/OLS_OLSE', component: HelpPageEquipmentOlsComponent},
    //{path: 'HelpPage/Equipment/OLSE', component: HelpPageEquipmentOlseComponent},
    {path: 'HelpPage/Equipment/RBG', component: HelpPageEquipmentRbgComponent},
    {path: 'HelpPage/Equipment/VCV', component: HelpPageEquipmentVcvComponent},


    // --- //
];


@NgModule({
    declarations: [
        HelpStartPageComponent,
        HelpPageLogInOutComponent,
        HelpPageDashboardComponent,
        HelpPageMenuNavigationComponent,
        HelpPageFavoriteComponent,
        HelpPageBannerComponent,
        HelpPageShortcutComponent,
        HelpPageGraphicsComponent,
        HelpPageActivityThingMonitorComponent,
        HelpPageLinkCostomerPortalComponent,
        HelpPageSetDateComponent,
        HelpPageTestdriveComponent,
        HelpPageHealthPageComponent,
        HelpPageGebhardtInsightComponent,
        HelpPageFrequentQuestionsComponent,
        HelpPageContactComponent,
        HelpPageEquipmentConverComponent,
        HelpPageEquipmentMlsComponent,
        HelpPageEquipmentCthComponent,
        HelpPageEquipmentLscComponent,
        HelpPageEquipmentOlsComponent,
        HelpPageEquipmentOlseComponent,
        HelpPageEquipmentRbgComponent,
        HelpPageEquipmentVcvComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(helpPageRouten),
        BannerModule,
        MonitoringModule,
        TranslateModule,
    ],
    providers: []
})
export class HelpPageModule
{
}
