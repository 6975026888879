<app-group-header [Heading]="'Schmiersystem' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Pumpstöße Gesamt' | translate"
                      [Error]="PumpsAll?.isError"
                      [ErrorLevel]="PumpsAll?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[PumpsAll?.TimeseriesData]"
                      [Legend]="['Pumpstöße Gesamt' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Pumpstöße Kartusche' | translate"
                      [Error]="PumpsCartridge?.isError"
                      [ErrorLevel]="PumpsCartridge?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[PumpsCartridge?.TimeseriesData]"
                      [Legend]="['Pumpstöße Kartusche' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Aktuelle Einstellung Schmierzyklen' | translate"
                      [Error]="LubricationCycles?.isError"
                      [ErrorLevel]="LubricationCycles?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[LubricationCycles?.TimeseriesData]"
                      [Unit]="'m'"
                      [Legend]="['Schmierzyklen' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


</div>
