import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-ssdtm-lubrication-cartridge',
    templateUrl: './ssdtm-lubrication-cartridge.component.html',
    styleUrls: ['./ssdtm-lubrication-cartridge.component.scss', './../card.scss']
})
export class SsdtmLubricationCartridgeComponent extends BaseViewElement implements OnChanges
{
    @Input() Schmiersystem: PropertySet;

    AmountCartridgeChange: Property<any>;
    DateCartridgeChange: Property<any>;
    OilLevel: Property<any>;
    EmptyMessage: Property<any>;
    WarningNearlyEmpty: Property<any>;

    constructor()
    {
        super('ssdtm-lubrication-cartridge');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Schmiersystem == null) return;
        this.AmountCartridgeChange = this.Schmiersystem.properties.get('ANZ_KARTUSCHENWECHSEL');
        this.DateCartridgeChange = this.Schmiersystem.properties.get('DATUM_KARTUSCHENWECHSEL_STR');
        this.OilLevel = this.Schmiersystem.properties.get('KARTUSCHEN_OELSTAND');
        this.EmptyMessage = this.Schmiersystem.properties.get('LEERMELDUNG');
        this.WarningNearlyEmpty = this.Schmiersystem.properties.get('WARNUNG_FAST_LEER');
    }

}
