import {LimitSizeArrayModel} from './helper-models/limit-size-array.model';
import {CSystem} from './system.model';
import {PropertySet} from './helper-models/property-set.model';
import {Property} from './helper-models/property.model';
import {BehaviorSubject} from 'rxjs';
import {MonitoringLevel} from './MonitoringLevels.enum';
import {Configurations} from '../../configurations';
import {DataPoint} from './helper-models/datapoint.model';
import { environment } from '../../../environments/environment';

export class Thing
{
    public id: string;
    public name: string;
    public parentSystem: CSystem;
    public thingType: string;
    public aisle: string;
    public level: string;
    public model3D: string;
    public displayName: string;
    public equipmentNumber: string;
    public layoutPosition: string;
    public serialNumber: string;
    public location: string;
    public system: string;
    public controlCabinet: Thing;
    public orderNumber: string;
    public ainUrl: string;
    public propertySets: Map<string, PropertySet>;
    public isReplacement = false;

    // Zeitpunkt der Inbetriebnahme als unix timestamp
    public installation: number;

    public layout: any;

    public isFavorite = false;
    public lastMaintenance: string = null;
    public type = 'THING';
    public icon = environment.storageUrl + '/img/production.png';

    // propertySets die überwacht werden und vorhanden sein müssen
    public monitoredPropertieSets: any = {};
    // propertySets die nicht mehr aktuell sind
    public outdatedProperties = new BehaviorSubject<Array<any>>(null);

    public lastPull: Date;

    public errorCount = 0;
    public warningCount = 0;
    public infoCount = 0;
    public isObserved = false;

    public status: OperationState = OperationState.UNDEFINED;

    public typeName = 'Unknow Typename of thing';

    constructor(pId: string, pName: string, pType, pAisle: string, pLevel: string, pModel3D: string, pDisplayName: string,
                pEquipment: string, pLayoutPosition: string, pSerialNumber: string,
                pLocation: string, pSystem: string, pOrderNumber: string, pAinUrl: string, pInstallation: number, pLayout: any, pMonitoredProperties: any)
    {
        this.id = pId;
        this.name = pName;
        this.type = pType;
        this.aisle = pAisle;
        this.level = pLevel;
        this.model3D = pModel3D;
        this.displayName = pDisplayName;
        this.equipmentNumber = pEquipment;
        this.layoutPosition = pLayoutPosition;
        this.serialNumber = pSerialNumber;
        this.location = pLocation;
        this.system = pSystem;
        this.orderNumber = pOrderNumber;
        this.propertySets = new Map<string, PropertySet>();
        this.installation = pInstallation;
        this.layout = pLayout;
        this.monitoredPropertieSets = pMonitoredProperties;

        if (pAinUrl != null)
        {
            // ToDo Entfernen der Hardgecodeten if abfragen.
            if (this.system === 'Logimat 2019' || this.serialNumber === '40071659')
            {
                this.ainUrl = pAinUrl + '//?tab=indicator';
            }
            else
            {
                this.ainUrl = pAinUrl;
            }
        }

        if (this.type != null)
        {
            this.icon = environment.storageUrl + '/img/' + this.type + '.png';
        }
    }


    public insertData(pValues: Map<string, any>)
    {
        pValues.forEach((value: any, key: string) =>
        {
            if (!this.propertySets.has(key))
            {
                this.propertySets.set(key, new PropertySet(key));
            }
            this.propertySets.get(key).insertData(value);
        });
    }

    /**
     * Befüllt die einzelnen propertySets mit historischen Daten
     * @param pValues
     */
    public pushHistoricValues(pValues: Map<string, any>)
    {

        this.propertySets.clear();

        for (const key of Object.keys(pValues))
        {
            this.propertySets.set(key, new PropertySet(key));

            for (const propKey of Object.keys(pValues[key]))
            {
                const limArr = new LimitSizeArrayModel<DataPoint<any>>(pValues[key][propKey].maxLength);
                limArr.values = pValues[key][propKey];
                
                
                this.propertySets.get(key).properties.set(propKey,
                    new Property<any>(pValues[key][propKey].maxLength, MonitoringLevel.Error, limArr));

            }

            this.propertySets.get(key).dataPulled.next(true);
        }
    }

    /**
     * Fügt alle propertySets die gemonitored werden sollen einer Liste hinzu
     * @param pObj
     */
    public setMonitoredPropertieSets(pObj: any)
    {
        for (const val of pObj.propertySets)
        {
            if (val.status === MonitoringLevel.None) continue;

            pObj.monitoredPropertieSets[val.name] = {};

            const props = Object.keys(val);

            for (const prop of props)
            {
                if (val[prop] instanceof Property)
                {
                    pObj.monitoredPropertieSets[val.name][prop] = '';
                }
            }
        }
    }

    /**
     * Löscht alle Daten in den Arrays
     */
    public clearSets()
    {
        this.propertySets.forEach((proSet: PropertySet) =>
        {
            proSet.lastPullDate = null;
            proSet.properties.forEach((pro) =>
            {
                pro.TimeseriesData.clear();
            });
        });
    }

    /**
     * Setzt die Anzahl der maximalen Daten höher
     */
    public setHistoric()
    {
        this.propertySets.forEach((proSet: PropertySet) =>
        {
            proSet.properties.forEach((prop) =>
            {
                prop.TimeseriesData.clear();
            });
        });
    }

    /**
     * Setzt die Anzahl der maximalen Daten auf den Wert der beim erstellen
     * des propertySets hinterlegt wurde
     */
    public setLive()
    {

        this.propertySets.forEach((value: PropertySet, key: string) =>
        {
            value.lastPullDate = null;
            value.properties.forEach((prop) =>
            {
                prop.TimeseriesData = new LimitSizeArrayModel(prop.maxDataCount);
            });
        });

    }

    /**
     * Gibt die Namen aller PropertySets wieder
     */
    public getPropertySetNames(): Array<string>
    {
        const arr = [];

        this.propertySets.forEach(x => arr.push(x.name));

        return arr;
    }

    /**
     * Gibt die Keys aller PropertySets wieder
     */
    public getPropertySetsKeyNames(): Array<string>
    {
        const arr = [];

        const keys = Object.keys(this);

        keys.forEach(key =>
        {
            if (this[key] instanceof PropertySet)
            {
                arr.push(key);
            }
        });

        return arr;
    }

    public calcDistanceValue(propSet: any)
    {
        let propSub;
        let setSub;

        Configurations.PullType.subscribe(x =>
        {
            if (x === 'live' || x == null)
            {
                if (setSub != null) setSub.unsubscribe();
                propSub = propSet.KILOMETER.TimeseriesData.valuePushed.subscribe(() =>
                {
                    if (propSet.KILOMETER.TimeseriesData.values.length === 0) return;
                    propSet.DistanceCalculated.TimeseriesData.pushDataPoint(new DataPoint<number>
                    (propSet.KILOMETER.TimeseriesData.currentDatapoint.value +
                        (propSet.METER.TimeseriesData.currentDatapoint.value / 1000),
                        propSet.KILOMETER.TimeseriesData.currentDatapoint.timestamp));
                });
            }
            else if (x === 'historic')
            {
                if (propSub != null) propSub.unsubscribe();
                setSub = propSet.dataPulled.subscribe(() =>
                {
                    for (let i = 0; i < propSet.KILOMETER.TimeseriesData.values.length; i++)
                    {
                        propSet.DistanceCalculated.TimeseriesData.pushDataPoint(new DataPoint<number>
                        (propSet.KILOMETER.TimeseriesData.values[i].value +
                            (propSet.METER.TimeseriesData.values[i].value / 1000),
                            propSet.KILOMETER.TimeseriesData.values[i].timestamp));
                    }
                });
            }
        });
    }
}


export class OperationState {
    static readonly UNDEFINED  = new OperationState('UNDEFINED', '#f3f3f3');
    static readonly STOP = new OperationState('STOP', 'lightcoral');
    static readonly HAND  = new OperationState('HAND', 'lightblue');
    static readonly YELLOWSTATE  = new OperationState('YELLOWSTATE', 'lightyellow');
    static readonly AUTOMATIC  = new OperationState('AUTOMATIC', 'lightgreen');

    // private to disallow creating other instances of this type
    private constructor(private readonly key: string, public readonly value: any) {
    }

    toString() {
        return this.key;
    }

    setState(code: number): OperationState
    {
        switch (code)
        {
            case 1:
                return OperationState.STOP;
            case 2:
                return OperationState.HAND;
            case 3:
                return OperationState.YELLOWSTATE;
            case 4:
                return OperationState.AUTOMATIC;
            default:
                return OperationState.UNDEFINED;
        }
    }
}

// LINKED INTERFACES: iThing
export interface iThing
{
    /**
     * thing: The subjected thing
     */
    thing: Thing;

    /**
     * contains: Things contained within the things
     */
    contains?: Array<iThing>[];
}
