<div #head class="card-header-custom">
    <ul class="nav nav-pills card-header-pills">
        <li class="nav-item">
            {{Heading}}
        </li>
        <li class="nav-item ms-auto">
            <div class="btn-group" role="group">
                <a *ngIf="!noText" (click)="setText()" [class.active]="!chart && !info"
                   class="btn btn-light btn-sm rounded-0">
                    <img [src]="environment.storageUrl + '/icons/title_black_18dp.svg'"/>
                </a>
                <a *ngIf="!noChart" (click)="setChart()" [class.active]="chart && !info"
                   class="btn btn-light btn-sm rounded-0">
                    <img [src]="environment.storageUrl + '/icons/show_chart_black_18dp.svg'"/>
                </a>
                <a *ngIf="!noInfo" (click)="setInfo()" [class.active]="info" class="btn btn-light btn-sm rounded-0">
                    <img [src]="environment.storageUrl + '/icons/outline_contact_support_black_48dp.png'">
                </a>
            </div>
        </li>
    </ul>
</div>
