<app-group-header [Heading]="'Fehlermeldungen' | translate"
                  [chart]="chart"
                  [noChart]="true"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Error]="StatusCode?.isError"
                      [ErrorLevel]="StatusCode?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [customTemplate]="custom"
                      [ChartType]="'table'"
                      [Info]="'ID: Nummer der Fehlermeldung.\n'+
                                'Text: Beschreibung der Fehlermeldung.\n'+
                                'Motor 1/ Motor 2: Fehlernummer am Motor.\n'+
                                'Zeit: Zeitpunkt, zu dem der Fehler aufgetreten ist.'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <ng-template #custom>
        <ng-template *ngIf="StatusCode != null; else noData">
            <table *ngIf="pullType !== 'historic';" class="table" style="overflow-y: auto">
                <thead>
                <tr>
                    <th class="table-spacing-auto">{{'ID' | translate}}</th>
                    <th class="table-spacing-auto">{{'TEXT' | translate}}</th>
                    <th class="table-spacing-auto">{{'Motor1' | translate}}</th>
                    <th class="table-spacing-auto">{{'Motor2' | translate}}</th>
                    <th class="table-spacing-auto">{{'Zeit' | translate}}</th>
                </tr>
                </thead>
                <tbody *ngIf="StatusCode.TimeseriesData.currentDatapoint != null; else noFaults">
                <!--Array wird nochmal gedreht das die neusten Daten immer oben stehen-->
                <tr *ngFor="let id of StatusCode.TimeseriesData.values; let i = index">
                    <td *ngIf="StatusCode.TimeseriesData.values > 0 != null" class="table-spacing-auto">
                        {{StatusCode.TimeseriesData.values[StatusCode.TimeseriesData.values.length - i - 1].value}}
                    </td>
                    <td *ngIf="StatusText.TimeseriesData.values > 0 != null" class="table-spacing-auto">
                        {{StatusText.TimeseriesData.values[StatusText.TimeseriesData.values.length - i - 1].value}}
                    </td>
                    <td *ngIf="DM1Code.TimeseriesData.values > 0 != null" class="table-spacing-auto">
                        {{DM1Code.TimeseriesData.values[DM1Code.TimeseriesData.values.length - i - 1].value}}
                    </td>
                    <td *ngIf="DM3Code.TimeseriesData.values > 0 != null" class="table-spacing-auto">
                        {{DM3Code.TimeseriesData.values[DM3Code.TimeseriesData.values.length - i - 1].value}}
                    </td>
                    <td *ngIf="StatusCode.TimeseriesData.values > 0 != null" class="table-spacing-auto">
                        {{StatusCode.TimeseriesData.values[StatusCode.TimeseriesData.values.length - i - 1].timestamp | date: 'long'}}
                    </td>
                </tr>
                </tbody>

                <ng-template #noFaults>
                    <div class="tablePlaceholdText">
                        {{'Keine Probleme' | translate}}
                    </div>
                </ng-template>

            </table>
        </ng-template>
    </ng-template>
</div>


<ng-template #noData>
    <app-placeholder-card class="card" style="border: 0;"></app-placeholder-card>
</ng-template>
