<style>
    .ng-select .ng-select-container .ng-value-container {
        border: none !important;
    }
</style>

<div class="row" id="headerRoot">
    <div class="col-lg-5 col-md-5 col-sm-12 col-6 verticalAlignText">
        <h2>
            <span class="headerText headerGeb">GEBHARDT </span>
            <span class="headerText">Galileo IoT</span>
        </h2>
    </div>

    <div class="col-lg-1">
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-6 verticalAlignText right" style="border-top: none !important;">

        <ng-select *ngIf="dropdownLang.length != 0" class="custom" style="border-top: none !important;"
                   [searchable]="false"
                   [clearable]="false"
                   [items]="dropdownLang"
                   (change)="switchLanguage()"
                   [(ngModel)]="selectedLanguage">
            <ng-template ng-label-tmp let-item="item" style="border-top: none !important;">
                <img style="border-top: none !important;" height="25" width="25"
                     [src]="environment.storageUrl + '/icons/flag'+item+'.svg'"/> {{item}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" style="border-top: none !important;">
                <img style="border-top: none !important;" height="25" width="25"
                     [src]="environment.storageUrl + '/icons/flag'+item+'.svg'"/> {{item}}
            </ng-template>
        </ng-select>

        <div *ngIf="data.activeCompany != null">
            <img *ngIf="data?.activeCompany?.LogoPath !== ''" [src]=" environment.storageUrl + data?.activeCompany?.LogoPath | safe" class="customerImg"
                alt="Kunden Logo"/>
        </div>
        <button [routerLink]="['/HelpPage']" routerLinkActive="active"
                class="btn btn-sm"> {{'Hilfe' | translate}} </button>
        <button (click)="logout()" class="btn btn-sm">{{'Abmelden' | translate}}</button>
    </div>
</div>
