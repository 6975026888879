import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HubConnection} from '@microsoft/signalr';
import {Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {DataProviderService} from './data-provider.service';
import jwtDecode from "jwt-decode";

const signalR = require('@microsoft/signalr');

@Injectable({
    providedIn: 'root'
})
export class EventService
{

    private readonly _http: HttpClient;
    // private readonly _baseUrl: string = 'http://localhost:7071/api';
    private readonly _baseUrl: string = environment.apiUrl + environment.apiVersion;
    private hubConnection: HubConnection;
    messages: Subject<string> = new Subject();

    constructor(http: HttpClient, private auth: AuthenticationService, private data: DataProviderService)
    {
        this._http = http;
    }

    private getConnectionInfo(header): Observable<SignalRConnectionInfo>
    {
        const requestUrl = `${this._baseUrl}/negotiate`;
        const tokenNoBearer = header.get('authorization').replace('Bearer ', '');
        let newHeader = header.set('authorization', tokenNoBearer);

        // For local debugging, in live it will be overwritten by a Microsoft Service
        const decoToken = jwtDecode(tokenNoBearer) as any;
        newHeader = newHeader.set('x-ms-client-principal-id', decoToken['oid']);

        return this._http.get<SignalRConnectionInfo>(requestUrl, {headers: newHeader});
    }

    init(header)
    {
        this.getConnectionInfo(header).subscribe((info) =>
        {
            const options = {
                accessTokenFactory: () => info.accessToken,
            };
            console.log(info);
            console.log(info.url);
            console.log(options);

            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(info.url, options)
                .configureLogging(signalR.LogLevel.Information)
                .build();

            this.hubConnection.start()
                .then(() =>
                {
                    console.log('connected to event service');
                })
                .catch((err) =>
                {
                    console.error(err.toString());
                });

            this.hubConnection.on('notify', (data: any) =>
            {
                console.log('notifyEvent thrown');
                console.log(data);
            });

            this.hubConnection.on('deviceEvent', (data: any) =>
            {
                console.log('deviceEvent thrown');
                console.log(data);
                //const thing = this.data.things.find(x => x.id === data.id );
                //thing.status = data.status;
            });

            this.hubConnection.on('newValue', (data: any) =>
            {
                console.log('newvalue thrown');
                console.log(data);
            });

            this.hubConnection.on('controllight', (data: any) =>
            {
                console.log('controllight thrown');
                console.log(data);
            });

        });
    }

    send(message: string): Observable<void>
    {
        const requestUrl = `${this._baseUrl}/messages`;
        return this._http.post(requestUrl, message).pipe(map((result: any) =>
        {
        }));
    }

    receieve(message: Message): Message[]
    {
        // read in from local strorage
        const messages = this.load();
        messages.unshift(message);
        localStorage.setItem('messages', JSON.stringify(messages));
        return messages;
    }

    load(): Message[]
    {
        const messagesLocal = localStorage.getItem('messages');
        let messagesResponse = [];
        if (messagesLocal !== null)
        {
            messagesResponse = JSON.parse(messagesLocal);
        }
        return messagesResponse;
    }

    clear(): Observable<void>
    {
        const messagesLocal = localStorage.getItem('messages');
        const messagesResponse = [];
        if (messagesLocal !== null)
        {
            localStorage.setItem('messages', JSON.stringify(messagesResponse));
        }
        return of(null);
    }

}

class SignalRConnectionInfo
{
    endpoint: string;
    accessToken: string;
    url: string;
}

class Message
{
    sender: string;
    body: string;
}
