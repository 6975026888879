import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from '../../../controller/services/navigation.service';
import {DataProviderService} from '../../../controller/services/data-provider.service';

@Component({
    selector: 'app-carrier-overview',
    templateUrl: './carrier-overview.component.html',
    styleUrls: ['./carrier-overview.component.scss']
})
export class CarrierOverviewComponent implements OnInit
{

    Carrier;
    Page = 1;

    startIndex = 0;
    endIndex = 0;
    nextPage = 0;
    previousPage = 0;
    maxPages = [];

    nextPageDisable;
    previousPageDisable;

    constructor(public route: ActivatedRoute,
                public router: Router,
                public nav: NavigationService,
                public data: DataProviderService)
    {
    }

    ngOnInit(): void
    {
        if (this.data.activeSystem == null) return;
        this.Carrier = this.data.activeSystem.allThingTypes.get('SSCRR');

        this.Carrier.sort((x, y) =>
        {
            const xNum = +x.displayName.split(' ')[1];
            const yNum = +y.displayName.split(' ')[1];

            if (xNum > yNum)
            {
                return 1;
            }
            else if (xNum < yNum)
            {
                return -1;
            }
            else
            {
                return 0;
            }

        });

        this.calcNewIndex();

        this.maxPages = Array(Math.round(this.Carrier.length / 60)).fill(null).map((x, i) => i + 1);


        this.route.params.subscribe(params =>
        {
            this.calcNewIndex();
        });
    }

    calcNewIndex()
    {

        this.previousPageDisable = false;
        this.nextPageDisable = false;
        this.Page = +this.route.snapshot.params.page;


        if (this.Carrier == null) return;

        if (this.Carrier.length > 0)
        {
            this.startIndex = 60 * (this.Page - 1);
            this.endIndex = this.startIndex + 60;

            this.nextPage = this.Page + 1;
            this.previousPage = this.Page - 1;

            if (this.previousPage <= 0) this.previousPageDisable = true;
            if (this.Carrier.length < this.endIndex) this.nextPageDisable = true;
        }
    }

    navigate(value: number)
    {
        this.router.navigateByUrl(this.nav.buildSystemUrl(this.data.activeSystem) + '/CarrierOverview/' + value);
    }

}
