<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>

<div class="container">
    <div>
        <h2>VCV</h2>
        <div>
            <h3 class="subheading" id="VertikaleStrecke">{{'Vertikale Strecke' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/VCV/verticalDistance_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Vertikale Strecke: '| translate}}</strong>{{'Strecke, die der Heber in Kilometern schon zurückgelegt hat.'| translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Einlagerungen">{{'Einlagerungen' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/VCV/storages_{{urlLanguage}}.png"
                 width="100%">
        </div>

        <div style="margin-top: 30px; margin-bottom: 100px;">
            <h3 class="subheading" id="CmSensorDaten">{{'CM Sensor Daten' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/VCV/cmSensors_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px; margin-bottom: 100px;">
                <li class="list-group-item">
                    <strong>{{'CM Sensor: ' | translate}}</strong>{{'Daten zu dem Condition Monitoring Sensor.' | translate}}
                </li>
            </ul>
        </div>
    </div>
</div>



