<app-group-header [Heading]="'Statistik' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Ausschleusungen' | translate"
                      [Error]="AmountOutfeed?.isError"
                      [ErrorLevel]="AmountOutfeed?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AmountOutfeed?.TimeseriesData]"
                      [Legend]="['Anzahl Ausschleusungen' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Endstelle Voll' | translate"
                      [Error]="AmountOutfeedFull?.isError"
                      [ErrorLevel]="AmountOutfeedFull?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AmountOutfeedFull?.TimeseriesData]"
                      [Legend]="['Anzahl Endstelle Voll' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Crash Anzahl' | translate"
                      [Error]="CrashCount?.isError"
                      [ErrorLevel]="CrashCount?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[CrashCount?.TimeseriesData]"
                      [Legend]="['Crash Anzahl' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>
</div>
