<button
    mat-button
    (mouseenter)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
    (mouseleave)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
    (click)="openDialog(node)"
    *ngIf="option === 'default'"
>
    <button
        *ngIf="node.data.live_aggregation.alerting.length > 0 || node.data.live_aggregation.warning.length > 0"
        mat-flat-button
        color="{{node.color}}"
        class="d-inline-block align-items-center"
    >
                <span class="d-inline-block">
                    <mat-icon
                        class="mat-icon-rtl-mirror vertical-center-icon"
                    >
                        error
                    </mat-icon>
                </span>
        <span class="d-inline-block">
                    {{node.data.live_aggregation.alerting.length}}
                </span>
        <span class="d-inline-block">
                    <mat-icon
                        class="mat-icon-rtl-mirror vertical-center-icon"
                    >
                        warning
                    </mat-icon>
                </span>
        <span class="d-inline-block">
                    {{node.data.live_aggregation.warning.length}}
                </span>
    </button>

    <div
        color="primary"
        class="d-inline-block align-items-center bold-black-text"
    >{{node.name}}</div>
</button>

<button
    mat-button
    (mouseenter)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
    (mouseleave)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
    (click)="openDialog(node)"
    *ngIf="option === 'onlyButton'"
>
    <button
        *ngIf="node.data.live_aggregation.alerting.length > 0 || node.data.live_aggregation.warning.length > 0"
        mat-flat-button
        color="{{node.color}}"
        class="d-inline-block align-items-center"
    >
                <span class="d-inline-block">
                    <mat-icon
                        class="mat-icon-rtl-mirror vertical-center-icon"
                    >
                        error
                    </mat-icon>
                </span>
        <span class="d-inline-block">
                    {{node.data.live_aggregation.alerting.length}}
                </span>
        <span class="d-inline-block">
                    <mat-icon
                        class="mat-icon-rtl-mirror vertical-center-icon"
                    >
                        warning
                    </mat-icon>
                </span>
        <span class="d-inline-block">
                    {{node.data.live_aggregation.warning.length}}
                </span>
    </button>

</button>

<button
    mat-button
    (mouseenter)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
    (mouseleave)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
    (click)="openDialog(node)"
    *ngIf="option === 'onlyName'"
>
    <div
        color="primary"
        class="d-inline-block align-items-center bold-black-text"
    >{{node.name}}</div>
</button>


<!--CARD Information-->
<app-health-overview
    [node]="node"
    *ngIf="!isHealthDialogCardHidden"
></app-health-overview>
