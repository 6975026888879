<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Carrier' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Carrier' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>


<app-sscrr-statistic *ngIf="widget === 'sscrr-statistic'"
                     [resizeEvent]="resizeEvent"
                     [Statistic]="Thing.propertySets.get('STATISTIK')">
</app-sscrr-statistic>


<app-sscrr-cylinder *ngIf="widget === 'sscrr-cylinder'"
                    [resizeEvent]="resizeEvent"
                    [ZylinderStatus]="Thing.propertySets.get('ZYLINDER_STATUS')">
</app-sscrr-cylinder>
