import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FtsComponent} from './fts/fts.component';
import {HcvComponent} from './hcv/hcv.component';
import {MlsComponent} from './mls/mls.component';
import {OlsComponent} from './ols/ols.component';
import {RbgComponent} from './rbg/rbg.component';
import {VcvComponent} from './vcv/vcv.component';
import {BotComponent} from './bot/bot.component';

const routes: Routes = [

    {path: 'ols/:id', component: OlsComponent},
    {path: 'mls/:id', component: MlsComponent},
    {path: 'fts/:id', component: FtsComponent},
    {path: 'rbg/:id', component: RbgComponent},
    {path: 'vcv/:id', component: VcvComponent},
    {path: 'hcv/:id', component: HcvComponent},
    {path: 'bot/:id', component: BotComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SilverRoutingModule
{
}
