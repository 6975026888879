import {Component} from '@angular/core';

@Component({
    selector: 'app-placehold-barchart',
    templateUrl: './placehold-barchart.component.html',
    styleUrls: ['./placehold-barchart.component.scss']
})
/**
 * Platzhalter wenn in einer Barchart keine Daten angekommen sind
 */
export class PlaceholdBarchartComponent
{
}
