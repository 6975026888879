import {Component} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
    selector: 'app-prbg-subcomp-parent',
    templateUrl: './prbg-subcomp-parent.component.html',
    styleUrls: ['./prbg-subcomp-parent.component.scss']
})
export class PrbgSubcompParentComponent extends BaseSubcomp
{

}
