<app-group-header [Heading]="'Fahrwerk KM' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Motor Kilometer' | translate"
                      [Error]="MOTOR_GEFAHRENE_KILOMETER?.isError"
                      [ErrorLevel]="MOTOR_GEFAHRENE_KILOMETER?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[MOTOR_GEFAHRENE_KILOMETER?.TimeseriesData]"
                      [Legend]="['Motor Kilometer' | translate]"
                      [Unit]="'km'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Lenkrolle Kilometer' | translate"
                      [Error]="LENKROLLE_GEFAHRENE_KM?.isError"
                      [ErrorLevel]="LENKROLLE_GEFAHRENE_KM?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[LENKROLLE_GEFAHRENE_KM?.TimeseriesData]"
                      [Legend]="['Lenkrolle Kilometer' | translate]"
                      [Unit]="'km'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Schwerlastantriebsrat Kilometer' | translate"
                      [Error]="SCHWERLAST_ANTRIEBSRAT_GEFAHRENE_KM?.isError"
                      [ErrorLevel]="SCHWERLAST_ANTRIEBSRAT_GEFAHRENE_KM?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[SCHWERLAST_ANTRIEBSRAT_GEFAHRENE_KM?.TimeseriesData]"
                      [Legend]="['Schwerlastantriebsrat Kilometer' | translate]"
                      [Unit]="'km'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Gebrauchsdauer Kilometer' | translate"
                      [Error]="GEBRAUCHSDAUER_KM?.isError"
                      [ErrorLevel]="GEBRAUCHSDAUER_KM?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[GEBRAUCHSDAUER_KM?.TimeseriesData]"
                      [Legend]="['Gebrauchsdauer Kilometer' | translate]"
                      [Unit]="'km'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Flachgetriebe Kilometer' | translate"
                      [Error]="FLACHGETRIEBE_KM?.isError"
                      [ErrorLevel]="FLACHGETRIEBE_KM?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[FLACHGETRIEBE_KM?.TimeseriesData]"
                      [Legend]="['Flachgetriebe Kilometer' | translate]"
                      [Unit]="'km'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
