import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-fts-energy',
    templateUrl: './fts-energy.component.html',
    styleUrls: ['./fts-energy.component.scss',
        './../card.scss']
})
export class FtsEnergyComponent extends BaseViewElement implements OnInit, OnChanges
{

    @Input() EnergyManagement: PropertySet;

    CountLadezyklen: Property<any>;
    CountSteckzyklen: Property<any>;
    CountLadezyklenStromabnehmer: Property<any>;

    constructor()
    {
        super('fts-energy');
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.EnergyManagement != null)
        {
            this.CountLadezyklen = this.EnergyManagement.properties.get('ANZAHL_LADEZYKLEN');
            this.CountSteckzyklen = this.EnergyManagement.properties.get('ANZAHL_STECKZYKLEN');
            this.CountLadezyklenStromabnehmer = this.EnergyManagement.properties.get('ANZAHL_LADEZYKLEN_STROMABNEHMER');
        }
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

}
