import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-current-voltage',
    templateUrl: './current-voltage.component.html',
    styleUrls: ['./current-voltage.component.scss',
        '../card.scss']
})
export class CurrentVoltageComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{
    @Input() Currentmeter: PropertySet;

    Voltage1: Property<any>;
    Voltage2: Property<any>;
    Voltage3: Property<any>;

    Current1: Property<any>;
    Current2: Property<any>;
    Current3: Property<any>;

    constructor()
    {
        super('current-voltage');
        this.chart = true;
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {

        if (this.Currentmeter != null)
        {

            this.Voltage1 = this.Currentmeter.properties.get('VOLTAGE_UL1_N');
            this.Voltage2 = this.Currentmeter.properties.get('VOLTAGE_UL2_N');
            this.Voltage3 = this.Currentmeter.properties.get('VOLTAGE_UL3_N');

            this.Current1 = this.Currentmeter.properties.get('AMPERAGE_L1');
            this.Current2 = this.Currentmeter.properties.get('AMPERAGE_L2');
            this.Current3 = this.Currentmeter.properties.get('AMPERAGE_L3');
        }
    }
}
