<app-current-voltage *ngIf="widget === 'current-voltage'"
                     [resizeEvent]="resizeEvent"
                     [Currentmeter]="Thing.controlCabinet.propertySets.get('CURRENTMETER')">
</app-current-voltage>

<app-sum-of-power *ngIf="widget === 'sum-of-power'"
                  [resizeEvent]="resizeEvent"
                  [Currentmeter]="Thing.controlCabinet.propertySets.get('CURRENTMETER')">
</app-sum-of-power>

<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung VCV' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung VCV' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>

<app-status *ngIf="widget === 'status'"
            [resizeEvent]="resizeEvent"
            [Status]="Thing.propertySets.get('STATUS')">
</app-status>


<app-cm-sensors *ngIf="widget === 'cm-mdw'"
                [resizeEvent]="resizeEvent"
                [Error]="CmError"
                [Type]="'MDW'"
                [Rotation]="Thing.propertySets.get('DS_ROT')"
                [AngularVelocity]="Thing.propertySets.get('DS_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('DS_ACL')"
                [Environment]="Thing.propertySets.get('DS_ENV')">
</app-cm-sensors>

<app-cm-sensors-two *ngIf="widget === 'cm-mdw-two'"
                    [resizeEvent]="resizeEvent"
                    [Type]="''"
                    [Environment]="Thing.propertySets.get('DS_ENV')"
                    [AngularVelocity]="Thing.propertySets.get('DS_ANGVEL')"
                    [Acceleration]="Thing.propertySets.get('DS_ACL')">
</app-cm-sensors-two>

<app-distance-vertical *ngIf="widget === 'distance-vertical'"
                       [resizeEvent]="resizeEvent"
                       [VerticalDistance]="Thing.propertySets.get('VERTICAL_DISTANCE')">
</app-distance-vertical>

<app-roll-in-out *ngIf="widget === 'roll-in-out'"
                 [resizeEvent]="resizeEvent"
                 [Statistic]="Thing.propertySets.get('STATISTICS')">
</app-roll-in-out>
