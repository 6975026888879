import {Component, OnInit} from '@angular/core';
import {HelpPageComponent} from '../../help-page.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-help-page-shortcut',
    templateUrl: './help-page-shortcut.component.html',
    styleUrls: ['./help-page-shortcut.component.scss', '../../helpPageModule.scss']
})
export class HelpPageShortcutComponent extends HelpPageComponent implements OnInit
{
    urlLanguage: string;

    constructor(public translate: TranslateService)
    {
        super(translate);
    }

    ngOnInit(): void
    {
        this.urlLanguage = this.translate.currentLang;
    }
}
