import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';

@Component({
    selector: 'app-cache',
    templateUrl: './cache.component.html',
    styleUrls: ['./cache.component.scss']
})
export class CacheComponent implements OnInit
{

    cachedList: Array<UserEntity> = [];
    selectedCustomer: string;

    constructor(private _http: HttpClient, public toastr: ToastrService, public dataProvider: DataProviderService)
    {
    }

    ngOnInit(): void
    {
        this.updateViewCache();
    }

    updateViewCache()
    {
        this._http.get('/backend/api/menu/cache').subscribe(value =>
        {
            console.log(this.dataProvider.customer);
            this.cachedList = value as Array<UserEntity>;
        });
    }

    updateThingMonitor()
    {
        this._http.get<any>('backend/api/thing-monitor/restart').subscribe(data =>
        {
        });
        this.toastr.info('Thingmonitor wird geupdatet. Dies dauert einige Sekunden.', null, {
            positionClass: 'toast-bottom-right',
            timeOut: 7000
        });
    }

    updateSingleThingMonitor(customer: string)
    {
        if (customer && customer !== '')
        {
            const url = 'backend/api/thing-monitor/restart';
            this._http.get<any>(url, {headers: {'customer': customer}}).subscribe(data =>
            {
            });
            this.toastr.info('Thingmonitor wird geupdatet. Dies dauert einige Sekunden.', null, {
                positionClass: 'toast-bottom-right',
                timeOut: 7000
            });
        }
        else
        {
            this.toastr.error('Bitte wählen sie einen Kunden aus.', null, {
                positionClass: 'toast-bottom-right',
                timeOut: 7000
            });
        }

    }

    clearAllMenu()
    {
        this._http.delete<any>('backend/api/menu/clearall').subscribe(data =>
        {
            this.updateViewCache();
            this.toastr.info('Cache für Menu wurde geleert.', null, {positionClass: 'toast-bottom-right'});
        });
    }

    clearSingleMenu(user: UserEntity)
    {
        this._http.delete<any>('backend/api/menu/cache', {headers: {'id': user.Id}}).subscribe(data =>
        {
            this.updateViewCache();
            this.toastr.info('Cache für ' + user.UserName + ' wurde geleert.', null, {positionClass: 'toast-bottom-right'});
        });
    }

}

// toDo Auslagern?
class UserEntity
{
    Email: string;
    FamilyName: string;
    GivenName: string;
    Id: string;
    UserName: string;
}
