import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-statistic',
    templateUrl: './statistic.component.html',
    styleUrls: ['./statistic.component.scss',
        './../card.scss']
})
export class StatisticComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{
    @Input() Statistic: PropertySet;

    RollIn: Property<any>;
    RollOut: Property<any>;
    Relocation: Property<any>;

    @ViewChild('cardWidth', {read: ElementRef}) cardWidth: ElementRef;


    constructor()
    {
        super('statistic');
    }

    onResize(result: any)
    {
        if (this.cardWidth != null)
        {
            const fontSizeWidth = this.cardWidth.nativeElement.offsetWidth * 0.1;

            this.fontsize = fontSizeWidth + 'px';
        }
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {

        if (this.Statistic == null) return;
        this.RollIn = this.Statistic.properties.get('ROLL_IN');
        this.RollOut = this.Statistic.properties.get('ROLL_OUT');
        this.Relocation = this.Statistic.properties.get('RELOCATION');
    }

}
