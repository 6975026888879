<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung DTM Modul' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung DTM Modul' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>


<app-temperature-current *ngIf="widget === 'temperature-current'"
                         [resizeEvent]="resizeEvent"
                         [Motor]="Thing.propertySets.get('MOTOR')">
</app-temperature-current>

<app-ssdtm-info *ngIf="widget === 'ssdtm-info'"
                [resizeEvent]="resizeEvent"
                [Motor]="Thing.propertySets.get('MOTOR')"
                [LedPanel]="Thing.propertySets.get('LED_PANEL')">
</app-ssdtm-info>

<app-ssdtm-lubrication-general *ngIf="widget === 'ssdtm-lubrication-general'"
                               [resizeEvent]="resizeEvent"
                               [Schmiersystem]="Thing.propertySets.get('SCHMIERSYSTEM')">
</app-ssdtm-lubrication-general>

<app-ssdtm-lubrication-cartridge *ngIf="widget === 'ssdtm-lubrication-cartridge'"
                                 [resizeEvent]="resizeEvent"
                                 [Schmiersystem]="Thing.propertySets.get('SCHMIERSYSTEM')">
</app-ssdtm-lubrication-cartridge>

<app-ssdtm-clamping-general *ngIf="widget === 'ssdtm-clamping-general'"
                            [resizeEvent]="resizeEvent"
                            [Spannsystem]="Thing.propertySets.get('SPANNSYSTEM')">
</app-ssdtm-clamping-general>


<app-ssdtm-clamping-chain *ngIf="widget === 'ssdtm-clamping-chain'"
                          [resizeEvent]="resizeEvent"
                          [Spannsystem]="Thing.propertySets.get('SPANNSYSTEM')">
</app-ssdtm-clamping-chain>
