import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BarTileComponent} from '../../view/tile/bar-tile/bar-tile.component';
import {CustomTileComponent} from '../../view/tile/custom-tile/custom-tile.component';
import {GaugeTileComponent} from '../../view/tile/gauge-tile/gauge-tile.component';
import {ImageTileComponent} from '../../view/tile/image-tile/image-tile.component';
import {LineTileComponent} from '../../view/tile/line-tile/line-tile.component';
import {PieTileComponent} from '../../view/tile/pie-tile/pie-tile.component';
import {ProgressTileComponent} from '../../view/tile/progress-tile/progress-tile.component';
import {TextTileComponent} from '../../view/tile/text-tile/text-tile.component';
import {ChartsModule} from './charts.module';
import {PipesModule} from './pipes.module';
import {ProgressBarModule} from './progress-bar.module';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CalculatedTestdriveTileComponent} from '../../view/tile/calculated-testdrive-tile/calculated-testdrive-tile.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        TextTileComponent,
        LineTileComponent,
        BarTileComponent,
        PieTileComponent,
        GaugeTileComponent,
        ImageTileComponent,
        CustomTileComponent,
        ProgressTileComponent,
        CalculatedTestdriveTileComponent,
    ],
    imports: [
        CommonModule,
        ChartsModule,
        PipesModule,
        ProgressBarModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        TranslateModule,
        RouterModule,
    ],
    exports: [
        TextTileComponent,
        LineTileComponent,
        BarTileComponent,
        PieTileComponent,
        GaugeTileComponent,
        ImageTileComponent,
        CustomTileComponent,
        ProgressTileComponent,
        CalculatedTestdriveTileComponent,
    ]
})
export class TileModule
{
}
