import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GridsterItem} from 'angular-gridster2';
import {environment} from 'environments/environment';
import {AuthenticationService} from './authentication.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService
{

    private url = environment.apiUrl + environment.apiVersion + '/user/layout';

    constructor(private http: HttpClient, private auth: AuthenticationService)
    {
    }

    getLayout(thingId: string)
    {
        return this.http.get(this.url + '?thingId=' + thingId, {responseType: 'json'});
    }

    async setLayout(thingId: string, azureId: string, layout: Array<GridsterItem>): Promise<any>
    {
        const reqBody = {
            thingId: thingId,
            azureId: azureId,
            layout: layout
        };

        const headers = await this.auth.getDefaultHttpHeaders();

        return firstValueFrom(this.http.put(this.url, reqBody, {headers: headers}));
    }

    deleteLayout(azureId: string, thingId: string)
    {
        const options = {
            headers: new HttpHeaders({'Content-Type': 'application/json'}),
            body: {
                azureId: azureId,
                thingId: thingId
            }
        };

        return this.http.delete(this.url, options);
    }
}
