<mat-card
    class="card_block"
>
    <mat-card-content class="card_content">
        <h4>{{'Health Details' | translate}}</h4>
    </mat-card-content>

    <mat-card-content class="card_content">
        <div>
            <h6>{{'(Click to View)' | translate}})</h6>
        </div>
    </mat-card-content>
</mat-card>
