import {Component, OnDestroy, OnInit} from '@angular/core';
import {Gateway} from '../../../core/model/gateway.model';
import {HttpClient} from '@angular/common/http';
import {MonitoringGatewayService} from '../../../core/controller/services/monitoring-gateway.service';
import {NavigationService} from '../../../core/controller/services/navigation.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {MonitoringGatewayV2Service} from '../../../core/controller/services/monitoring-gateway.v2.service';
import {GatewayV2} from '../../../core/model/gateway.v2.model';
import {AuthenticationService} from '../../../core/controller/services/authentication.service';

@Component({
    selector: 'app-monitoring-gateway',
    templateUrl: './monitoring-gateway.component.html',
    styleUrls: ['./monitoring-gateway.component.scss', '../../../core/view/monitoring/monitoring.scss']
})
export class MonitoringGatewayComponent implements OnInit, OnDestroy
{
    constructor(private _http: HttpClient, private auth: AuthenticationService, public gatewayServiceV2: MonitoringGatewayV2Service, public nav: NavigationService, public toastr: ToastrService, private router: Router)
    {
    }

    async ngOnInit(): Promise<void>
    {
        // await this.auth.checkAndSetActiveAccount();
        const auth_headers = await this.auth.getDefaultHttpHeaders();
        await this.gatewayServiceV2.initialize(auth_headers);

        console.log('Open Gateways');
        if (this.gatewayServiceV2.UpdateGatewayList == null) return;
        await this.gatewayServiceV2.UpdateGatewayList();
        console.log(this.gatewayServiceV2.gateways);
        // this.gatewayServiceV2.startPullTimer();
    }

    ngOnDestroy(): void
    {
        this.gatewayServiceV2.gateways = [];
        // this.gatewayServiceV2.stopPullTimer();
    }

    gotoGateway(gateway: GatewayV2)
    {
        console.log('DEBUG', gateway);
        this.router.navigate(['/gateway/' + gateway.device._id]);
    }

    // getGateways(): void
    // {
    //     let that = this;
    //     this._http.get<Array<Gateway>>('/backend/api/gateway').subscribe(
    //         gateways =>
    //         {
    //             // that.gateways = gateways;
    //             gateways.forEach(gateway =>
    //             {
    //                 that.gateways.push(gateway);
    //             });
    //         });
    // }
}
