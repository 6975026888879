import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss',
        './../card.scss']
})
export class OrdersComponent extends BaseViewElement implements OnInit, OnChanges
{

    @Input() Orders: PropertySet;

    OrdersHour: Property<any>;
    OrdersTotal: Property<any>;


    constructor()
    {
        super('orders');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Orders != null)
        {
            if (this.Orders.properties.get('ORDERS_HOURS') == null)
            {
                this.OrdersHour = this.Orders.properties.get('ORDERS_PER_H');
                this.OrdersTotal = this.Orders.properties.get('ORDERS_TOTAL');
            }
            else
            {
                this.OrdersHour = this.Orders.properties.get('ORDERS_HOURS');
                this.OrdersTotal = this.Orders.properties.get('ORDERS_TOTAL');
            }
        }

    }
}
