<div class="tile" [ngClass]="{'errorTile' : error}">

    <div class="tileHead">
        {{heading}}
        <div class="unit">
            {{unit}}
        </div>
    </div>

    <div class="tileBody">
        <div *ngIf="value[0] != null; else placeholdBar">
            <app-barchart [value]='value'
                          [setOptions]="options">
            </app-barchart>
        </div>
    </div>
</div>

<ng-template #placeholdBar>
    <app-placehold-barchart></app-placehold-barchart>
</ng-template>