import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-fts-general',
    templateUrl: './fts-general.component.html',
    styleUrls: ['./fts-general.component.scss',
        './../card.scss']
})
export class FtsGeneralComponent extends BaseViewElement implements OnInit, OnChanges
{

    @Input() FTS_ALLGEMEIN: PropertySet;

    ANZAHL_NOT_STOPPS: Property<any>;
    DC_LASTTRENNSCHALTER_SCHALTSPIELE: Property<any>;
    LEISTUNGSVERSTAERKER_DREHZAHL: Property<any>;
    GESCHWINDIGKEIT: Property<any>;

    constructor()
    {
        super('fts-general');
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.FTS_ALLGEMEIN != null)
        {
            this.ANZAHL_NOT_STOPPS = this.FTS_ALLGEMEIN.properties.get('ANZAHL_NOT_STOPPS');
            this.DC_LASTTRENNSCHALTER_SCHALTSPIELE = this.FTS_ALLGEMEIN.properties.get('DC_LASTTRENNSCHALTER_SCHALTSPIELE');
            this.LEISTUNGSVERSTAERKER_DREHZAHL = this.FTS_ALLGEMEIN.properties.get('LEISTUNGSVERSTAERKER_DREHZAHL');
            this.GESCHWINDIGKEIT = this.FTS_ALLGEMEIN.properties.get('GESCHWINDIGKEIT');
        }
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

}

