<ng-container
>
    <h2>
    <span>
        <em>{{ data['mode'] }}</em>
    </span>
        <span style="float: right">
            <button mat-button (click)="closeModal()">
                <mat-icon>close</mat-icon>
            </button>
        </span>
    </h2>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <table class="table caption-top">
                    <caption>
                        {{ data['mode'] }}:
                        {{ data['mode'] === "WARNING" ? 'Verschlechterung der Health bis zur Warnstufe':'Verschlechterung der Health auf KRITISCHES Niveau' }}
                    </caption>
                    <thead>
                        <tr scope="col">
                            <th>{{'Entity' | translate}}</th>
                            <th>{{'Entity Typ' | translate}}</th>
                            <th>{{'Entity Beschribung' | translate}}</th>
                            <th>{{'Messgroesse' | translate}}</th>
                            <th>{{'Live Value' | translate}}</th>
                            <th>{{'Grenzwert' | translate}}</th>
                            <th>{{'Health Nachricht' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tableRow of data['iData']['alerting_obj']">
                            <th scope="row">
                                <button mat-button class="mat-button-bold" (click)="onWarnerClick(tableRow)">
                                    {{
                                    translate.currentLang === 'en'?tableRow.subentity_name_en:
                                        translate.currentLang === 'de' ? tableRow.subentity_name:
                                            tableRow.subentity_name
                                        | translate
                                    }}
                                </button>
<!--                                <div>{{tableRow | json}}</div>-->
                            </th>
                            <td>{{tableRow.subentity_type | translate}}</td>
                            <th>{{tableRow.subentity_instance_description | translate}}</th>
                            <td>{{tableRow.messgroessen_name | translate}}</td>
                            <td>{{tableRow.live_value | translate}}</td>
                            <td>{{tableRow.momentaner_grenzwert | translate}}</td>
                            <td>
                                {{tableRow.health_percentage}}% :
                                {{
                                (data['mode'] === "WARNING" ?
                                    'Unterhalb der Warnstufe' :
                                    'Unterhalb der Stufe CRITICAL')
                                        | translate
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-container>
