import {CustomerDetail} from './customer-detail.model';
import {CLocation} from './location.model';
import {Thing} from './thing.model';

export class Company extends CustomerDetail
{
    public id: string;
    public locations = new Map<string, CLocation>();
    public LogoPath: string;

    public type = 'COMPANY';

    constructor(pId: string, pCompanyName: string, pLogoPath: string)
    {
        super();
        this.id = pId;
        this.name = pCompanyName;
        this.LogoPath = pLogoPath;

        this.getOutdatedProperties();
    }

    /**
     * Gibt alle things einer Company zurück
     */
    public getAllThings(): Array<Thing>
    {
        const allThings = [];

        this.locations.forEach(location =>
        {
            location.systems.forEach(system =>
            {
                allThings.push(...system.getAllThings());
            });
        });


        return allThings;
    }

    public getOutdatedProperties()
    {
        this.getAllThings().forEach(thing =>
        {
            thing.outdatedProperties.subscribe(x =>
            {
                if (x != null)
                {
                    this.errorCount = 0;
                    this.warningCount = 0;
                    this.infoCount = 0;

                    for (let i = 0; i < this.locations.size; i++)
                    {
                        this.errorCount += this.locations[i].errorCount;
                        this.warningCount += this.locations[i].warningCount;
                        this.infoCount += this.locations[i].infoCount;
                    }
                }
            });
        });
    }
}
