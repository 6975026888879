import {Component, Input} from '@angular/core';
import {DateService} from '../../../controller/services/date.service';
import {DataPoint} from '../../../model/helper-models/datapoint.model';
import {Tile} from '../tile';

@Component({
    selector: 'app-text-tile',
    templateUrl: './text-tile.component.html',
    styleUrls: ['./text-tile.component.scss',
        '../tile.scss']
})
/**
 * Klasse für Text und Zahlen
 */
export class TextTileComponent<T> extends Tile
{
    /**
     * Datenpunkt
     */
    @Input() datapoint: DataPoint<T>;

    /**
     * Kann anstelle von Datenpunkt übergeben werden, wenn ein Wert direkt übergeben werden soll.
     */
    @Input() directInput: any;

    /**
     * Kann zusätzlich befüllt werden um noch zusätzliche Informationen nach dem directInput anzuzeigen.
     */
    @Input() directInputRowTwo: any;

    /**
     * Wert der letzten Wartung
     */
    @Input() lastMaintenanceValue: number = null;

    /**
     * Zeitpunkt der letzten Wartung
     */
    @Input() lastMaintenanceTime: Date = null;

    /**
     * Historische Daten
     */
    @Input() historicValues: Array<Array<DataPoint<any>>>;

    /**
     * Legende der Historischen Daten
     */
    @Input() historicLegend: Array<string>;

    /**
     * Wert der seit letzter Wartung vergagen ist.
     * Aktueller Wert - Wert der letzten Wartung = Wert seit letzter Wartung
     */
    public deltaCurrentMaintenance: number;

    /**
     *
     * @param date
     */
    constructor(public date: DateService)
    {
        super();
    }
}
