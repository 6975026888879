<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>

<div class="container">
    <div>
        <h2>Leuze Scanner</h2>
        <div>
            <h3 class="subheading" id="LeuzeScannerInformationen">{{'Leuze Scanner Informationen' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/LSC/information_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">{{'Der Leuze Scanner ist an einem Loop befestigt. Er liest den Barcode jeder Box aus, welche eingelagert, ausgelagert und umgelagert wird. Auf jeder Box befindet sich ein eindeutiger Barcode.' | translate}}</li>
                <li class="list-group-item">
                    <strong>{{'Durchschnittliche Lesedauer (seit Auslieferung): ' | translate}}</strong>{{'Wie viele Millisekunden eine Barcodeauslesung durch den Scanner im Durchschnitt seit der ersten Benutzung benötigt werden.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Durchschnittliche Lesedauer (seit Reset): ' | translate}}</strong>{{'Wie viele Millisekunden eine Barcodeauslesung durch den Scanner im Durchschnitt seit der letzten Ausschaltung benötigt werden.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Status: ' | translate}}</strong>{{'Gibt den aktuellen Status des Scanners an: Scanning/Untätig/Error.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Serialnummer: ' | translate}}</strong>{{'Die Serialnummer des Scanners wird hier angegeben.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Gut-/Fehl-Lesungen (seit Reset): ' | translate}}</strong>{{'Der rote Bereich („Gut-Lesungen“) gibt an, wie viele Barcodes seit der letzten Ausschaltung gelesen werden konnten. Der graue Bereich („Fehl-Lesungen“) gibt an, wie viele Barcodes seit der letzten Ausschaltung nicht gelesen werden konnten.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Scan Qualität: ' | translate}}</strong>{{'Die Scan Qualität sollte im Optimalfall über 95% liegen. In dem Diagramm kann man die Scanqualität zu den ausgewählten Daten (Livedaten oder historische Daten) beobachten.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px; margin-bottom: 100px;">
            <h3 class="subheading" id="LetzterScan">{{'Letzter Scan' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/LSC/lastScan_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Start Position: '| translate}}</strong>{{'In welchem Winkel der Scanner anfängt zu Scannen.'| translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'End Position:'| translate}}</strong>{{' In welchem Winkel der Scanner aufhört zu Scannen.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Scan Qualität: ' | translate}}</strong>{{'Die durchschnittliche Scanqualität des Leuze Scanners wird festgehalten.' | translate}}
                </li>
            </ul>
        </div>
    </div>
</div>
