import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';
import {AuthenticationService} from '../../../core/controller/services/authentication.service';
import {Subscription} from 'rxjs';
import {Thing} from '../../../core/model/thing.model';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {environment} from '../../../../environments/environment';
import {Entity} from '../interfaces/entity';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {MatDialog} from '@angular/material/dialog';
import {MaterialHealthDialogComponent} from '../view/dialog/material-dialog-health/material-dialog.component';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {HierarchyDialogComponent} from '../view/dialog/hierarchy-dialog/hierarchy-dialog.component';
import {L_FlatNode} from '../interfaces/tree-interfaces';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatSort, Sort} from '@angular/material/sort';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-thing-health-test',
    templateUrl: './thing-health-v2.component.html',
    styleUrls: ['./thing-health-v2.component.scss'],

})
/**
 *  ThingsHealth Component V2: Newer and easier visualization
 *  (Adapts to instances schema and adapts to the frontend as a tree structure)
 *
 *  @constructor
 *  @injectable
 *  @param {ActivatedRoute} activatedRoute [private]
 *  @param {Router} router [private]
 *  @param {ToastrService} _toastr [private]
 *  @param {HttpClient} http [private]
 *  @param {DataProviderService} _data [private]
 *  @param {AuthenticationService} auth [public]
 *  @param {MatDialog} dialog [public]
 *  @return {ThingHealthV2Component} [scoped: app.package.platinum]
 * */
export class ThingHealthV2Component implements OnInit
{
    public routerSubscription: Subscription;
    public thing: Thing;
    public selection: SelectionModel<any>;
    public loadingBool: boolean =  true;
    public errorBool: boolean = false;
    public editMode: boolean = false;
    public globalEditMode: boolean = false;
    public editThresholdsArray: any[] = [];
    public selectedNode : L_FlatNode;
    public originalDataState: any;

    public error : string;


    // Data Hook binders
    public newTableData: any;
    public newEntityStructure: Entity[];

    /**
     * Check for the default language selected!
     * */
    public selectedLanguage: string;

    /**
     * Private function transforming an entity and a level into a flat dataPoint
     *
     * @param {Entity} node
     * @param {number} level
     * @return {Object}
     * */
    private _transformer = (node: Entity, level: number) => {


        let resetCounter = node.data.resetCounter;
        let worstHealthValue = node.data.worstHealthValue;
        let extendCounter = node.data.extendCounter;

        if (node.data.entity_type === 'bauteil'){
            const badComponent = node.children.reduce((prev, curr ) => {
                return prev.data.worstHealthValue < curr.data.worstHealthValue ?
                    prev : curr;
            });
            worstHealthValue = badComponent.data.worstHealthValue;

            extendCounter = node.children.reduce((prev, curr) => {
                return prev + curr.data.extendCounter;
            }, 0);

            const maxReplacedComponent = node.children.reduce((prev, curr ) => {
                return prev.data.resetCounter > curr.data.resetCounter ?
                    prev : curr;
            });
            resetCounter = maxReplacedComponent.data.resetCounter;
        }

        let messgroessen: any;
        if (node.data.messgroessen !== undefined){
            messgroessen = node.data.messgroessen.reduce((obj, item) => {
                obj[String(item.messgroessen_name).toLowerCase()] = {
                    richtung: item.richtung,
                    einheit: item.einheit,
                    live_value: item.live_value,
                    health_percentage: item.health_percentage,
                    momentaner_grenzwert: item.momentaner_grenzwert
                };
                return obj;
            }, {});
        }


        return {
            expandable: !!node.children && node.children.length > 0,
            name: this.getName(node, this.translate.currentLang),
            level: level,
            data: {...node.data, children: node.children},
            color: node.data.live_aggregation.alerting.length > 0 ? 'error':
                node.data.live_aggregation.warning.length > 0 ? 'warning':
                    'allcool',
            avg_health: node.data.live_aggregation.avg_health,
            reset_counter: resetCounter,
            worst_health_value: worstHealthValue,
            extend_counter: extendCounter,
            entity_type: node.data.entity_type,
            materialnummer: node.data.materialnummer,
            messgroessen: messgroessen
        };
    }

    /**
     * @type {FlatTreeControl<L_FlatNode>} treeControl [public] Controls the tree operations
     * */
    treeControl = new FlatTreeControl<L_FlatNode>(
        node => node.level,
        node => node.expandable,
    );

    /**
     * @type {MatTreeFlattener} treeFlattener [public] Material Tree data source flattener transforms data source to a flat tree format
     * */
    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children,
    );

    /**
     *  Expands all the tree nodes showing all available children
     *
     *  @return
     * */
    private expandAllTreeNodes(): void {
        this.treeControl.expandAll();
    }

    /**
     *  Collapses all the tree nodes hiding all available children
     *
     *  @return
     * */
    private collapseAllTreeNodes(): void{
        this.treeControl.collapseAll();
    }

    /**
     * @type {MatTreeFlatDataSource} dataSource [public] TreeControl render-capable flat data source
     * */
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


    /**
     * Flat Table columns
     * */
    viewColumns = [
        'name',
        'view_komponente',
        'materialnummer',
        'avg_health',
        'reset_counter',
        'worst_health_value',
        'extend_counter',
        'warning_button'
    ];

    /**
     * Editing Threshold Columns
     * */
    editModeColumns = [
        'name',
        'strecke',
        'intervall',
        'bewegungen'
    ];

    //Defualt Columns to Display
    displayColumns = this.viewColumns;

    /**
     * Return Edit Mode
     * */
    public getEditMode = () => {
      return this.editMode;
    }


    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private _toastr: ToastrService,
        private http: HttpClient,
        private _data: DataProviderService,
        public auth: AuthenticationService,
        public dialog: MatDialog,
        public translate: TranslateService
    )
    {
        this.selectedLanguage = this.translate.defaultLang;
        this.routerSubscription = router.events.subscribe(val =>
        {
            if (this.thing == null) return;

            if (val instanceof NavigationEnd){
                this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
                this.selection = new SelectionModel(true, []);
                this.loadingBool = true;
                this.errorBool = false;
            }
        });
    }

    /**
     * Checks if a node has a child or not based on the expandable status provided by the Flattener and Transformer function
     *
     * @param {number} _
     * @param {L_FlatNode} node
     * @return {boolean}
     * */
    hasChild = (_: number, node: L_FlatNode) => node.expandable;

    ngOnInit(): void
    {
        this.translate.onLangChange.subscribe((event)=>{
            location.reload();
        });
        this.thing = this._data.activeSystem.getAllThings().find(thing => thing.id === this.activatedRoute.snapshot.paramMap.get('id'));
        this.getNewHealthTableData();
    }


    private subscribeNodeSelection(node: L_FlatNode): void{
        this.selectedNode = node;
        // console.log(node);
    }

    public slideToggle($event) : boolean{
        this.editMode = !(this.editMode);
        console.log(this.editMode);
        return this.editMode;
    }

    /**
     * Gets the enhanced data information from backend and fills up following class scoped public variables
     *      -   this.newTableData : Older format of the data
     *      -   this.newEntityStructure : Entity structure in a Tree view
     *
     * @async
     * @return
     * */
    public async getNewHealthTableData()
    {
        this.loadingBool = true;
        this.errorBool = false;
        const headers = await this.auth.getDefaultHttpHeaders();

        const url = environment.apiUrl + environment.apiVersion + '/health/getSystemHealth' +
            '?CustomerId=' + this._data.activeCompany.id +
            '&LocationId=' + this._data.activeLocation.id +
            '&SystemId=' + this._data.activeSystem.id +
            '&ThingId=' + this.thing.id;
        this.http.get(url, {headers: headers}).subscribe(
            res =>
            {
                this.newTableData = res['gateway_health_data'];
                this.newEntityStructure = [res['entity_structure']];
                this.loadingBool = false;
                this.dataSource.data = this.newEntityStructure;
                this.originalDataState = this.newEntityStructure;
            },
            error1 => {
                this.errorBool = true;
                this.error = error1.statusText;
            }
            );
        this.loadingBool = false;
            //
            // this.errorBool = true;
            // this.error = String(e);

    }

    public handleNodeClick(node: L_FlatNode): void {
        this.selectedNode = node;
    }

    /**
     * [FUTURE]
     * Displays a link-point tree view in a dialog
     * NODE_A (O) ---> (O) NODE_B
     *
     * @access {HierarchyDialogComponent} Currently an empty component
     * @return
     * */
    openTreeViewHierarchy(): void {
        const dialogRef = this.dialog.open(HierarchyDialogComponent,
            {
                data:{
                    entity_structure: this.newEntityStructure
                },
                height: '90%',
                width: '90%'
            }
            );
    }

    /**
     * Opens a node-specific dialog component
     *
     * @access {MaterialHealthDialogComponent}
     * @param {L_FlatNode} node
     * @return
     * */
    openDialog(node: L_FlatNode) : void {

        const dialogRef = this.dialog.open(MaterialHealthDialogComponent,
            {
                data: {
                    name: `${node.data.entity_type.toUpperCase()}: ${node.name}`,
                    data: node.data,
                    iData: {
                        avg_health: new DataPoint(
                            node.data.live_aggregation.avg_health.toFixed(2),
                            0
                        ),
                        warning: node.data.live_aggregation.warning,
                        alerting: node.data.live_aggregation.alerting,
                    },

                },
                height: '80%',
                width: '60%'
            }
            );
        /**
         * Dialog Reference for after closed operations
         *
         * TODO: Resolve subscription pull from {MaterialHealthDialogComponent}
         *      Currently the subscription is not responding on the closure of {MaterialHealthDialogComponent}
         * */
        // dialogRef.afterClosed();
        dialogRef.afterClosed().subscribe(d_results => {
            if(d_results){
                if (d_results.sender === 'HEALTH'){
                    this.selectedNode = d_results.result;
                }
                console.log(d_results);
            }
        });
    }

    private getName = (
        node: Entity,
        lang: string
        ): string => {
        const nameKey : string = `${node.data.entity_type}_name_${lang}`;
        const fallback_nameKey : string = `${node.data.entity_type}_name_de`;
        if(node.data[nameKey] === undefined) return node.data[fallback_nameKey];
        if(node.data[nameKey] !== undefined && node.data[nameKey] === "") return node.data[fallback_nameKey];

        return node.data[nameKey];
    };

    /**
     * Enables edit mode globally
     * @param {MatSlideToggleChange} event
     * @return
     * */
    enableEditMode(event: MatSlideToggleChange) : void{
        this.editMode = event.checked;
        this.editMode ? this.expandAllTreeNodes() : this.collapseAllTreeNodes();
        this.editMode ? this.pushEditModeColumns() : this.popEditModeColumns();
    }

    /**
     * Change the current Grenzwert values to Edited values
     * @return
     * */
    saveThresholds(){
        this.loadingBool = true;
        this.treeControl.dataNodes.forEach((node) => {
            if (node.data.editGrenzwert !== undefined){
                this.editThresholdsArray.push(...node.data.editGrenzwert);
            }
        });

        if (this.globalEditMode){
          this.updateThresholdsForThingType();
        } else {
            this.updateThresholdsForThing();
        }
        this.treeControl.collapseAll();
        this.loadingBool = false;
        this.editMode = false;
        this.globalEditMode = false;
    }

    /**
     * Update the Grenzwert for whole thing type
     * @async
     * @return
     * */
    public async updateThresholdsForThingType()
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const url = environment.apiUrl + environment.apiVersion + '/health/updateThresholdForThingType';
        const requestJson = {
            'SystemId': this._data.activeSystem.id,
            'ThingType': this.thing.type,
            'Thresholds': this.editThresholdsArray
        };
        this.http.post(url, requestJson, {headers: headers}).subscribe((res: any) =>
        {
            this.getNewHealthTableData();
            this.editThresholdsArray = [];
        });
    }

    /**
     * Update the threshold values for a specific thing
     * @async
     * @return
     * */
    public async updateThresholdsForThing()
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const url = environment.apiUrl + environment.apiVersion + '/health/updateThresholdForThing';
        const requestJson = {
            'SystemId': this._data.activeSystem.id,
            'ThingId': this.thing.id,
            'Thresholds': this.editThresholdsArray
        };
        this.http.post(url, requestJson, {headers: headers}).subscribe((res: any) =>
        {
            this.getNewHealthTableData();
            this.editThresholdsArray = [];

        });
    }

    /**
     * Format the rows for editing the values of the grenzwert and other option from the messgrossen information
     * @return
     * */
    public formatEditThresholdsArray()
    {
        this.editThresholdsArray = [];
        this.newTableData.forEach((row) =>
        {
            row.messgroessen.forEach((messgroesse) =>
            {
                const rowJson = {
                    'bauteil': row.bauteil_name_de,
                    'komponente': row.komponenten_name_de,
                    'messgroesse': messgroesse.messgroessen_name,
                    'grenzwert': messgroesse.momentaner_grenzwert,
                    'einheit': messgroesse.einheit
                };
                this.editThresholdsArray.push(rowJson);
            });
        });
    }

    private updateNodeNames(node: Entity, lang: string){
        if(node.children !== undefined) {
            node.name = this.getName(node, lang);
            node.children.forEach(child => this.updateNodeNames(child, lang));
        }
    }

    private sortByHealth(node: any, mode: string){
        if (node.children !== undefined){
            if (mode === 'asc'){
                node.children.sort((c_a, c_b) =>
                    c_a.data.live_aggregation.avg_health - c_b.data.live_aggregation.avg_health
                );
            }
            if (mode === 'desc'){
                node.children.sort((c_a, c_b) =>
                    c_b.data.live_aggregation.avg_health - c_a.data.live_aggregation.avg_health
                );
            }

        node.children.forEach(child => this.sortByHealth(child, mode));

        }
    }

    private sortDNByHealth(mode: string){
        if (mode === 'asc'){
            this.treeControl.dataNodes.sort((c_a, c_b) =>
                c_a.data.live_aggregation.avg_health - c_b.data.live_aggregation.avg_health
            );
        }
    }

    private sortByAustausch(node: any, mode: string){
        // 'reset_counter'
        if (node.children !== undefined){
            if (node.children[0].data.resetCounter !== undefined)
            {
                if (mode === 'asc')
                {
                    node.children.sort((c_a, c_b) =>
                        c_a.data.resetCounter - c_b.data.resetCounter
                    );
                }
                if (mode === 'desc')
                {
                    node.children.sort((c_a, c_b) =>
                        c_b.data.resetCounter - c_a.data.resetCounter
                    );
                }
            }
            node.children.forEach(child => this.sortByAustausch(child, mode));
        }
    }

    private sortByWartungen(node: any, mode: string){
        // 'extend_counter'
        if (node.children !== undefined){
            if (node.children[0].data.extendCounter !== undefined)
            {
                if (mode === 'asc')
                {
                    node.children.sort((c_a, c_b) =>
                        c_a.data.extendCounter - c_b.data.extendCounter
                    );
                }
                if (mode === 'desc')
                {
                    node.children.sort((c_a, c_b) =>
                        c_b.data.extendCounter - c_a.data.extendCounter
                    );
                }
            }
            node.children.forEach(child => this.sortByWartungen(child, mode));
        }
    }

    private sortBySchlechtesteHealth(node: any, mode: string){
        // 'worst_health_value',
        if (node.children !== undefined){
            if (node.children[0].data.worstHealthValue !== undefined)
            {
                if (mode === 'asc')
                {
                    node.children.sort((c_a, c_b) =>
                        c_a.data.worstHealthValue - c_b.data.worstHealthValue
                    );
                }
                if (mode === 'desc')
                {
                    node.children.sort((c_a, c_b) =>
                        c_b.data.worstHealthValue - c_a.data.worstHealthValue
                    );
                }
            }
            node.children.forEach(child => this.sortBySchlechtesteHealth(child, mode));
        }
    }

    prevExpansionModel: any;
    saveExpansionState(){
        this.prevExpansionModel = this.treeControl.expansionModel.selected;
    }

    expandLevelOne(){
        this.expandAllTreeNodes();
        this.treeControl.dataNodes.forEach((obj)=>{
            if (obj.level === 1) this.treeControl.collapse(obj);
        });
    }

    applyExpansionState(){
        this.expandLevelOne();
    }

    announceSortChange(sort: Sort){
        this.saveExpansionState();

        let sortingData = this.dataSource.data[0];

        if (sort.direction === '') {
            this.dataSource.data = this.originalDataState;
        }
        else {
            if (sort.active === 'avg_health') this.sortByHealth(sortingData, sort.direction);
            if (sort.active === 'reset_counter') {
                this.sortByAustausch(sortingData, sort.direction);
            }
            if (sort.active === 'extend_counter') this.sortByWartungen(sortingData, sort.direction);
            if (sort.active === 'worst_health_value') this.sortBySchlechtesteHealth(sortingData, sort.direction);

            this.dataSource.data = [sortingData];
        }
        this.applyExpansionState();
        console.log(this.prevExpansionModel);
    }

    private pushEditModeColumns()
    {
        this.displayColumns = this.editModeColumns;
    }

    private popEditModeColumns()
    {
        this.displayColumns = this.viewColumns;
    }

    public changeInWert(messgroesse_name: string, element: any){
        const rowJson = {
            'bauteil': element.data.bauteil_name_de,
            'komponente': element.data.komponenten_name_de,
            'messgroesse': messgroesse_name,
            'grenzwert': element.messgroessen[messgroesse_name].momentaner_grenzwert,
            'einheit': element.messgroessen[messgroesse_name].einheit
        };

        if (element.data['editGrenzwert'] === undefined){
            element.data['editGrenzwert'] = [rowJson];
        } else {
            element.data['editGrenzwert'].push(rowJson);
        }
    }


    ngAfterViewInit(): void
    {
        this.expandAllTreeNodes();
        this.expandLevelOne();
    }
}
