/**
 * Datenpunkt der in die Charts übergeben wird
 */
export class DataPoint<T>
{
    private _value: T;
    private _timestamp: Date;

    constructor(pValue: T, pTimestamp: any)
    {
        this.value = pValue;
        this.timestamp = pTimestamp;
    }

    public get value(): T
    {
        return this._value;
    }

    public set value(value: T)
    {
        this._value = value;
    }

    public get timestamp(): Date
    {
        return this._timestamp;
    }

    public set timestamp(value: Date)
    {
        this._timestamp = value;
    }
}
