<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>

<div class="container">
    <div>
        <h2>Multi Level Shuttle</h2>
        <div>
            <h3 class="subheading" id="Elektrische Leistung">{{'Elektrische Leistung' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/MLS/voltageCurrent_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Spannung: ' | translate}} </strong>{{'Zeigt die aktuelle elektrische Spannung des MLS an.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Strom: ' | translate}}</strong>{{'Zeigt die aktuelle elektrische Stromstärke des MLS an.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="SummeDerLeistungsdaten">{{'Summe der Leistungsdaten' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/MLS/sumOfPower_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Wirkleistung: '| translate}}</strong>{{'Die Wirkleistung ist die Energie, die tatsächlich wirkt und in eine andere Energieform (z.B. Bewegungsenergie) umgewandelt werden kann.'| translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Blindleistung: '| translate}}</strong>{{'Die Blindleistung befindet sich in Transformatoren und Kondensatoren, um Wirkleistung zu übertragen.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Scheinleistung: ' | translate}}</strong>{{'Die Scheinleistung ist die geometrische Summe aus Wirk- und Blindleistung.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Energieverbrauch">{{'Energieverbrauch' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/MLS/energyConsumption_{{urlLanguage}}.png"
                 width="100%">
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Distanz">{{'Distanz' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/MLS/distance_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Horizontale Strecke: ' | translate}}</strong>{{'Strecke, die das MLS horizontal zurückgelegt hat (in km).' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Vertikale Strecke: ' | translate}}</strong>{{'Strecke, die das MLS vertikal zurückgelegt hat (in km).' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Teleskop Strecke: ' | translate}}</strong>{{'Strecke, die das Teleskop zurückgelegt hat (in km).' | translate}}
                </li>

            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Statistik">{{'Statistik' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/MLS/statistic_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Einlagerungen: ' | translate}}</strong>{{'Anzahl der Einlagerungen der Boxen.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Auslagerungen: ' | translate}}</strong>{{'Anzahl der Auslagerungen der Boxen (z.B. gelagerte Teile werden benötigt oder entsorgt).' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Umlagerungen: ' | translate}}</strong>{{'Anzahl der Umlagerungen der Boxen (z.B. oft genutzte Boxen näher zum Ausgang).' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Status">{{'Status' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/MLS/status_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Betriebart: ' | translate}}</strong>{{'Läuft das MLS manuell oder automatisch?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Betriebsstunden: ' | translate}}</strong>{{'Wie lange ist das MLS schon seit Inbetriebnahme/ Wartung in Betrieb?' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px; margin-bottom: 100px;">
            <h3 class="subheading" id="CmSensorDaten">{{'CM Sensor Daten' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/MLS/cmSensor_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px; margin-bottom: 100px;">
                <li class="list-group-item">{{'Bei einem Multi-Level-Shuttle befinden sich die Condition Monitoring Sensoren am Fahrkorb, am Must unten und am Mast oben.' | translate}}</li>
            </ul>
        </div>
    </div>
</div>

