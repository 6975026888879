import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-distance',
    templateUrl: './distance.component.html',
    styleUrls: ['./distance.component.scss',
        './../card.scss']
})
export class DistanceComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{

    @Input() HorizontalDistance: PropertySet;
    @Input() VerticalDistance: PropertySet;
    @Input() TelescopeDistance: PropertySet;

    HorizontalKilometer: Property<any>;
    HorizontalMeter: Property<any>;
    VerticalKilometer: Property<any>;
    VerticalMeter: Property<any>;
    TelescopeKilometer: Property<any>;
    TelescopeMeter: Property<any>;

    constructor()
    {
        super('distance');
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.HorizontalDistance != null)
        {
            this.HorizontalKilometer = this.HorizontalDistance.properties.get('KILOMETER');
            this.HorizontalMeter = this.HorizontalDistance.properties.get('METER');
        }

        if (this.VerticalDistance != null)
        {
            this.VerticalKilometer = this.VerticalDistance.properties.get('KILOMETER');
            this.VerticalMeter = this.VerticalDistance.properties.get('METER');
        }

        if (this.TelescopeDistance != null)
        {
            this.TelescopeKilometer = this.TelescopeDistance.properties.get('KILOMETER');
            this.TelescopeMeter = this.TelescopeDistance.properties.get('METER');
        }
    }


    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

}
